import React, { useCallback, useContext, useState } from "react";
import { Flex, NoSSR, NotificationBadge, Text } from "@catchoftheday/cg-components";
import { MOBILE_BOTTOM_NAV_HEIGHT } from "@header/consts";
import { BOTTOM_NAV } from "../consts";
import { AccountMenu } from "./AccountMenu";
import { CategoryMenu } from "./CategoryMenu";
import { MobileMenuContext } from "./MobileMenuContext";
import { MobileNavItem } from "./MobileNavItem";
import { jsx as ___EmotionJSX } from "@emotion/react";
var CATEGORY_MENU = "category_menu";
var ACCOUNT_MENU = "account_menu";
export var MobileNav = function MobileNav() {
  var _useContext = useContext(MobileMenuContext),
      _useContext$wishlistI = _useContext.wishlistItemsCount,
      wishlistItemsCount = _useContext$wishlistI === void 0 ? 0 : _useContext$wishlistI,
      _useContext$cartItems = _useContext.cartItemsCount,
      cartItemsCount = _useContext$cartItems === void 0 ? 0 : _useContext$cartItems;

  var _useState = useState(false),
      showCategoryMenu = _useState[0],
      setShowCategoryMenu = _useState[1];

  var _useState2 = useState(false),
      showAccountMenu = _useState2[0],
      setShowAccountMenu = _useState2[1];

  var toggleMenuVisibility = useCallback(function (menuId) {
    if (!menuId) return;

    if (menuId === CATEGORY_MENU) {
      setShowCategoryMenu(function (prevState) {
        return !prevState;
      });
      setShowAccountMenu(false);
    } else if (menuId === ACCOUNT_MENU) {
      setShowCategoryMenu(false);
      setShowAccountMenu(function (prevState) {
        return !prevState;
      });
    }
  }, []);
  var isActive = useCallback(function (navItem) {
    var isOnActivePage = true && window.location.pathname !== "#" && window.location.pathname === navItem.url;
    var isCategoryOrAccountMenu = navItem.title === "Categories" && showCategoryMenu || navItem.title === "Account" && showAccountMenu;
    return isCategoryOrAccountMenu || !showCategoryMenu && !showAccountMenu && isOnActivePage;
  }, [showCategoryMenu, showAccountMenu]);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Flex, {
    "aria-label": "Mobile navigation",
    as: "nav",
    flexDirection: "row",
    position: "fixed",
    bottom: "0px",
    bg: "ui.backgroundColor",
    width: "100%",
    height: "calc(".concat(MOBILE_BOTTOM_NAV_HEIGHT, "px + env(safe-area-inset-bottom))"),
    minHeight: MOBILE_BOTTOM_NAV_HEIGHT,
    pb: "env(safe-area-inset-bottom)",
    zIndex: 51,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px -1px 0px #DDDDDD"
  }, BOTTOM_NAV.map(function (navItem, index) {
    return ___EmotionJSX(MobileNavItem, {
      "aria-label": "Mobile ".concat(navItem.title),
      key: "".concat(navItem.title, "-").concat(index),
      url: navItem.url,
      isActive: isActive(navItem),
      onClick: function onClick() {
        return toggleMenuVisibility(navItem.title === "Categories" ? CATEGORY_MENU : navItem.title === "Account" ? ACCOUNT_MENU : undefined);
      }
    }, navItem.title === "Cart" && cartItemsCount > 0 && ___EmotionJSX(NoSSR, null, ___EmotionJSX(NotificationBadge, {
      "aria-label": "Cart item count",
      text: cartItemsCount,
      top: "2px",
      left: "calc(50% + 7px)",
      absRight: "auto",
      lineHeight: "14px",
      minWidth: "auto",
      fontSize: "2xs",
      padding: "0 2px"
    })), navItem.title === "Wishlist" && wishlistItemsCount > 0 && ___EmotionJSX(NoSSR, null, ___EmotionJSX(NotificationBadge, {
      "aria-label": "Wishlist item count",
      text: "",
      top: "4px",
      left: "calc(50% + 4px)",
      height: "10px",
      width: "10px",
      minWidth: "auto"
    })), isActive(navItem) ? ___EmotionJSX(navItem.activeIcon, {
      width: "20px",
      height: "20px",
      title: navItem.title
    }) : ___EmotionJSX(navItem.icon, {
      width: "20px",
      height: "20px"
    }), ___EmotionJSX(Text, {
      m: "0px",
      fontWeight: "medium",
      fontSize: "xs"
    }, navItem.title));
  })), ___EmotionJSX(CategoryMenu, {
    isOpen: showCategoryMenu,
    toggleMenu: function toggleMenu() {
      return toggleMenuVisibility(CATEGORY_MENU);
    }
  }), ___EmotionJSX(AccountMenu, {
    isOpen: showAccountMenu
  }));
};