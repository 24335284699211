export var HEADER_META_DATA_API = "/header/meta.json";
export var HEADER_MINICART_API = "/header/cart.json";
export var HEADER_MINICART_REMOVE_ITEM_API = "/header/remove/cart.json";
export var HEADER_MY_ACCOUNT_API = "/header/account.json";
export var HEADER_WISHLIST_API = "/header/wishlist.json";
export var HEADER_WISHLIST_REMOVE_ITEM_API = "/header/remove/wishlist.json";
export var HEADER_LOYALTY_API = "/header/loyalty.json";
export var HEADER_COUPONS_API = "/header/coupons.json";
export var HEADER_XMAS_HAT = "https://s.catch.com.au/static/catch/images/xmas-hat-cap.png";
export var HEADER_XMAS_POMPOM = "https://s.catch.com.au/static/catch/images/xmas-hat-pompom.png";
export var HEADER_XMAS_TILE = "https://s.catch.com.au/static/catch/images/xmas-tile.png";
export var DESKTOP_HEADER_HEIGHT = 127;
export var NOTIFICATION_TOAST_CONTAINER_ID = "notification-toast";
/** Flash Banner Messages */

export var FLASH_BANNER_MIGRATION_SUCCESS_MSG = "Your new OnePass account is all set! Look out for an email from OnePass with more details.";
export var FLASH_BANNER_MIGRATION_SUCCESS_DOUBLE_SUB_MSG = "Your new OnePass account is all set!";
export var FLASH_BANNER_MIGRATION_ERROR_MSG = "Something went wrong. Please call our customer service at 1300 222 824";
export var FLASH_BANNER_NOT_ELIGIBLE_FOR_ONEPASS_MIGRATION_MSG = "The email used in this account is not eligible for transfer. If you originally signed up with Club Catch try using that email to sign in.";
export var FLASH_BANNER_EMAIL_VERIFIED_LOGGED_IN = "Your email address was successfully verified, enjoy shopping with Catch!";
/** Query params used for flash banners */

export var GLOBAL_NOTICE_QUERY_PARAM = "global_notice";
export var GLOBAL_NOTICE_TYPE_QUERY_PARAM = "global_notice_type";
export var EMAIL_VERIFICATION_NOTICE_QUERY_PARAM = "emailVerified";
/** Header layout constants */

export var STICKY_HEADER_HEIGHT_DESKTOP = 145;
export var STICKY_HEADER_HEIGHT_MOBILE = 70;
export var MOBILE_BOTTOM_NAV_HEIGHT = 48;
/** Enhanced Search */

export { MAX_ENHANCED_SEARCH_PRODUCTS_IN_VIEW } from "../components/SearchBar/consts"; // Do not display Trending Searches ticker if page path contains any of the following substrings

export var PAGES_TICKER_EXCLUDED_ON = ["product", "checkout", "cart"];
/** Footer */

export var DEFAULT_ERROR_MESSAGE_SUBSCRIBE_NEWSLETTER_PREFERENCE = "Hmm. Something went wrong and we can't subscribe you right now. Please try again later.";
/** Onepass notification popup */

export var URL_CATCH_HELP_CENTRE_SUBMIT_REQUEST = "https://help.catch.com.au/s/article-display?id=ka0IT000000YH3vYAG&catName=My_Account";
export var URL_ONEPASS_HELP_CENTRE_SUBMIT_REQUEST = "https://support.onepass.com.au/s/customer-support";
/** Constructor */

export var CONSTRUCTOR_CLIENT_ID = "ConstructorioID_client_id";
export var CONSTRUCTOR_SESSION_ID = "ConstructorioID_session_id";