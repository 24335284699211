import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import Head from "next/head";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MetaTags = function MetaTags(_ref) {
  var tags = _ref.tags,
      withHead = _ref.withHead;
  var Tags = tags.map(function (tag, index) {
    return ___EmotionJSX("meta", _extends({
      key: "".concat(Object.keys(tag)[0], "-").concat(index)
    }, tag));
  });

  if (withHead) {
    return ___EmotionJSX(Head, null, Tags);
  }

  return ___EmotionJSX(React.Fragment, null, Tags);
};