import React from "react";
import Head from "next/head";
import { capitaliseFirstLetter } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MetaTitle = function MetaTitle(_ref) {
  var ssrHost = _ref.host,
      children = _ref.children;
  var isClient = true;

  if (!ssrHost && !isClient) {
    return null;
  }

  var host = ssrHost || window.location.host || "";
  var cleanHost = host.replace(/(www|m)\./, "");
  var formattedHost = capitaliseFirstLetter(cleanHost);

  if (typeof children === "function") {
    return ___EmotionJSX(Head, null, ___EmotionJSX("title", null, children(formattedHost)));
  }

  return ___EmotionJSX(Head, null, ___EmotionJSX("title", null, "".concat(children, " | ").concat(formattedHost)));
};