import { useMedia } from "@catchoftheday/theme";
var defaultSlidesPerBreakpoint = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5
};
export var getRecommenderTilesPerBreakpoint = function getRecommenderTilesPerBreakpoint(tilesPerBreakpoint) {
  return [(tilesPerBreakpoint === null || tilesPerBreakpoint === void 0 ? void 0 : tilesPerBreakpoint.xl) || defaultSlidesPerBreakpoint.xl, (tilesPerBreakpoint === null || tilesPerBreakpoint === void 0 ? void 0 : tilesPerBreakpoint.lg) || defaultSlidesPerBreakpoint.lg, (tilesPerBreakpoint === null || tilesPerBreakpoint === void 0 ? void 0 : tilesPerBreakpoint.md) || defaultSlidesPerBreakpoint.md, (tilesPerBreakpoint === null || tilesPerBreakpoint === void 0 ? void 0 : tilesPerBreakpoint.sm) || defaultSlidesPerBreakpoint.sm, (tilesPerBreakpoint === null || tilesPerBreakpoint === void 0 ? void 0 : tilesPerBreakpoint.xs) || defaultSlidesPerBreakpoint.xs];
};
export var useVisibleRecommenderTiles = function useVisibleRecommenderTiles(tilesPerBreakpoint) {
  return useMedia(["(min-width: 1280px)", "(min-width: 1024px)", "(min-width: 768px)", "(min-width: 320px)", "(min-width: 0px)"], getRecommenderTilesPerBreakpoint(tilesPerBreakpoint)) || 0;
};