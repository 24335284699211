import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchForm = function SearchForm(props) {
  return ___EmotionJSX("form", _extends({
    role: "search",
    style: {
      display: "flex",
      width: "100%"
    },
    autoComplete: "off",
    "data-cnstrc-search-form": true
  }, props));
};