import React from "react";
import { Box, Flex, IconOnePass, ImageResponsive, Span, Text } from "@catchoftheday/cg-components";
import { APP_DOWNLOAD_QR_IMG } from "../../../consts/assets";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AppNudge = function AppNudge() {
  return ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "100%",
    mx: {
      xs: 2,
      md: 3
    }
  }, ___EmotionJSX(Span, {
    fontWeight: 600,
    fontSize: "2xl",
    lineHeight: "normal",
    mt: 0,
    mb: 3
  }, "Coming soon ", ___EmotionJSX("br", null), "to\xA0", ___EmotionJSX(IconOnePass, {
    color: "onePass.primary",
    height: "1em",
    width: "auto",
    verticalAlign: "unset"
  })), ___EmotionJSX(Text, {
    fontWeight: "semibold",
    m: 0
  }, "New deals launch every Friday at 2pm."), ___EmotionJSX(Text, {
    mt: 0
  }, "Download the Catch App today to be the first to know when the deals go live!"), ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "center"
  }, ___EmotionJSX(Box, {
    borderRadius: "default",
    borderColor: "ui.borderColor",
    border: 1,
    overflow: "hidden",
    backgroundColor: "ui.backgroundColorSecondary",
    p: 3,
    width: "120px",
    height: "120px"
  }, ___EmotionJSX(ImageResponsive, {
    src: APP_DOWNLOAD_QR_IMG,
    sourceWidths: [100, 200, 300, 400],
    width: "100",
    height: "100",
    alt: "Catch App QR Code",
    ratio: 100,
    lazyLoad: true
  })), ___EmotionJSX(Span, {
    fontSize: "xs"
  }, "Scan to download")));
};