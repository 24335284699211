import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isOnePass", "icon", "desc"];
import React from "react";
import { Box, Flex, Text } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BannerTile = function BannerTile(_ref) {
  var isOnePass = _ref.isOnePass,
      icon = _ref.icon,
      desc = _ref.desc,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var theme = useTheme();
  return ___EmotionJSX(Box, _extends({
    width: {
      xs: "100%",
      lg: "33.33%"
    },
    margin: {
      xs: 1,
      lg: 3
    },
    padding: {
      xs: 4,
      md: 6,
      lg: 0
    },
    borderColor: "ui.borderColor",
    border: {
      xs: 1,
      lg: "none"
    },
    borderRadius: "default",
    overflow: "visible",
    background: {
      xs: isOnePass ? theme.colors.onePass.primary : "none",
      lg: "none"
    }
  }, boxProps), ___EmotionJSX(Flex, {
    alignItems: {
      xs: "center",
      lg: "flex-start"
    },
    flexDirection: {
      xs: "row",
      lg: "column"
    }
  }, ___EmotionJSX(Flex, {
    marginBottom: {
      xs: 0,
      lg: 3
    },
    marginRight: {
      xs: 4,
      lg: 0
    },
    width: {
      xs: "30%",
      md: "40%",
      lg: "100%"
    },
    flexGrow: 0,
    flexShrink: 0,
    alignItems: "center",
    justifyContent: {
      xs: "center",
      lg: "flex-start"
    },
    height: {
      xs: "auto",
      lg: "45px"
    }
  }, icon), ___EmotionJSX(Text, {
    fontWeight: "semibold",
    margin: 0,
    color: {
      xs: isOnePass ? "textWhite" : "text",
      lg: "text"
    },
    fontSize: {
      xs: "sm",
      md: "lg",
      lg: "base"
    }
  }, desc)));
};