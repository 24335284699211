import { createSvgIconComponent } from "../utils";
export var IconOnePassRibbon = createSvgIconComponent({
  paths: [{
    d: "M23.677 28.1a2.28 2.28 0 0 1-.559.593c-.662.485-1.49.56-2.23.21l-2.285-1.093a.991.991 0 0 0-1.3.434l-1.235 2.297c-.26.479-.657.828-1.128 1.018l.28.719c.368.949 1.495 1.727 2.5 1.722l6.987-.015c1.005 0 1.461-.753 1.015-1.672l-2.05-4.214h.005ZM12.18 30.536l-1.235-2.297a.986.986 0 0 0-1.3-.434l-2.284 1.093a2.136 2.136 0 0 1-2.226-.21 2.223 2.223 0 0 1-.74-.918l-2.207 4.538c-.446.919.015 1.667 1.015 1.672l6.987.015c1.005 0 2.127-.773 2.5-1.727l.333-.859a2.22 2.22 0 0 1-.843-.878",
    props: {
      fill: "currentColor"
    }
  }, {
    d: "M25.575 17.26a2.314 2.314 0 0 1 0-3.05l1.784-2.027c.25-.284.329-.664.211-1.028a1.008 1.008 0 0 0-.75-.69l-2.535-.533c-1.132-.24-1.892-1.308-1.76-2.487l.3-2.695a1.031 1.031 0 0 0-.418-.964.954.954 0 0 0-1-.095l-2.284 1.093c-1.055.505-2.32.08-2.883-.963l-1.236-2.297a.991.991 0 0 0-.877-.534c-.368 0-.697.2-.878.534l-1.236 2.297c-.563 1.043-1.828 1.468-2.882.963L6.846 3.691a.954.954 0 0 0-1 .095c-.304.22-.461.584-.417.964l.299 2.695c.132 1.179-.628 2.252-1.76 2.487l-2.53.534a.991.991 0 0 0-.75.689c-.118.36-.04.744.21 1.028l1.785 2.027a2.314 2.314 0 0 1 0 3.05L.9 19.288c-.25.28-.329.665-.211 1.029.118.354.397.614.75.689l2.53.53c1.137.239 1.892 1.307 1.76 2.485l-.299 2.696c-.044.385.113.744.417.964a.965.965 0 0 0 1 .095L9.13 26.68c.299-.145.617-.214.936-.214.79 0 1.55.429 1.951 1.173l1.236 2.296a.986.986 0 0 0 1.755 0l1.236-2.296c.563-1.044 1.828-1.468 2.883-.964l2.284 1.094c.329.16.706.12 1-.095.304-.22.461-.584.417-.964l-.299-2.696c-.132-1.178.628-2.246 1.76-2.486l2.535-.53c.353-.074.637-.334.75-.688a1.05 1.05 0 0 0-.21-1.029l-1.785-2.026-.005.005Zm-6.516 1.778c0 1.058-.363 2.002-1.01 2.816-1.064 1.333-2.451 1.947-4.133 1.802-1.388-.12-2.5-.779-3.349-1.907-.672-.894-.951-1.922-.951-3.04v-3.66c0-.085-.01-.165-.015-.25-1.059 0-2.113-.005-3.172-.01h-.304c.098-.104.152-.164.206-.224 1.383-1.408 2.76-2.816 4.148-4.214.392-.4.78-.819 1.23-1.138a4.561 4.561 0 0 1 5.506.155c1.192.948 1.834 2.211 1.839 3.769 0 1.967.005 3.934 0 5.9",
    props: {
      fill: "currentColor"
    }
  }, {
    d: "M16.539 12.827c-.162-.898-.667-1.512-1.52-1.792s-1.628-.06-2.265.57c-1.059 1.047-2.093 2.126-3.138 3.19.084.004.162.014.245.014.687 0 1.368.01 2.055 0 .181 0 .24.045.24.24-.01 1.283 0 2.571 0 3.854 0 1.233.985 2.227 2.196 2.232 1.221 0 2.216-.994 2.216-2.237v-5.751c0-.105 0-.215-.02-.32",
    props: {
      fill: "currentColor"
    }
  }],
  viewBox: "0 0 28 34"
});