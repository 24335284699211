import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Flex, IconOnePass, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ExclusiveBadge = function ExclusiveBadge(_ref) {
  var compact = _ref.compact,
      containerProps = _ref.containerProps;
  return ___EmotionJSX(Flex, _extends({
    justifyContent: {
      xs: "center",
      md: "flex-start"
    }
  }, containerProps), ___EmotionJSX(Box, {
    display: "inline-flex",
    px: 2,
    py: 1,
    borderColor: "onePass.primary",
    borderRadius: "semi",
    borderWidth: 1,
    borderStyle: "solid",
    fontFamily: "onePass",
    alignItems: "center",
    mb: 1,
    color: "onePass.primary",
    backgroundColor: "white",
    fontSize: compact ? "xs" : null
  }, ___EmotionJSX(IconOnePass, {
    title: "OnePass",
    height: "1em",
    width: "auto",
    mr: 1,
    mt: -1,
    color: "onePass.primary",
    role: "img"
  }), ___EmotionJSX(Span, {
    fontWeight: "bold",
    color: "onePass.textPrimary",
    fontSize: "inherit"
  }, "Exclusive")));
};