import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useEffect, useState } from "react";
import { Alert, Text } from "@catchoftheday/cg-components";
import { cancellablePromise, noop } from "@catchoftheday/utilities";
import { EMAIL_VERIFICATION_NOTICE_QUERY_PARAM, GLOBAL_NOTICE_QUERY_PARAM, GLOBAL_NOTICE_TYPE_QUERY_PARAM } from "@header/consts";
import { getCountryDataMessage, getEmailVerifiedMessage, getGlobalNoticeMessage, getOnePassMigrationMessage } from "./message-handlers";
import { jsx as ___EmotionJSX } from "@emotion/react";

var updateBannersList = function updateBannersList(_ref) {
  var key = _ref.key,
      props = _ref.props,
      setter = _ref.setter;
  setter(function (prevBanners) {
    var banners = _toConsumableArray(prevBanners);

    var bannerIndex = banners.findIndex(function (banner) {
      return banner.key === key;
    }); // If banner exists:

    if (bannerIndex >= 0) {
      if (props) {
        banners[bannerIndex] = _objectSpread({
          key: key
        }, props);
      } else {
        // If props is null, remove the banner
        banners = banners.filter(function (banner) {
          return banner.key !== key;
        });
      } // If banner does not exist:

    } else if (props) {
      banners.push(_objectSpread({
        key: key
      }, props));
    }

    return banners;
  });
};

export var FlashMessageBanner = function FlashMessageBanner(_ref2) {
  var isLoggedIn = _ref2.isLoggedIn;

  var _useState = useState([]),
      banners = _useState[0],
      setBanners = _useState[1];

  useEffect(function () {
    var queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get(EMAIL_VERIFICATION_NOTICE_QUERY_PARAM) === "true") {
      updateBannersList({
        key: "emailVerified",
        props: _objectSpread(_objectSpread({}, getEmailVerifiedMessage(isLoggedIn)), {}, {
          onClose: function onClose() {
            queryParams["delete"](EMAIL_VERIFICATION_NOTICE_QUERY_PARAM);
            window.history.replaceState({}, "", "".concat(window.location.pathname).concat(queryParams.size > 0 ? "?".concat(queryParams) : ""));
          }
        }),
        setter: setBanners
      });
    }
  }, [isLoggedIn]);
  useEffect(function () {
    var _cancellablePromise = cancellablePromise(getCountryDataMessage()),
        promise = _cancellablePromise.promise,
        cancel = _cancellablePromise.cancel;

    promise.then(function (d) {
      updateBannersList({
        key: "country",
        props: d,
        setter: setBanners
      });
    })["catch"](noop);
    return cancel;
  }, []);
  useEffect(function () {
    var onePassMigrationMessage = getOnePassMigrationMessage();
    updateBannersList({
      key: "onePassMigration",
      props: onePassMigrationMessage,
      setter: setBanners
    });
  }, []);
  useEffect(function () {
    var queryParams = new URLSearchParams(window.location.search);
    var noticeMessage = queryParams.get(GLOBAL_NOTICE_QUERY_PARAM);
    var noticeType = queryParams.get(GLOBAL_NOTICE_TYPE_QUERY_PARAM);

    if (noticeMessage) {
      updateBannersList({
        key: "globalNotice",
        props: _objectSpread(_objectSpread({}, getGlobalNoticeMessage({
          message: noticeMessage,
          type: noticeType
        })), {}, {
          onClose: function onClose() {
            queryParams["delete"](GLOBAL_NOTICE_QUERY_PARAM);
            queryParams["delete"](GLOBAL_NOTICE_TYPE_QUERY_PARAM);
            window.history.replaceState({}, "", "".concat(window.location.pathname).concat(queryParams.size > 0 ? "?".concat(queryParams) : ""));
          }
        }),
        setter: setBanners
      });
    }
  }, []);
  return ___EmotionJSX(React.Fragment, null, banners.map(function (_ref3) {
    var key = _ref3.key,
        theme = _ref3.theme,
        variant = _ref3.variant,
        showIcon = _ref3.showIcon,
        message = _ref3.message,
        onClose = _ref3.onClose;
    return ___EmotionJSX(Alert, {
      key: "flash-banner-".concat(key),
      type: "inline",
      theme: theme,
      variant: variant,
      showAlertIcon: showIcon !== null && showIcon !== void 0 ? showIcon : false,
      showCloseButton: true,
      hideAlert: function hideAlert() {
        if (typeof onClose === "function") {
          onClose();
        }

        updateBannersList({
          key: key,
          props: null,
          setter: setBanners
        });
      },
      borderRadius: "none"
    }, ___EmotionJSX(Text, {
      fontSize: {
        xs: "sm",
        md: "base"
      },
      fontWeight: "normal",
      my: 0,
      mx: {
        lg: "auto"
      }
    }, message));
  }));
};