import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "displayName", "totalApplied", "hideClearButton", "children", "labelText", "expanded"];
import React, { useContext, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Flex, NotificationBadge, Span, TextButton } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FacetAccordion = function FacetAccordion(_ref) {
  var id = _ref.id,
      displayName = _ref.displayName,
      totalApplied = _ref.totalApplied,
      hideClearButton = _ref.hideClearButton,
      children = _ref.children,
      labelText = _ref.labelText,
      _ref$expanded = _ref.expanded,
      _expanded = _ref$expanded === void 0 ? true : _ref$expanded,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(CatalogueContext),
      removeFilterKey = _useContext.removeFilterKey;

  var _useState = useState(_expanded),
      expanded = _useState[0],
      setExpanded = _useState[1];

  return ___EmotionJSX(Box, _extends({
    mb: 2
  }, props), ___EmotionJSX(Accordion, {
    showDividerTop: false,
    showDividerBottom: false,
    backgroundColor: "white",
    expanded: expanded,
    onChange: function onChange() {
      return setExpanded(function (val) {
        return !val;
      });
    }
  }, ___EmotionJSX(AccordionSummary, {
    py: 4,
    position: {
      xs: "sticky",
      md: "relative"
    },
    top: {
      xs: "50px",
      md: "0"
    },
    backgroundColor: "white",
    zIndex: 1
  }, ___EmotionJSX(Flex, null, ___EmotionJSX(Box, {
    flexGrow: 1
  }, displayName), !!totalApplied && ___EmotionJSX(React.Fragment, null, !hideClearButton && ___EmotionJSX(TextButton, {
    fontSize: "xs",
    pr: 10,
    onClick: function onClick(e) {
      e.stopPropagation();
      removeFilterKey("f.".concat(id));
    },
    opacity: expanded ? 1 : 0
  }, "Clear"), " ", ___EmotionJSX(NotificationBadge, {
    text: totalApplied,
    top: 0,
    absRight: "25px",
    position: "relative",
    height: "20px",
    backgroundColor: "secondary"
  })), labelText && ___EmotionJSX(Span, {
    fontSize: "xs",
    color: "typography.inactive",
    mr: 4
  }, labelText))), ___EmotionJSX(AccordionDetails, null, children)));
};