import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconCoupon = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M2 13v-2.7l.19-.047c.852-.095 1.563-.9 1.563-1.753 0-.853-.71-1.658-1.564-1.753L2 6.7V4h13.737v2.7l-.19.047c-.852.095-1.563.9-1.563 1.753 0 .853.71 1.658 1.563 1.753l.19.047V13H2z",
    stroke: "currentColor",
    strokeWidth: ".75",
    strokeMiterlimit: "10"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M10.716 6.084l-4.832 4.832M6.5 7.6a.9.9 0 100-1.8.9.9 0 000 1.8zM10.053 11.152a.9.9 0 100-1.8.9.9 0 000 1.8z",
    stroke: "currentColor",
    strokeWidth: ".75",
    strokeMiterlimit: "10",
    strokeLinecap: "round"
  }), ___EmotionJSX("path", {
    key: "part-3",
    d: "M12.421 5.658v6.158",
    stroke: "currentColor",
    strokeWidth: ".75",
    strokeDasharray: "1.1 1.1"
  })],
  fill: "none",
  viewBox: "0 0 18 18"
});