import React, { useCallback, useEffect, useState } from "react";
import { localStorage } from "@catchoftheday/utilities";
import { RECENT_SEARCH_TERMS_KEY } from "../../consts";
import { RecentSearches } from "../../pure/RecentSearches";
import { TrendingSearches } from "../../pure/TrendingSearches";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchTerms = function SearchTerms(_ref) {
  var trendingSearchTerms = _ref.trendingSearchTerms,
      focussedLinkIndex = _ref.focussedLinkIndex,
      linkRefs = _ref.linkRefs;

  var _useState = useState([]),
      recentSearchTerms = _useState[0],
      setRecentSearchTerms = _useState[1];

  var getRecentSearches = useCallback(function () {
    var storedSearches = localStorage.getItem(RECENT_SEARCH_TERMS_KEY) || "[]";
    var storedSearchesArray = JSON.parse(storedSearches).filter(Boolean);

    if (storedSearchesArray.length) {
      var terms = storedSearchesArray.map(function (recentSearch) {
        if (typeof recentSearch !== "string") {
          return recentSearch.term;
        } else {
          return recentSearch;
        }
      });
      setRecentSearchTerms(terms);
    }
  }, []);

  function clearAll() {
    localStorage.removeItem(RECENT_SEARCH_TERMS_KEY);
    setRecentSearchTerms([]);
  }

  function clearItem(termToRemove) {
    var remaining = recentSearchTerms.filter(function (term) {
      return term !== termToRemove;
    });
    localStorage.setItem(RECENT_SEARCH_TERMS_KEY, JSON.stringify(remaining));
    setRecentSearchTerms(remaining);
  }

  useEffect(function () {
    getRecentSearches();
  }, [getRecentSearches]);

  if (recentSearchTerms.length > 0) {
    return ___EmotionJSX(RecentSearches, {
      recentSearchTerms: recentSearchTerms,
      clearAll: clearAll,
      clearItem: clearItem,
      focussedLinkIndex: focussedLinkIndex,
      linkRefs: linkRefs
    });
  }

  if (trendingSearchTerms) {
    return ___EmotionJSX(TrendingSearches, {
      trendingTerms: trendingSearchTerms,
      focussedLinkIndex: focussedLinkIndex,
      linkRefs: linkRefs
    });
  }

  return null;
};