import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "label"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var convertProductCard = function convertProductCard(productItem, offer) {
  var _productItem$options$, _offer$sellPrice, _offer$variant;

  return {
    id: productItem.id,
    adult: productItem.adult,
    attributeName: (_productItem$options$ = productItem.options[0]) === null || _productItem$options$ === void 0 ? void 0 : _productItem$options$.name,
    averageRating: productItem.ratingsSummary.averageRating,
    brand: productItem.brand,
    clubCatchEligible: offer === null || offer === void 0 ? void 0 : offer.clubFreeShipping,
    clubCatchExclusive: offer === null || offer === void 0 ? void 0 : offer.clubExclusive,
    freeShippingAvailable: offer === null || offer === void 0 ? void 0 : offer.freeShipping,
    imageLocation: productItem.assets.hero.url,
    isPersonalisedProduct: productItem.options.length > 0,
    offerId: offer === null || offer === void 0 ? void 0 : offer.id,
    ratingCount: productItem.ratingsSummary.numberOfRatings,
    sellable: productItem.sellable,
    sellerId: offer === null || offer === void 0 ? void 0 : offer.seller.id,
    title: productItem.title,
    url: "/product/".concat(productItem.slug),
    priceTag: (offer === null || offer === void 0 ? void 0 : offer.priceTag) || productItem.priceTag,
    badges: offer === null || offer === void 0 ? void 0 : offer.badges,
    sellPrice: offer === null || offer === void 0 ? void 0 : (_offer$sellPrice = offer.sellPrice) === null || _offer$sellPrice === void 0 ? void 0 : _offer$sellPrice.amount,
    isSponsored: productItem.isSponsored,
    hideSponsoredLabel: productItem.hideSponsoredLabel,
    sourceId: productItem.sourceId,
    sourceType: productItem.sourceType,
    selectedOfferSalesRuleOffers: mapSalesRuleOffers((offer === null || offer === void 0 ? void 0 : offer.salesRuleOffers) || []),
    variantId: offer === null || offer === void 0 ? void 0 : (_offer$variant = offer.variant) === null || _offer$variant === void 0 ? void 0 : _offer$variant.id,
    linkToOffer: productItem.linkToOffer
  };
};

var mapSalesRuleOffers = function mapSalesRuleOffers(salesRuleOffers) {
  return salesRuleOffers.map(function (_ref) {
    var id = _ref.id,
        label = _ref.label,
        rest = _objectWithoutProperties(_ref, _excluded);

    return _objectSpread({
      salesRuleId: id,
      salesRuleLabel: label
    }, rest);
  });
};