import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { ProductPriceContainer } from "./ProductPriceContainer";
import { ProductPriceFromLabel } from "./ProductPriceFromLabel";
import { ProductPriceOnePass } from "./ProductPriceOnePass";
import { ProductPricePill } from "./ProductPricePill";
import { ProductPriceSell } from "./ProductPriceSell";
import { ProductPriceWas } from "./ProductPriceWas";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPrice = function ProductPrice(_ref) {
  var priceTag = _ref.priceTag,
      _ref$large = _ref.large,
      large = _ref$large === void 0 ? false : _ref$large;
  var priceDiscount = priceTag.priceDiscount,
      topLabel = priceTag.topLabel,
      priceLabel = priceTag.priceLabel,
      clubSellPrice = priceTag.clubSellPrice,
      retailPriceDisclaimer = priceTag.retailPriceDisclaimer;
  var sellPrice = priceLabel.price.amount;
  return ___EmotionJSX(ProductPriceContainer, {
    large: large
  }, (topLabel === null || topLabel === void 0 ? void 0 : topLabel.includes("From")) && ___EmotionJSX(ProductPriceFromLabel, {
    topLabel: topLabel
  }), ___EmotionJSX(Flex, {
    alignItems: "center",
    flexWrap: "wrap"
  }, ___EmotionJSX(ProductPriceSell, {
    sellPrice: sellPrice,
    large: large
  }), ___EmotionJSX(ProductPricePill, {
    priceLabel: priceLabel,
    priceDiscount: priceDiscount,
    large: large
  })), ___EmotionJSX(ProductPriceWas, {
    priceLabel: priceLabel,
    topLabel: topLabel,
    retailPriceDisclaimer: retailPriceDisclaimer,
    hasClubSellPrice: !!clubSellPrice,
    large: large
  }), clubSellPrice && ___EmotionJSX(ProductPriceOnePass, {
    price: clubSellPrice,
    large: large
  }));
};