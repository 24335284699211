import { createSvgIconComponent } from "../utils";
export var IconBook = createSvgIconComponent({
  paths: [{
    d: "M7.54 12.75S5.78 11.2 1.96 11.3V1.91s3.61 0 5.58 2.27z"
  }, {
    d: "M7.54 14.09s-1.97-2.17-6.5-1.96V3.15s-.11-.1-1.04 0v9.8s4.85-.3 7.54 1.14zm.92-1.34s1.76-1.55 5.58-1.45V1.91s-3.61 0-5.58 2.27z"
  }, {
    d: "M8.46 14.09s1.97-2.17 6.5-1.96V3.15s.11-.1 1.04 0v9.8s-4.85-.3-7.54 1.14z"
  }],
  fill: "currentColor",
  viewBox: "0 0 16 16"
});