import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Flex, Heading, IconSearch, List, ListItem } from "@catchoftheday/cg-components";
import { SearchSuggestionsLoading } from "./SearchSuggestionsLoading";
import { jsx as ___EmotionJSX } from "@emotion/react";
var MOBILE_MAX_VISIBLE_TERMS = 5;

var SearchSuggestionsContainer = function SearchSuggestionsContainer(_ref) {
  var children = _ref.children;
  return ___EmotionJSX(Box, {
    flex: 1
  }, ___EmotionJSX(Heading.h4, {
    m: 0
  }, "Suggested Searches"), children);
};

export var SearchSuggestions = function SearchSuggestions(_ref2) {
  var data = _ref2.data,
      renderListItem = _ref2.renderListItem,
      onListItemClick = _ref2.onListItemClick,
      focussedLinkIndex = _ref2.focussedLinkIndex,
      linkRefs = _ref2.linkRefs,
      isSearchTermLoading = _ref2.isSearchTermLoading,
      searchTerm = _ref2.searchTerm;

  if (isSearchTermLoading) {
    return ___EmotionJSX(SearchSuggestionsContainer, null, ___EmotionJSX(SearchSuggestionsLoading, null));
  }

  if (!data.length) {
    data.push({
      title: searchTerm
    });
  }

  return ___EmotionJSX(SearchSuggestionsContainer, null, ___EmotionJSX(List, {
    type: "none",
    role: "listbox",
    mt: "16px",
    "data-cnstrc-item-section": "Search Suggestions"
  }, data.map(function (itemData, idx) {
    var _itemData$value;

    var highlighted = focussedLinkIndex === idx;
    var props = {
      onClick: function onClick() {
        return onListItemClick(itemData);
      },
      role: "option",
      highlighted: highlighted,
      "aria-selected": highlighted,
      "data-suggestion-index": idx
    };
    return renderListItem && ___EmotionJSX(ListItem, _extends({
      mb: "6px",
      key: idx,
      display: {
        xs: idx >= MOBILE_MAX_VISIBLE_TERMS ? "none" : "list-item",
        md: "list-item"
      },
      ref: function ref(el) {
        return linkRefs.current[idx] = el;
      },
      "data-cnstrc-item-name": itemData.title,
      "data-cnstrc-item-group": (_itemData$value = itemData.value) === null || _itemData$value === void 0 ? void 0 : _itemData$value.id
    }, props), ___EmotionJSX(Flex, {
      alignItems: "center"
    }, ___EmotionJSX(IconSearch, {
      width: "15px",
      mr: 2
    }), ___EmotionJSX(Box, {
      flex: 1
    }, renderListItem({
      itemData: itemData,
      isHighlighted: highlighted
    }))));
  })));
};