import React from "react";
import { SOURCE_TYPE_CONSTRUCTOR_SEARCH_RECENT_SEARCH_TERM } from "@catchoftheday/analytics";
import { AccessTimeIcon, Box, Flex, IconCross, Link, List, ListItem, TextButton } from "@catchoftheday/cg-components";
import { SEARCH_PAGE_PATH } from "../../../../consts";
import { SearchBarHeader } from "../../../layouts/SearchBar/SearchBarLayouts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RecentSearchTerms = function RecentSearchTerms(_ref) {
  var recentSearchTerms = _ref.recentSearchTerms,
      clearAll = _ref.clearAll,
      clearItem = _ref.clearItem;
  return ___EmotionJSX(Flex, {
    width: "100%",
    flexDirection: "column"
  }, ___EmotionJSX(Box, {
    display: {
      xs: "block",
      md: "flex"
    },
    justifyContent: "space-between",
    alignContent: "center"
  }, ___EmotionJSX(SearchBarHeader, null, "Recent Searches"), ___EmotionJSX(TextButton, {
    fontSize: "xs",
    fontWeight: "100",
    onClick: clearAll,
    textTransform: "none",
    mt: {
      xs: 3,
      md: 0
    }
  }, "Clear all")), ___EmotionJSX(List, {
    mt: "16px"
  }, recentSearchTerms.map(function (term, index) {
    return ___EmotionJSX(ListItem, {
      key: "recent-term-".concat(index),
      mb: "10px",
      display: {
        xs: index > 4 ? "none" : "list-item",
        md: "list-item"
      }
    }, ___EmotionJSX(Flex, {
      alignItems: "center"
    }, ___EmotionJSX(AccessTimeIcon, {
      style: {
        width: "15px",
        marginRight: "8px"
      }
    }), ___EmotionJSX(Link, {
      href: "".concat(SEARCH_PAGE_PATH, "?query=").concat(term, "&st=").concat(SOURCE_TYPE_CONSTRUCTOR_SEARCH_RECENT_SEARCH_TERM),
      fontWeight: "100",
      fontSize: "14px",
      style: {
        flex: 1,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
      },
      color: "text",
      overflow: "hidden",
      pr: 2
    }, term), ___EmotionJSX(TextButton, {
      onClick: function onClick() {
        return clearItem(term);
      },
      color: "text",
      title: "Delete search term: ".concat(term),
      "aria-label": "Delete search term: ".concat(term)
    }, ___EmotionJSX(IconCross, {
      width: "10px"
    }))));
  })));
};