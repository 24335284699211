/**
 * Styles for the different button sizes
 */
import { variant } from "@catchoftheday/theme";
var sizeVariantDefs = {
  content: {},
  "x-small": {
    maxWidth: "400px"
  },
  small: {
    maxWidth: "500px"
  },
  medium: {
    maxWidth: "600px"
  },
  large: {
    maxWidth: "960px"
  }
};
export var sizeVariant = variant({
  prop: "sizeVariant",
  variants: sizeVariantDefs
});