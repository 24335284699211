import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DropdownOverlay = function DropdownOverlay(props) {
  return ___EmotionJSX(Box, _extends({
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "100vh",
    bg: "rgba(0,0,0,0.3)",
    zIndex: 3
  }, props));
};