import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";

var _templateObject;

var _excluded = ["children", "hasAside", "hasPromotion", "onePassExclusive", "onePassSecondary", "noArrow", "enabled", "large"];
import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { styled, useTheme } from "@catchoftheday/theme";
import { removeProperty } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceMain = function ProductPriceMain(_ref) {
  var children = _ref.children,
      hasAside = _ref.hasAside,
      hasPromotion = _ref.hasPromotion,
      onePassExclusive = _ref.onePassExclusive,
      onePassSecondary = _ref.onePassSecondary,
      noArrow = _ref.noArrow,
      _ref$enabled = _ref.enabled,
      enabled = _ref$enabled === void 0 ? true : _ref$enabled,
      large = _ref.large,
      flexProps = _objectWithoutProperties(_ref, _excluded);

  var content = children || " ";
  var theme = useTheme();
  var memberBackgroundColor = theme.colors.onePass.primary;
  var memberBackgroundColorSecondary = theme.colors.onePass.primaryLighter;
  var backgroundColor = theme.colors.error;

  if (onePassExclusive) {
    backgroundColor = memberBackgroundColor;
  } else if (onePassSecondary) {
    backgroundColor = memberBackgroundColorSecondary;
  } else if (!enabled) {
    backgroundColor = "#b7b7b7";
  }

  var arrowImage = "url(\"data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2050%20100%22%20preserveAspectRatio%3D%22none%22%3E%3Cpolygon%20points%3D%220%2C%200%2050%2C%2050%200%2C%20100%22%20fill%3D%22%23".concat(backgroundColor.substring(1), "%22%20%2F%3E%3C%2Fsvg%3E\")");
  var ArrowPriceBox = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    &::after {\n      content: \"\";\n      display: block;\n      position: absolute;\n      z-index: 1;\n      top: 0;\n      bottom: 0;\n      left: 100%;\n      width: 0.21875em;\n      height: 100%;\n      background-repeat: no-repeat;\n      background-size: 100% 100%;\n      ", "\n    }\n  "])), !hasPromotion && "background-image: ".concat(arrowImage));
  var PriceComponent = noArrow ? Flex : ArrowPriceBox;
  var sizeBuffer = large ? 36 : 0;
  return ___EmotionJSX(PriceComponent, _extends({
    position: "relative",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "row",
    flexWrap: "nowrap",
    minWidth: hasAside ? "".concat(74 + sizeBuffer, "px") : onePassSecondary ? "0px" : "".concat(92 + sizeBuffer, "px"),
    paddingX: "6px",
    alignItems: "center",
    padding: large ? "0 10px" : "0 4px",
    lineHeight: "1",
    textAlign: "center",
    backgroundColor: backgroundColor,
    color: onePassSecondary ? "onepass.primary" : "white"
  }, removeProperty(flexProps, "ref")), content);
};