import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["showMore", "toggleShowMore"];
import React from "react";
import { IconDownArrow, TextButton } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShowMoreButton = function ShowMoreButton(_ref) {
  var showMore = _ref.showMore,
      toggleShowMore = _ref.toggleShowMore,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(TextButton, _extends({
    onClick: toggleShowMore,
    fontSize: "sm",
    color: "text",
    mt: 2
  }, props), showMore ? "Show less" : "Show more", ___EmotionJSX(IconDownArrow, {
    height: 12,
    width: 12,
    ml: 2,
    style: {
      transform: showMore ? "rotate(180deg)" : "rotate(0deg)"
    }
  }));
};