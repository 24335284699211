import { createSvgIconComponent } from "../utils";
export var IconMemberDiscount = createSvgIconComponent({
  paths: [{
    d: "M24.186 23.575c-.886 0-1.64.72-1.64 1.703 0 1 .773 1.704 1.64 1.704.886 0 1.658-.7 1.658-1.704 0-.983-.75-1.703-1.658-1.703ZM16.98 17.327c0-.98-.772-1.704-1.639-1.704-.905 0-1.658.72-1.658 1.704s.772 1.704 1.658 1.704c.871 0 1.64-.72 1.64-1.704Z",
    props: {
      fill: "currentColor"
    }
  }, {
    d: "M35.067 22.5a2.32 2.32 0 0 1 0-3.001l2.362-2.734c1.11-1.287.466-3.349-1.154-3.695l-3.351-.72c-1.083-.232-1.817-1.294-1.692-2.447l.397-3.64c.19-1.738-1.51-3.02-3.021-2.281l-3.021 1.475c-1.015.496-2.227.083-2.768-.944l-1.636-3.1a2.087 2.087 0 0 0-3.733 0l-1.635 3.1c-.542 1.027-1.753 1.44-2.768.944l-3.021-1.475c-1.51-.74-3.21.543-3.021 2.282l.397 3.64c.125 1.152-.61 2.214-1.692 2.446l-3.351.72c-1.62.346-2.264 2.408-1.155 3.695l2.363 2.734a2.32 2.32 0 0 1 0 3.002l-2.363 2.734C.094 26.522.74 28.583 2.36 28.93l3.35.72c1.084.232 1.818 1.294 1.693 2.447l-.397 3.639c-.19 1.739 1.51 3.022 3.02 2.282l3.022-1.476c1.015-.495 2.226-.082 2.768.945l1.635 3.1a2.087 2.087 0 0 0 3.733 0l1.636-3.1c.541-1.027 1.753-1.44 2.768-.945l3.02 1.476c1.512.74 3.211-.543 3.022-2.282l-.397-3.64c-.125-1.152.61-2.214 1.692-2.446l3.35-.72c1.621-.347 2.265-2.408 1.155-3.695l-2.362-2.734Zm-23.402-5.153c0-2.042 1.64-3.702 3.68-3.702 2.022 0 3.661 1.66 3.661 3.702 0 2.002-1.639 3.643-3.66 3.643-2.045.004-3.68-1.64-3.68-3.643Zm4.18 11.354h-2.639l10.366-14.797h2.658L15.845 28.701Zm8.34.24a3.646 3.646 0 0 1-3.66-3.643c0-2.022 1.616-3.682 3.66-3.682 2.041 0 3.68 1.66 3.68 3.682 0 2.003-1.635 3.643-3.68 3.643Z",
    props: {
      fill: "currentColor"
    }
  }],
  viewBox: "0 0 38 42"
});