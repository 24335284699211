import React from "react";
import { Span } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceSell = function ProductPriceSell(_ref) {
  var large = _ref.large,
      sellPrice = _ref.sellPrice;
  return ___EmotionJSX(Span, {
    fontSize: large ? "40px" : "28px",
    fontWeight: 600,
    lineHeight: 1.2,
    color: "text",
    mr: 2
  }, formatPrice(sellPrice, false));
};