import { graphql } from "msw";
import { getMockRecommendedProduct } from "../mockRecommendedProducts";
export var handler = graphql.query("RecentlyViewedProducts", function (request, response, context) {
  var _request$variables = request.variables,
      limit = _request$variables.limit,
      offset = _request$variables.offset;
  return response(context.data({
    __typename: "Query",
    recentlyViewed: {
      __typename: "RecentlyViewedResponse",
      products: Array.from({
        length: limit
      }, function (_, index) {
        return getMockRecommendedProduct({
          id: (index + 1).toString()
        });
      }),
      metaData: {
        hydrationErrorCount: 0,
        preHydrationCount: 1,
        postHydrationCount: 1,
        __typename: "RecommenderMetaData"
      },
      pagination: {
        nextItemIndex: offset + limit,
        totalCount: 30
      }
    }
  }));
});