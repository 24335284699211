import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useEffect, useState } from "react";
import { Box } from "@catchoftheday/cg-components";
import { useEventBus } from "@catchoftheday/state";
import { css } from "@catchoftheday/theme";
import { getCookie } from "@catchoftheday/utilities";
import { TRIGGER_ADULT_MODAL_EVENT } from "./trigger";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AdultBlurContainer = function AdultBlurContainer(_ref) {
  var _ref$adult = _ref.adult,
      adult = _ref$adult === void 0 ? false : _ref$adult,
      children = _ref.children;

  var _useState = useState(adult),
      open = _useState[0],
      setOpen = _useState[1];

  useEventBus(TRIGGER_ADULT_MODAL_EVENT, function (event) {
    if (event.payload) {
      var _open = event.payload.open;
      setOpen(_open);
    }
  });
  useEffect(function () {
    if (adult) {
      var hasAdultCookie = getCookie("over18", false);
      setOpen(!hasAdultCookie);
    }
  }, [adult]);
  return ___EmotionJSX(Box, {
    css: open ? css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n              filter: blur(20px);\n            "]))) : undefined
  }, children);
};