import React from "react";
import { SOURCE_TYPE_SEARCH_NO_RESULTS } from "@catchoftheday/analytics";
import { SearchTermRecommendedProducts } from "@catchoftheday/personalisation";
import { useBreakpoint } from "@catchoftheday/theme";
import { NoResultsZone } from "../../layouts/PageLayout/PageLayout";
import { CategoryNav } from "../../pure/NoResults/CategoryNav/CategoryNav";
import { NoResultsFooter } from "../../pure/NoResults/NoResultsFooter/NoResultsFooter";
import { NoResultsHeader } from "../../pure/NoResults/NoResultsHeader/NoResultsHeader";
import { TrendingSearches } from "../../pure/NoResults/TrendingSearches/TrendingSearches";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoResultsContainer = function NoResultsContainer(_ref) {
  var host = _ref.host,
      searchTerm = _ref.searchTerm;

  var _useBreakpoint = useBreakpoint({
    minusOnePx: true
  }),
      isMobile = _useBreakpoint.isMobile;

  return ___EmotionJSX(NoResultsZone, null, ___EmotionJSX(NoResultsHeader, {
    searchQuery: searchTerm
  }), ___EmotionJSX(TrendingSearches, null), ___EmotionJSX(CategoryNav, {
    sourceType: SOURCE_TYPE_SEARCH_NO_RESULTS,
    domain: host
  }), !!searchTerm && ___EmotionJSX(SearchTermRecommendedProducts, {
    searchTerm: searchTerm,
    sourceId: searchTerm,
    isMobile: isMobile
  }), ___EmotionJSX(NoResultsFooter, null));
};