import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { ExclusiveBadge } from "@catchoftheday/onepass";
import { useTheme } from "@catchoftheday/theme";
import { AvailabilityTag } from "./AvailabilityTag";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function AvailabilityTags(_ref) {
  var badges = _ref.badges;
  var theme = useTheme();
  var AvailabilityTagBackground = {
    ALMOST_GONE: theme.colors.tertiary,
    SOLD_OUT: theme.colors.error,
    SHORT_DATED: theme.colors.secondary,
    CLEARANCE: theme.colors.tertiary
  };

  if (!badges.length) {
    return null;
  }

  var showSoldOut = badges.includes("sold-out") && ___EmotionJSX(AvailabilityTag, {
    backgroundColor: AvailabilityTagBackground.SOLD_OUT
  }, "Sold Out");

  var showUrgentMessage = badges.includes("clearance") ? ___EmotionJSX(AvailabilityTag, {
    backgroundColor: AvailabilityTagBackground.CLEARANCE
  }, "Clearance") : badges.includes("almost-gone") ? ___EmotionJSX(AvailabilityTag, {
    backgroundColor: AvailabilityTagBackground.ALMOST_GONE
  }, "Almost Gone") : null;

  var showShortDated = badges.includes("short-dated") && ___EmotionJSX(AvailabilityTag, {
    backgroundColor: AvailabilityTagBackground.SHORT_DATED,
    color: "white",
    position: "absolute",
    top: "7px",
    left: "7px"
  }, "Short Dated");

  var showOnePassExclusive = badges.includes("onepass-exclusive") && ___EmotionJSX(ExclusiveBadge, {
    compact: true,
    containerProps: {
      justifyContent: "flex-end"
    }
  });

  if (!showSoldOut && !showUrgentMessage && !showShortDated && !showOnePassExclusive) {
    return null;
  }

  return ___EmotionJSX(Flex, {
    position: "absolute",
    zIndex: 1,
    textAlign: "right",
    fontSize: "2xs",
    right: {
      xs: "10px",
      sm: "12px"
    },
    bottom: {
      xs: "5px",
      sm: "6px"
    },
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-end"
  }, showShortDated, ___EmotionJSX(Box, null, showSoldOut || showUrgentMessage), showOnePassExclusive);
}