import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import React, { useEffect, useState } from "react";
import { Box, Flex, Span } from "@catchoftheday/cg-components";
import { keyframes, styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var animationTime = 3000;
var animation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateY(-15px);\n  }\n  8%{\n    opacity: 1;\n    transform: translateY(0px);\n  }\n  92%{\n    opacity: 1;\n    transform: translateY(0px);\n  }\n  100%{\n    opacity: 0;\n    transform: translateY(15px);\n  }\n"])));
var NoPointer = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  pointer-events: none;\n  white-space: nowrap;\n"])));
var Ticker = styled(Span)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  animation: ", " ", "ms ease-in-out;\n"])), animation, animationTime);

var updateTicker = function updateTicker(term) {
  return ___EmotionJSX(Ticker, {
    key: term,
    fontSize: "base",
    fontWeight: "bold",
    display: "inline-block",
    "data-testid": "searchticker"
  }, term);
};

export var SearchTicker = function SearchTicker(_ref) {
  var visible = _ref.visible,
      trendingSearchTerms = _ref.trendingSearchTerms;

  var _useState = useState(),
      visibleTerm = _useState[0],
      setVisibleTerm = _useState[1];

  useEffect(function () {
    var count = 1;
    var interval = setInterval(function () {
      setVisibleTerm(updateTicker(trendingSearchTerms[count++ % trendingSearchTerms.length]));
    }, animationTime);
    setVisibleTerm(updateTicker(trendingSearchTerms[0]));
    return function () {
      return clearInterval(interval);
    };
  }, [trendingSearchTerms]);

  if (!trendingSearchTerms.length) {
    return null;
  }

  return ___EmotionJSX(NoPointer, {
    position: "absolute",
    top: "1px",
    bottom: "5px",
    alignItems: "center",
    left: {
      xs: "11px",
      md: "21px"
    },
    right: "1px",
    zIndex: 200,
    background: "white",
    overflow: "hidden",
    display: visible ? "flex" : "none"
  }, ___EmotionJSX(Box, {
    color: "typography.inactive",
    position: "relative",
    fontWeight: "400"
  }, "Search for ", visibleTerm));
};