import { useContext, useEffect } from "react";
import { CustomerContext } from "@catchoftheday/customer";
import { getQueryStringValue } from "@header/utilities";
import { showToast } from "../utils";
export var useFlybuysNotification = function useFlybuysNotification() {
  var _useContext = useContext(CustomerContext),
      customer = _useContext.data;

  var _ref = customer || {},
      flybuys = _ref.flybuys;

  var _ref2 = flybuys || {},
      isLinked = _ref2.isLinked;

  var showMessageQueryParam = getQueryStringValue("showmessage");
  useEffect(function () {
    if (customer && showMessageQueryParam === "fl") {
      var toastMessage = "";
      var isError = false;

      if (!isLinked) {
        isError = true;
        toastMessage = "Sorry, there's a problem with linking your account. Please try again later";
      } else {
        toastMessage = "Your flybuys card has been linked";
      }

      if (toastMessage) {
        showToast({
          message: toastMessage,
          isError: isError,
          options: isError ? {
            autoClose: false,
            closeButton: true
          } : {}
        });
      }
    }
  }, [customer, isLinked, showMessageQueryParam]);
};