import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { CataloguePageType } from "../../../consts";
import { AppliedFacets } from "../../pure/Facets/AppliedFacets/AppliedFacets";
import { CategoryFacet } from "../../pure/Facets/CategoryFacet/CategoryFacet";
import { ListFacet } from "../../pure/Facets/ListFacet/ListFacet";
import { MobileFacetFooter } from "../../pure/Facets/MobileFacetFooter/MobileFacetFooter";
import { MobileFacetHeader } from "../../pure/Facets/MobileFacetHeader/MobileFacetHeader";
import { RangeFacet } from "../../pure/Facets/RangeFacet/RangeFacet";
import { SafeSearchToggle } from "../../pure/Facets/SafeSearchToggle/SafeSearchToggle";
import { SizeFacet } from "../../pure/Facets/SizeFacet/SizeFacet";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FacetContainer = function FacetContainer(_ref) {
  var facets = _ref.facets,
      categories = _ref.categories,
      showSafeSearch = _ref.showSafeSearch,
      totalResults = _ref.totalResults,
      pageType = _ref.pageType;
  var hasApplied = facets.appliedFacets.length > 0;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MobileFacetHeader, {
    hasFacetsApplied: hasApplied
  }), hasApplied && ___EmotionJSX(AppliedFacets, {
    appliedFacets: facets.appliedFacets
  }), showSafeSearch && ___EmotionJSX(SafeSearchToggle, null), ___EmotionJSX(Flex, {
    flexDirection: "column"
  }, categories && (categories === null || categories === void 0 ? void 0 : categories.options.length) > 0 && pageType !== CataloguePageType.CATEGORY && ___EmotionJSX(CategoryFacet, {
    categories: categories
  }), facets.availableFacets.map(function (facet) {
    if (facet.__typename === "SearchListFacet") {
      return facet.type === "HIERARCHICAL" ? ___EmotionJSX(SizeFacet, {
        key: facet.id,
        facet: facet
      }) : ___EmotionJSX(ListFacet, {
        key: facet.id,
        facet: facet
      });
    }

    if (facet.__typename === "SearchRangeFacet") {
      return ___EmotionJSX(RangeFacet, {
        key: facet.id,
        facet: facet
      });
    }

    return null;
  })), ___EmotionJSX(MobileFacetFooter, {
    hasFacetsApplied: hasApplied,
    totalResults: totalResults
  }));
};