import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { Box, List } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { CATEGORY_FACET_SHOW_MORE_LIMIT } from "../../../../consts";
import { ShowMoreButton } from "../ShowMoreButton/ShowMoreButton";
import { CategoryFacetListItem } from "./CategoryFacetListItem";
import { jsx as ___EmotionJSX } from "@emotion/react";
var showMoreLimit = CATEGORY_FACET_SHOW_MORE_LIMIT;
var CategoryList = styled(List)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  li {\n    margin-bottom: 0.5rem;\n    &:last-of-type {\n      margin-bottom: 0;\n    }\n  }\n  li ul li {\n    &:first-of-type {\n      margin-top: 0.5rem;\n    }\n  }\n"])));
export var CategoryFacetList = function CategoryFacetList(_ref) {
  var categories = _ref.categories,
      parentId = _ref.parentId,
      isRoot = _ref.isRoot,
      showMoreMap = _ref.showMoreMap,
      setShowMoreMap = _ref.setShowMoreMap,
      visitedCategories = _ref.visitedCategories,
      updateVisited = _ref.updateVisited;
  var extraIndent = categories.some(function (category) {
    var _category$children;

    return (_category$children = category.children) === null || _category$children === void 0 ? void 0 : _category$children.some(function (child) {
      var _child$children;

      return ((_child$children = child.children) === null || _child$children === void 0 ? void 0 : _child$children.length) || child.selected;
    });
  });
  var displayValues = categories.slice(0, showMoreMap["".concat(parentId)] ? showMoreLimit : categories.length);

  var renderList = function renderList(categories, parentId) {
    return ___EmotionJSX(CategoryFacetList, {
      categories: categories,
      parentId: parentId,
      showMoreMap: showMoreMap,
      setShowMoreMap: setShowMoreMap,
      visitedCategories: visitedCategories,
      updateVisited: updateVisited
    });
  };

  return ___EmotionJSX(Box, {
    pl: isRoot ? extraIndent ? 4 : 0 : 4
  }, ___EmotionJSX(CategoryList, {
    width: "100%",
    p: 0,
    m: 0
  }, displayValues.map(function (category) {
    return ___EmotionJSX(CategoryFacetListItem, {
      key: category.id,
      category: category,
      visitedCategories: visitedCategories,
      updateVisited: updateVisited,
      renderList: renderList
    });
  })), categories.length > showMoreLimit && ___EmotionJSX(ShowMoreButton, {
    toggleShowMore: function toggleShowMore() {
      return setShowMoreMap(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, _defineProperty({}, "".concat(parentId), !prev["".concat(parentId)]));
      });
    },
    showMore: !showMoreMap[parentId],
    mt: 3
  }));
};