import React from "react";
import { IconClickCollect, IconFlybuysCardSingle, IconOnePassDeliveryTruck, IconOnePassReturns, IconOnePassTags } from "@catchoftheday/cg-components";
import { rem } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var commonIconProps = {
  width: rem("32px"),
  height: rem("32px"),
  color: "onePass.primary"
};
export var BENEFIT_ITEMS = [{
  text: "Free Delivery on eligible items or orders*",
  icon: ___EmotionJSX(IconOnePassDeliveryTruck, commonIconProps)
}, {
  text: "5x Flybuys points in-store*",
  icon: ___EmotionJSX(IconFlybuysCardSingle, commonIconProps)
}, {
  text: "365 day change of mind returns*",
  icon: ___EmotionJSX(IconOnePassReturns, commonIconProps)
}, {
  text: "Express Click & Collect in-store*",
  icon: ___EmotionJSX(IconClickCollect, commonIconProps)
}, {
  text: "Member Exclusives*",
  icon: ___EmotionJSX(IconOnePassTags, commonIconProps)
}];