import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconMessage = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.773 15a.671.671 0 00.434-.169l3.726-2.535h4.334c.433 0 .866-.17 1.213-.507.347-.338.52-.76.52-1.183V4.69c0-.422-.173-.845-.52-1.183A1.733 1.733 0 0013.267 3H3.733c-.433 0-.866.169-1.213.507-.347.338-.52.76-.52 1.183v5.916c0 .422.173.845.52 1.183.347.338.78.507 1.213.507v1.859c0 .169.087.338.174.423a.668.668 0 00.346.338c.174.084.347.084.52.084zM5.9 12.887c-.087.085-.173.085-.26.085-.173 0-.26-.085-.347-.17-.086-.084-.173-.168-.173-.337v-.338c-.029-.141-.048-.254-.064-.348-.032-.188-.052-.3-.11-.413-.086-.169-.346-.253-.78-.253-.52-.085-.953-.507-.953-1.014V5.197c0-.253.087-.507.26-.676.174-.169.434-.253.694-.253h8.666c.26 0 .52.084.694.253.173.17.26.423.26.676v4.986c0 .254-.087.507-.26.676-.174.17-.434.254-.694.254h-4.16c-.173 0-.346.084-.52.169L5.9 12.887z",
    fill: "currentColor"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M5 8.5h3-3z",
    fill: "currentColor"
  }), ___EmotionJSX("path", {
    key: "part-3",
    d: "M5 6.5h5.5M5 8.5h3",
    stroke: "currentColor",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })],
  viewBox: "0 0 18 18"
});