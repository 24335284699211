import React from "react";
import { Box, Heading, Text } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { ONEPASS_MONTHLY_PRICE, URL_ONEPASS_LANDING } from "../../../consts";
import { BenefitIconZone, BenefitRowZone, BenefitsCopyZone } from "../../layouts/UpsellBoxLayout/UpsellBoxLayout";
import { CTAButton } from "../CTAButton";
import { SubscriptionCTA } from "../SubscriptionCTA";
import { BENEFITS } from "./benefits";
import { BenefitText } from "./BenefitText";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellBoxContent = function UpsellBoxContent(_ref) {
  var isFreeTrialEligible = _ref.isFreeTrialEligible,
      onLearnMoreClick = _ref.onLearnMoreClick,
      onCTAClick = _ref.onCTAClick;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Heading.h4, {
    fontWeight: "bold",
    fontSize: {
      md: "xl"
    },
    m: 0,
    mb: 4
  }, isFreeTrialEligible ? "Join OnePass for benefits from Australia's favourite retail brands" : "We miss you, come back to OnePass for amazing benefits"), ___EmotionJSX(Text, {
    fontWeight: "semibold",
    m: 0,
    mb: 2
  }, "From ", formatPrice(ONEPASS_MONTHLY_PRICE, false), " a month. Benefits include:"), ___EmotionJSX(BenefitsCopyZone, null, BENEFITS.map(function (_ref2, idx) {
    var Icon = _ref2.Icon,
        text = _ref2.text;
    return ___EmotionJSX(BenefitRowZone, {
      key: "benefit-row-".concat(idx)
    }, ___EmotionJSX(BenefitIconZone, null, ___EmotionJSX(Icon, null)), ___EmotionJSX(BenefitText, null, text));
  })), ___EmotionJSX(SubscriptionCTA, {
    onClick: onCTAClick,
    variant: "onePassPrimary"
  }, isFreeTrialEligible ? "Start free 30 day trial" : "Join OnePass"), ___EmotionJSX(Box, {
    textAlign: "center",
    mt: 2
  }, ___EmotionJSX(CTAButton, {
    href: URL_ONEPASS_LANDING,
    hoverColor: "inherit",
    fontSize: "inherit",
    onClick: onLearnMoreClick
  }, "Find out more")));
};