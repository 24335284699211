import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { emitCancellationModalAction, emitCancellationModalImpression } from "@catchoftheday/analytics";
import { CustomerContext } from "@catchoftheday/customer";
import { ONEPASS_DOUBLE_SUBSCRIPTION_QUERY_PARAM_KEY, ONEPASS_SUCCESS_NOTIFICATION_TOAST_PARAM_KEY } from "../../../consts";
import { CancellationModal } from "../../pure/CancellationModal";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CancellationModalContainer = function CancellationModalContainer() {
  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data,
      loading = _useContext.loading;

  var _ref = customer || {},
      membership = _ref.membership;

  var openModal = function openModal() {
    emitCancellationModalImpression();
    setIsOpen(true);
  };

  var closeModal = function closeModal() {
    setIsOpen(false);
  };

  useEffect(function () {
    if (true) {
      var _window, _window$location;

      var queryParams = queryString.parse((_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.search);

      if ((queryParams === null || queryParams === void 0 ? void 0 : queryParams[ONEPASS_SUCCESS_NOTIFICATION_TOAST_PARAM_KEY]) === "l" && membership !== null && membership !== void 0 && membership.doubleSubscription) {
        openModal();
      } else if ((queryParams === null || queryParams === void 0 ? void 0 : queryParams[ONEPASS_DOUBLE_SUBSCRIPTION_QUERY_PARAM_KEY]) === "1") {
        openModal();
      }
    }
  }, [membership === null || membership === void 0 ? void 0 : membership.doubleSubscription]);
  return ___EmotionJSX(CancellationModal, {
    isOpen: isOpen,
    loading: loading,
    onClose: function onClose() {
      emitCancellationModalAction("close");
      closeModal();
    },
    onConfirm: function onConfirm() {
      emitCancellationModalAction("confirm");
      closeModal();
    }
  });
};