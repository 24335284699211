function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import React, { useMemo } from "react";
import { SearchPillNavigation } from "../SearchPillNavigation/SearchPillNavigation";
import { jsx as ___EmotionJSX } from "@emotion/react";

var findCategoryNavItems = function findCategoryNavItems(categories) {
  var _iterator = _createForOfIteratorHelper(categories),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var category = _step.value;

      if (category.selected) {
        var _category$children;

        return ((_category$children = category.children) === null || _category$children === void 0 ? void 0 : _category$children.length) > 0 ? category.children : (categories === null || categories === void 0 ? void 0 : categories.length) > 1 // Return siblings if there are any.
        ? categories : [];
      }

      if ("children" in category && category.children.length > 0) {
        var selectedCategoryList = findCategoryNavItems(category.children);

        if (selectedCategoryList.length > 0) {
          return selectedCategoryList;
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return [];
};

export var CategoryPillNavigation = function CategoryPillNavigation(_ref) {
  var categories = _ref.categories;
  var categoryNavItems = useMemo(function () {
    return findCategoryNavItems(categories).filter(function (_ref2) {
      var slug = _ref2.slug;
      return !!slug;
    }).map(function (_ref3) {
      var displayName = _ref3.displayName,
          selected = _ref3.selected,
          slug = _ref3.slug;
      return {
        name: displayName,
        url: "/category/".concat(slug.replace(/^\//, "")),
        selected: selected
      };
    });
  }, [categories]);

  if (!categoryNavItems.length) {
    return null;
  }

  return ___EmotionJSX(SearchPillNavigation, {
    label: "Category Navigation",
    items: categoryNavItems
  });
};