import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { categoryMenuItemFilter, MappedMenuItems } from "../../../consts";
export var getNavData = function getNavData(shops) {
  var seasonalNav = [];
  var todaysDeals = undefined;
  var onePassItem = undefined;

  var primaryNav = _toConsumableArray(shops).filter(function (shop) {
    if (shop.slug === MappedMenuItems.TODAYS_DEALS) {
      todaysDeals = shop;
      return false;
    }

    if (shop.slug === MappedMenuItems.ONEPASS) {
      onePassItem = shop;
      return false;
    }

    if (shop.pinToTop) {
      seasonalNav.push(shop);
      return false;
    }

    return categoryMenuItemFilter(shop);
  });

  if (todaysDeals) {
    seasonalNav.unshift(todaysDeals);
  }

  if (onePassItem) {
    seasonalNav.unshift(onePassItem);
  }

  return {
    primaryNav: primaryNav,
    seasonalNav: seasonalNav
  };
};