import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconPadlock = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M8.746 11.759c.21 0 .38-.171.38-.381V9.8a.38.38 0 10-.761 0v1.578c0 .21.17.38.38.38z",
    fill: "currentColor",
    stroke: "currentColor",
    strokeWidth: ".5"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M13.11 6.178h-1.802V4.322c0-1.28-1.15-2.322-2.562-2.322-1.413 0-2.563 1.042-2.563 2.322v1.856H4.381a.38.38 0 00-.381.38v4.871A3.575 3.575 0 007.57 15h2.351a3.575 3.575 0 003.57-3.57V6.557c0-.21-.17-.38-.38-.38zM6.946 4.322c0-.86.808-1.56 1.8-1.56.994 0 1.802.7 1.802 1.56v1.856H6.945V4.322zm5.785 7.107c0 1.55-1.26 2.81-2.81 2.81H7.57a2.812 2.812 0 01-2.808-2.81V6.94h7.968v4.49z",
    fill: "currentColor",
    stroke: "currentColor",
    strokeWidth: ".5"
  })],
  viewBox: "0 0 18 18"
});