import React, { useCallback, useContext, useEffect, useState } from "react";
import { findLastIndex } from "lodash-es";
import { Box } from "@catchoftheday/cg-components";
import { PageConfigurationContext } from "@catchoftheday/common-vendors";
import { formatPrice } from "@catchoftheday/utilities";
import { ONEPASS_SHOP_TILES_PER_ROW } from "../../../consts";
import { OnePassConfigurationContext } from "../../../contexts";
import { constructScheduleEntryInserts } from "../../../utilities/schedule-entry";
import { CatchAppBanner } from "../../pure/CatchAppBanner";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ONEPASS_SCHEDULE_ENTRIES_LIMIT = 9;
export var InsertsContainer = function InsertsContainer(_ref) {
  var positionPointer = _ref.positionPointer,
      scheduleEntryOrdinals = _ref.scheduleEntryOrdinals,
      eventEntriesLength = _ref.eventEntriesLength,
      bannerIndices = _ref.bannerIndices,
      renderRemaining = _ref.renderRemaining,
      recentlyViewedProductsComponent = _ref.recentlyViewedProductsComponent;

  var _useState = useState([]),
      inserts = _useState[0],
      setInserts = _useState[1];

  var _useContext = useContext(PageConfigurationContext),
      _useContext$data = _useContext.data,
      homeUspGooglePlayUrl = _useContext$data.homeUspGooglePlayUrl,
      homeUspItunesAppUrl = _useContext$data.homeUspItunesAppUrl;

  var _useContext2 = useContext(OnePassConfigurationContext),
      priceMonthly = _useContext2.data.priceMonthly;

  useEffect(function () {
    if (scheduleEntryOrdinals && !inserts.length) {
      setInserts(constructScheduleEntryInserts(scheduleEntryOrdinals));
    }
  }, [inserts.length, scheduleEntryOrdinals]);
  var mapInsertComponent = useCallback(function (ordinalKey) {
    switch (ordinalKey) {
      case "catchAppBanner":
        return ___EmotionJSX(CatchAppBanner, {
          key: "entry-insert-".concat(ordinalKey),
          appStoreLink: homeUspItunesAppUrl,
          googlePlayLink: homeUspGooglePlayUrl,
          onePassPriceMonthly: formatPrice(priceMonthly === null || priceMonthly === void 0 ? void 0 : priceMonthly.amount, false),
          gridColumn: {
            xs: "auto",
            md: "span ".concat(ONEPASS_SHOP_TILES_PER_ROW)
          }
        });

      case "recentlyViewedProducts":
        return ___EmotionJSX(Box, {
          key: "entry-insert-".concat(ordinalKey),
          gridColumn: {
            xs: "auto",
            md: "span ".concat(ONEPASS_SHOP_TILES_PER_ROW)
          }
        }, recentlyViewedProductsComponent);

      default:
        return null;
    }
  }, [recentlyViewedProductsComponent, homeUspGooglePlayUrl, homeUspItunesAppUrl, priceMonthly === null || priceMonthly === void 0 ? void 0 : priceMonthly.amount]); // render based on the ordinal position

  if (!renderRemaining && typeof positionPointer === "number") {
    // filters out the inserts by filling up the tiles first using modulo
    // render insert(s) when the remainder is 0 and when ordinal <= index < ordinal + tiles per row
    // (logic for filling up the tiles based on the number of tiles per row)
    var currentPos = positionPointer;
    var lastBannerIndex = findLastIndex(bannerIndices, function (ind) {
      return ind <= currentPos;
    });

    if (lastBannerIndex >= 0) {
      currentPos = currentPos - (lastBannerIndex + 1);
    }

    var pointInserts = inserts.filter(function (ins) {
      return currentPos + 1 > ins.ordinal && currentPos + 1 <= ins.ordinal + ONEPASS_SHOP_TILES_PER_ROW && (currentPos + ins.colspan) % ONEPASS_SHOP_TILES_PER_ROW === 0;
    });

    if (pointInserts.length) {
      return ___EmotionJSX(React.Fragment, null, pointInserts.map(function (ins) {
        return mapInsertComponent(ins.key);
      }));
    }
  } // render the remaining inserts
  else if (renderRemaining) {
    var remainingInserts = inserts.filter(function (ins) {
      return ins.ordinal > eventEntriesLength;
    });

    if (remainingInserts.length > 0 && eventEntriesLength) {
      return ___EmotionJSX(React.Fragment, null, remainingInserts.sort(function (a, b) {
        return a.ordinal - b.ordinal;
      }).map(function (ins) {
        return mapInsertComponent(ins.key);
      }));
    }
  }

  return null;
};