import React, { useContext } from "react";
import { emitOnePassAccountManagementAction } from "@catchoftheday/analytics";
import { CustomerContext } from "@catchoftheday/customer";
import { isCancellingMembership, isMembershipFreeTrialEligible } from "../../../utilities";
import { StatusDetailsRowZone, SubscriptionDetailsPageZone } from "../../layouts/SubscriptionDetailsLayout/SubscriptionDetailsLayout";
import { ManageSubscriptionLink } from "../../pure/ManageSubscriptionLink";
import { InlineOnePassHelp, PinnedOnePassHelp } from "../../pure/OnePassHelp";
import { SubscriptionDetailsPlaceholder } from "../../pure/SubscriptionDetailsPlaceholder";
import { PaymentDetailsContainer } from "../PaymentDetailsContainer";
import { PlanDetailsContainer } from "../PlanDetailsContainer";
import { UpsellBoxContainer } from "../UpsellBoxContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SubscriptionDetailsContainer = function SubscriptionDetailsContainer() {
  var _useContext = useContext(CustomerContext),
      data = _useContext.data,
      loading = _useContext.loading;

  var _ref = data || {},
      membership = _ref.membership;

  var _ref2 = membership || {},
      subscription = _ref2.subscription;

  var _ref3 = subscription || {},
      active = _ref3.active,
      activeDaysRemaining = _ref3.activeDaysRemaining,
      purchasePrice = _ref3.purchasePrice,
      interval = _ref3.interval,
      isTeamMember = _ref3.isTeamMember,
      latestMembershipPeriodEnd = _ref3.latestMembershipPeriodEnd;

  var handleManageLinkClick = function handleManageLinkClick() {
    emitOnePassAccountManagementAction("manage");
  };

  var isCancelling = isCancellingMembership(subscription);
  var shouldShowUpsellBox = !loading && !isTeamMember && !isCancelling && (!membership || !(subscription !== null && subscription !== void 0 && subscription.active));
  var shouldShowDetailsSection = !loading && Boolean(membership);
  var isFreeTrialEligible = isMembershipFreeTrialEligible(data);
  return ___EmotionJSX(SubscriptionDetailsPageZone, null, ___EmotionJSX(PinnedOnePassHelp, null), loading && ___EmotionJSX(SubscriptionDetailsPlaceholder, null), shouldShowUpsellBox && ___EmotionJSX(UpsellBoxContainer, {
    isFreeTrialEligible: isFreeTrialEligible
  }), shouldShowDetailsSection && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(StatusDetailsRowZone, null, ___EmotionJSX(PlanDetailsContainer, {
    active: Boolean(active),
    activeDaysRemaining: activeDaysRemaining,
    purchasePrice: purchasePrice,
    interval: interval,
    isCancelling: isCancelling,
    isTeamMember: Boolean(isTeamMember),
    latestMembershipPeriodEnd: latestMembershipPeriodEnd
  }), ___EmotionJSX(PaymentDetailsContainer, {
    active: Boolean(active),
    latestMembershipPeriodEnd: latestMembershipPeriodEnd,
    isTeamMember: Boolean(isTeamMember),
    isCancelling: isCancelling
  })), ___EmotionJSX(ManageSubscriptionLink, {
    onClick: handleManageLinkClick
  })), ___EmotionJSX(InlineOnePassHelp, null));
};