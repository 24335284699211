export var emitViewMiniCartAnalytic = function emitViewMiniCartAnalytic(cart) {
  var _window$dataLayer, _window$dataLayer2;

  var orderSummary = {
    totalPrice: {
      currency: "AUD",
      amount: (cart === null || cart === void 0 ? void 0 : cart.totalPrice) || 0
    }
  };
  var cartItems = (cart === null || cart === void 0 ? void 0 : cart.items) || [];

  var _getViewMiniCartDataL = getViewMiniCartDataLayer(cartItems, orderSummary),
      gtmDataLayer = _getViewMiniCartDataL.gtmDataLayer;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  (_window$dataLayer = window.dataLayer) === null || _window$dataLayer === void 0 ? void 0 : _window$dataLayer.push({
    ecommerce: null
  });
  (_window$dataLayer2 = window.dataLayer) === null || _window$dataLayer2 === void 0 ? void 0 : _window$dataLayer2.push(gtmDataLayer);
};
export var emitRemoveFromMiniCartAnalytic = function emitRemoveFromMiniCartAnalytic(cartItem) {
  var _window$dataLayer3, _window$dataLayer4;

  var _getRemoveFromMiniCar = getRemoveFromMiniCartDataLayer(cartItem),
      gtmDataLayer = _getRemoveFromMiniCar.gtmDataLayer;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  (_window$dataLayer3 = window.dataLayer) === null || _window$dataLayer3 === void 0 ? void 0 : _window$dataLayer3.push({
    ecommerce: null
  });
  (_window$dataLayer4 = window.dataLayer) === null || _window$dataLayer4 === void 0 ? void 0 : _window$dataLayer4.push(gtmDataLayer);
};
export var getViewMiniCartDataLayer = function getViewMiniCartDataLayer(cartItems, orderSummary) {
  var _orderSummary$totalPr, _orderSummary$totalPr2;

  var products = [];
  cartItems.forEach(function (item, index) {
    products.push({
      item_id: item.productId,
      item_name: item.productName,
      price: item.sellPrice,
      quantity: item.quantity,
      index: index,
      added_by_coupon: item.addedByCoupon
    });
  });
  return {
    gtmDataLayer: {
      event: "view_cart",
      ecommerce: {
        currency: (orderSummary === null || orderSummary === void 0 ? void 0 : (_orderSummary$totalPr = orderSummary.totalPrice) === null || _orderSummary$totalPr === void 0 ? void 0 : _orderSummary$totalPr.currency) || "AUD",
        value: (orderSummary === null || orderSummary === void 0 ? void 0 : (_orderSummary$totalPr2 = orderSummary.totalPrice) === null || _orderSummary$totalPr2 === void 0 ? void 0 : _orderSummary$totalPr2.amount) || 0,
        items: products
      }
    }
  };
};
export var getRemoveFromMiniCartDataLayer = function getRemoveFromMiniCartDataLayer(cartItem) {
  var products = [];
  products.push({
    item_id: cartItem.productId,
    item_name: cartItem.productName,
    price: cartItem.sellPrice,
    quantity: cartItem.quantity,
    index: 0,
    added_by_coupon: cartItem.addedByCoupon
  });
  return {
    gtmDataLayer: {
      event: "remove_from_cart",
      ecommerce: {
        currency: "AUD",
        value: (cartItem === null || cartItem === void 0 ? void 0 : cartItem.sellPrice) || 0,
        items: products
      }
    }
  };
};