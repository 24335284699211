import React from "react";
import { SizeItemButton } from "../../../layouts/PageLayout/PageLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SizeFacetOption = function SizeFacetOption(_ref) {
  var id = _ref.id,
      value = _ref.value,
      displayName = _ref.displayName,
      selected = _ref.selected,
      optionKey = _ref.optionKey,
      onChange = _ref.onChange;
  return ___EmotionJSX(SizeItemButton, {
    id: id,
    name: value,
    value: value,
    onClick: function onClick() {
      return onChange(optionKey, value, selected);
    },
    "aria-selected": selected,
    selected: selected
  }, displayName);
};