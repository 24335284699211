import React from "react";
import { Flex, Link, Truncate } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardBrand = function ProductCardBrand(_ref) {
  var brand = _ref.brand;

  function renderContent() {
    if (!brand) {
      return ___EmotionJSX(React.Fragment, null, "\xA0");
    }

    var name = brand.name,
        slug = brand.slug;
    return ___EmotionJSX(Link, {
      textDecoration: "underline",
      color: "typography.subText",
      href: "/brand/".concat(slug)
    }, name);
  }

  return ___EmotionJSX(Flex, {
    "data-testid": "product-brand",
    fontSize: "xs",
    fontWeight: "light",
    color: "typography.subText",
    px: "10px",
    mb: "6px",
    justifyContent: "center",
    position: "relative"
  }, ___EmotionJSX(Truncate, {
    maxWidth: {
      xs: "130px",
      md: "200px"
    }
  }, renderContent()));
};