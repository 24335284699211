import queryString from "query-string";

function getQueryString() {
  if (true) {
    return window.location.search;
  }

  return "";
}

export function getQueryStringValue(key) {
  var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : getQueryString();

  if (true) {
    return queryString.parse(query)[key] || "";
  }

  return undefined;
}