import React, { useContext } from "react";
import { Modal, Text } from "@catchoftheday/cg-components";
import { GlobalContext } from "@catchoftheday/common-vendors";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceDisclaimerModal = function ProductPriceDisclaimerModal() {
  var _useContext = useContext(GlobalContext),
      _useContext$priceTagM = _useContext.priceTagModal,
      isPriceTagModalOpen = _useContext$priceTagM.isPriceTagModalOpen,
      setIsPriceTagModalOpen = _useContext$priceTagM.setIsPriceTagModalOpen;

  return ___EmotionJSX(Modal, {
    isOpen: isPriceTagModalOpen,
    onRequestClose: function onRequestClose() {
      return setIsPriceTagModalOpen(false);
    },
    shouldDisableBodyScrolling: true
  }, ___EmotionJSX(Text, null, "'RRP' is the recommended retail price provided by the supplier, obtained from the manufacturer, or supplied by a marketplace seller, or is the recently advertised price for the same product on a different or competing online platform or store. Catch may not have previously sold the product at the RRP. Where a product is being sold by a marketplace seller, the RRP is nominated by that seller and the seller takes full responsibility for ensuring accuracy for the RRP price."));
};