import { createSvgIconComponent } from "../utils";
export var IconOnePassMembershipCard = createSvgIconComponent({
  paths: [{
    d: "M19.846 25.457a3.002 3.002 0 0 0-.428 4.578 3 3 0 0 0 4.583-.378l4.98-4.965a3 3 0 0 0-2.1-5.07h-5.415l2.1 2.115-3.72 3.72Z",
    props: {
      fill: "currentColor"
    }
  }, {
    d: "M42.96 16.247 31.5 4.772a4.395 4.395 0 0 0-6.18 0L3.525 26.567a4.366 4.366 0 0 0 0 6.18L15 44.222a4.366 4.366 0 0 0 6.18 0l21.78-21.795a4.365 4.365 0 0 0 0-6.18Zm-11.85 10.5-4.965 5.025c-1.155.89-3.104 1.839-4.558 1.745a6 6 0 0 1-5.602-5.602c-.094-1.454.855-3.403 1.745-4.558l3.735-3.735-3-3h8.415a6 6 0 0 1 4.215 10.155l.015-.03Z",
    props: {
      fill: "currentColor"
    }
  }],
  viewBox: "2.24 3.5 42 42",
  fill: "none"
});