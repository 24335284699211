import React from "react";
import { Link } from "@catchoftheday/cg-components";
import { URL_ONEPASS_SUBSCRIPTION_MANAGEMENT } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ManageSubscriptionLink = function ManageSubscriptionLink(_ref) {
  var onClick = _ref.onClick;
  return ___EmotionJSX(Link, {
    textDecoration: "underline",
    fontWeight: "bold",
    href: URL_ONEPASS_SUBSCRIPTION_MANAGEMENT,
    onClick: onClick
  }, "Manage subscription with OnePass");
};