import { rest } from "msw";
export var metaHandler = [rest.get("/header/meta.json", function (req, res, ctx) {
  return res(ctx.json({
    data: {
      loggedIn: true,
      displayName: "Bob",
      clubActive: false,
      flybuysLinked: false,
      cartItemCount: 0,
      wishlistItemCount: 0,
      messageCount: 0,
      couponNotificationEnabled: true
    }
  }));
}), rest.get("/country.json", function (req, res, ctx) {
  return res(ctx.json({
    data: {
      userCountryName: "Australia",
      shouldShopUrl: "catch.com.au"
    }
  }));
}), rest.get("/header/loyalty.json", function (req, res, ctx) {
  return res(ctx.json({
    data: {
      club: {
        active: false
      },
      membership: {
        linked: false
      },
      flybuys: {
        linked: false
      }
    }
  }));
}), rest.get("/header/cart.json", function (req, res, ctx) {
  return res(ctx.json({
    data: {
      totalPrice: "10.00",
      subTotalPrice: "10.00",
      clubCatchMinSubTotal: "0",
      hideClubCatchFreeShippingNotice: true,
      items: [{
        id: "3c138efe-71cd-4971-b883-ee86044770d3",
        offerId: 65347,
        sellerId: 1,
        quantity: 1,
        rowTotalPrice: "10.00",
        sellPrice: "10.00",
        attributeValueText: "",
        attributeValueId: 0,
        addedByCoupon: false,
        eventId: "1",
        productName: "Dove Beauty Bar 4pk",
        productId: 162014,
        clubCatchExclusive: false,
        grouping: "in-stock",
        freeShippingMarketPlace: false,
        freeShippingClubCatch: false,
        attributeName: ""
      }],
      containsClubCatchLineItem: false
    }
  }));
})];