import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { emitProductListInteraction, emitTrackingPixel } from "@catchoftheday/analytics";
/** TODO (RU): Circular dep: import from elsewhere */

import { convertProductCard, ProductCard, ProductCardNew } from "@catchoftheday/product-components";
import { jsx as ___EmotionJSX } from "@emotion/react";

function isProductItem(obj) {
  return "isProductItem" in obj && !!obj.isProductItem;
}

export var getProductTiles = function getProductTiles(_ref) {
  var products = _ref.products,
      sourceId = _ref.sourceId,
      recommenderSourceType = _ref.sourceType,
      fromProduct = _ref.fromProduct,
      render = _ref.render,
      newProductCard = _ref.newProductCard,
      sponsoredItemsTracking = _ref.sponsoredItemsTracking,
      onAddToCartSuccess = _ref.onAddToCartSuccess;
  var productItems = products.map(function (product, index) {
    if (!isProductItem(product)) {
      return _objectSpread(_objectSpread({}, convertProductCard(product, product.selectedOffer || product.bestOffer)), {}, {
        sourceType: product.sourceType || recommenderSourceType,
        sourceId: sourceId,
        sourcePosition: index + 1
      });
    }

    return product;
  });

  var handleProductClick = function handleProductClick(_ref2) {
    var interaction = _ref2.interaction,
        product = _ref2.product,
        position = _ref2.position;

    if (sponsoredItemsTracking !== null && sponsoredItemsTracking !== void 0 && sponsoredItemsTracking[product.id]) {
      emitTrackingPixel(sponsoredItemsTracking[product.id].clickTrackingUrl);
    }

    emitProductListInteraction(_objectSpread({
      interaction: interaction,
      product_id: Number(product.id),
      offer_id: Number(product.offerId),
      source_position: position,
      source_type: product.sourceType,
      source_id: sourceId || null
    }, fromProduct));
    onAddToCartSuccess === null || onAddToCartSuccess === void 0 ? void 0 : onAddToCartSuccess();
  };

  var productProps = function productProps(productItem, index) {
    return {
      productItem: productItem,
      onProductClick: function onProductClick() {
        return handleProductClick({
          interaction: "click",
          product: productItem,
          position: index + 1
        });
      },
      onAddToCart: function onAddToCart() {
        return handleProductClick({
          interaction: "addToCart",
          product: productItem,
          position: index + 1
        });
      }
    };
  };

  if (render) {
    return render(productItems.map(function (productItem, index) {
      return productProps(productItem, index);
    }));
  }

  return productItems.map(function (productItem, index) {
    return newProductCard ? ___EmotionJSX(ProductCardNew, _extends({
      key: productItem.id
    }, productProps(productItem, index))) : ___EmotionJSX(ProductCard, _extends({
      key: productItem.id
    }, productProps(productItem, index)));
  });
};