// Test values
export var ITEM_ID_THAT_WILL_THROW_ERROR = "825557c2-2c48-4de2-b86a-84e5dcc14a6f";
export var OFFER_ID_THAT_WILL_THROW_ERROR = "bad-offer-id";
export var GIFT_CARD_THAT_WILL_THROW_ERROR = "998877665544332211";
export var ONEPASS_REWARD_TEST_CODE = "121234345656787810";
export var ONEPASS_MONTHLY_OFFER_ID = "1039776";
export var UNAUTHORISED_USER_COUPON_CODE = "NOENTRY";
export var OFFER_ID_THAT_WILL_PRODUCE_ERROR_NOTICE = "error-notice-offer-id"; // Test error messages

export var TEST_ERROR_MESSAGE = "This is an error message for testing MSW mocks.";
export var ITEM_OUT_OF_STOCK_ERROR = "Item is out of stock.";
export var ITEM_OOS_NOTICE_MESSAGE = "Sorry, the seller has run out of stock of the requested product";