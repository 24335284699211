import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useState } from "react";
import { IconToolTip, Modal, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SalesRulePromotionTagDisclaimer = function SalesRulePromotionTagDisclaimer(_ref) {
  var title = _ref.title,
      iconProps = _ref.iconProps;

  var _useState = useState(false),
      showDisclaimer = _useState[0],
      setShowDisclaimer = _useState[1];

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconToolTip, _extends({
    tabIndex: 0,
    height: 14,
    width: 14,
    "aria-label": "".concat(title, " disclaimer"),
    cursor: "pointer",
    onClick: function onClick() {
      return setShowDisclaimer(true);
    }
  }, iconProps)), ___EmotionJSX(Modal, {
    isOpen: showDisclaimer,
    sizeVariant: "small",
    height: {
      xs: "auto",
      md: "auto"
    },
    onRequestClose: function onRequestClose() {
      return setShowDisclaimer(false);
    }
  }, ___EmotionJSX(Text, {
    fontSize: "base",
    fontWeight: "medium"
  }, title), ___EmotionJSX(Text, {
    fontSize: "base",
    m: 0
  }, "Applies to products labelled with this tag. Excludes delivery and/or use of gift cards. Only available while stocks last and while advertised. Not in conjunction with any other offer. Discount applied at cart. Catch reserves the right to modify or cancel deals at any time.")));
};