import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useState } from "react";
import useSWR from "swr";
import { emitUpsellModalImpression } from "@catchoftheday/analytics";
import { Flex, IconOnePass, Span } from "@catchoftheday/cg-components";
import { DEFAULT_FREE_TRIAL_DAYS } from "@catchoftheday/onepass";
import { useTheme } from "@catchoftheday/theme";
import { pluralize } from "@catchoftheday/utilities";
import { fetcher } from "@header/api";
import { HeaderTile } from "@header/components/HeaderTile";
import { SpanWithAfterDownArrow } from "@header/components/SpanWithAfterDownArrow";
import { HEADER_LOYALTY_API } from "@header/consts";
import { HeaderTileContainer } from "../HeaderTileContainer";
import { PopupContent } from "./PopupContent";
import { jsx as ___EmotionJSX } from "@emotion/react";

var IconSpan = function IconSpan(props) {
  return ___EmotionJSX(Span, _extends({
    fontSize: "xs",
    fontWeight: 400,
    textAlign: "center"
  }, props));
};

export var OnePassTile = function OnePassTile(_ref) {
  var _loyaltyResponse$data, _loyaltyResponse$data2, _membershipData$subsc, _membershipData$subsc2, _membershipData$subsc3, _membershipData$subsc4, _membershipData$subsc5;

  var isLoggedIn = _ref.isLoggedIn;
  var theme = useTheme();

  var _useState = useState(false),
      isImpressionFired = _useState[0],
      setIsImpressionFired = _useState[1];

  var _useSWR = useSWR(HEADER_LOYALTY_API, fetcher),
      loyaltyResponse = _useSWR.data,
      isValidating = _useSWR.isValidating;

  var membershipData = loyaltyResponse === null || loyaltyResponse === void 0 ? void 0 : (_loyaltyResponse$data = loyaltyResponse.data) === null || _loyaltyResponse$data === void 0 ? void 0 : _loyaltyResponse$data.membership;
  var clubData = loyaltyResponse === null || loyaltyResponse === void 0 ? void 0 : (_loyaltyResponse$data2 = loyaltyResponse.data) === null || _loyaltyResponse$data2 === void 0 ? void 0 : _loyaltyResponse$data2.club;
  var isLinked = membershipData === null || membershipData === void 0 ? void 0 : membershipData.linked;
  var hasSubscription = (membershipData === null || membershipData === void 0 ? void 0 : (_membershipData$subsc = membershipData.subscription) === null || _membershipData$subsc === void 0 ? void 0 : _membershipData$subsc.active) || (clubData === null || clubData === void 0 ? void 0 : clubData.active);
  var isFreeTrial = membershipData === null || membershipData === void 0 ? void 0 : (_membershipData$subsc2 = membershipData.subscription) === null || _membershipData$subsc2 === void 0 ? void 0 : _membershipData$subsc2.onFreeTrial;
  var freeTrialDaysRemaining = (_membershipData$subsc3 = membershipData === null || membershipData === void 0 ? void 0 : (_membershipData$subsc4 = membershipData.subscription) === null || _membershipData$subsc4 === void 0 ? void 0 : _membershipData$subsc4.freeTrialDays) !== null && _membershipData$subsc3 !== void 0 ? _membershipData$subsc3 : DEFAULT_FREE_TRIAL_DAYS;
  var hidePopupContent = isLoggedIn && hasSubscription;
  var showFreeTrial = !isLoggedIn || !membershipData || !(clubData !== null && clubData !== void 0 && clubData.active) && !(membershipData !== null && membershipData !== void 0 && membershipData.linked) && !(clubData !== null && clubData !== void 0 && clubData.eligibleFreeTrial) || (membershipData === null || membershipData === void 0 ? void 0 : (_membershipData$subsc5 = membershipData.subscription) === null || _membershipData$subsc5 === void 0 ? void 0 : _membershipData$subsc5.freeTrialEligible);
  var showLogIn = !isLoggedIn;
  var showOnePassLink = isLoggedIn && !isLinked;
  var showAlreadyAMember = showLogIn || showOnePassLink;

  var renderOnePassIcon = function renderOnePassIcon() {
    return ___EmotionJSX(IconOnePass, {
      verticalAlign: "top",
      height: "15px",
      width: "72px",
      title: "OnePass"
    });
  };

  var renderIconContent = function renderIconContent() {
    if (!hasSubscription) {
      return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconSpan, {
        mb: "4px"
      }, renderOnePassIcon()), ___EmotionJSX(SpanWithAfterDownArrow, {
        fontSize: "xs"
      }, "Free delivery"));
    }

    if (isFreeTrial) {
      return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconSpan, null, renderOnePassIcon()), ___EmotionJSX(IconSpan, null, "Free trial expires in ".concat(pluralize(freeTrialDaysRemaining, "day"))));
    }

    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconSpan, null, renderOnePassIcon()), ___EmotionJSX(IconSpan, null, "Show me exclusive deals!"));
  };

  var getTileLinkProps = function getTileLinkProps() {
    return {
      "aria-label": hasSubscription ? "OnePass shop" : "Free delivery with OnePass",
      href: hasSubscription ? "/shop/onepass" : "/onepass"
    };
  };

  var renderIcon = function renderIcon(_ref2) {
    var _theme$colors, _theme$colors$onePass;

    var isOpen = _ref2.isOpen;
    var iconStyleOnHover = {
      svg: {
        fill: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : (_theme$colors$onePass = _theme$colors["onePass"]) === null || _theme$colors$onePass === void 0 ? void 0 : _theme$colors$onePass["primary"]
      }
    };

    var iconStyle = _objectSpread({
      "&:hover": iconStyleOnHover
    }, isOpen && {
      svg: iconStyleOnHover.svg
    });

    return ___EmotionJSX(HeaderTileContainer, {
      hasRightBorder: true,
      flexProps: {
        px: "10px",
        maxWidth: "130px"
      },
      linkProps: _objectSpread({
        css: iconStyle,
        backgroundColor: isOpen ? "white" : undefined,
        color: isOpen ? "secondaryDark" : "white",
        className: "js-dnt",
        "data-tracking": "header-app-onepass-tile"
      }, getTileLinkProps())
    }, ___EmotionJSX(Flex, {
      flexDirection: "column",
      alignItems: "center"
    }, renderIconContent()));
  };

  if (isValidating) {
    return null;
  }

  return ___EmotionJSX(HeaderTile, {
    popoverProps: {
      placement: "bottom"
    },
    icon: renderIcon,
    onTileShow: function onTileShow(isOpen) {
      if (isOpen && !isImpressionFired) {
        setIsImpressionFired(true);
        emitUpsellModalImpression("Upsell Modal Header");
      }
    }
  }, hidePopupContent || isValidating ? null : ___EmotionJSX(PopupContent, {
    showFreeTrial: showFreeTrial,
    showLogIn: showLogIn,
    showAlreadyAMember: showAlreadyAMember
  }));
};