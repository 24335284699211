import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { toast } from "@catchoftheday/cg-components";
import { NOTIFICATION_TOAST_CONTAINER_ID } from "@header/consts";
import { NotificationToastContent } from "../NotificationToast/components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var showToast = function showToast(_ref) {
  var message = _ref.message,
      _ref$isError = _ref.isError,
      isError = _ref$isError === void 0 ? false : _ref$isError,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? {} : _ref$options;
  toast(___EmotionJSX(NotificationToastContent, {
    message: message,
    type: isError ? "error" : "success"
  }), _objectSpread({
    containerId: NOTIFICATION_TOAST_CONTAINER_ID
  }, options));
};