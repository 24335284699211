import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppExperimentContext, getSourcePlatform, SOURCE_TYPE_ONEPASS_HERO_EVENT } from "@catchoftheday/analytics";
import { useAddToCartToast } from "@catchoftheday/cart-components";
import { TopicStats, triggerOnePassUpsellModal } from "@catchoftheday/state";
import { date } from "@catchoftheday/utilities";
import { SOURCE_POSITION_ONEPASS_HERO_EVENT } from "../../../consts";
import { getEventTitles } from "../../../utilities";
import { HeroEventBanner } from "../../pure/HeroEventBanner";
import { EventBannerWithGallery } from "./EventBannerWithGallery";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EventBanner = function EventBanner(_ref) {
  var event = _ref.event,
      socialUrgencyStats = _ref.socialUrgencyStats,
      isActiveMember = _ref.isActiveMember,
      isOnePassExclusive = _ref.isOnePassExclusive,
      endDate = _ref.endDate,
      isNewHeroDeal = _ref.isNewHeroDeal;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["onepass_shop_toggle_may24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      shopToggleExperiment = _getExperiments$exper[0];
  /**
   * Have to use local state here because the social urgency stats
   * from the props are constantly changing based on the incoming websocket data
   * so we keep the stats for this specific banner within its own state
   * and update it accordingly if the incoming data matches the banner's event id (that is passed in as a prop)
   */


  var _useState = useState(new TopicStats()),
      localSocialUrgencyStats = _useState[0],
      setLocalSocialUrgencyStats = _useState[1];

  useEffect(function () {
    if (socialUrgencyStats) {
      setLocalSocialUrgencyStats(socialUrgencyStats);
    }
  }, [socialUrgencyStats]);
  var endDateDisplay = endDate ? date.format(endDate, "cccc hh:mm aaa zzz d LLLL yyyy") : null;
  var image = event.image,
      eventUrl = event.url,
      product = event.product;

  var _ref2 = (product === null || product === void 0 ? void 0 : product.bestOffer) || {},
      sellPrice = _ref2.sellPrice,
      retailPrice = _ref2.retailPrice,
      clubSellPrice = _ref2.clubSellPrice;

  var _getEventTitles = getEventTitles(event),
      title = _getEventTitles.title,
      subtitle = _getEventTitles.subtitle;

  var isSingleProductEvent = event.productCount === 1 && !!event.product;
  var isSoldOut = isSingleProductEvent && !(product !== null && product !== void 0 && product.sellable); // Add to cart

  var addToCart = useAddToCartToast({
    showCheckoutOnSuccess: true,
    showTickOnSuccess: true
  });
  var handlePrimaryCTAClick = useCallback(function () {
    if (!isActiveMember) {
      triggerOnePassUpsellModal();
    } else {
      var _product$assets, _product$assets$hero, _product$bestOffer;

      var _product = event.product;
      addToCart({
        imageUrl: _product === null || _product === void 0 ? void 0 : (_product$assets = _product.assets) === null || _product$assets === void 0 ? void 0 : (_product$assets$hero = _product$assets.hero) === null || _product$assets$hero === void 0 ? void 0 : _product$assets$hero.url,
        title: _product === null || _product === void 0 ? void 0 : _product.title
      }, {
        eventId: event !== null && event !== void 0 && event.id ? parseInt(event === null || event === void 0 ? void 0 : event.id) : undefined,
        sourceId: event === null || event === void 0 ? void 0 : event.id,
        offerId: _product === null || _product === void 0 ? void 0 : (_product$bestOffer = _product.bestOffer) === null || _product$bestOffer === void 0 ? void 0 : _product$bestOffer.id,
        quantity: 1,
        sourcePlatform: getSourcePlatform(),
        sourcePosition: SOURCE_POSITION_ONEPASS_HERO_EVENT,
        sourceType: SOURCE_TYPE_ONEPASS_HERO_EVENT.toString()
      });
    }
  }, [addToCart, event, isActiveMember]);

  if (isNewHeroDeal) {
    return ___EmotionJSX(EventBannerWithGallery, {
      isShopToggleExperiment: (shopToggleExperiment === null || shopToggleExperiment === void 0 ? void 0 : shopToggleExperiment.bucket) === 1,
      eventId: event.id,
      product: product,
      eventUrl: eventUrl,
      title: title,
      subtitle: subtitle,
      endDate: endDateDisplay,
      isActiveMember: isActiveMember,
      isSingleProductEvent: isSingleProductEvent,
      isSoldOut: isSoldOut,
      sellPrice: sellPrice === null || sellPrice === void 0 ? void 0 : sellPrice.amount,
      retailPrice: retailPrice === null || retailPrice === void 0 ? void 0 : retailPrice.amount,
      clubSellPrice: clubSellPrice === null || clubSellPrice === void 0 ? void 0 : clubSellPrice.amount,
      socialUrgencyStats: localSocialUrgencyStats,
      onPrimaryCTAClick: handlePrimaryCTAClick
    });
  } else {
    return ___EmotionJSX(HeroEventBanner, {
      isShopToggleExperiment: (shopToggleExperiment === null || shopToggleExperiment === void 0 ? void 0 : shopToggleExperiment.bucket) === 1,
      eventUrl: eventUrl,
      title: title,
      subtitle: subtitle,
      imageUrl: image === null || image === void 0 ? void 0 : image.single.url,
      imageAltText: (image === null || image === void 0 ? void 0 : image.single.altText) || title,
      endDate: endDateDisplay,
      isOnePassExclusive: isOnePassExclusive,
      isActiveMember: isActiveMember,
      isSingleProductEvent: isSingleProductEvent,
      isSoldOut: isSoldOut,
      socialUrgencyStats: localSocialUrgencyStats,
      onPrimaryCTAClick: handlePrimaryCTAClick
    });
  }
};