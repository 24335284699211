import React, { useContext } from "react";
import { Box, Flex, Span, Switch, Text } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { FacetAccordion } from "../FacetAccordion/FacetAccordion";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SafeSearchToggle = function SafeSearchToggle() {
  var _useContext = useContext(CatalogueContext),
      safeSearchEnabled = _useContext.safeSearchEnabled,
      toggleSafeSearch = _useContext.toggleSafeSearch;

  return ___EmotionJSX(FacetAccordion, {
    id: "safe-search",
    displayName: "Safe Search",
    labelText: safeSearchEnabled ? "On" : "Off"
  }, ___EmotionJSX(Flex, null, ___EmotionJSX(Box, null, ___EmotionJSX(Switch, {
    checked: safeSearchEnabled,
    onChange: toggleSafeSearch
  })), ___EmotionJSX(Box, {
    ml: 2
  }, ___EmotionJSX(Text, {
    mt: 0,
    mb: 1,
    fontWeight: "semibold",
    fontSize: "base"
  }, "Hide adult products"), ___EmotionJSX(Span, null, "By turning this off you agree you are over 18 years old."))));
};