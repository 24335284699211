import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Flex, IconOnePass, Span } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { ProductPriceAside } from "./ProductPriceAside";
import { ProductPriceItemChildren } from "./ProductPriceItem";
import { ProductPriceMain } from "./ProductPriceMain";
import { ProductPriceParts } from "./ProductPriceParts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceOnePass = function ProductPriceOnePass(_ref) {
  var price = _ref.price,
      hasPromotion = _ref.hasPromotion,
      large = _ref.large;
  var TextOnePass = styled(Span)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    transform: translateY(1px);\n  "])));
  return price ? ___EmotionJSX(Flex, {
    flex: "1 1 auto",
    flexDirection: "row",
    flexWrap: "nowrap",
    fontSize: "sm"
  }, ___EmotionJSX(ProductPriceItemChildren, {
    alignItems: "center"
  }, ___EmotionJSX(ProductPriceMain, {
    height: "24px",
    hasPromotion: hasPromotion,
    onePassSecondary: true,
    noArrow: true,
    large: large
  }, ___EmotionJSX(ProductPriceParts, {
    color: "onePass.textPrimary",
    fontSize: "sm",
    price: price,
    normalView: true
  })), ___EmotionJSX(ProductPriceAside, {
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    flexWrap: "nowrap",
    fontSize: hasPromotion ? "0.875rem" : "0.656rem",
    hasPromotion: hasPromotion,
    backgroundColor: "onePass.primary",
    borderColor: "onePass.primary",
    height: "24px",
    px: "2px",
    color: "textWhite",
    fontFamily: "onePass",
    large: large
  }, ___EmotionJSX(TextOnePass, {
    fontWeight: "bold",
    color: "inherit",
    fontSize: "2xs",
    margin: "0 2.5px"
  }, "with"), ___EmotionJSX(IconOnePass, {
    height: "auto",
    color: "textWhite",
    preserveAspectRatio: "xMinYMin meet",
    role: "img",
    "aria-label": "OnePass",
    title: "OnePass",
    width: large ? "70px" : "48px"
  })))) : null;
};