import React from "react";
import { ChristmasTheme } from "./ChristmasTheme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LogoDecoration = function LogoDecoration(_ref) {
  var eventTheme = _ref.eventTheme;

  var getThemeType = function getThemeType() {
    switch (eventTheme) {
      case "christmas":
        {
          return ___EmotionJSX(ChristmasTheme, null);
        }

      default:
        {
          return null;
        }
    }
  };

  return getThemeType();
};