import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { AppExperimentContext, emitMigrationModalAction, emitMigrationModalImpression } from "@catchoftheday/analytics";
import { CustomerContext } from "@catchoftheday/customer";
import { cookies, getCookie } from "@catchoftheday/utilities";
import { COOKIE_ONEPASS_MIGRATION_MODAL_KEY } from "../../../consts";
import { generateOnePassConsentUrl, isEligibleForOnePassMigration } from "../../../utilities";
import { MigrationModal } from "../../pure/MigrationModal";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MigrationModalContainer = function MigrationModalContainer() {
  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var _useState2 = useState("/"),
      currentPathname = _useState2[0],
      setCurrentPathname = _useState2[1];

  var _useState3 = useState({}),
      currentQuery = _useState3[0],
      setCurrentQuery = _useState3[1];

  var setCookie = function setCookie() {
    cookies.set(COOKIE_ONEPASS_MIGRATION_MODAL_KEY, "true", {
      expires: 30
    });
  };

  var openModal = function openModal() {
    emitMigrationModalImpression();
    setIsOpen(true);
  };

  var closeModal = function closeModal() {
    setCookie();
    emitMigrationModalAction("close");
    setIsOpen(false);
  };

  var handleProceed = function handleProceed() {
    setCookie();
    emitMigrationModalAction("click");
  };

  var handleLearnMore = function handleLearnMore() {
    setCookie();
  };

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["onepass_migration_popup_may_23"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      experiment = _getExperiments$exper[0],
      experimentLoading = _getExperiments.loading;

  var _useContext2 = useContext(CustomerContext),
      customer = _useContext2.data,
      customerLoading = _useContext2.loading;

  useEffect(function () {
    if (typeof globalThis !== "undefined") {
      setCurrentPathname(globalThis.location.pathname);
      setCurrentQuery(queryString.parse(globalThis.location.search));
    }
  }, []);
  useEffect(function () {
    var cookie = getCookie(COOKIE_ONEPASS_MIGRATION_MODAL_KEY, false);

    if (!experimentLoading && (experiment === null || experiment === void 0 ? void 0 : experiment.bucket) === 1 && !customerLoading && isEligibleForOnePassMigration(customer) && !cookie) {
      openModal();
    }
  }, [customer, customerLoading, experiment === null || experiment === void 0 ? void 0 : experiment.bucket, experimentLoading]);
  return ___EmotionJSX(MigrationModal, {
    isOpen: isOpen,
    ctaUrl: generateOnePassConsentUrl(currentPathname, currentQuery),
    onClose: closeModal,
    onProceed: handleProceed,
    onLearnMore: handleLearnMore
  });
};