import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useContext, useEffect, useState } from "react";
import { CustomSelect, SortIcon, Truncate } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { CatalogueContext } from "../../../contexts/CatalogueContext.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SortOption = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 15px;\n  line-height: 1;\n  cursor: pointer;\n  font-weight: ", ";\n  font-size: ", ";\n  border-bottom: 1px solid ", ";\n  :hover {\n    color: black;\n    background-color: ", ";\n  }\n  ", "\n"])), themeGet("fontWeights.light"), themeGet("fontSizes.sm"), themeGet("colors.ui.borderColor"), themeGet("colors.ui.borderColor"), function (props) {
  return props.isSelected && "\n      background-color: ".concat(themeGet("colors.secondary")(props), "; color: white;\n      &:hover {background-color: ").concat(themeGet("colors.secondary")(props), "; color: white;}\n    ");
});

var getSelectedOption = function getSelectedOption(options) {
  return options.find(function (option) {
    return option.selected;
  }) || options[0];
};

export var SearchSortOptions = function SearchSortOptions(_ref) {
  var options = _ref.options;

  var _useState = useState(getSelectedOption(options)),
      selectedOption = _useState[0],
      setSelectedOption = _useState[1];

  var _useContext = useContext(CatalogueContext),
      addMultipleFilterValues = _useContext.addMultipleFilterValues,
      isSearchLoading = _useContext.isSearchLoading;

  useEffect(function () {
    setSelectedOption(getSelectedOption(options));
  }, [options]);
  var optionItems = options.map(function (option) {
    return {
      label: option.label,
      component: ___EmotionJSX(SortOption, {
        key: option.key,
        title: option.label,
        isSelected: option.selected
      }, option.label),
      onSelect: function onSelect() {
        return addMultipleFilterValues({
          sortBy: option.key,
          sortDirection: option.direction.toLowerCase(),
          page: "1"
        });
      }
    };
  });
  return ___EmotionJSX(CustomSelect, {
    "aria-label": "Sort Options",
    disabled: isSearchLoading,
    customSelectItems: optionItems,
    containerProps: {
      maxWidth: "250px",
      width: {
        xs: "auto",
        md: "250px"
      },
      height: {
        xs: "40px",
        md: "45px"
      }
    },
    height: {
      xs: "40px",
      md: "45px"
    },
    prefixIcon: SortIcon,
    focusBorderColor: {
      xs: "none",
      md: "secondary"
    },
    style: {
      textTransform: "capitalize",
      color: "secondary",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center"
    },
    value: selectedOption.label,
    menuProps: {
      boxShadow: "0 5px 5px 1px rgba(0, 0, 0, 0.15)"
    },
    borderColor: {
      xs: "transparent",
      md: "ui.borderColor"
    },
    py: {
      xs: "0",
      md: "auto"
    },
    borderRadius: "none",
    defaultSelectedIndex: options.map(function (o) {
      return o.label;
    }).indexOf(selectedOption.label)
  }, ___EmotionJSX(Truncate, {
    fontWeight: "inherit",
    fontSize: "base"
  }, "Sort: ", selectedOption.label));
};