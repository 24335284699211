import React, { useCallback } from "react";
import { Text } from "@catchoftheday/cg-components";
import { logIn, LOGIN_URL } from "@catchoftheday/customer";
import { CTAButton } from "../CTAButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellSecondaryCTA = function UpsellSecondaryCTA(_ref) {
  var tight = _ref.tight,
      isLoggedIn = _ref.isLoggedIn,
      onLoginClick = _ref.onLoginClick,
      onLinkClick = _ref.onLinkClick;
  var handleLoginClick = useCallback(function (e) {
    e.preventDefault();

    if (typeof onLoginClick === "function") {
      onLoginClick();
    }

    logIn({
      returnToSelf: true
    });
  }, [onLoginClick]);
  return ___EmotionJSX(Text, {
    fontSize: "xs",
    fontWeight: "normal",
    textAlign: "center",
    color: "textSecondary",
    mb: 0,
    mt: tight ? 1 : 4
  }, "Already a member?", " ", ___EmotionJSX(CTAButton, {
    href: !isLoggedIn ? LOGIN_URL : undefined,
    onClick: isLoggedIn ? onLinkClick : handleLoginClick,
    fontSize: "inherit",
    fontWeight: "semibold"
  }, isLoggedIn ? "Continue with OnePass" : "Log in"));
};