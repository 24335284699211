import React from "react";
import { Box, Heading, Link, Text } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopProductsCarouselZone = function ShopProductsCarouselZone(_ref) {
  var _ref$useBorder = _ref.useBorder,
      useBorder = _ref$useBorder === void 0 ? false : _ref$useBorder,
      sectionHeading = _ref.sectionHeading,
      title = _ref.title,
      subtitle = _ref.subtitle,
      viewAllUrl = _ref.viewAllUrl,
      children = _ref.children;
  var theme = useTheme();
  return ___EmotionJSX(Box, {
    as: "section",
    mt: 3,
    px: 4,
    pb: 15,
    borderBottom: useBorder ? 1 : undefined,
    borderBottomColor: "grey"
  }, sectionHeading, ___EmotionJSX(Box, {
    maxWidth: theme.maxWidths.cotdWebsite,
    margin: "0 auto"
  }, ___EmotionJSX(Box, {
    position: "relative",
    mb: {
      xs: 3,
      md: 8
    }
  }, ___EmotionJSX(Heading.h2, {
    textAlign: "center",
    m: 0,
    fontSize: {
      xs: "xl",
      md: "3xl"
    }
  }, title), subtitle && ___EmotionJSX(Text, {
    textAlign: "center",
    color: "textTertiary",
    fontSize: {
      xs: "sm",
      md: "base"
    },
    m: 0,
    fontWeight: "semibold"
  }, subtitle), viewAllUrl && ___EmotionJSX(Box, {
    position: {
      xs: "static",
      md: "absolute"
    },
    bottom: 0,
    right: 40,
    textAlign: "center",
    mt: 3
  }, ___EmotionJSX(Link, {
    fontWeight: "semibold",
    href: viewAllUrl,
    color: "onePass.primary"
  }, "View All"))), children));
};