import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconHamburger = createSvgIconComponent({
  children: [___EmotionJSX("rect", {
    key: "part-1",
    x: "4",
    y: "6",
    width: "16",
    height: "2",
    rx: "1",
    fill: "currentColor"
  }), ___EmotionJSX("rect", {
    key: "part-2",
    x: "4",
    y: "11",
    width: "16",
    height: "2",
    rx: "1",
    fill: "currentColor"
  }), ___EmotionJSX("rect", {
    key: "part-3",
    x: "4",
    y: "16",
    width: "16",
    height: "2",
    rx: "1",
    fill: "currentColor"
  })],
  viewBox: "0 0 24 24"
});