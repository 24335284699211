import { MAX_RECENT_SEARCH_TERMS, RECENT_SEARCH_TERMS_KEY } from "../consts";
export var updateRecentSearches = function updateRecentSearches(term) {
  var storedSearches = localStorage.getItem(RECENT_SEARCH_TERMS_KEY);
  var storedSearchesArray = [];

  if (storedSearches) {
    var stored = JSON.parse(storedSearches);
    storedSearchesArray = stored.map(function (item) {
      if (typeof item === "string") {
        return item;
      }

      return item.term;
    });
  }

  if (!storedSearchesArray.includes(term)) {
    storedSearchesArray.unshift(term);
  }

  localStorage.setItem(RECENT_SEARCH_TERMS_KEY, JSON.stringify(storedSearchesArray.slice(0, MAX_RECENT_SEARCH_TERMS)));
};