import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";

var _templateObject;

var _excluded = ["height", "width", "src", "alt", "href", "imageCss"];
import React from "react";
import { ImageLazy, Link } from "@catchoftheday/cg-components";
import { css } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LinkImage = function LinkImage(_ref) {
  var height = _ref.height,
      width = _ref.width,
      src = _ref.src,
      alt = _ref.alt,
      href = _ref.href,
      imageCss = _ref.imageCss,
      LinkProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Link, _extends({
    href: href
  }, LinkProps), ___EmotionJSX(ImageLazy, {
    alt: alt,
    src: src,
    width: width,
    height: height,
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n          ", "\n        "])), imageCss || "")
  }));
};