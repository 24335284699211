import React from "react";
import { Box, Flex, Heading, IconOnePass, LinkButton, Text } from "@catchoftheday/cg-components";
import { URL_ONEPASS_PUBLIC_MIGRATION } from "../../../consts";
import { ContentZone, SideImageZone } from "../../layouts/MigrationModalLayout/MigrationModalLayout";
import { CTAButton } from "../CTAButton";
import { MigrationModalSideImage } from "../image-assets/MigrationModalSideImage";
import { OnePassModal } from "../OnePassModal";
import { BenefitItem } from "./BenefitItem";
import { BENEFITS } from "./benefits";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MigrationModal = function MigrationModal(_ref) {
  var isOpen = _ref.isOpen,
      ctaUrl = _ref.ctaUrl,
      onClose = _ref.onClose,
      onProceed = _ref.onProceed,
      onLearnMore = _ref.onLearnMore;
  return ___EmotionJSX(OnePassModal, {
    sizeVariant: "large",
    isOpen: isOpen,
    onRequestClose: onClose,
    contentProps: {
      height: "auto",
      px: 0,
      py: 0
    }
  }, ___EmotionJSX(Flex, {
    flexWrap: "wrap",
    alignItems: "center"
  }, ___EmotionJSX(SideImageZone, null, ___EmotionJSX(MigrationModalSideImage, null)), ___EmotionJSX(ContentZone, null, ___EmotionJSX(Heading.h3, {
    m: 0,
    mb: 4,
    fontWeight: "bold"
  }, ___EmotionJSX(IconOnePass, {
    color: "onePass.primary",
    height: "1em",
    width: "auto",
    mr: 1.5,
    title: "OnePass",
    "aria-label": "OnePass",
    mt: -2
  }), "has moved to a new location"), ___EmotionJSX(Box, {
    fontSize: "sm",
    fontWeight: "medium"
  }, ___EmotionJSX(Text, {
    m: 0,
    mb: 4,
    fontWeight: "inherit"
  }, "Follow the steps to securely transfer your subscription to the new OnePass."), ___EmotionJSX(Text, {
    m: 0,
    fontWeight: "bold"
  }, "You'll get access to:"), BENEFITS.map(function (_ref2, idx) {
    var icon = _ref2.icon,
        description = _ref2.description;
    return ___EmotionJSX(BenefitItem, {
      key: "migration-benefit-".concat(idx),
      icon: icon
    }, description);
  })), ___EmotionJSX(LinkButton, {
    href: ctaUrl,
    variant: "onePassPrimary",
    sizeVariant: "sm",
    fontWeight: "bold",
    mb: 2,
    mt: 4,
    onClick: onProceed
  }, "Transfer to the new OnePass"), ___EmotionJSX(Box, {
    textAlign: "center",
    mb: 4
  }, ___EmotionJSX(CTAButton, {
    href: URL_ONEPASS_PUBLIC_MIGRATION,
    hoverColor: "onePass.primaryDarker",
    onClick: onLearnMore
  }, "Find out more")), ___EmotionJSX(Text, {
    fontSize: "xs",
    textAlign: "center",
    fontWeight: "medium",
    color: "textTertiary",
    m: 0
  }, "*Excludes bulky, special order and marketplace items"))));
};