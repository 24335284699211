export var MOCK_HEADER_DATA = {
  meta: {
    salesChannel: {
      code: "COTD"
    }
  },
  topHeader: {
    leftSection: [{
      name: "Catch Mobile",
      url: "/catch-connect",
      icon: "mobile",
      iconSize: null
    }],
    rightSection: [{
      name: "Careers",
      url: "https://www.catch.com.au/careers ",
      icon: null,
      iconSize: null
    }, {
      name: "Gift Cards",
      url: "/gift-card/",
      icon: null,
      iconSize: null
    }, {
      name: "Help",
      url: "https://help.catch.com.au",
      icon: null,
      iconSize: null
    }, {
      name: "Track My Order",
      url: "https://help.catch.com.au",
      icon: null,
      iconSize: null
    }]
  },
  primaryHeader: {
    logo: {
      enabled: true,
      imageSrc: "https://static.devcat.ch/static/catch/images/logo-83d9b21199.svg",
      alt: "Catch - Screaming Good Deals",
      url: "/",
      condensedImageSrc: null
    },
    searchBar: {
      enabled: true
    },
    club: {
      enabled: false,
      clubCatchTileEnabled: true
    },
    clubFlybuys: {
      enabled: true
    },
    flybuys: {
      enabled: true
    },
    cart: {
      enabled: true
    },
    wishlist: {
      enabled: true
    },
    myAccount: {
      enabled: true,
      showFlybuysLink: true
    }
  },
  headerTheme: "christmas",
  megaMenu: {
    enabled: true,
    navData: {
      shops: [{
        id: 146,
        slug: "beauty",
        name: "Beauty",
        url: "/shop/beauty",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 238,
          slug: "makeup",
          name: "Makeup",
          url: "/shop/beauty/makeup",
          shopNavigationItems: [{
            id: 997,
            slug: "face",
            name: "Face",
            url: "/shop/beauty/makeup/face"
          }, {
            id: 998,
            slug: "eyes",
            name: "Eyes",
            url: "/shop/beauty/makeup/eyes"
          }, {
            id: 999,
            slug: "lips",
            name: "Lips",
            url: "/shop/beauty/makeup/lips"
          }, {
            id: 1000,
            slug: "cheeks",
            name: "Cheeks",
            url: "/shop/beauty/makeup/cheeks"
          }, {
            id: 1001,
            slug: "makeup-sets",
            name: "Makeup Sets",
            url: "/shop/beauty/makeup/makeup-sets"
          }, {
            id: 1002,
            slug: "brushes-tools-accessories",
            name: "Brushes, Tools & Accessories",
            url: "/shop/beauty/makeup/brushes-tools-accessories"
          }, {
            id: 1003,
            slug: "cosmetic-organisers-cases",
            name: "Cosmetic Organisers & Cases",
            url: "/shop/beauty/makeup/cosmetic-organisers-cases"
          }]
        }, {
          id: 249,
          slug: "toiletries",
          name: "Toiletries",
          url: "/shop/beauty/toiletries",
          shopNavigationItems: [{
            id: 1044,
            slug: "handwash-sanitisers",
            name: "Handwash & Sanitisers",
            url: "/shop/beauty/toiletries/handwash-sanitisers"
          }, {
            id: 1045,
            slug: "tissues-cotton-wool-toilet-paper",
            name: "Tissues, Cotton Wool & Toilet Paper",
            url: "/shop/beauty/toiletries/tissues-cotton-wool-toilet-paper"
          }, {
            id: 1046,
            slug: "deodorant-body-spray",
            name: "Deodorant & Body Spray",
            url: "/shop/beauty/toiletries/deodorant-body-spray"
          }, {
            id: 1047,
            slug: "feminine-hygiene",
            name: "Feminine Hygiene",
            url: "/shop/beauty/toiletries/feminine-hygiene"
          }, {
            id: 1048,
            slug: "hair-removal",
            name: "Hair Removal",
            url: "/shop/beauty/toiletries/hair-removal"
          }]
        }, {
          id: 251,
          slug: "manicure-pedicure",
          name: "Manicure & Pedicure",
          url: "/shop/beauty/manicure-pedicure",
          shopNavigationItems: [{
            id: 1055,
            slug: "gel-nail-polish",
            name: "Gel Nail Polish",
            url: "/shop/beauty/manicure-pedicure/gel-nail-polish"
          }, {
            id: 1056,
            slug: "nail-design",
            name: "Nail Design",
            url: "/shop/beauty/manicure-pedicure/nail-design"
          }, {
            id: 1057,
            slug: "nail-polish",
            name: "Nail Polish",
            url: "/shop/beauty/manicure-pedicure/nail-polish"
          }, {
            id: 1058,
            slug: "tools-accessories",
            name: "Tools & Accessories",
            url: "/shop/beauty/manicure-pedicure/tools-accessories"
          }, {
            id: 1059,
            slug: "foot-care",
            name: "Foot Care",
            url: "/shop/beauty/manicure-pedicure/foot-care"
          }]
        }, {
          id: 243,
          slug: "skincare",
          name: "Skincare",
          url: "/shop/beauty/skincare",
          shopNavigationItems: [{
            id: 1020,
            slug: "body",
            name: "Body",
            url: "/shop/beauty/skincare/body"
          }, {
            id: 1021,
            slug: "eyes",
            name: "Eyes",
            url: "/shop/beauty/skincare/eyes"
          }, {
            id: 1022,
            slug: "face",
            name: "Face",
            url: "/shop/beauty/skincare/face"
          }, {
            id: 1023,
            slug: "hands-feet",
            name: "Hands & Feet",
            url: "/shop/beauty/skincare/hands-feet"
          }, {
            id: 1024,
            slug: "sun-care-tanning",
            name: "Sun Care & Tanning",
            url: "/shop/beauty/skincare/sun-care-tanning"
          }]
        }, {
          id: 245,
          slug: "beauty-grooming",
          name: "Beauty & Grooming",
          url: "/shop/beauty/beauty-grooming",
          shopNavigationItems: [{
            id: 1027,
            slug: "facial-brush",
            name: "Facial Brush",
            url: "/shop/beauty/beauty-grooming/facial-brush"
          }, {
            id: 1028,
            slug: "facial-sauner",
            name: "Facial Sauna",
            url: "/shop/beauty/beauty-grooming/facial-sauner"
          }, {
            id: 1029,
            slug: "massager-footspa",
            name: "Massager & Footspa",
            url: "/shop/beauty/beauty-grooming/massager-footspa"
          }, {
            id: 1030,
            slug: "shaving-hair-removal",
            name: "Shaving & Hair Removal",
            url: "/shop/beauty/beauty-grooming/shaving-hair-removal"
          }]
        }, {
          id: 250,
          slug: "dental-oral-care",
          name: "Dental & Oral Care",
          url: "/shop/beauty/dental-oral-care",
          shopNavigationItems: [{
            id: 1049,
            slug: "toothbrushes",
            name: "Toothbrushes",
            url: "/shop/beauty/dental-oral-care/toothbrushes"
          }, {
            id: 1050,
            slug: "toothpaste",
            name: "Toothpaste",
            url: "/shop/beauty/dental-oral-care/toothpaste"
          }, {
            id: 1051,
            slug: "teeth-whitening",
            name: "Teeth Whitening",
            url: "/shop/beauty/dental-oral-care/teeth-whitening"
          }, {
            id: 1052,
            slug: "mouthwash-breath-fresheners",
            name: "Mouthwash & Breath Fresheners",
            url: "/shop/beauty/dental-oral-care/mouthwash-breath-fresheners"
          }, {
            id: 1053,
            slug: "dental-floss-picks",
            name: "Dental Floss & Picks",
            url: "/shop/beauty/dental-oral-care/dental-floss-picks"
          }]
        }, {
          id: 240,
          slug: "mens-grooming",
          name: "Men's Grooming",
          url: "/shop/beauty/mens-grooming",
          shopNavigationItems: [{
            id: 1007,
            slug: "fragrances",
            name: "Fragrances",
            url: "/shop/beauty/mens-grooming/fragrances"
          }, {
            id: 1008,
            slug: "hair-care",
            name: "Hair Care",
            url: "/shop/beauty/mens-grooming/hair-care"
          }, {
            id: 1009,
            slug: "skin-care",
            name: "Skin Care",
            url: "/shop/beauty/mens-grooming/skin-care"
          }, {
            id: 1010,
            slug: "shaving",
            name: "Shaving",
            url: "/shop/beauty/mens-grooming/shaving"
          }]
        }, {
          id: 248,
          slug: "hair-care",
          name: "Hair Care",
          url: "/shop/beauty/hair-care",
          shopNavigationItems: [{
            id: 1037,
            slug: "hair-care-products",
            name: "Shampoo & Conditioner",
            url: "/shop/beauty/hair-care/hair-care-products"
          }, {
            id: 1041,
            slug: "styling-products",
            name: "Styling Products",
            url: "/shop/beauty/hair-care/styling-products"
          }, {
            id: 1042,
            slug: "hair-straighteners-curlers",
            name: "Hair Straighteners/Curlers",
            url: "/shop/beauty/hair-care/hair-straighteners-curlers"
          }, {
            id: 1043,
            slug: "hair-dryer",
            name: "Hair Dryer",
            url: "/shop/beauty/hair-care/hair-dryer"
          }, {
            id: 1040,
            slug: "hair-styling-accessories",
            name: "Hair Styling Accessories",
            url: "/shop/beauty/hair-care/hair-styling-accessories"
          }, {
            id: 1039,
            slug: "hair-cutting-tools",
            name: "Hair Cutting Tools",
            url: "/shop/beauty/hair-care/hair-cutting-tools"
          }, {
            id: 1038,
            slug: "hair-colour",
            name: "Hair Colour",
            url: "/shop/beauty/hair-care/hair-colour"
          }]
        }, {
          id: 244,
          slug: "fragrance",
          name: "Fragrance",
          url: "/shop/beauty/fragrance",
          shopNavigationItems: [{
            id: 1025,
            slug: "women",
            name: "Women",
            url: "/shop/beauty/fragrance/women"
          }, {
            id: 1026,
            slug: "men",
            name: "Men",
            url: "/shop/beauty/fragrance/men"
          }]
        }, {
          id: 239,
          slug: "bath-body",
          name: "Bath & Body",
          url: "/shop/beauty/bath-body",
          shopNavigationItems: [{
            id: 1004,
            slug: "body-moisturiser-body-wash",
            name: "Body Moisturiser & Body Wash",
            url: "/shop/beauty/bath-body/body-moisturiser-body-wash"
          }, {
            id: 1005,
            slug: "bathing-accessories",
            name: "Bathing Accessories",
            url: "/shop/beauty/bath-body/bathing-accessories"
          }, {
            id: 1006,
            slug: "hand-care",
            name: "Hand Care",
            url: "/shop/beauty/bath-body/hand-care"
          }]
        }, {
          id: 241,
          slug: "aromatherapy",
          name: "Aromatherapy",
          url: "/shop/beauty/aromatherapy",
          shopNavigationItems: [{
            id: 1011,
            slug: "essential-oils-melts",
            name: "Essential Oils & Melts",
            url: "/shop/beauty/aromatherapy/essential-oils-melts"
          }, {
            id: 1012,
            slug: "massage-oil",
            name: "Massage Oil",
            url: "/shop/beauty/aromatherapy/massage-oil"
          }, {
            id: 1013,
            slug: "room-mist",
            name: "Room Mist",
            url: "/shop/beauty/aromatherapy/room-mist"
          }, {
            id: 1014,
            slug: "air-purifier",
            name: "Air Purifier",
            url: "/shop/beauty/aromatherapy/air-purifier"
          }, {
            id: 1015,
            slug: "humidifier",
            name: "Humidifier",
            url: "/shop/beauty/aromatherapy/humidifier"
          }, {
            id: 1016,
            slug: "candles",
            name: "Candles",
            url: "/shop/beauty/aromatherapy/candles"
          }]
        }],
        topBrands: [{
          id: 7075,
          slug: "nars",
          name: "Nars",
          url: "/brand/nars"
        }, {
          id: 6065,
          slug: "bys",
          name: "BYS",
          url: "/brand/bys"
        }, {
          id: 4784,
          slug: "loreal",
          name: "L'Oreal",
          url: "/brand/loreal"
        }, {
          id: 4335,
          slug: "maybelline",
          name: "Maybelline",
          url: "/brand/maybelline"
        }, {
          id: 3179,
          slug: "revlon",
          name: "Revlon",
          url: "/brand/revlon"
        }, {
          id: 6143,
          slug: "nude-by-nature",
          name: "Nude by Nature",
          url: "/brand/nude-by-nature"
        }, {
          id: 7630,
          slug: "max-factor",
          name: "Max Factor",
          url: "/brand/max-factor"
        }]
      }, {
        id: 148,
        slug: "home-kitchen",
        name: "Home & Kitchen",
        url: "/shop/home-kitchen",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 267,
          slug: "bedding",
          name: "Bedding",
          url: "/shop/home-kitchen/bedding",
          shopNavigationItems: [{
            id: 1137,
            slug: "sheets",
            name: "Sheets",
            url: "/shop/home-kitchen/bedding/sheets"
          }, {
            id: 1138,
            slug: "quilt-covers",
            name: "Quilt Covers",
            url: "/shop/home-kitchen/bedding/quilt-covers"
          }, {
            id: 1139,
            slug: "quilts",
            name: "Quilts",
            url: "/shop/home-kitchen/bedding/quilts"
          }, {
            id: 1140,
            slug: "throws-blankets",
            name: "Throws & Blankets",
            url: "/shop/home-kitchen/bedding/throws-blankets"
          }, {
            id: 1141,
            slug: "pillows",
            name: "Pillows",
            url: "/shop/home-kitchen/bedding/pillows"
          }, {
            id: 1142,
            slug: "protectors",
            name: "Protectors",
            url: "/shop/home-kitchen/bedding/protectors"
          }]
        }, {
          id: 273,
          slug: "bathroom",
          name: "Bathroom",
          url: "/shop/home-kitchen/bathroom",
          shopNavigationItems: [{
            id: 1173,
            slug: "bath-towels",
            name: "Bath Towels",
            url: "/shop/home-kitchen/bathroom/bath-towels"
          }, {
            id: 1174,
            slug: "beach-towels",
            name: "Beach Towels",
            url: "/shop/home-kitchen/bathroom/beach-towels"
          }, {
            id: 1175,
            slug: "bathroom-accessories",
            name: "Bathroom Accessories",
            url: "/shop/home-kitchen/bathroom/bathroom-accessories"
          }, {
            id: 1176,
            slug: "bath-mats",
            name: "Bath Mats",
            url: "/shop/home-kitchen/bathroom/bath-mats"
          }]
        }, {
          id: 268,
          slug: "home-decor",
          name: "Home Decor",
          url: "/shop/home-kitchen/home-decor",
          shopNavigationItems: [{
            id: 1147,
            slug: "candles-diffusers",
            name: "Candles & Diffusers",
            url: "/shop/home-kitchen/home-decor/candles-diffusers"
          }, {
            id: 1143,
            slug: "cushions",
            name: "Cushions",
            url: "/shop/home-kitchen/home-decor/cushions"
          }, {
            id: 1144,
            slug: "decor",
            name: "Decor",
            url: "/shop/home-kitchen/home-decor/decor"
          }, {
            id: 1145,
            slug: "lighting",
            name: "Lighting",
            url: "/shop/home-kitchen/home-decor/lighting"
          }, {
            id: 1146,
            slug: "storage-organisation",
            name: "Storage & Organisation",
            url: "/shop/home-kitchen/home-decor/storage-organisation"
          }, {
            id: 1148,
            slug: "photo-frames-albums",
            name: "Photo Frames & Albums",
            url: "/shop/home-kitchen/home-decor/photo-frames-albums"
          }, {
            id: 1149,
            slug: "vases",
            name: "Vases",
            url: "/shop/home-kitchen/home-decor/vases"
          }, {
            id: 1150,
            slug: "wall-art",
            name: "Wall Art",
            url: "/shop/home-kitchen/home-decor/wall-art"
          }, {
            id: 1151,
            slug: "christmas-trim",
            name: "Christmas Trim",
            url: "/shop/home-kitchen/home-decor/christmas-trim"
          }]
        }, {
          id: 351,
          slug: "personalised",
          name: "Personalised",
          url: "/shop/home-kitchen/personalised",
          shopNavigationItems: []
        }, {
          id: 365,
          slug: "diy",
          name: "DIY",
          url: "/shop/home-kitchen/diy",
          shopNavigationItems: []
        }, {
          id: 270,
          slug: "kitchen",
          name: "Kitchen",
          url: "/shop/home-kitchen/kitchen",
          shopNavigationItems: [{
            id: 1155,
            slug: "baking",
            name: "Baking",
            url: "/shop/home-kitchen/kitchen/baking"
          }, {
            id: 1156,
            slug: "chopping-boards",
            name: "Chopping Boards",
            url: "/shop/home-kitchen/kitchen/chopping-boards"
          }, {
            id: 1157,
            slug: "cookware",
            name: "Cookware",
            url: "/shop/home-kitchen/kitchen/cookware"
          }, {
            id: 1158,
            slug: "food-preparation",
            name: "Food Preparation",
            url: "/shop/home-kitchen/kitchen/food-preparation"
          }, {
            id: 1159,
            slug: "knives",
            name: "Knives",
            url: "/shop/home-kitchen/kitchen/knives"
          }, {
            id: 1160,
            slug: "food-storage",
            name: "Food Storage",
            url: "/shop/home-kitchen/kitchen/food-storage"
          }]
        }, {
          id: 354,
          slug: "dining",
          name: "Dining",
          url: "/shop/home-kitchen/dining",
          shopNavigationItems: [{
            id: 1587,
            slug: "dinnerware-serveware",
            name: "Dinnerware & Serveware",
            url: "/shop/home-kitchen/dining/dinnerware-serveware"
          }, {
            id: 1588,
            slug: "drinkware-barware",
            name: "Drinkware & Barware",
            url: "/shop/home-kitchen/dining/drinkware-barware"
          }, {
            id: 1589,
            slug: "cutlery",
            name: "Cutlery",
            url: "/shop/home-kitchen/dining/cutlery"
          }, {
            id: 1590,
            slug: "table-linen-accessories",
            name: "Table Linen & Accessories",
            url: "/shop/home-kitchen/dining/table-linen-accessories"
          }, {
            id: 1591,
            slug: "tea-coffee",
            name: "Tea & Coffee",
            url: "/shop/home-kitchen/dining/tea-coffee"
          }]
        }, {
          id: 350,
          slug: "rugs",
          name: "Rugs",
          url: "/shop/home-kitchen/rugs",
          shopNavigationItems: [{
            id: 1571,
            slug: "outdoor-rugs",
            name: "Outdoor rugs",
            url: "/shop/home-kitchen/rugs/outdoor-rugs"
          }, {
            id: 1572,
            slug: "contemporary-rugs",
            name: "Contemporary Rugs",
            url: "/shop/home-kitchen/rugs/contemporary-rugs"
          }, {
            id: 1573,
            slug: "shag-rugs",
            name: "Shag Rugs",
            url: "/shop/home-kitchen/rugs/shag-rugs"
          }, {
            id: 1574,
            slug: "traditional-rugs",
            name: "Traditional Rugs",
            url: "/shop/home-kitchen/rugs/traditional-rugs"
          }, {
            id: 1575,
            slug: "mat",
            name: "Mat",
            url: "/shop/home-kitchen/rugs/mat"
          }, {
            id: 1576,
            slug: "kids-rugs",
            name: "Kids Rugs",
            url: "/shop/home-kitchen/rugs/kids-rugs"
          }]
        }, {
          id: 277,
          slug: "home-storage",
          name: "Home Storage",
          url: "/shop/home-kitchen/home-storage",
          shopNavigationItems: [{
            id: 1187,
            slug: "clothing-wardrobe",
            name: "Clothing & Wardrobe",
            url: "/shop/home-kitchen/home-storage/clothing-wardrobe"
          }, {
            id: 1188,
            slug: "kitchen",
            name: "Kitchen",
            url: "/shop/home-kitchen/home-storage/kitchen"
          }, {
            id: 1189,
            slug: "laundry",
            name: "Laundry",
            url: "/shop/home-kitchen/home-storage/laundry"
          }, {
            id: 1190,
            slug: "bathroom",
            name: "Bathroom",
            url: "/shop/home-kitchen/home-storage/bathroom"
          }]
        }, {
          id: 272,
          slug: "stationery",
          name: "Stationery",
          url: "/shop/home-kitchen/stationery",
          shopNavigationItems: [{
            id: 1167,
            slug: "cards-gift-packaging",
            name: "Cards & Gift Packaging",
            url: "/shop/home-kitchen/stationery/cards-gift-packaging"
          }, {
            id: 1168,
            slug: "pens-pencils-pencil-cases",
            name: "Pens, Pencils & Pencil Cases",
            url: "/shop/home-kitchen/stationery/pens-pencils-pencil-cases"
          }, {
            id: 1169,
            slug: "notebooks-paper",
            name: "Notebooks & Paper",
            url: "/shop/home-kitchen/stationery/notebooks-paper"
          }, {
            id: 1170,
            slug: "diaries-calendars",
            name: "Diaries & Calendars",
            url: "/shop/home-kitchen/stationery/diaries-calendars"
          }, {
            id: 1171,
            slug: "office-supplies",
            name: "Office Supplies",
            url: "/shop/home-kitchen/stationery/office-supplies"
          }, {
            id: 1172,
            slug: "personalised-photobooks",
            name: "Personalised Photo Books",
            url: "/shop/home-kitchen/stationery/personalised-photobooks"
          }]
        }, {
          id: 269,
          slug: "arts-crafts-sewing",
          name: "Arts, Crafts & Sewing",
          url: "/shop/home-kitchen/arts-crafts-sewing",
          shopNavigationItems: [{
            id: 1152,
            slug: "craft-hobbies",
            name: "Craft & Hobbies",
            url: "/shop/home-kitchen/arts-crafts-sewing/craft-hobbies"
          }, {
            id: 1153,
            slug: "sewing-fabric",
            name: "Sewing & Fabric",
            url: "/shop/home-kitchen/arts-crafts-sewing/sewing-fabric"
          }, {
            id: 1154,
            slug: "art-drawing",
            name: "Art & Drawing",
            url: "/shop/home-kitchen/arts-crafts-sewing/art-drawing"
          }]
        }, {
          id: 271,
          slug: "festive-party-supplies",
          name: "Festive & Party Supplies",
          url: "/shop/home-kitchen/festive-party-supplies",
          shopNavigationItems: []
        }, {
          id: 275,
          slug: "books-media",
          name: "Books & Media",
          url: "/shop/home-kitchen/books-media",
          shopNavigationItems: [{
            id: 1182,
            slug: "books",
            name: "Books",
            url: "/shop/home-kitchen/books-media/books"
          }, {
            id: 1183,
            slug: "cookbook",
            name: "Cookbook",
            url: "/shop/home-kitchen/books-media/cookbook"
          }, {
            id: 1184,
            slug: "movies",
            name: "Movies",
            url: "/shop/home-kitchen/books-media/movies"
          }, {
            id: 1185,
            slug: "music",
            name: "Music",
            url: "/shop/home-kitchen/books-media/music"
          }]
        }, {
          id: 364,
          slug: "tools-hardware",
          name: "Tools & Hardware",
          url: "/shop/home-kitchen/tools-hardware",
          shopNavigationItems: []
        }, {
          id: 274,
          slug: "outdoor",
          name: "Outdoor",
          url: "/shop/home-kitchen/outdoor",
          shopNavigationItems: [{
            id: 1177,
            slug: "bbq",
            name: "BBQ",
            url: "/shop/home-kitchen/outdoor/bbq"
          }, {
            id: 1178,
            slug: "bbq-accessories",
            name: "BBQ Accessories",
            url: "/shop/home-kitchen/outdoor/bbq-accessories"
          }, {
            id: 1179,
            slug: "bbq-tools",
            name: "BBQ Tools",
            url: "/shop/home-kitchen/outdoor/bbq-tools"
          }, {
            id: 1180,
            slug: "bbq-cleaning",
            name: "BBQ Cleaning",
            url: "/shop/home-kitchen/outdoor/bbq-cleaning"
          }, {
            id: 1181,
            slug: "picnic-supplies",
            name: "Picnic Supplies",
            url: "/shop/home-kitchen/outdoor/picnic-supplies"
          }]
        }],
        topBrands: []
      }, {
        id: 187,
        slug: "shop-apple",
        name: "Apple",
        url: "/shop/shop-apple",
        pinToTop: true,
        shopNavigationGroups: [],
        topBrands: [{
          id: 2992,
          slug: "apple",
          name: "Apple",
          url: "/brand/apple"
        }]
      }, {
        id: 69,
        slug: "onepass",
        name: "OnePass",
        url: "/shop/onepass",
        pinToTop: true,
        shopNavigationGroups: [],
        topBrands: []
      }, {
        id: 95,
        slug: "marketplace",
        name: "Marketplace Deals",
        url: "/shop/marketplace",
        pinToTop: true,
        shopNavigationGroups: [],
        topBrands: [{
          id: 8577,
          slug: "amit",
          name: "amit",
          url: "/brand/amit"
        }, {
          id: 8586,
          slug: "amit-clothing",
          name: "amit-clothing",
          url: "/brand/amit-clothing"
        }, {
          id: 12983,
          slug: "javees",
          name: "Javees",
          url: "/brand/javees"
        }, {
          id: 2820,
          slug: "nike",
          name: "Nike",
          url: "/brand/nike"
        }, {
          id: 5143,
          slug: "dora-the-explorer",
          name: "Dora The Explorer",
          url: "/brand/dora-the-explorer"
        }]
      }, {
        id: 144,
        slug: "mens-fashion",
        name: "Men's Fashion",
        url: "/shop/mens-fashion",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 208,
          slug: "mens-clothing",
          name: "Clothing",
          url: "/shop/mens-fashion/mens-clothing",
          shopNavigationItems: [{
            id: 885,
            slug: "tops",
            name: "Tops",
            url: "/shop/mens-fashion/mens-clothing/tops"
          }, {
            id: 886,
            slug: "shirts",
            name: "Shirts",
            url: "/shop/mens-fashion/mens-clothing/shirts"
          }, {
            id: 887,
            slug: "polo-shirts",
            name: "Polo Shirts",
            url: "/shop/mens-fashion/mens-clothing/polo-shirts"
          }, {
            id: 888,
            slug: "coats-jackets",
            name: "Coats & Jackets",
            url: "/shop/mens-fashion/mens-clothing/coats-jackets"
          }, {
            id: 889,
            slug: "knitwear-sweaters",
            name: "Knitwear & Sweaters",
            url: "/shop/mens-fashion/mens-clothing/knitwear-sweaters"
          }, {
            id: 890,
            slug: "singlets-tanks",
            name: "Singlets & Tanks",
            url: "/shop/mens-fashion/mens-clothing/singlets-tanks"
          }, {
            id: 891,
            slug: "jeans",
            name: "Jeans",
            url: "/shop/mens-fashion/mens-clothing/jeans"
          }, {
            id: 892,
            slug: "shorts",
            name: "Shorts",
            url: "/shop/mens-fashion/mens-clothing/shorts"
          }, {
            id: 893,
            slug: "pants-chinos",
            name: "Pants & Chinos",
            url: "/shop/mens-fashion/mens-clothing/pants-chinos"
          }, {
            id: 894,
            slug: "trackpants-and-joggers",
            name: "Trackpants and Joggers",
            url: "/shop/mens-fashion/mens-clothing/trackpants-and-joggers"
          }, {
            id: 1594,
            slug: "sweats-and-hoodies",
            name: "Sweats and Hoodies",
            url: "/shop/mens-fashion/mens-clothing/sweats-and-hoodies"
          }]
        }, {
          id: 223,
          slug: "watches",
          name: "Watches",
          url: "/shop/mens-fashion/watches",
          shopNavigationItems: [{
            id: 922,
            slug: "casual",
            name: "Casual",
            url: "/shop/mens-fashion/watches/casual"
          }, {
            id: 923,
            slug: "sports",
            name: "Sports",
            url: "/shop/mens-fashion/watches/sports"
          }, {
            id: 924,
            slug: "luxury",
            name: "Luxury",
            url: "/shop/mens-fashion/watches/luxury"
          }]
        }, {
          id: 224,
          slug: "sunglasses",
          name: "Sunglasses",
          url: "/shop/mens-fashion/sunglasses",
          shopNavigationItems: []
        }, {
          id: 227,
          slug: "luggage",
          name: "Luggage",
          url: "/shop/mens-fashion/luggage",
          shopNavigationItems: [{
            id: 935,
            slug: "backpacks",
            name: "Backpacks",
            url: "/shop/mens-fashion/luggage/backpacks"
          }, {
            id: 936,
            slug: "carry-ons",
            name: "Carry-ons",
            url: "/shop/mens-fashion/luggage/carry-ons"
          }, {
            id: 937,
            slug: "suitcases",
            name: "Suitcases",
            url: "/shop/mens-fashion/luggage/suitcases"
          }, {
            id: 938,
            slug: "luggage-sets",
            name: "Luggage Sets",
            url: "/shop/mens-fashion/luggage/luggage-sets"
          }, {
            id: 939,
            slug: "duffels",
            name: "Duffels",
            url: "/shop/mens-fashion/luggage/duffels"
          }, {
            id: 940,
            slug: "overnight",
            name: "Overnight",
            url: "/shop/mens-fashion/luggage/overnight"
          }, {
            id: 941,
            slug: "travel-accessories",
            name: "Travel Accessories",
            url: "/shop/mens-fashion/luggage/travel-accessories"
          }]
        }, {
          id: 220,
          slug: "swim",
          name: "Swim",
          url: "/shop/mens-fashion/swim",
          shopNavigationItems: [{
            id: 906,
            slug: "board-shorts",
            name: "Board Shorts",
            url: "/shop/mens-fashion/swim/board-shorts"
          }, {
            id: 907,
            slug: "trunks-briefs",
            name: "Trunks & Briefs",
            url: "/shop/mens-fashion/swim/trunks-briefs"
          }, {
            id: 908,
            slug: "rash-vests",
            name: "Rash Vests",
            url: "/shop/mens-fashion/swim/rash-vests"
          }]
        }, {
          id: 222,
          slug: "footwear",
          name: "Footwear",
          url: "/shop/mens-fashion/footwear",
          shopNavigationItems: [{
            id: 914,
            slug: "thongs",
            name: "Thongs",
            url: "/shop/mens-fashion/footwear/thongs"
          }, {
            id: 915,
            slug: "sandles",
            name: "Sandals",
            url: "/shop/mens-fashion/footwear/sandles"
          }, {
            id: 916,
            slug: "comfort",
            name: "Comfort",
            url: "/shop/mens-fashion/footwear/comfort"
          }, {
            id: 917,
            slug: "casual-sneakers",
            name: "Casual Sneakers",
            url: "/shop/mens-fashion/footwear/casual-sneakers"
          }, {
            id: 918,
            slug: "dress-shoes",
            name: "Dress Shoes",
            url: "/shop/mens-fashion/footwear/dress-shoes"
          }, {
            id: 919,
            slug: "boots",
            name: "Boots",
            url: "/shop/mens-fashion/footwear/boots"
          }, {
            id: 920,
            slug: "uggs-and-slippers",
            name: "Uggs and Slippers",
            url: "/shop/mens-fashion/footwear/uggs-and-slippers"
          }, {
            id: 921,
            slug: "sport-shoes",
            name: "Sport Shoes",
            url: "/shop/mens-fashion/footwear/sport-shoes"
          }]
        }, {
          id: 225,
          slug: "hats-and-caps",
          name: "Hats and Caps",
          url: "/shop/mens-fashion/hats-and-caps",
          shopNavigationItems: [{
            id: 926,
            slug: "snap-back",
            name: "Snap Back",
            url: "/shop/mens-fashion/hats-and-caps/snap-back"
          }, {
            id: 927,
            slug: "beanies",
            name: "Beanies",
            url: "/shop/mens-fashion/hats-and-caps/beanies"
          }, {
            id: 928,
            slug: "bucket-hats",
            name: "Bucket Hats",
            url: "/shop/mens-fashion/hats-and-caps/bucket-hats"
          }]
        }, {
          id: 363,
          slug: "tradie-workwear",
          name: "Tradie Workwear",
          url: "/shop/mens-fashion/tradie-workwear",
          shopNavigationItems: [{
            id: 1658,
            slug: "clothing",
            name: "Clothing",
            url: "/shop/mens-fashion/tradie-workwear/clothing"
          }, {
            id: 1659,
            slug: "footwear",
            name: "Footwear",
            url: "/shop/mens-fashion/tradie-workwear/footwear"
          }, {
            id: 1660,
            slug: "hi-vis",
            name: "Hi Vis",
            url: "/shop/mens-fashion/tradie-workwear/hi-vis"
          }, {
            id: 1661,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/mens-fashion/tradie-workwear/accessories"
          }]
        }, {
          id: 218,
          slug: "underwear-socks-loungewear",
          name: "Underwear, Socks & Loungewear",
          url: "/shop/mens-fashion/underwear-socks-loungewear",
          shopNavigationItems: [{
            id: 896,
            slug: "underwear",
            name: "Underwear",
            url: "/shop/mens-fashion/underwear-socks-loungewear/underwear"
          }, {
            id: 897,
            slug: "socks",
            name: "Socks",
            url: "/shop/mens-fashion/underwear-socks-loungewear/socks"
          }, {
            id: 898,
            slug: "loungewear",
            name: "Loungewear",
            url: "/shop/mens-fashion/underwear-socks-loungewear/loungewear"
          }, {
            id: 899,
            slug: "sleepwear-robes",
            name: "Sleepwear & Robes",
            url: "/shop/mens-fashion/underwear-socks-loungewear/sleepwear-robes"
          }]
        }, {
          id: 219,
          slug: "suits-officewear",
          name: "Suits & Officewear",
          url: "/shop/mens-fashion/suits-officewear",
          shopNavigationItems: [{
            id: 900,
            slug: "business-shirts",
            name: "Business Shirts",
            url: "/shop/mens-fashion/suits-officewear/business-shirts"
          }, {
            id: 901,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/mens-fashion/suits-officewear/accessories"
          }, {
            id: 902,
            slug: "business-dress-shoes",
            name: "Business & Dress Shoes",
            url: "/shop/mens-fashion/suits-officewear/business-dress-shoes"
          }, {
            id: 903,
            slug: "suits",
            name: "Suits",
            url: "/shop/mens-fashion/suits-officewear/suits"
          }, {
            id: 904,
            slug: "pants",
            name: "Pants",
            url: "/shop/mens-fashion/suits-officewear/pants"
          }, {
            id: 905,
            slug: "jackets",
            name: "Jackets",
            url: "/shop/mens-fashion/suits-officewear/jackets"
          }]
        }, {
          id: 221,
          slug: "accessories",
          name: "Accessories",
          url: "/shop/mens-fashion/accessories",
          shopNavigationItems: [{
            id: 909,
            slug: "belts",
            name: "Belts",
            url: "/shop/mens-fashion/accessories/belts"
          }, {
            id: 910,
            slug: "ties-cufflinks",
            name: "Ties & Cufflinks",
            url: "/shop/mens-fashion/accessories/ties-cufflinks"
          }, {
            id: 911,
            slug: "gloves-scarfs",
            name: "Gloves & Scarfs",
            url: "/shop/mens-fashion/accessories/gloves-scarfs"
          }, {
            id: 912,
            slug: "jewellery",
            name: "Jewellery",
            url: "/shop/mens-fashion/accessories/jewellery"
          }, {
            id: 913,
            slug: "keyring",
            name: "Keyrings",
            url: "/shop/mens-fashion/accessories/keyring"
          }]
        }, {
          id: 226,
          slug: "bag-wallets",
          name: "Bags & Wallets",
          url: "/shop/mens-fashion/bag-wallets",
          shopNavigationItems: [{
            id: 930,
            slug: "bum-bags",
            name: "Bum bags",
            url: "/shop/mens-fashion/bag-wallets/bum-bags"
          }, {
            id: 931,
            slug: "gym-bags",
            name: "Gym bags",
            url: "/shop/mens-fashion/bag-wallets/gym-bags"
          }, {
            id: 932,
            slug: "laptop-bags",
            name: "Laptop Bags",
            url: "/shop/mens-fashion/bag-wallets/laptop-bags"
          }, {
            id: 933,
            slug: "bags",
            name: "Bags",
            url: "/shop/mens-fashion/bag-wallets/bags"
          }, {
            id: 934,
            slug: "wallets-cardholders",
            name: "Wallets & Cardholders",
            url: "/shop/mens-fashion/bag-wallets/wallets-cardholders"
          }]
        }],
        topBrands: [{
          id: 4001,
          slug: "puma",
          name: "Puma",
          url: "/brand/puma"
        }, {
          id: 2837,
          slug: "adidas",
          name: "Adidas",
          url: "/brand/adidas"
        }, {
          id: 3972,
          slug: "polo-ralph-lauren",
          name: "Polo Ralph Lauren",
          url: "/brand/polo-ralph-lauren"
        }, {
          id: 3793,
          slug: "lacoste",
          name: "Lacoste",
          url: "/brand/lacoste"
        }, {
          id: 15384,
          slug: "levis-1",
          name: "Levi's",
          url: "/brand/levis-1"
        }, {
          id: 3716,
          slug: "lee",
          name: "Lee",
          url: "/brand/lee"
        }, {
          id: 2953,
          slug: "nixon",
          name: "Nixon",
          url: "/brand/nixon"
        }, {
          id: 3425,
          slug: "nerf",
          name: "NERF",
          url: "/brand/nerf"
        }, {
          id: 2979,
          slug: "casio",
          name: "Casio",
          url: "/brand/casio"
        }, {
          id: 2808,
          slug: "asics",
          name: "ASICS",
          url: "/brand/asics"
        }, {
          id: 5332,
          slug: "birkenstock",
          name: "Birkenstock",
          url: "/brand/birkenstock"
        }, {
          id: 2867,
          slug: "converse",
          name: "Converse",
          url: "/brand/converse"
        }, {
          id: 2885,
          slug: "havaianas",
          name: "Havaianas",
          url: "/brand/havaianas"
        }, {
          id: 3016,
          slug: "teva",
          name: "Teva",
          url: "/brand/teva"
        }, {
          id: 2834,
          slug: "vans",
          name: "Vans",
          url: "/brand/vans"
        }, {
          id: 2820,
          slug: "nike",
          name: "Nike",
          url: "/brand/nike"
        }, {
          id: 3159,
          slug: "hugo-boss",
          name: "Hugo Boss",
          url: "/brand/hugo-boss"
        }, {
          id: 18649,
          slug: "la-deqor",
          name: "La Deqor",
          url: "/brand/la-deqor"
        }]
      }, {
        id: 143,
        slug: "womens-fashion",
        name: "Women's Fashion",
        url: "/shop/womens-fashion",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 199,
          slug: "womens-clothing",
          name: "Clothing",
          url: "/shop/womens-fashion/womens-clothing",
          shopNavigationItems: [{
            id: 769,
            slug: "bottoms",
            name: "Bottoms",
            url: "/shop/womens-fashion/womens-clothing/bottoms"
          }, {
            id: 770,
            slug: "dresses",
            name: "Dresses",
            url: "/shop/womens-fashion/womens-clothing/dresses"
          }, {
            id: 771,
            slug: "knitwear",
            name: "Knitwear",
            url: "/shop/womens-fashion/womens-clothing/knitwear"
          }, {
            id: 772,
            slug: "outerwear",
            name: "Jackets & Coats",
            url: "/shop/womens-fashion/womens-clothing/outerwear"
          }, {
            id: 773,
            slug: "playsuits",
            name: "Playsuits",
            url: "/shop/womens-fashion/womens-clothing/playsuits"
          }, {
            id: 774,
            slug: "tops-tees",
            name: "Tops & Tees",
            url: "/shop/womens-fashion/womens-clothing/tops-tees"
          }, {
            id: 775,
            slug: "maternity",
            name: "Maternity",
            url: "/shop/womens-fashion/womens-clothing/maternity"
          }, {
            id: 776,
            slug: "plus-size",
            name: "Plus Size",
            url: "/shop/womens-fashion/womens-clothing/plus-size"
          }, {
            id: 777,
            slug: "officewear",
            name: "Officewear",
            url: "/shop/womens-fashion/womens-clothing/officewear"
          }]
        }, {
          id: 200,
          slug: "swimwear",
          name: "Swimwear",
          url: "/shop/womens-fashion/swimwear",
          shopNavigationItems: [{
            id: 831,
            slug: "one-piece",
            name: "One Piece",
            url: "/shop/womens-fashion/swimwear/one-piece"
          }, {
            id: 832,
            slug: "seperates",
            name: "Seperates",
            url: "/shop/womens-fashion/swimwear/seperates"
          }, {
            id: 833,
            slug: "rashvests",
            name: "Rash Vests",
            url: "/shop/womens-fashion/swimwear/rashvests"
          }, {
            id: 834,
            slug: "beachwear",
            name: "Beachwear",
            url: "/shop/womens-fashion/swimwear/beachwear"
          }, {
            id: 835,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/womens-fashion/swimwear/accessories"
          }]
        }, {
          id: 202,
          slug: "intimates",
          name: "Intimates",
          url: "/shop/womens-fashion/intimates",
          shopNavigationItems: [{
            id: 844,
            slug: "bras",
            name: "Bras",
            url: "/shop/womens-fashion/intimates/bras"
          }, {
            id: 845,
            slug: "briefs",
            name: "Briefs",
            url: "/shop/womens-fashion/intimates/briefs"
          }, {
            id: 846,
            slug: "lingerie-sets",
            name: "Lingerie Sets",
            url: "/shop/womens-fashion/intimates/lingerie-sets"
          }, {
            id: 847,
            slug: "shapewear",
            name: "Shapewear",
            url: "/shop/womens-fashion/intimates/shapewear"
          }, {
            id: 848,
            slug: "sleepwear-and-robes",
            name: "Sleepwear and Robes",
            url: "/shop/womens-fashion/intimates/sleepwear-and-robes"
          }, {
            id: 849,
            slug: "socks-and-hosiery",
            name: "Socks and Hosiery",
            url: "/shop/womens-fashion/intimates/socks-and-hosiery"
          }, {
            id: 850,
            slug: "maternity",
            name: "Maternity",
            url: "/shop/womens-fashion/intimates/maternity"
          }, {
            id: 852,
            slug: "loungewear",
            name: "Loungewear",
            url: "/shop/womens-fashion/intimates/loungewear"
          }, {
            id: 853,
            slug: "thermals",
            name: "Thermals",
            url: "/shop/womens-fashion/intimates/thermals"
          }]
        }, {
          id: 203,
          slug: "footwear",
          name: "Footwear",
          url: "/shop/womens-fashion/footwear",
          shopNavigationItems: [{
            id: 854,
            slug: "thongs",
            name: "Thongs",
            url: "/shop/womens-fashion/footwear/thongs"
          }, {
            id: 855,
            slug: "sandals",
            name: "Sandals",
            url: "/shop/womens-fashion/footwear/sandals"
          }, {
            id: 856,
            slug: "comfort",
            name: "Comfort",
            url: "/shop/womens-fashion/footwear/comfort"
          }, {
            id: 857,
            slug: "heels",
            name: "Heels",
            url: "/shop/womens-fashion/footwear/heels"
          }, {
            id: 858,
            slug: "casual-sneakers",
            name: "Casual Sneakers",
            url: "/shop/womens-fashion/footwear/casual-sneakers"
          }, {
            id: 859,
            slug: "boots",
            name: "Boots",
            url: "/shop/womens-fashion/footwear/boots"
          }, {
            id: 860,
            slug: "uggs-and-slippers",
            name: "Uggs and Slippers",
            url: "/shop/womens-fashion/footwear/uggs-and-slippers"
          }, {
            id: 861,
            slug: "sports-shoes",
            name: "Sports Shoes",
            url: "/shop/womens-fashion/footwear/sports-shoes"
          }]
        }, {
          id: 201,
          slug: "jewellery",
          name: "Jewellery",
          url: "/shop/womens-fashion/jewellery",
          shopNavigationItems: [{
            id: 836,
            slug: "bracelets",
            name: "Bracelets",
            url: "/shop/womens-fashion/jewellery/bracelets"
          }, {
            id: 837,
            slug: "earrings",
            name: "Earrings",
            url: "/shop/womens-fashion/jewellery/earrings"
          }, {
            id: 838,
            slug: "necklaces",
            name: "Necklaces",
            url: "/shop/womens-fashion/jewellery/necklaces"
          }, {
            id: 839,
            slug: "rings",
            name: "Rings",
            url: "/shop/womens-fashion/jewellery/rings"
          }, {
            id: 840,
            slug: "charms",
            name: "Charms",
            url: "/shop/womens-fashion/jewellery/charms"
          }, {
            id: 841,
            slug: "gift-sets",
            name: "Gift Sets",
            url: "/shop/womens-fashion/jewellery/gift-sets"
          }, {
            id: 842,
            slug: "keychain",
            name: "Keychains",
            url: "/shop/womens-fashion/jewellery/keychain"
          }, {
            id: 843,
            slug: "brooches-and-pins",
            name: "Brooches and Pins",
            url: "/shop/womens-fashion/jewellery/brooches-and-pins"
          }]
        }, {
          id: 204,
          slug: "accessories",
          name: "Accessories",
          url: "/shop/womens-fashion/accessories",
          shopNavigationItems: [{
            id: 862,
            slug: "sunglasses",
            name: "Sunglasses",
            url: "/shop/womens-fashion/accessories/sunglasses"
          }, {
            id: 863,
            slug: "hats-caps",
            name: "Hats & Caps",
            url: "/shop/womens-fashion/accessories/hats-caps"
          }, {
            id: 864,
            slug: "belts",
            name: "Belts",
            url: "/shop/womens-fashion/accessories/belts"
          }, {
            id: 866,
            slug: "gloves",
            name: "Gloves",
            url: "/shop/womens-fashion/accessories/gloves"
          }, {
            id: 867,
            slug: "umbrellas",
            name: "Umbrellas",
            url: "/shop/womens-fashion/accessories/umbrellas"
          }, {
            id: 865,
            slug: "scarfs-wraps",
            name: "Scarfs & Wraps",
            url: "/shop/womens-fashion/accessories/scarfs-wraps"
          }]
        }, {
          id: 205,
          slug: "watches",
          name: "Watches",
          url: "/shop/womens-fashion/watches",
          shopNavigationItems: [{
            id: 868,
            slug: "casual-leather",
            name: "Casual",
            url: "/shop/womens-fashion/watches/casual-leather"
          }, {
            id: 869,
            slug: "sports",
            name: "Sports",
            url: "/shop/womens-fashion/watches/sports"
          }, {
            id: 870,
            slug: "luxury-steel-band",
            name: "Luxury",
            url: "/shop/womens-fashion/watches/luxury-steel-band"
          }, {
            id: 871,
            slug: "watch-accessories",
            name: "Watch Accessories",
            url: "/shop/womens-fashion/watches/watch-accessories"
          }]
        }, {
          id: 355,
          slug: "sunglasses-eyewear",
          name: "Sunglasses & Eyewear",
          url: "/shop/womens-fashion/sunglasses-eyewear",
          shopNavigationItems: []
        }, {
          id: 206,
          slug: "handbag-wallets",
          name: "Handbag & Wallets",
          url: "/shop/womens-fashion/handbag-wallets",
          shopNavigationItems: [{
            id: 872,
            slug: "cross-body-bags",
            name: "Cross Body Bags",
            url: "/shop/womens-fashion/handbag-wallets/cross-body-bags"
          }, {
            id: 873,
            slug: "shoulder-bags",
            name: "Shoulder Bags",
            url: "/shop/womens-fashion/handbag-wallets/shoulder-bags"
          }, {
            id: 874,
            slug: "evening-bags",
            name: "Evening Bags",
            url: "/shop/womens-fashion/handbag-wallets/evening-bags"
          }, {
            id: 875,
            slug: "wallets-and-clutches",
            name: "Wallets and Clutches",
            url: "/shop/womens-fashion/handbag-wallets/wallets-and-clutches"
          }, {
            id: 876,
            slug: "fashion-backpacks",
            name: "Fashion Backpacks",
            url: "/shop/womens-fashion/handbag-wallets/fashion-backpacks"
          }, {
            id: 877,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/womens-fashion/handbag-wallets/accessories"
          }]
        }, {
          id: 207,
          slug: "luggage",
          name: "Luggage",
          url: "/shop/womens-fashion/luggage",
          shopNavigationItems: [{
            id: 878,
            slug: "carry-ons",
            name: "Carry-ons",
            url: "/shop/womens-fashion/luggage/carry-ons"
          }, {
            id: 879,
            slug: "suitcases",
            name: "Suitcases",
            url: "/shop/womens-fashion/luggage/suitcases"
          }, {
            id: 880,
            slug: "luggage-sets",
            name: "Luggage Sets",
            url: "/shop/womens-fashion/luggage/luggage-sets"
          }, {
            id: 881,
            slug: "duffels",
            name: "Duffle Bags",
            url: "/shop/womens-fashion/luggage/duffels"
          }, {
            id: 882,
            slug: "overnight",
            name: "Overnight",
            url: "/shop/womens-fashion/luggage/overnight"
          }, {
            id: 883,
            slug: "travel-accessories",
            name: "Travel Accessories",
            url: "/shop/womens-fashion/luggage/travel-accessories"
          }]
        }],
        topBrands: [{
          id: 4498,
          slug: "women-s-weekly",
          name: "Women's Weekly",
          url: "/brand/women-s-weekly"
        }, {
          id: 3062,
          slug: "fila",
          name: "FILA",
          url: "/brand/fila"
        }, {
          id: 3716,
          slug: "lee",
          name: "Lee",
          url: "/brand/lee"
        }, {
          id: 4535,
          slug: "diana-ferrari",
          name: "Diana Ferrari",
          url: "/brand/diana-ferrari"
        }, {
          id: 3445,
          slug: "mossimo",
          name: "Mossimo",
          url: "/brand/mossimo"
        }]
      }, {
        id: 151,
        slug: "sports-outdoor",
        name: "Sports & Outdoors",
        url: "/shop/sports-outdoor",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 311,
          slug: "women",
          name: "Women",
          url: "/shop/sports-outdoor/women",
          shopNavigationItems: [{
            id: 1365,
            slug: "womens-clothing",
            name: "Women's Clothing",
            url: "/shop/sports-outdoor/women/womens-clothing"
          }, {
            id: 1366,
            slug: "womens-footwear",
            name: "Women's Footwear",
            url: "/shop/sports-outdoor/women/womens-footwear"
          }, {
            id: 1367,
            slug: "women-swimwear",
            name: "Women's Swimwear",
            url: "/shop/sports-outdoor/women/women-swimwear"
          }]
        }, {
          id: 312,
          slug: "girls",
          name: "Girls",
          url: "/shop/sports-outdoor/girls",
          shopNavigationItems: [{
            id: 1368,
            slug: "girls-clothing",
            name: "Girl's Clothing",
            url: "/shop/sports-outdoor/girls/girls-clothing"
          }, {
            id: 1369,
            slug: "girls-footwear",
            name: "Girl's Footwear",
            url: "/shop/sports-outdoor/girls/girls-footwear"
          }, {
            id: 1370,
            slug: "girls-swimwear",
            name: "Girl's Swimwear",
            url: "/shop/sports-outdoor/girls/girls-swimwear"
          }, {
            id: 1371,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/sports-outdoor/girls/accessories"
          }]
        }, {
          id: 316,
          slug: "health-wellbeing",
          name: "Health & Wellbeing",
          url: "/shop/sports-outdoor/health-wellbeing",
          shopNavigationItems: [{
            id: 1392,
            slug: "protein-bars-powders",
            name: "Protein Bars & Powders",
            url: "/shop/sports-outdoor/health-wellbeing/protein-bars-powders"
          }, {
            id: 1393,
            slug: "diet-slimming",
            name: "Diet & Slimming",
            url: "/shop/sports-outdoor/health-wellbeing/diet-slimming"
          }, {
            id: 1394,
            slug: "vitamins",
            name: "Vitamins and Supplements",
            url: "/shop/sports-outdoor/health-wellbeing/vitamins"
          }, {
            id: 1395,
            slug: "first-aid-medicinal",
            name: "First Aid & Medicinal",
            url: "/shop/sports-outdoor/health-wellbeing/first-aid-medicinal"
          }, {
            id: 1396,
            slug: "footcare-orthotics",
            name: "Footcare & Orthotics",
            url: "/shop/sports-outdoor/health-wellbeing/footcare-orthotics"
          }]
        }, {
          id: 310,
          slug: "ball-sports-equipment",
          name: "Ball Sports Equipment",
          url: "/shop/sports-outdoor/ball-sports-equipment",
          shopNavigationItems: [{
            id: 1355,
            slug: "basketball",
            name: "Basketball",
            url: "/shop/sports-outdoor/ball-sports-equipment/basketball"
          }, {
            id: 1356,
            slug: "football",
            name: "Football",
            url: "/shop/sports-outdoor/ball-sports-equipment/football"
          }, {
            id: 1357,
            slug: "netball",
            name: "Netball",
            url: "/shop/sports-outdoor/ball-sports-equipment/netball"
          }, {
            id: 1358,
            slug: "rugby",
            name: "Rugby",
            url: "/shop/sports-outdoor/ball-sports-equipment/rugby"
          }, {
            id: 1359,
            slug: "soccer",
            name: "Soccer",
            url: "/shop/sports-outdoor/ball-sports-equipment/soccer"
          }, {
            id: 1360,
            slug: "volleyball",
            name: "Volleyball",
            url: "/shop/sports-outdoor/ball-sports-equipment/volleyball"
          }, {
            id: 1361,
            slug: "snooker-pool-indoor-games",
            name: "Snooker Pool & Indoor Games",
            url: "/shop/sports-outdoor/ball-sports-equipment/snooker-pool-indoor-games"
          }, {
            id: 1362,
            slug: "bowls",
            name: "Bowls",
            url: "/shop/sports-outdoor/ball-sports-equipment/bowls"
          }]
        }, {
          id: 307,
          slug: "men",
          name: "Men",
          url: "/shop/sports-outdoor/men",
          shopNavigationItems: [{
            id: 1342,
            slug: "mens-clothing",
            name: "Men's Clothing",
            url: "/shop/sports-outdoor/men/mens-clothing"
          }, {
            id: 1343,
            slug: "mens-footwear",
            name: "Men's Footwear",
            url: "/shop/sports-outdoor/men/mens-footwear"
          }, {
            id: 1344,
            slug: "mens-swimwear",
            name: "Men's Swimwear",
            url: "/shop/sports-outdoor/men/mens-swimwear"
          }]
        }, {
          id: 308,
          slug: "boys",
          name: "Boys",
          url: "/shop/sports-outdoor/boys",
          shopNavigationItems: [{
            id: 1345,
            slug: "boys-clothing",
            name: "Boy's Clothing",
            url: "/shop/sports-outdoor/boys/boys-clothing"
          }, {
            id: 1346,
            slug: "boys-footwear",
            name: "Boy's Footwear",
            url: "/shop/sports-outdoor/boys/boys-footwear"
          }, {
            id: 1347,
            slug: "boys-swimwear",
            name: "Boy's Swimwear",
            url: "/shop/sports-outdoor/boys/boys-swimwear"
          }, {
            id: 1348,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/sports-outdoor/boys/accessories"
          }]
        }, {
          id: 315,
          slug: "accessories",
          name: "Accessories",
          url: "/shop/sports-outdoor/accessories",
          shopNavigationItems: [{
            id: 1387,
            slug: "towel",
            name: "Towel",
            url: "/shop/sports-outdoor/accessories/towel"
          }, {
            id: 1388,
            slug: "training-bag",
            name: "Training Bag",
            url: "/shop/sports-outdoor/accessories/training-bag"
          }, {
            id: 1389,
            slug: "bottles",
            name: "Bottles",
            url: "/shop/sports-outdoor/accessories/bottles"
          }, {
            id: 1390,
            slug: "socks",
            name: "Socks",
            url: "/shop/sports-outdoor/accessories/socks"
          }, {
            id: 1391,
            slug: "hats",
            name: "Hats",
            url: "/shop/sports-outdoor/accessories/hats"
          }]
        }, {
          id: 309,
          slug: "bat-ball-sports-equipment",
          name: "Bat & Ball Sports Equipment",
          url: "/shop/sports-outdoor/bat-ball-sports-equipment",
          shopNavigationItems: [{
            id: 1350,
            slug: "cricket",
            name: "Cricket",
            url: "/shop/sports-outdoor/bat-ball-sports-equipment/cricket"
          }, {
            id: 1351,
            slug: "golf",
            name: "Golf",
            url: "/shop/sports-outdoor/bat-ball-sports-equipment/golf"
          }, {
            id: 1352,
            slug: "hockey-icehockey",
            name: "Hockey & Icehockey",
            url: "/shop/sports-outdoor/bat-ball-sports-equipment/hockey-icehockey"
          }]
        }, {
          id: 313,
          slug: "racquet-sports-equipment",
          name: "Racquet Sports Equipment",
          url: "/shop/sports-outdoor/racquet-sports-equipment",
          shopNavigationItems: [{
            id: 1372,
            slug: "tennis",
            name: "Tennis",
            url: "/shop/sports-outdoor/racquet-sports-equipment/tennis"
          }, {
            id: 1373,
            slug: "table-tennis",
            name: "Table Tennis",
            url: "/shop/sports-outdoor/racquet-sports-equipment/table-tennis"
          }, {
            id: 1374,
            slug: "squash",
            name: "Squash",
            url: "/shop/sports-outdoor/racquet-sports-equipment/squash"
          }, {
            id: 1375,
            slug: "badminton",
            name: "Badminton",
            url: "/shop/sports-outdoor/racquet-sports-equipment/badminton"
          }]
        }, {
          id: 314,
          slug: "camping-hiking",
          name: "Camping & Hiking",
          url: "/shop/sports-outdoor/camping-hiking",
          shopNavigationItems: [{
            id: 1376,
            slug: "apparel",
            name: "Apparel",
            url: "/shop/sports-outdoor/camping-hiking/apparel"
          }, {
            id: 1377,
            slug: "footwear",
            name: "Footwear",
            url: "/shop/sports-outdoor/camping-hiking/footwear"
          }, {
            id: 1378,
            slug: "torches",
            name: "Torches",
            url: "/shop/sports-outdoor/camping-hiking/torches"
          }, {
            id: 1379,
            slug: "hydration",
            name: "Hydration",
            url: "/shop/sports-outdoor/camping-hiking/hydration"
          }, {
            id: 1380,
            slug: "tents-camp-furniture",
            name: "Tents & Camp Furniture",
            url: "/shop/sports-outdoor/camping-hiking/tents-camp-furniture"
          }, {
            id: 1381,
            slug: "sleeping",
            name: "Sleeping",
            url: "/shop/sports-outdoor/camping-hiking/sleeping"
          }, {
            id: 1382,
            slug: "backpacks",
            name: "Backpacks",
            url: "/shop/sports-outdoor/camping-hiking/backpacks"
          }, {
            id: 1383,
            slug: "camping-toilet-shower",
            name: "Camping Toilet & Shower",
            url: "/shop/sports-outdoor/camping-hiking/camping-toilet-shower"
          }, {
            id: 1384,
            slug: "camp-cooking",
            name: "Camp Cooking",
            url: "/shop/sports-outdoor/camping-hiking/camp-cooking"
          }, {
            id: 1385,
            slug: "power-cooling",
            name: "Power & Cooling",
            url: "/shop/sports-outdoor/camping-hiking/power-cooling"
          }]
        }, {
          id: 317,
          slug: "gym-equipment",
          name: "Gym Equipment",
          url: "/shop/sports-outdoor/gym-equipment",
          shopNavigationItems: [{
            id: 1399,
            slug: "running",
            name: "Running",
            url: "/shop/sports-outdoor/gym-equipment/running"
          }, {
            id: 1400,
            slug: "boxing-mma",
            name: "Boxing / MMA",
            url: "/shop/sports-outdoor/gym-equipment/boxing-mma"
          }, {
            id: 1401,
            slug: "yoga",
            name: "Yoga",
            url: "/shop/sports-outdoor/gym-equipment/yoga"
          }, {
            id: 1402,
            slug: "gymnastics",
            name: "Gymnastics",
            url: "/shop/sports-outdoor/gym-equipment/gymnastics"
          }]
        }, {
          id: 318,
          slug: "outdoor-sport-activities",
          name: "Outdoor Sport Activities",
          url: "/shop/sports-outdoor/outdoor-sport-activities",
          shopNavigationItems: [{
            id: 1407,
            slug: "snow",
            name: "Snow",
            url: "/shop/sports-outdoor/outdoor-sport-activities/snow"
          }, {
            id: 1403,
            slug: "cycling",
            name: "Cycling",
            url: "/shop/sports-outdoor/outdoor-sport-activities/cycling"
          }, {
            id: 1404,
            slug: "fishing",
            name: "Fishing",
            url: "/shop/sports-outdoor/outdoor-sport-activities/fishing"
          }, {
            id: 1405,
            slug: "trampoline",
            name: "Trampoline",
            url: "/shop/sports-outdoor/outdoor-sport-activities/trampoline"
          }, {
            id: 1406,
            slug: "skate-blades",
            name: "Skate/Blades",
            url: "/shop/sports-outdoor/outdoor-sport-activities/skate-blades"
          }, {
            id: 1408,
            slug: "outdoor-sports",
            name: "Outdoor Sports",
            url: "/shop/sports-outdoor/outdoor-sport-activities/outdoor-sports"
          }, {
            id: 1409,
            slug: "surf-watersports",
            name: "Surf & Watersports",
            url: "/shop/sports-outdoor/outdoor-sport-activities/surf-watersports"
          }, {
            id: 1410,
            slug: "powered-vehicles",
            name: "Powered Vehicles",
            url: "/shop/sports-outdoor/outdoor-sport-activities/powered-vehicles"
          }, {
            id: 1411,
            slug: "swimming",
            name: "Swimming",
            url: "/shop/sports-outdoor/outdoor-sport-activities/swimming"
          }]
        }],
        topBrands: [{
          id: 4001,
          slug: "puma",
          name: "Puma",
          url: "/brand/puma"
        }, {
          id: 2837,
          slug: "adidas",
          name: "Adidas",
          url: "/brand/adidas"
        }, {
          id: 2820,
          slug: "nike",
          name: "Nike",
          url: "/brand/nike"
        }, {
          id: 3016,
          slug: "teva",
          name: "Teva",
          url: "/brand/teva"
        }, {
          id: 2808,
          slug: "asics",
          name: "ASICS",
          url: "/brand/asics"
        }, {
          id: 3425,
          slug: "nerf",
          name: "NERF",
          url: "/brand/nerf"
        }, {
          id: 6260,
          slug: "2xu",
          name: "2XU",
          url: "/brand/2xu"
        }, {
          id: 2934,
          slug: "champion",
          name: "Champion",
          url: "/brand/champion"
        }, {
          id: 3727,
          slug: "everlast",
          name: "Everlast",
          url: "/brand/everlast"
        }, {
          id: 5518,
          slug: "merrell",
          name: "Merrell",
          url: "/brand/merrell"
        }, {
          id: 2921,
          slug: "new-balance",
          name: "New Balance",
          url: "/brand/new-balance"
        }, {
          id: 3999,
          slug: "sketchers",
          name: "Sketchers",
          url: "/brand/sketchers"
        }, {
          id: 2937,
          slug: "russell-athletic",
          name: "Russell Athletic",
          url: "/brand/russell-athletic"
        }, {
          id: 4334,
          slug: "the-north-face",
          name: "The North Face",
          url: "/brand/the-north-face"
        }]
      }, {
        id: 147,
        slug: "electronics",
        name: "Electronics",
        url: "/shop/electronics",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 253,
          slug: "headphones",
          name: "Headphones",
          url: "/shop/electronics/headphones",
          shopNavigationItems: [{
            id: 1066,
            slug: "wireless-bluetooth-headphones",
            name: "Wireless Bluetooth Headphones",
            url: "/shop/electronics/headphones/wireless-bluetooth-headphones"
          }, {
            id: 1067,
            slug: "noise-cancelling-headphones",
            name: "Noise Cancelling Headphones",
            url: "/shop/electronics/headphones/noise-cancelling-headphones"
          }, {
            id: 1068,
            slug: "in-ear-headphones",
            name: "In Ear Headphones",
            url: "/shop/electronics/headphones/in-ear-headphones"
          }, {
            id: 1069,
            slug: "on-ear-headphones",
            name: "On Ear Headphones",
            url: "/shop/electronics/headphones/on-ear-headphones"
          }, {
            id: 1070,
            slug: "over-ear-headphones",
            name: "Over Ear Headphones",
            url: "/shop/electronics/headphones/over-ear-headphones"
          }, {
            id: 1071,
            slug: "sports-headphones",
            name: "Sports Headphones",
            url: "/shop/electronics/headphones/sports-headphones"
          }, {
            id: 1072,
            slug: "kids-headphones",
            name: "Kids Headphones",
            url: "/shop/electronics/headphones/kids-headphones"
          }]
        }, {
          id: 256,
          slug: "computers-laptops",
          name: "Computers & Laptops",
          url: "/shop/electronics/computers-laptops",
          shopNavigationItems: [{
            id: 1085,
            slug: "laptops",
            name: "Laptops",
            url: "/shop/electronics/computers-laptops/laptops"
          }, {
            id: 1086,
            slug: "desktops",
            name: "Desktops",
            url: "/shop/electronics/computers-laptops/desktops"
          }, {
            id: 1087,
            slug: "computer-accessories",
            name: "Computer Accessories",
            url: "/shop/electronics/computers-laptops/computer-accessories"
          }, {
            id: 1088,
            slug: "monitors",
            name: "Monitors",
            url: "/shop/electronics/computers-laptops/monitors"
          }, {
            id: 1089,
            slug: "networking",
            name: "Networking",
            url: "/shop/electronics/computers-laptops/networking"
          }, {
            id: 1090,
            slug: "software",
            name: "Software",
            url: "/shop/electronics/computers-laptops/software"
          }, {
            id: 1091,
            slug: "hardrive-and-storage",
            name: "Hard Drives and Storage",
            url: "/shop/electronics/computers-laptops/hardrive-and-storage"
          }]
        }, {
          id: 264,
          slug: "power",
          name: "Power",
          url: "/shop/electronics/power",
          shopNavigationItems: [{
            id: 1122,
            slug: "powerboards-adapters",
            name: "Powerboards & Adapters",
            url: "/shop/electronics/power/powerboards-adapters"
          }, {
            id: 1123,
            slug: "powerbanks",
            name: "Powerbanks",
            url: "/shop/electronics/power/powerbanks"
          }, {
            id: 1124,
            slug: "cables",
            name: "Cables",
            url: "/shop/electronics/power/cables"
          }, {
            id: 1125,
            slug: "batteries",
            name: "Batteries",
            url: "/shop/electronics/power/batteries"
          }]
        }, {
          id: 258,
          slug: "tablet-ereader",
          name: "Tablet & eReader",
          url: "/shop/electronics/tablet-ereader",
          shopNavigationItems: [{
            id: 1096,
            slug: "ipad-tablets",
            name: "iPad & Tablets",
            url: "/shop/electronics/tablet-ereader/ipad-tablets"
          }, {
            id: 1097,
            slug: "ipad-tablet-accessories",
            name: "iPad & Tablet Accessories",
            url: "/shop/electronics/tablet-ereader/ipad-tablet-accessories"
          }, {
            id: 1098,
            slug: "ebook-readers",
            name: "eBook Readers",
            url: "/shop/electronics/tablet-ereader/ebook-readers"
          }]
        }, {
          id: 261,
          slug: "drones",
          name: "Drones",
          url: "/shop/electronics/drones",
          shopNavigationItems: []
        }, {
          id: 349,
          slug: "catch-mobile",
          name: "Catch Mobile",
          url: "/shop/electronics/catch-mobile",
          shopNavigationItems: [{
            id: 1567,
            slug: "pre-paid-plans",
            name: "Pre-paid Plans",
            url: "/shop/electronics/catch-mobile/pre-paid-plans"
          }, {
            id: 1568,
            slug: "pre-paid-data-only-plans",
            name: "Pre-paid DATA ONLY Plans",
            url: "/shop/electronics/catch-mobile/pre-paid-data-only-plans"
          }]
        }, {
          id: 265,
          slug: "phones-smart-tech",
          name: "Phones & Smart Tech",
          url: "/shop/electronics/phones-smart-tech",
          shopNavigationItems: [{
            id: 1126,
            slug: "mobile-phones",
            name: "Mobile Phones",
            url: "/shop/electronics/phones-smart-tech/mobile-phones"
          }, {
            id: 1127,
            slug: "phone-accessories",
            name: "Phone Accessories",
            url: "/shop/electronics/phones-smart-tech/phone-accessories"
          }, {
            id: 1128,
            slug: "home-phones",
            name: "Home Phones",
            url: "/shop/electronics/phones-smart-tech/home-phones"
          }, {
            id: 1129,
            slug: "wearable-technology",
            name: "Wearable Technology",
            url: "/shop/electronics/phones-smart-tech/wearable-technology"
          }]
        }, {
          id: 252,
          slug: "audio-speakers",
          name: "Audio & Speakers",
          url: "/shop/electronics/audio-speakers",
          shopNavigationItems: [{
            id: 1060,
            slug: "audio-speakers",
            name: "Audio Speakers",
            url: "/shop/electronics/audio-speakers/audio-speakers"
          }, {
            id: 1062,
            slug: "sound-bars",
            name: "Sound Bars",
            url: "/shop/electronics/audio-speakers/sound-bars"
          }, {
            id: 1063,
            slug: "radios",
            name: "Radios",
            url: "/shop/electronics/audio-speakers/radios"
          }, {
            id: 1064,
            slug: "audio-accessories",
            name: "Audio Accessories",
            url: "/shop/electronics/audio-speakers/audio-accessories"
          }, {
            id: 1065,
            slug: "turntable-cd-player",
            name: "Turntable & CD Player",
            url: "/shop/electronics/audio-speakers/turntable-cd-player"
          }]
        }, {
          id: 263,
          slug: "home-security",
          name: "Home Security",
          url: "/shop/electronics/home-security",
          shopNavigationItems: [{
            id: 1116,
            slug: "security-lights",
            name: "Security Lights",
            url: "/shop/electronics/home-security/security-lights"
          }, {
            id: 1117,
            slug: "security-monitors-recorders",
            name: "Security Monitors & Recorders",
            url: "/shop/electronics/home-security/security-monitors-recorders"
          }, {
            id: 1118,
            slug: "baby-monitor",
            name: "Baby Monitor",
            url: "/shop/electronics/home-security/baby-monitor"
          }, {
            id: 1119,
            slug: "security-safe",
            name: "Security Safe",
            url: "/shop/electronics/home-security/security-safe"
          }, {
            id: 1120,
            slug: "door-bells",
            name: "Door Bells",
            url: "/shop/electronics/home-security/door-bells"
          }, {
            id: 1121,
            slug: "bluetooth-location-trackers",
            name: "Bluetooth Location Trackers",
            url: "/shop/electronics/home-security/bluetooth-location-trackers"
          }]
        }, {
          id: 257,
          slug: "digital-storage",
          name: "Digital Storage",
          url: "/shop/electronics/digital-storage",
          shopNavigationItems: [{
            id: 1092,
            slug: "portable-drive",
            name: "Portable Drive",
            url: "/shop/electronics/digital-storage/portable-drive"
          }, {
            id: 1093,
            slug: "flash-drive",
            name: "Flash Drive",
            url: "/shop/electronics/digital-storage/flash-drive"
          }, {
            id: 1094,
            slug: "hard-drive",
            name: "Hard Drive",
            url: "/shop/electronics/digital-storage/hard-drive"
          }, {
            id: 1095,
            slug: "memory-cards",
            name: "Memory Cards",
            url: "/shop/electronics/digital-storage/memory-cards"
          }]
        }, {
          id: 255,
          slug: "car-electronics",
          name: "Car Electronics",
          url: "/shop/electronics/car-electronics",
          shopNavigationItems: [{
            id: 1081,
            slug: "car-safety-dash-camera",
            name: "Car Safety & Dash Camera",
            url: "/shop/electronics/car-electronics/car-safety-dash-camera"
          }, {
            id: 1082,
            slug: "alarm-system-security",
            name: "Alarm System Security",
            url: "/shop/electronics/car-electronics/alarm-system-security"
          }, {
            id: 1083,
            slug: "gps",
            name: "GPS",
            url: "/shop/electronics/car-electronics/gps"
          }, {
            id: 1084,
            slug: "car-radios-monitors",
            name: "Car Radios & Monitors",
            url: "/shop/electronics/car-electronics/car-radios-monitors"
          }]
        }, {
          id: 262,
          slug: "gaming",
          name: "Gaming",
          url: "/shop/electronics/gaming",
          shopNavigationItems: [{
            id: 1112,
            slug: "consoles",
            name: "Consoles",
            url: "/shop/electronics/gaming/consoles"
          }, {
            id: 1113,
            slug: "games",
            name: "Games",
            url: "/shop/electronics/gaming/games"
          }, {
            id: 1114,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/electronics/gaming/accessories"
          }, {
            id: 1115,
            slug: "vr-gaming-headset",
            name: "VR & Gaming Headset",
            url: "/shop/electronics/gaming/vr-gaming-headset"
          }]
        }, {
          id: 254,
          slug: "home-cinema-tv-video",
          name: "Home Cinema, TV & Video",
          url: "/shop/electronics/home-cinema-tv-video",
          shopNavigationItems: [{
            id: 1073,
            slug: "tvs",
            name: "TVs",
            url: "/shop/electronics/home-cinema-tv-video/tvs"
          }, {
            id: 1074,
            slug: "cables-tv-mounts",
            name: "Cables & TV Mounts",
            url: "/shop/electronics/home-cinema-tv-video/cables-tv-mounts"
          }, {
            id: 1075,
            slug: "batteries-power-supply",
            name: "Batteries & Power Supply",
            url: "/shop/electronics/home-cinema-tv-video/batteries-power-supply"
          }, {
            id: 1076,
            slug: "sound-bars",
            name: "Sound Bars",
            url: "/shop/electronics/home-cinema-tv-video/sound-bars"
          }, {
            id: 1078,
            slug: "projectors-accessories",
            name: "Projectors & Accessories",
            url: "/shop/electronics/home-cinema-tv-video/projectors-accessories"
          }, {
            id: 1079,
            slug: "players-recorders",
            name: "Players & Recorders",
            url: "/shop/electronics/home-cinema-tv-video/players-recorders"
          }, {
            id: 1080,
            slug: "media-streaming",
            name: "Media Streaming",
            url: "/shop/electronics/home-cinema-tv-video/media-streaming"
          }]
        }, {
          id: 260,
          slug: "cameras",
          name: "Cameras",
          url: "/shop/electronics/cameras",
          shopNavigationItems: [{
            id: 1104,
            slug: "digital-camera",
            name: "Digital Camera",
            url: "/shop/electronics/cameras/digital-camera"
          }, {
            id: 1105,
            slug: "compact-camera",
            name: "Compact Camera",
            url: "/shop/electronics/cameras/compact-camera"
          }, {
            id: 1106,
            slug: "action-cameras",
            name: "Action Cameras",
            url: "/shop/electronics/cameras/action-cameras"
          }, {
            id: 1107,
            slug: "video-camera",
            name: "Video Camera",
            url: "/shop/electronics/cameras/video-camera"
          }, {
            id: 1108,
            slug: "binoculars-telescopes",
            name: "Binoculars & Telescopes",
            url: "/shop/electronics/cameras/binoculars-telescopes"
          }, {
            id: 1109,
            slug: "camera-accessories",
            name: "Camera Accessories",
            url: "/shop/electronics/cameras/camera-accessories"
          }]
        }, {
          id: 266,
          slug: "music",
          name: "Music",
          url: "/shop/electronics/music",
          shopNavigationItems: [{
            id: 1131,
            slug: "guitars",
            name: "Guitars",
            url: "/shop/electronics/music/guitars"
          }, {
            id: 1132,
            slug: "drums",
            name: "Drums",
            url: "/shop/electronics/music/drums"
          }, {
            id: 1133,
            slug: "keyboards",
            name: "Keyboards",
            url: "/shop/electronics/music/keyboards"
          }, {
            id: 1134,
            slug: "string-instruments",
            name: "String Instruments",
            url: "/shop/electronics/music/string-instruments"
          }, {
            id: 1135,
            slug: "accessories-other-instruments",
            name: "Accessories & Other Instruments",
            url: "/shop/electronics/music/accessories-other-instruments"
          }, {
            id: 1136,
            slug: "cds-vinyl",
            name: "CDs & Vinyl",
            url: "/shop/electronics/music/cds-vinyl"
          }]
        }, {
          id: 259,
          slug: "printers-scanners",
          name: "Printers & Scanners",
          url: "/shop/electronics/printers-scanners",
          shopNavigationItems: [{
            id: 1099,
            slug: "printers",
            name: "Printers",
            url: "/shop/electronics/printers-scanners/printers"
          }, {
            id: 1100,
            slug: "ink-cartridges",
            name: "Ink Cartridges",
            url: "/shop/electronics/printers-scanners/ink-cartridges"
          }, {
            id: 1101,
            slug: "scanner",
            name: "Scanner",
            url: "/shop/electronics/printers-scanners/scanner"
          }, {
            id: 1103,
            slug: "fax-machine",
            name: "Fax Machine",
            url: "/shop/electronics/printers-scanners/fax-machine"
          }]
        }],
        topBrands: [{
          id: 2992,
          slug: "apple",
          name: "Apple",
          url: "/brand/apple"
        }, {
          id: 2954,
          slug: "samsung",
          name: "Samsung",
          url: "/brand/samsung"
        }, {
          id: 2963,
          slug: "belkin",
          name: "Belkin",
          url: "/brand/belkin"
        }, {
          id: 3626,
          slug: "sony",
          name: "Sony",
          url: "/brand/sony"
        }, {
          id: 12932,
          slug: "teac",
          name: "Teac",
          url: "/brand/teac"
        }, {
          id: 4181,
          slug: "bose",
          name: "Bose",
          url: "/brand/bose"
        }]
      }, {
        id: 20,
        slug: "cheap-groceries",
        name: "Grocery & Liquor",
        url: "/shop/cheap-groceries",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 181,
          slug: "shop-all-pantry",
          name: "Shop All Pantry",
          url: "/shop/cheap-groceries/shop-all-pantry",
          shopNavigationItems: []
        }, {
          id: 7,
          slug: "breakfast",
          name: "Breakfast",
          url: "/shop/cheap-groceries/breakfast",
          shopNavigationItems: [{
            id: 634,
            slug: "coffee-tea",
            name: "Coffee & Tea",
            url: "/shop/cheap-groceries/breakfast/coffee-tea"
          }, {
            id: 635,
            slug: "cereals-muesli-more",
            name: "Cereals, Muesli & More",
            url: "/shop/cheap-groceries/breakfast/cereals-muesli-more"
          }]
        }, {
          id: 1,
          slug: "snacks",
          name: "Snacks",
          url: "/shop/cheap-groceries/snacks",
          shopNavigationItems: [{
            id: 1,
            slug: "muesli-bars",
            name: "Muesli Bars & Biscuits",
            url: "/shop/cheap-groceries/snacks/muesli-bars"
          }, {
            id: 3,
            slug: "lunch-box-snacks",
            name: "Lunch Box Snacks",
            url: "/shop/cheap-groceries/snacks/lunch-box-snacks"
          }, {
            id: 4,
            slug: "nuts",
            name: "Nuts, Chips & More",
            url: "/shop/cheap-groceries/snacks/nuts"
          }, {
            id: 6,
            slug: "dried-fruit",
            name: "Dried Fruit",
            url: "/shop/cheap-groceries/snacks/dried-fruit"
          }]
        }, {
          id: 8,
          slug: "pantry-staples",
          name: "Pantry",
          url: "/shop/cheap-groceries/pantry-staples",
          shopNavigationItems: [{
            id: 24,
            slug: "pantry-staples",
            name: "Pantry - all",
            url: "/shop/cheap-groceries/pantry-staples/pantry-staples"
          }, {
            id: 636,
            slug: "sauces-oils-spices",
            name: "Sauces, Oils & Spices",
            url: "/shop/cheap-groceries/pantry-staples/sauces-oils-spices"
          }, {
            id: 637,
            slug: "meals-tuna-more",
            name: "Meals, Tuna & More",
            url: "/shop/cheap-groceries/pantry-staples/meals-tuna-more"
          }, {
            id: 638,
            slug: "baking",
            name: "Baking",
            url: "/shop/cheap-groceries/pantry-staples/baking"
          }]
        }, {
          id: 135,
          slug: "liquor",
          name: "Liquor",
          url: "/shop/cheap-groceries/liquor",
          shopNavigationItems: [{
            id: 626,
            slug: "red-wine",
            name: "Red Wine",
            url: "/shop/cheap-groceries/liquor/red-wine"
          }, {
            id: 627,
            slug: "white-wine",
            name: "White Wine",
            url: "/shop/cheap-groceries/liquor/white-wine"
          }, {
            id: 628,
            slug: "sparkling-wine",
            name: "Sparkling Wine",
            url: "/shop/cheap-groceries/liquor/sparkling-wine"
          }, {
            id: 629,
            slug: "mixed-cases",
            name: "Mixed Cases",
            url: "/shop/cheap-groceries/liquor/mixed-cases"
          }, {
            id: 1667,
            slug: "spirits",
            name: "Spirits",
            url: "/shop/cheap-groceries/liquor/spirits"
          }, {
            id: 1668,
            slug: "whiskey",
            name: "Whiskey",
            url: "/shop/cheap-groceries/liquor/whiskey"
          }, {
            id: 1669,
            slug: "beer",
            name: "Beer",
            url: "/shop/cheap-groceries/liquor/beer"
          }, {
            id: 1670,
            slug: "cider",
            name: "Cider",
            url: "/shop/cheap-groceries/liquor/cider"
          }]
        }, {
          id: 3,
          slug: "confectionery",
          name: "Confectionery",
          url: "/shop/cheap-groceries/confectionery",
          shopNavigationItems: [{
            id: 11,
            slug: "chocolate",
            name: "Chocolate",
            url: "/shop/cheap-groceries/confectionery/chocolate"
          }, {
            id: 12,
            slug: "sweets-lollies",
            name: "Sweets and Lollies",
            url: "/shop/cheap-groceries/confectionery/sweets-lollies"
          }, {
            id: 13,
            slug: "chewing-gum",
            name: "Mints & Gum",
            url: "/shop/cheap-groceries/confectionery/chewing-gum"
          }]
        }, {
          id: 6,
          slug: "healthy-foods",
          name: "Health Foods",
          url: "/shop/cheap-groceries/healthy-foods",
          shopNavigationItems: [{
            id: 21,
            slug: "gluten-free-foods",
            name: "Gluten Free",
            url: "/shop/cheap-groceries/healthy-foods/gluten-free-foods"
          }]
        }, {
          id: 2,
          slug: "international-foods",
          name: "International Foods",
          url: "/shop/cheap-groceries/international-foods",
          shopNavigationItems: []
        }, {
          id: 137,
          slug: "vitamins-medicinal",
          name: "Health & Wellbeing",
          url: "/shop/cheap-groceries/vitamins-medicinal",
          shopNavigationItems: [{
            id: 428,
            slug: "vitamins-medicinal",
            name: "Health & Wellbeing",
            url: "/shop/cheap-groceries/vitamins-medicinal/vitamins-medicinal"
          }, {
            id: 475,
            slug: "diet-fitness",
            name: "Protein Bars & Powders",
            url: "/shop/cheap-groceries/vitamins-medicinal/diet-fitness"
          }, {
            id: 630,
            slug: "diet-slimming",
            name: "Diet & Slimming",
            url: "/shop/cheap-groceries/vitamins-medicinal/diet-slimming"
          }, {
            id: 476,
            slug: "vitamins",
            name: "Vitamins",
            url: "/shop/cheap-groceries/vitamins-medicinal/vitamins"
          }, {
            id: 477,
            slug: "first-aid-mecidinal",
            name: "First Aid & Medicinal",
            url: "/shop/cheap-groceries/vitamins-medicinal/first-aid-mecidinal"
          }, {
            id: 478,
            slug: "footcare-orthotics",
            name: "Footcare & Orthotics",
            url: "/shop/cheap-groceries/vitamins-medicinal/footcare-orthotics"
          }, {
            id: 642,
            slug: "sexual-health",
            name: "Sexual Health",
            url: "/shop/cheap-groceries/vitamins-medicinal/sexual-health"
          }]
        }, {
          id: 4,
          slug: "beverage",
          name: "Beverage",
          url: "/shop/cheap-groceries/beverage",
          shopNavigationItems: [{
            id: 14,
            slug: "beverage",
            name: "Beverage - all",
            url: "/shop/cheap-groceries/beverage/beverage"
          }, {
            id: 15,
            slug: "coffee",
            name: "Coffee",
            url: "/shop/cheap-groceries/beverage/coffee"
          }, {
            id: 16,
            slug: "tea",
            name: "Tea",
            url: "/shop/cheap-groceries/beverage/tea"
          }, {
            id: 17,
            slug: "energy-drinks",
            name: "Energy Drinks",
            url: "/shop/cheap-groceries/beverage/energy-drinks"
          }]
        }, {
          id: 143,
          slug: "toiletries",
          name: "Toiletries",
          url: "/shop/cheap-groceries/toiletries",
          shopNavigationItems: [{
            id: 469,
            slug: "oral-care",
            name: "Oral Care",
            url: "/shop/cheap-groceries/toiletries/oral-care"
          }, {
            id: 470,
            slug: "shaving-waxing",
            name: "Shaving & Waxing",
            url: "/shop/cheap-groceries/toiletries/shaving-waxing"
          }, {
            id: 471,
            slug: "deodorant",
            name: "Deodorant",
            url: "/shop/cheap-groceries/toiletries/deodorant"
          }, {
            id: 472,
            slug: "bath-body",
            name: "Bath & Body",
            url: "/shop/cheap-groceries/toiletries/bath-body"
          }, {
            id: 473,
            slug: "feminine-hygiene",
            name: "Feminine Hygiene",
            url: "/shop/cheap-groceries/toiletries/feminine-hygiene"
          }]
        }, {
          id: 5,
          slug: "home-car-cleaning",
          name: "Cleaning and Laundry",
          url: "/shop/cheap-groceries/home-car-cleaning",
          shopNavigationItems: [{
            id: 343,
            slug: "dishwashing",
            name: "Dishwashing",
            url: "/shop/cheap-groceries/home-car-cleaning/dishwashing"
          }, {
            id: 344,
            slug: "general-cleaning",
            name: "General Cleaning",
            url: "/shop/cheap-groceries/home-car-cleaning/general-cleaning"
          }, {
            id: 345,
            slug: "laundry",
            name: "Laundry",
            url: "/shop/cheap-groceries/home-car-cleaning/laundry"
          }]
        }, {
          id: 9,
          slug: "baby-food",
          name: "Baby",
          url: "/shop/cheap-groceries/baby-food",
          shopNavigationItems: [{
            id: 623,
            slug: "nappies-wipes",
            name: "Nappies & Wipes",
            url: "/shop/cheap-groceries/baby-food/nappies-wipes"
          }, {
            id: 624,
            slug: "formula-feeding",
            name: "Formula & Feeding",
            url: "/shop/cheap-groceries/baby-food/formula-feeding"
          }, {
            id: 625,
            slug: "bathtime",
            name: "Bathtime",
            url: "/shop/cheap-groceries/baby-food/bathtime"
          }]
        }, {
          id: 128,
          slug: "pets",
          name: "Pets",
          url: "/shop/cheap-groceries/pets",
          shopNavigationItems: [{
            id: 355,
            slug: "food-treats",
            name: "Food & Treats",
            url: "/shop/cheap-groceries/pets/food-treats"
          }, {
            id: 356,
            slug: "pet-health",
            name: "Pet Health",
            url: "/shop/cheap-groceries/pets/pet-health"
          }, {
            id: 357,
            slug: "toys-accessories",
            name: "Toys & Accessories",
            url: "/shop/cheap-groceries/pets/toys-accessories"
          }, {
            id: 358,
            slug: "bedding-and-furniture",
            name: "Bedding & Furniture",
            url: "/shop/cheap-groceries/pets/bedding-and-furniture"
          }]
        }],
        topBrands: [{
          id: 3048,
          slug: "finish",
          name: "Finish",
          url: "/brand/finish"
        }, {
          id: 2956,
          slug: "cadbury",
          name: "Cadbury",
          url: "/brand/cadbury"
        }, {
          id: 3114,
          slug: "carmans",
          name: "Carman's",
          url: "/brand/carmans"
        }, {
          id: 2930,
          slug: "kleenex",
          name: "Kleenex",
          url: "/brand/kleenex"
        }, {
          id: 3449,
          slug: "john-west",
          name: "John West",
          url: "/brand/john-west"
        }, {
          id: 3228,
          slug: "m-ms",
          name: "M&M'S",
          url: "/brand/m-ms"
        }, {
          id: 4316,
          slug: "nescafe",
          name: "Nescafé",
          url: "/brand/nescafe"
        }, {
          id: 7304,
          slug: "elastoplast",
          name: "Elastoplast",
          url: "/brand/elastoplast"
        }, {
          id: 2947,
          slug: "musashi",
          name: "Musashi",
          url: "/brand/musashi"
        }, {
          id: 3270,
          slug: "huggies",
          name: "Huggies",
          url: "/brand/huggies"
        }, {
          id: 7685,
          slug: "optimum-nutrition",
          name: "Optimum Nutrition",
          url: "/brand/optimum-nutrition"
        }, {
          id: 2959,
          slug: "swisse",
          name: "Swisse",
          url: "/brand/swisse"
        }, {
          id: 5354,
          slug: "maxines-burn",
          name: "Maxine's Burn",
          url: "/brand/maxines-burn"
        }, {
          id: 8444,
          slug: "cellucor",
          name: "Cellucor",
          url: "/brand/cellucor"
        }, {
          id: 5990,
          slug: "quest",
          name: "Quest",
          url: "/brand/quest"
        }]
      }, {
        id: 19,
        slug: "kids-clothing-toys",
        name: "Kids & Baby",
        url: "/shop/kids-clothing-toys",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 80,
          slug: "girls-clothing",
          name: "Girls Clothing",
          url: "/shop/kids-clothing-toys/girls-clothing",
          shopNavigationItems: [{
            id: 200,
            slug: "girls-shoe-sale",
            name: "Footwear",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-shoe-sale"
          }, {
            id: 201,
            slug: "girls-tops",
            name: "Tops",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-tops"
          }, {
            id: 584,
            slug: "bottom",
            name: "Bottoms",
            url: "/shop/kids-clothing-toys/girls-clothing/bottom"
          }, {
            id: 204,
            slug: "girls-sleepwear",
            name: "Sleepwear",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-sleepwear"
          }, {
            id: 209,
            slug: "girls-sportswear",
            name: "Sportswear",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-sportswear"
          }, {
            id: 219,
            slug: "girls-swimwear",
            name: "Swimwear",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-swimwear"
          }, {
            id: 205,
            slug: "girls-dresses-online",
            name: "Dresses",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-dresses-online"
          }, {
            id: 525,
            slug: "schoolwear",
            name: "Schoolwear",
            url: "/shop/kids-clothing-toys/girls-clothing/schoolwear"
          }, {
            id: 213,
            slug: "girls-jackets",
            name: "Coats and Jackets",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-jackets"
          }, {
            id: 203,
            slug: "girls-undies",
            name: "Underwear",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-undies"
          }, {
            id: 216,
            slug: "girls-jeans",
            name: "Jeans",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-jeans"
          }, {
            id: 214,
            slug: "girls-jumpers",
            name: "Jumpers",
            url: "/shop/kids-clothing-toys/girls-clothing/girls-jumpers"
          }]
        }, {
          id: 102,
          slug: "baby-bedding",
          name: "Baby Bedding",
          url: "/shop/kids-clothing-toys/baby-bedding",
          shopNavigationItems: []
        }, {
          id: 84,
          slug: "kids-bedroom",
          name: "Kid's Bedroom",
          url: "/shop/kids-clothing-toys/kids-bedroom",
          shopNavigationItems: [{
            id: 421,
            slug: "kids-bedding",
            name: "Kids Bedding",
            url: "/shop/kids-clothing-toys/kids-bedroom/kids-bedding"
          }, {
            id: 422,
            slug: "decor",
            name: "Decor",
            url: "/shop/kids-clothing-toys/kids-bedroom/decor"
          }, {
            id: 423,
            slug: "lighting",
            name: "Lighting",
            url: "/shop/kids-clothing-toys/kids-bedroom/lighting"
          }, {
            id: 424,
            slug: "furniture",
            name: "Furniture",
            url: "/shop/kids-clothing-toys/kids-bedroom/furniture"
          }]
        }, {
          id: 178,
          slug: "personalised",
          name: "Personalised",
          url: "/shop/kids-clothing-toys/personalised",
          shopNavigationItems: []
        }, {
          id: 85,
          slug: "boys-clothing",
          name: "Boys Clothing",
          url: "/shop/kids-clothing-toys/boys-clothing",
          shopNavigationItems: [{
            id: 251,
            slug: "boys-jumpers",
            name: "Jumpers",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-jumpers"
          }, {
            id: 236,
            slug: "boys-shoe-sale",
            name: "Footwear",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-shoe-sale"
          }, {
            id: 252,
            slug: "boys-jeans",
            name: "Jeans",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-jeans"
          }, {
            id: 239,
            slug: "boys-tops",
            name: "Tops",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-tops"
          }, {
            id: 585,
            slug: "bottoms",
            name: "Bottoms",
            url: "/shop/kids-clothing-toys/boys-clothing/bottoms"
          }, {
            id: 254,
            slug: "boys-swimwear",
            name: "Swimwear",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-swimwear"
          }, {
            id: 526,
            slug: "schoolwear",
            name: "Schoolwear",
            url: "/shop/kids-clothing-toys/boys-clothing/schoolwear"
          }, {
            id: 248,
            slug: "boys-sportswear",
            name: "Sportswear",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-sportswear"
          }, {
            id: 243,
            slug: "boys-underwear",
            name: "Underwear",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-underwear"
          }, {
            id: 249,
            slug: "boys-jackets",
            name: "Coats and Jackets",
            url: "/shop/kids-clothing-toys/boys-clothing/boys-jackets"
          }]
        }, {
          id: 149,
          slug: "baby-clothing",
          name: "Baby Clothing",
          url: "/shop/kids-clothing-toys/baby-clothing",
          shopNavigationItems: [{
            id: 502,
            slug: "footwear",
            name: "Footwear",
            url: "/shop/kids-clothing-toys/baby-clothing/footwear"
          }, {
            id: 503,
            slug: "tops",
            name: "Tops",
            url: "/shop/kids-clothing-toys/baby-clothing/tops"
          }, {
            id: 505,
            slug: "rompers",
            name: "Rompers",
            url: "/shop/kids-clothing-toys/baby-clothing/rompers"
          }, {
            id: 506,
            slug: "dresses",
            name: "Dresses",
            url: "/shop/kids-clothing-toys/baby-clothing/dresses"
          }, {
            id: 507,
            slug: "pants",
            name: "Bottoms",
            url: "/shop/kids-clothing-toys/baby-clothing/pants"
          }, {
            id: 509,
            slug: "sets",
            name: "Sets",
            url: "/shop/kids-clothing-toys/baby-clothing/sets"
          }, {
            id: 510,
            slug: "sleepwear",
            name: "Sleepwear",
            url: "/shop/kids-clothing-toys/baby-clothing/sleepwear"
          }]
        }, {
          id: 82,
          slug: "kids-accessories",
          name: "Kids Accessories",
          url: "/shop/kids-clothing-toys/kids-accessories",
          shopNavigationItems: []
        }, {
          id: 99,
          slug: "baby-sleep-products",
          name: "Sleeping",
          url: "/shop/kids-clothing-toys/baby-sleep-products",
          shopNavigationItems: []
        }, {
          id: 150,
          slug: "baby-travel",
          name: "Baby Travel",
          url: "/shop/kids-clothing-toys/baby-travel",
          shopNavigationItems: [{
            id: 511,
            slug: "baby-carriers",
            name: "Baby Carriers",
            url: "/shop/kids-clothing-toys/baby-travel/baby-carriers"
          }, {
            id: 512,
            slug: "prams-strollers",
            name: "Prams/Strollers",
            url: "/shop/kids-clothing-toys/baby-travel/prams-strollers"
          }, {
            id: 513,
            slug: "nappy-bags",
            name: "Nappy Bags",
            url: "/shop/kids-clothing-toys/baby-travel/nappy-bags"
          }, {
            id: 514,
            slug: "car-seats",
            name: "Car Seats",
            url: "/shop/kids-clothing-toys/baby-travel/car-seats"
          }, {
            id: 515,
            slug: "travel-cots",
            name: "Travel Cots",
            url: "/shop/kids-clothing-toys/baby-travel/travel-cots"
          }, {
            id: 516,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/kids-clothing-toys/baby-travel/accessories"
          }]
        }, {
          id: 105,
          slug: "nappies-on-sale",
          name: "Nappies & Toilet",
          url: "/shop/kids-clothing-toys/nappies-on-sale",
          shopNavigationItems: []
        }, {
          id: 103,
          slug: "baby-bath-products",
          name: "Bath Time",
          url: "/shop/kids-clothing-toys/baby-bath-products",
          shopNavigationItems: []
        }, {
          id: 104,
          slug: "baby-feeding",
          name: "Baby Feeding",
          url: "/shop/kids-clothing-toys/baby-feeding",
          shopNavigationItems: [{
            id: 519,
            slug: "baby-feeding",
            name: "Baby Feeding",
            url: "/shop/kids-clothing-toys/baby-feeding/baby-feeding"
          }, {
            id: 520,
            slug: "toddler-feeding",
            name: "Toddler Feeding",
            url: "/shop/kids-clothing-toys/baby-feeding/toddler-feeding"
          }, {
            id: 290,
            slug: "breastfeeding-products",
            name: "Breast Feeding",
            url: "/shop/kids-clothing-toys/baby-feeding/breastfeeding-products"
          }]
        }, {
          id: 106,
          slug: "baby-safety",
          name: "Health & Safety",
          url: "/shop/kids-clothing-toys/baby-safety",
          shopNavigationItems: [{
            id: 295,
            slug: "baby-monitor-sale",
            name: "Baby Monitors",
            url: "/shop/kids-clothing-toys/baby-safety/baby-monitor-sale"
          }, {
            id: 296,
            slug: "baby-medical-kit",
            name: "Medical",
            url: "/shop/kids-clothing-toys/baby-safety/baby-medical-kit"
          }, {
            id: 297,
            slug: "kids-dental-care",
            name: "Oral Care",
            url: "/shop/kids-clothing-toys/baby-safety/kids-dental-care"
          }, {
            id: 298,
            slug: "baby-safety-products",
            name: "Safety",
            url: "/shop/kids-clothing-toys/baby-safety/baby-safety-products"
          }]
        }],
        topBrands: [{
          id: 2975,
          slug: "pumpkin-patch",
          name: "Pumpkin Patch",
          url: "/brand/pumpkin-patch"
        }, {
          id: 2910,
          slug: "bonds",
          name: "Bonds",
          url: "/brand/bonds"
        }, {
          id: 3075,
          slug: "the-gro-company",
          name: "The Gro Company",
          url: "/brand/the-gro-company"
        }, {
          id: 5383,
          slug: "baby-bjorn",
          name: "Baby Bjorn",
          url: "/brand/baby-bjorn"
        }, {
          id: 8451,
          slug: "disney-baby",
          name: "DISNEY BABY",
          url: "/brand/disney-baby"
        }, {
          id: 7064,
          slug: "ingenuity",
          name: "INGENUITY",
          url: "/brand/ingenuity"
        }, {
          id: 3972,
          slug: "polo-ralph-lauren",
          name: "Polo Ralph Lauren",
          url: "/brand/polo-ralph-lauren"
        }, {
          id: 5696,
          slug: "cupid-girl",
          name: "Cupid Girl",
          url: "/brand/cupid-girl"
        }, {
          id: 8390,
          slug: "bqt",
          name: "BQT",
          url: "/brand/bqt"
        }, {
          id: 3156,
          slug: "bubba-blue",
          name: "Bubba Blue",
          url: "/brand/bubba-blue"
        }, {
          id: 3000,
          slug: "closer-to-nature",
          name: "Closer To Nature",
          url: "/brand/closer-to-nature"
        }, {
          id: 2864,
          slug: "avent",
          name: "Avent",
          url: "/brand/avent"
        }, {
          id: 3365,
          slug: "camelbak",
          name: "Camelbak",
          url: "/brand/camelbak"
        }]
      }, {
        id: 94,
        slug: "pets",
        name: "Pets",
        url: "/shop/pets",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 170,
          slug: "food-and-treats",
          name: "Food & Treats",
          url: "/shop/pets/food-and-treats",
          shopNavigationItems: [{
            id: 600,
            slug: "food-treats",
            name: "Food & Treats",
            url: "/shop/pets/food-and-treats/food-treats"
          }, {
            id: 601,
            slug: "dog",
            name: "Dog",
            url: "/shop/pets/food-and-treats/dog"
          }, {
            id: 602,
            slug: "cat",
            name: "Cat",
            url: "/shop/pets/food-and-treats/cat"
          }]
        }, {
          id: 171,
          slug: "pet-medicine",
          name: "Pet Medicine",
          url: "/shop/pets/pet-medicine",
          shopNavigationItems: [{
            id: 603,
            slug: "pet-medicine",
            name: "Pet Medicine",
            url: "/shop/pets/pet-medicine/pet-medicine"
          }, {
            id: 604,
            slug: "dog",
            name: "Dog",
            url: "/shop/pets/pet-medicine/dog"
          }, {
            id: 605,
            slug: "cat",
            name: "Cat",
            url: "/shop/pets/pet-medicine/cat"
          }]
        }, {
          id: 173,
          slug: "toys",
          name: "Toys",
          url: "/shop/pets/toys",
          shopNavigationItems: [{
            id: 613,
            slug: "toys",
            name: "Toys",
            url: "/shop/pets/toys/toys"
          }, {
            id: 614,
            slug: "dog",
            name: "Dog",
            url: "/shop/pets/toys/dog"
          }, {
            id: 615,
            slug: "cat",
            name: "Cat",
            url: "/shop/pets/toys/cat"
          }]
        }, {
          id: 172,
          slug: "pet-accessories",
          name: "Pet Accessories",
          url: "/shop/pets/pet-accessories",
          shopNavigationItems: [{
            id: 607,
            slug: "feeding",
            name: "Feeding",
            url: "/shop/pets/pet-accessories/feeding"
          }, {
            id: 608,
            slug: "collars-leads",
            name: "Collars & Leads",
            url: "/shop/pets/pet-accessories/collars-leads"
          }, {
            id: 609,
            slug: "harnesses-more",
            name: "Harnesses & More",
            url: "/shop/pets/pet-accessories/harnesses-more"
          }, {
            id: 610,
            slug: "grooming",
            name: "Grooming",
            url: "/shop/pets/pet-accessories/grooming"
          }, {
            id: 611,
            slug: "clean-up-odour-control",
            name: "Clean Up & Odour Control",
            url: "/shop/pets/pet-accessories/clean-up-odour-control"
          }, {
            id: 612,
            slug: "coats-apparel",
            name: "Coats & Apparel",
            url: "/shop/pets/pet-accessories/coats-apparel"
          }]
        }, {
          id: 174,
          slug: "bedding",
          name: "Bedding",
          url: "/shop/pets/bedding",
          shopNavigationItems: [{
            id: 616,
            slug: "bedding",
            name: "Bedding",
            url: "/shop/pets/bedding/bedding"
          }, {
            id: 617,
            slug: "dog-beds",
            name: "Dog Beds",
            url: "/shop/pets/bedding/dog-beds"
          }, {
            id: 618,
            slug: "elevated-beds",
            name: "Elevated Beds",
            url: "/shop/pets/bedding/elevated-beds"
          }, {
            id: 633,
            slug: "heated-pet-beds",
            name: "Heated Pet Beds",
            url: "/shop/pets/bedding/heated-pet-beds"
          }, {
            id: 619,
            slug: "cooling-mats",
            name: "Cooling Mats",
            url: "/shop/pets/bedding/cooling-mats"
          }]
        }, {
          id: 175,
          slug: "kennels",
          name: "Kennels",
          url: "/shop/pets/kennels",
          shopNavigationItems: []
        }, {
          id: 176,
          slug: "cat-scratchers",
          name: "Cat Scratchers",
          url: "/shop/pets/cat-scratchers",
          shopNavigationItems: []
        }, {
          id: 177,
          slug: "carriers-cages",
          name: "Carriers & Cages",
          url: "/shop/pets/carriers-cages",
          shopNavigationItems: []
        }],
        topBrands: [{
          id: 2978,
          slug: "schmackos",
          name: "Schmackos",
          url: "/brand/schmackos"
        }, {
          id: 3481,
          slug: "purina",
          name: "Purina",
          url: "/brand/purina"
        }, {
          id: 3874,
          slug: "pedigree",
          name: "Pedigree",
          url: "/brand/pedigree"
        }, {
          id: 2980,
          slug: "my-dog",
          name: "My Dog",
          url: "/brand/my-dog"
        }, {
          id: 8021,
          slug: "greenies",
          name: "Greenies",
          url: "/brand/greenies"
        }, {
          id: 5419,
          slug: "love-em",
          name: "Love 'em",
          url: "/brand/love-em"
        }, {
          id: 3742,
          slug: "whiskas",
          name: "Whiskas",
          url: "/brand/whiskas"
        }, {
          id: 3778,
          slug: "dine",
          name: "Dine",
          url: "/brand/dine"
        }, {
          id: 8320,
          slug: "felix",
          name: "Felix",
          url: "/brand/felix"
        }, {
          id: 3504,
          slug: "frontline",
          name: "Frontline",
          url: "/brand/frontline"
        }, {
          id: 8315,
          slug: "advantage",
          name: "Advantage",
          url: "/brand/advantage"
        }, {
          id: 3802,
          slug: "kong",
          name: "KONG",
          url: "/brand/kong"
        }]
      }, {
        id: 93,
        slug: "toys-games",
        name: "Toys & Games",
        url: "/shop/toys-games",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 157,
          slug: "kids-toys-games",
          name: "Kids Toys & Games",
          url: "/shop/toys-games/kids-toys-games",
          shopNavigationItems: [{
            id: 529,
            slug: "puzzles",
            name: "Puzzles",
            url: "/shop/toys-games/kids-toys-games/puzzles"
          }, {
            id: 530,
            slug: "board-games",
            name: "Board Games",
            url: "/shop/toys-games/kids-toys-games/board-games"
          }, {
            id: 531,
            slug: "building-construction",
            name: "Building & Construction",
            url: "/shop/toys-games/kids-toys-games/building-construction"
          }, {
            id: 532,
            slug: "educational",
            name: "Educational",
            url: "/shop/toys-games/kids-toys-games/educational"
          }, {
            id: 533,
            slug: "figures-playsets",
            name: "Figures & Playsets",
            url: "/shop/toys-games/kids-toys-games/figures-playsets"
          }, {
            id: 534,
            slug: "cars-trucks",
            name: "Cars & Trucks",
            url: "/shop/toys-games/kids-toys-games/cars-trucks"
          }, {
            id: 535,
            slug: "soft-toys",
            name: "Soft Toys",
            url: "/shop/toys-games/kids-toys-games/soft-toys"
          }, {
            id: 536,
            slug: "role-play",
            name: "Role Play",
            url: "/shop/toys-games/kids-toys-games/role-play"
          }, {
            id: 537,
            slug: "dolls-playsets",
            name: "Dolls & Playsets",
            url: "/shop/toys-games/kids-toys-games/dolls-playsets"
          }, {
            id: 538,
            slug: "wheels",
            name: "Wheels",
            url: "/shop/toys-games/kids-toys-games/wheels"
          }, {
            id: 539,
            slug: "outdoor",
            name: "Outdoor",
            url: "/shop/toys-games/kids-toys-games/outdoor"
          }, {
            id: 540,
            slug: "costumes",
            name: "Costumes",
            url: "/shop/toys-games/kids-toys-games/costumes"
          }]
        }, {
          id: 182,
          slug: "radio-control-toys",
          name: "Radio Control Toys",
          url: "/shop/toys-games/radio-control-toys",
          shopNavigationItems: []
        }, {
          id: 160,
          slug: "baby-toys-activities",
          name: "Baby Toys & Activities",
          url: "/shop/toys-games/baby-toys-activities",
          shopNavigationItems: [{
            id: 546,
            slug: "gyms-rocker-bouncers",
            name: "Gyms, Rocker & Bouncers",
            url: "/shop/toys-games/baby-toys-activities/gyms-rocker-bouncers"
          }, {
            id: 547,
            slug: "baby-toys",
            name: "Baby Toys",
            url: "/shop/toys-games/baby-toys-activities/baby-toys"
          }]
        }, {
          id: 384,
          slug: "costumes-novelty",
          name: "Costumes & Novelty",
          url: "/shop/toys-games/costumes-novelty",
          shopNavigationItems: [{
            id: 1703,
            slug: "costumes",
            name: "Costumes",
            url: "/shop/toys-games/costumes-novelty/costumes"
          }, {
            id: 1704,
            slug: "novelty",
            name: "Novelty",
            url: "/shop/toys-games/costumes-novelty/novelty"
          }]
        }, {
          id: 159,
          slug: "books",
          name: "Books",
          url: "/shop/toys-games/books",
          shopNavigationItems: [{
            id: 542,
            slug: "childrens-books",
            name: "Childrens Books",
            url: "/shop/toys-games/books/childrens-books"
          }, {
            id: 543,
            slug: "educational-books",
            name: "Educational Books",
            url: "/shop/toys-games/books/educational-books"
          }, {
            id: 544,
            slug: "dvds-cds",
            name: "DVDs & CDs",
            url: "/shop/toys-games/books/dvds-cds"
          }]
        }, {
          id: 161,
          slug: "art-craft",
          name: "Art & Craft",
          url: "/shop/toys-games/art-craft",
          shopNavigationItems: [{
            id: 549,
            slug: "stationery",
            name: "Stationery",
            url: "/shop/toys-games/art-craft/stationery"
          }, {
            id: 550,
            slug: "art-craft",
            name: "Art & Craft",
            url: "/shop/toys-games/art-craft/art-craft"
          }]
        }],
        topBrands: [{
          id: 7418,
          slug: "vtech",
          name: "Vtech",
          url: "/brand/vtech"
        }, {
          id: 6119,
          slug: "britzn-pieces",
          name: "Britz'n Pieces",
          url: "/brand/britzn-pieces"
        }, {
          id: 4251,
          slug: "wahu",
          name: "Wahu",
          url: "/brand/wahu"
        }, {
          id: 8118,
          slug: "john-deere",
          name: "John Deere",
          url: "/brand/john-deere"
        }, {
          id: 5293,
          slug: "hot-wheels",
          name: "Hot Wheels",
          url: "/brand/hot-wheels"
        }, {
          id: 2946,
          slug: "lego",
          name: "Lego",
          url: "/brand/lego"
        }, {
          id: 2831,
          slug: "disney",
          name: "Disney",
          url: "/brand/disney"
        }, {
          id: 5743,
          slug: "little-tikes",
          name: "Little Tikes",
          url: "/brand/little-tikes"
        }, {
          id: 3527,
          slug: "melissa-doug",
          name: "Melissa & Doug",
          url: "/brand/melissa-doug"
        }, {
          id: 2988,
          slug: "hasbro",
          name: "Hasbro",
          url: "/brand/hasbro"
        }, {
          id: 6707,
          slug: "spin-master",
          name: "Spin Master",
          url: "/brand/spin-master"
        }, {
          id: 3425,
          slug: "nerf",
          name: "NERF",
          url: "/brand/nerf"
        }]
      }, {
        id: 149,
        slug: "furniture",
        name: "Furniture",
        url: "/shop/furniture",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 284,
          slug: "bedroom",
          name: "Bedroom",
          url: "/shop/furniture/bedroom",
          shopNavigationItems: [{
            id: 1231,
            slug: "bedheads",
            name: "Bedheads",
            url: "/shop/furniture/bedroom/bedheads"
          }, {
            id: 1228,
            slug: "bed-frames",
            name: "Bed Frames",
            url: "/shop/furniture/bedroom/bed-frames"
          }, {
            id: 1233,
            slug: "mattresses",
            name: "Mattresses",
            url: "/shop/furniture/bedroom/mattresses"
          }, {
            id: 1229,
            slug: "bedside-tables",
            name: "Bedside Tables",
            url: "/shop/furniture/bedroom/bedside-tables"
          }, {
            id: 1230,
            slug: "dressers-and-tallboys",
            name: "Dressers and Tallboys",
            url: "/shop/furniture/bedroom/dressers-and-tallboys"
          }, {
            id: 1232,
            slug: "wardrobes",
            name: "Wardrobes",
            url: "/shop/furniture/bedroom/wardrobes"
          }]
        }, {
          id: 278,
          slug: "dining-kitchen",
          name: "Dining & Kitchen",
          url: "/shop/furniture/dining-kitchen",
          shopNavigationItems: [{
            id: 1193,
            slug: "dining-chair",
            name: "Dining Chair",
            url: "/shop/furniture/dining-kitchen/dining-chair"
          }, {
            id: 1192,
            slug: "bar-stools",
            name: "Bar Stools",
            url: "/shop/furniture/dining-kitchen/bar-stools"
          }, {
            id: 1194,
            slug: "dining-table",
            name: "Dining Table",
            url: "/shop/furniture/dining-kitchen/dining-table"
          }, {
            id: 1196,
            slug: "buffets-sideboards",
            name: "Buffets & Sideboards",
            url: "/shop/furniture/dining-kitchen/buffets-sideboards"
          }, {
            id: 1195,
            slug: "dining-sets",
            name: "Dining Sets",
            url: "/shop/furniture/dining-kitchen/dining-sets"
          }, {
            id: 1197,
            slug: "wine-racks-cabinets",
            name: "Wine Racks & Cabinets",
            url: "/shop/furniture/dining-kitchen/wine-racks-cabinets"
          }]
        }, {
          id: 281,
          slug: "living-room",
          name: "Living Room",
          url: "/shop/furniture/living-room",
          shopNavigationItems: [{
            id: 1212,
            slug: "sofa-beds",
            name: "Sofa Beds",
            url: "/shop/furniture/living-room/sofa-beds"
          }, {
            id: 1209,
            slug: "side-tables",
            name: "Side Tables",
            url: "/shop/furniture/living-room/side-tables"
          }, {
            id: 1210,
            slug: "coffee-tables",
            name: "Coffee Tables",
            url: "/shop/furniture/living-room/coffee-tables"
          }, {
            id: 1214,
            slug: "tv-units",
            name: "TV Units",
            url: "/shop/furniture/living-room/tv-units"
          }, {
            id: 1213,
            slug: "living-room-chairs",
            name: "Living Room Chairs",
            url: "/shop/furniture/living-room/living-room-chairs"
          }, {
            id: 1215,
            slug: "ottomans-poufs",
            name: "Ottomans & Poufs",
            url: "/shop/furniture/living-room/ottomans-poufs"
          }, {
            id: 1211,
            slug: "sofas-lounge-suites",
            name: "Sofas & Lounge Suites",
            url: "/shop/furniture/living-room/sofas-lounge-suites"
          }]
        }, {
          id: 285,
          slug: "office",
          name: "Office",
          url: "/shop/furniture/office",
          shopNavigationItems: [{
            id: 1236,
            slug: "office-chair",
            name: "Office Chair",
            url: "/shop/furniture/office/office-chair"
          }, {
            id: 1237,
            slug: "desks",
            name: "Desks",
            url: "/shop/furniture/office/desks"
          }, {
            id: 1239,
            slug: "storage-cabinets",
            name: "Storage Cabinets",
            url: "/shop/furniture/office/storage-cabinets"
          }, {
            id: 1238,
            slug: "office-accessories",
            name: "Office Accessories",
            url: "/shop/furniture/office/office-accessories"
          }, {
            id: 1240,
            slug: "safes",
            name: "Safes",
            url: "/shop/furniture/office/safes"
          }, {
            id: 1241,
            slug: "lockers",
            name: "Lockers",
            url: "/shop/furniture/office/lockers"
          }]
        }, {
          id: 279,
          slug: "storage-organisation",
          name: "Storage & Organisation",
          url: "/shop/furniture/storage-organisation",
          shopNavigationItems: [{
            id: 1199,
            slug: "boxes-baskets",
            name: "Boxes & Baskets",
            url: "/shop/furniture/storage-organisation/boxes-baskets"
          }, {
            id: 1200,
            slug: "display-cabinets",
            name: "Display Cabinets",
            url: "/shop/furniture/storage-organisation/display-cabinets"
          }, {
            id: 1201,
            slug: "bookcases-shelving-units",
            name: "Bookcases & Shelving Units",
            url: "/shop/furniture/storage-organisation/bookcases-shelving-units"
          }, {
            id: 1202,
            slug: "room-divider",
            name: "Room Divider",
            url: "/shop/furniture/storage-organisation/room-divider"
          }]
        }, {
          id: 282,
          slug: "outdoor",
          name: "Outdoor",
          url: "/shop/furniture/outdoor",
          shopNavigationItems: [{
            id: 1217,
            slug: "chairs",
            name: "Chairs",
            url: "/shop/furniture/outdoor/chairs"
          }, {
            id: 1216,
            slug: "sofas-lounge-sets",
            name: "Sofas & Lounge Sets",
            url: "/shop/furniture/outdoor/sofas-lounge-sets"
          }, {
            id: 1218,
            slug: "dining-sets",
            name: "Dining Sets",
            url: "/shop/furniture/outdoor/dining-sets"
          }, {
            id: 1221,
            slug: "rugs",
            name: "Rugs",
            url: "/shop/furniture/outdoor/rugs"
          }]
        }, {
          id: 280,
          slug: "nursery-furniture",
          name: "Nursery Furniture",
          url: "/shop/furniture/nursery-furniture",
          shopNavigationItems: [{
            id: 1204,
            slug: "bassinets-cradles",
            name: "Bassinets & Cradles",
            url: "/shop/furniture/nursery-furniture/bassinets-cradles"
          }, {
            id: 1205,
            slug: "change-tables-chests",
            name: "Change Tables & Chests",
            url: "/shop/furniture/nursery-furniture/change-tables-chests"
          }, {
            id: 1206,
            slug: "cot-mattresses",
            name: "Cot Mattresses",
            url: "/shop/furniture/nursery-furniture/cot-mattresses"
          }, {
            id: 1208,
            slug: "bookcases-shelves",
            name: "Bookcases & Shelves",
            url: "/shop/furniture/nursery-furniture/bookcases-shelves"
          }]
        }, {
          id: 286,
          slug: "kids",
          name: "Kids",
          url: "/shop/furniture/kids",
          shopNavigationItems: [{
            id: 1242,
            slug: "bed-frames",
            name: "Bed Frames",
            url: "/shop/furniture/kids/bed-frames"
          }, {
            id: 1247,
            slug: "bookcases-shelves",
            name: "Bookcases & Shelves",
            url: "/shop/furniture/kids/bookcases-shelves"
          }, {
            id: 1248,
            slug: "toy-boxes",
            name: "Toy Boxes",
            url: "/shop/furniture/kids/toy-boxes"
          }, {
            id: 1249,
            slug: "cabinets",
            name: "Cabinets",
            url: "/shop/furniture/kids/cabinets"
          }, {
            id: 1243,
            slug: "dressers-sidetables",
            name: "Dressers & Sidetables",
            url: "/shop/furniture/kids/dressers-sidetables"
          }, {
            id: 1244,
            slug: "table-chairs-seats",
            name: "Table, Chairs & Seats",
            url: "/shop/furniture/kids/table-chairs-seats"
          }, {
            id: 1245,
            slug: "rugs",
            name: "Rugs",
            url: "/shop/furniture/kids/rugs"
          }, {
            id: 1246,
            slug: "childrens-bed-linen",
            name: "Childrens Bed Linen",
            url: "/shop/furniture/kids/childrens-bed-linen"
          }]
        }, {
          id: 283,
          slug: "rugs",
          name: "Rugs",
          url: "/shop/furniture/rugs",
          shopNavigationItems: [{
            id: 1222,
            slug: "outdoor-rugs",
            name: "Outdoor Rugs",
            url: "/shop/furniture/rugs/outdoor-rugs"
          }, {
            id: 1223,
            slug: "contemporary-rugs",
            name: "Contemporary Rugs",
            url: "/shop/furniture/rugs/contemporary-rugs"
          }, {
            id: 1224,
            slug: "shag-rugs",
            name: "Shag Rugs",
            url: "/shop/furniture/rugs/shag-rugs"
          }, {
            id: 1225,
            slug: "traditional-rugs",
            name: "Traditional Rugs",
            url: "/shop/furniture/rugs/traditional-rugs"
          }, {
            id: 1226,
            slug: "mats",
            name: "Mats",
            url: "/shop/furniture/rugs/mats"
          }, {
            id: 1227,
            slug: "kids-rugs",
            name: "Kids Rugs",
            url: "/shop/furniture/rugs/kids-rugs"
          }]
        }],
        topBrands: [{
          id: 2831,
          slug: "disney",
          name: "Disney",
          url: "/brand/disney"
        }]
      }, {
        id: 92,
        slug: "entertainment",
        name: "Entertainment",
        url: "/shop/entertainment",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 167,
          slug: "books",
          name: "Books",
          url: "/shop/entertainment/books",
          shopNavigationItems: [{
            id: 591,
            slug: "kids-books",
            name: "Kids' Books",
            url: "/shop/entertainment/books/kids-books"
          }, {
            id: 592,
            slug: "cookbooks",
            name: "Cookbooks",
            url: "/shop/entertainment/books/cookbooks"
          }, {
            id: 593,
            slug: "lifestyle-novels",
            name: "Lifestyle & Novels",
            url: "/shop/entertainment/books/lifestyle-novels"
          }]
        }, {
          id: 168,
          slug: "media",
          name: "Media",
          url: "/shop/entertainment/media",
          shopNavigationItems: [{
            id: 595,
            slug: "cds",
            name: "CD's",
            url: "/shop/entertainment/media/cds"
          }, {
            id: 596,
            slug: "dvds",
            name: "DVD's",
            url: "/shop/entertainment/media/dvds"
          }, {
            id: 766,
            slug: "blu-ray",
            name: "Blu-Ray",
            url: "/shop/entertainment/media/blu-ray"
          }]
        }, {
          id: 166,
          slug: "gaming",
          name: "Gaming",
          url: "/shop/entertainment/gaming",
          shopNavigationItems: [{
            id: 587,
            slug: "consoles",
            name: "Consoles",
            url: "/shop/entertainment/gaming/consoles"
          }, {
            id: 588,
            slug: "games",
            name: "Games",
            url: "/shop/entertainment/gaming/games"
          }, {
            id: 589,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/entertainment/gaming/accessories"
          }]
        }, {
          id: 163,
          slug: "novelty",
          name: "Novelty",
          url: "/shop/entertainment/novelty",
          shopNavigationItems: [{
            id: 577,
            slug: "novelty",
            name: "Novelty",
            url: "/shop/entertainment/novelty/novelty"
          }, {
            id: 647,
            slug: "costumes",
            name: "Costumes",
            url: "/shop/entertainment/novelty/costumes"
          }]
        }, {
          id: 164,
          slug: "stationery",
          name: "Stationery",
          url: "/shop/entertainment/stationery",
          shopNavigationItems: [{
            id: 578,
            slug: "stationery-office-supplies",
            name: "Stationery & Office Supplies",
            url: "/shop/entertainment/stationery/stationery-office-supplies"
          }, {
            id: 579,
            slug: "kids-art-craft",
            name: "Kids Art & Craft",
            url: "/shop/entertainment/stationery/kids-art-craft"
          }]
        }, {
          id: 156,
          slug: "entertainment",
          name: "Entertainment",
          url: "/shop/entertainment/entertainment",
          shopNavigationItems: [{
            id: 521,
            slug: "books",
            name: "Books",
            url: "/shop/entertainment/entertainment/books"
          }, {
            id: 522,
            slug: "music",
            name: "Music",
            url: "/shop/entertainment/entertainment/music"
          }, {
            id: 523,
            slug: "blu-ray-dvds",
            name: "Blu-Ray & DVD's",
            url: "/shop/entertainment/entertainment/blu-ray-dvds"
          }, {
            id: 524,
            slug: "novelty-collectables",
            name: "Novelty & Collectables",
            url: "/shop/entertainment/entertainment/novelty-collectables"
          }]
        }, {
          id: 165,
          slug: "tvs-home-entertainment",
          name: "TVs & Home Entertainment",
          url: "/shop/entertainment/tvs-home-entertainment",
          shopNavigationItems: [{
            id: 581,
            slug: "tvs",
            name: "TVs",
            url: "/shop/entertainment/tvs-home-entertainment/tvs"
          }, {
            id: 582,
            slug: "players-recorders",
            name: "Players & Recorders",
            url: "/shop/entertainment/tvs-home-entertainment/players-recorders"
          }, {
            id: 583,
            slug: "accessories",
            name: "Accessories",
            url: "/shop/entertainment/tvs-home-entertainment/accessories"
          }]
        }, {
          id: 169,
          slug: "audio",
          name: "Audio",
          url: "/shop/entertainment/audio",
          shopNavigationItems: [{
            id: 598,
            slug: "audio-players-recorders",
            name: "Audio Players & Recorders",
            url: "/shop/entertainment/audio/audio-players-recorders"
          }, {
            id: 599,
            slug: "headphones",
            name: "Headphones",
            url: "/shop/entertainment/audio/headphones"
          }]
        }, {
          id: 183,
          slug: "adults-only",
          name: "Adults Only",
          url: "/shop/entertainment/adults-only",
          shopNavigationItems: [{
            id: 656,
            slug: "for-him",
            name: "For Him",
            url: "/shop/entertainment/adults-only/for-him"
          }, {
            id: 657,
            slug: "for-her",
            name: "For Her",
            url: "/shop/entertainment/adults-only/for-her"
          }, {
            id: 658,
            slug: "for-couples",
            name: "For Couples",
            url: "/shop/entertainment/adults-only/for-couples"
          }, {
            id: 659,
            slug: "lingerie",
            name: "Lingerie",
            url: "/shop/entertainment/adults-only/lingerie"
          }, {
            id: 660,
            slug: "condoms-lubricants",
            name: "Condoms & Lubricants",
            url: "/shop/entertainment/adults-only/condoms-lubricants"
          }]
        }],
        topBrands: [{
          id: 4351,
          slug: "scholastic",
          name: "Scholastic",
          url: "/brand/scholastic"
        }, {
          id: 4282,
          slug: "crayola",
          name: "Crayola",
          url: "/brand/crayola"
        }, {
          id: 3568,
          slug: "spirograph",
          name: "Spirograph",
          url: "/brand/spirograph"
        }, {
          id: 5436,
          slug: "pilot",
          name: "Pilot",
          url: "/brand/pilot"
        }, {
          id: 6654,
          slug: "sheaffer",
          name: "Sheaffer",
          url: "/brand/sheaffer"
        }, {
          id: 6046,
          slug: "dymo",
          name: "Dymo",
          url: "/brand/dymo"
        }, {
          id: 8398,
          slug: "sellotape",
          name: "Sellotape",
          url: "/brand/sellotape"
        }, {
          id: 5721,
          slug: "faber-castell",
          name: "Faber-Castell",
          url: "/brand/faber-castell"
        }, {
          id: 4387,
          slug: "derwent",
          name: "Derwent",
          url: "/brand/derwent"
        }, {
          id: 5671,
          slug: "sharpie",
          name: "Sharpie",
          url: "/brand/sharpie"
        }, {
          id: 6189,
          slug: "collins-debden",
          name: "Collins Debden",
          url: "/brand/collins-debden"
        }, {
          id: 4501,
          slug: "australian-women-s-weekly",
          name: "Australian Women's Weekly",
          url: "/brand/australian-women-s-weekly"
        }, {
          id: 4051,
          slug: "bantex",
          name: "Bantex",
          url: "/brand/bantex"
        }, {
          id: 3266,
          slug: "bic",
          name: "BiC",
          url: "/brand/bic"
        }, {
          id: 5340,
          slug: "beats-by-dr-dre",
          name: "Beats by Dr. Dre",
          url: "/brand/beats-by-dr-dre"
        }, {
          id: 3869,
          slug: "logitech",
          name: "Logitech",
          url: "/brand/logitech"
        }, {
          id: 3711,
          slug: "jbl",
          name: "JBL",
          url: "/brand/jbl"
        }, {
          id: 4009,
          slug: "gopro",
          name: "Gopro",
          url: "/brand/gopro"
        }]
      }, {
        id: 152,
        slug: "appliances",
        name: "Appliances",
        url: "/shop/appliances",
        pinToTop: false,
        shopNavigationGroups: [{
          id: 320,
          slug: "vacuum-floorcare",
          name: "Vacuum & Floorcare",
          url: "/shop/appliances/vacuum-floorcare",
          shopNavigationItems: [{
            id: 1420,
            slug: "vacuum",
            name: "Vacuum",
            url: "/shop/appliances/vacuum-floorcare/vacuum"
          }, {
            id: 1421,
            slug: "handheld-vacuum",
            name: "Handheld Vacuum",
            url: "/shop/appliances/vacuum-floorcare/handheld-vacuum"
          }, {
            id: 1422,
            slug: "stick-vacuum",
            name: "Stick Vacuum",
            url: "/shop/appliances/vacuum-floorcare/stick-vacuum"
          }, {
            id: 1423,
            slug: "robot-vacuum",
            name: "Robot Vacuum",
            url: "/shop/appliances/vacuum-floorcare/robot-vacuum"
          }, {
            id: 1424,
            slug: "vacuum-accessories",
            name: "Vacuum Accessories",
            url: "/shop/appliances/vacuum-floorcare/vacuum-accessories"
          }, {
            id: 1425,
            slug: "steam-mop-cleaner",
            name: "Steam Mop/Cleaner",
            url: "/shop/appliances/vacuum-floorcare/steam-mop-cleaner"
          }, {
            id: 1426,
            slug: "pressure-cleaner",
            name: "Pressure Cleaner",
            url: "/shop/appliances/vacuum-floorcare/pressure-cleaner"
          }]
        }, {
          id: 328,
          slug: "toaster-kettle",
          name: "Toaster & Kettle",
          url: "/shop/appliances/toaster-kettle",
          shopNavigationItems: [{
            id: 1468,
            slug: "toaster",
            name: "Toaster",
            url: "/shop/appliances/toaster-kettle/toaster"
          }, {
            id: 1469,
            slug: "kettle",
            name: "Kettle",
            url: "/shop/appliances/toaster-kettle/kettle"
          }, {
            id: 1470,
            slug: "sandwich-press-jaffel",
            name: "Sandwich Press/Jaffel",
            url: "/shop/appliances/toaster-kettle/sandwich-press-jaffel"
          }, {
            id: 1471,
            slug: "toaster-kettle",
            name: "Toaster & Kettle",
            url: "/shop/appliances/toaster-kettle/toaster-kettle"
          }]
        }, {
          id: 321,
          slug: "coffee-machines-beverage",
          name: "Coffee Machines & Beverage",
          url: "/shop/appliances/coffee-machines-beverage",
          shopNavigationItems: [{
            id: 1427,
            slug: "coffee-machines",
            name: "Coffee Machines",
            url: "/shop/appliances/coffee-machines-beverage/coffee-machines"
          }, {
            id: 1428,
            slug: "coffee-grinders",
            name: "Coffee Grinders",
            url: "/shop/appliances/coffee-machines-beverage/coffee-grinders"
          }, {
            id: 1429,
            slug: "juicers",
            name: "Juicers",
            url: "/shop/appliances/coffee-machines-beverage/juicers"
          }, {
            id: 1430,
            slug: "water-filter-urns",
            name: "Water Filter & Urns",
            url: "/shop/appliances/coffee-machines-beverage/water-filter-urns"
          }]
        }, {
          id: 322,
          slug: "specialty-appliances",
          name: "Specialty Appliances",
          url: "/shop/appliances/specialty-appliances",
          shopNavigationItems: [{
            id: 1431,
            slug: "food-vacuum-sealers",
            name: "Food Vacuum Sealers",
            url: "/shop/appliances/specialty-appliances/food-vacuum-sealers"
          }, {
            id: 1432,
            slug: "soup-makers",
            name: "Soup Makers",
            url: "/shop/appliances/specialty-appliances/soup-makers"
          }, {
            id: 1433,
            slug: "bread-makers",
            name: "Bread Makers",
            url: "/shop/appliances/specialty-appliances/bread-makers"
          }, {
            id: 1434,
            slug: "food-dehydrators",
            name: "Food Dehydrators",
            url: "/shop/appliances/specialty-appliances/food-dehydrators"
          }, {
            id: 1435,
            slug: "pie-pizza-makers",
            name: "Pie & Pizza Makers",
            url: "/shop/appliances/specialty-appliances/pie-pizza-makers"
          }, {
            id: 1436,
            slug: "novelty-appliances",
            name: "Novelty Appliances",
            url: "/shop/appliances/specialty-appliances/novelty-appliances"
          }, {
            id: 1437,
            slug: "food-warmer",
            name: "Food Warmer",
            url: "/shop/appliances/specialty-appliances/food-warmer"
          }, {
            id: 1438,
            slug: "kitchen-scale",
            name: "Kitchen Scale",
            url: "/shop/appliances/specialty-appliances/kitchen-scale"
          }, {
            id: 1439,
            slug: "mincer",
            name: "Mincer",
            url: "/shop/appliances/specialty-appliances/mincer"
          }, {
            id: 1440,
            slug: "pasta-machine",
            name: "Pasta Machine",
            url: "/shop/appliances/specialty-appliances/pasta-machine"
          }]
        }, {
          id: 329,
          slug: "mixers-food-processor",
          name: "Mixers & Food Processor",
          url: "/shop/appliances/mixers-food-processor",
          shopNavigationItems: [{
            id: 1472,
            slug: "stand-mixer",
            name: "Stand Mixer",
            url: "/shop/appliances/mixers-food-processor/stand-mixer"
          }, {
            id: 1473,
            slug: "hand-mixer",
            name: "Hand Mixer",
            url: "/shop/appliances/mixers-food-processor/hand-mixer"
          }, {
            id: 1474,
            slug: "food-processors",
            name: "Food Processors",
            url: "/shop/appliances/mixers-food-processor/food-processors"
          }, {
            id: 1475,
            slug: "blenders",
            name: "Blenders",
            url: "/shop/appliances/mixers-food-processor/blenders"
          }, {
            id: 1476,
            slug: "electric-slicer",
            name: "Electric Slicer",
            url: "/shop/appliances/mixers-food-processor/electric-slicer"
          }]
        }, {
          id: 330,
          slug: "bathroom",
          name: "Bathroom",
          url: "/shop/appliances/bathroom",
          shopNavigationItems: [{
            id: 1477,
            slug: "facial-brush-sauna",
            name: "Facial Brush & Sauna",
            url: "/shop/appliances/bathroom/facial-brush-sauna"
          }, {
            id: 1478,
            slug: "shaving-hair-removal",
            name: "Shaving & Hair Removal",
            url: "/shop/appliances/bathroom/shaving-hair-removal"
          }, {
            id: 1479,
            slug: "hair-straightener-curler",
            name: "Hair Straightener & Curler",
            url: "/shop/appliances/bathroom/hair-straightener-curler"
          }, {
            id: 1480,
            slug: "hair-dryer",
            name: "Hair Dryer",
            url: "/shop/appliances/bathroom/hair-dryer"
          }, {
            id: 1481,
            slug: "hair-clippers",
            name: "Hair Clippers",
            url: "/shop/appliances/bathroom/hair-clippers"
          }, {
            id: 1482,
            slug: "scale",
            name: "Scale",
            url: "/shop/appliances/bathroom/scale"
          }]
        }, {
          id: 324,
          slug: "laundry",
          name: "Laundry",
          url: "/shop/appliances/laundry",
          shopNavigationItems: [{
            id: 1444,
            slug: "washing-machine",
            name: "Washing Machine",
            url: "/shop/appliances/laundry/washing-machine"
          }, {
            id: 1445,
            slug: "dryer",
            name: "Dryer",
            url: "/shop/appliances/laundry/dryer"
          }, {
            id: 1446,
            slug: "ironing",
            name: "Ironing",
            url: "/shop/appliances/laundry/ironing"
          }, {
            id: 1447,
            slug: "electric-clothes-rack",
            name: "Electric Clothes Rack",
            url: "/shop/appliances/laundry/electric-clothes-rack"
          }, {
            id: 1448,
            slug: "garment-steamer",
            name: "Garment Steamer",
            url: "/shop/appliances/laundry/garment-steamer"
          }]
        }, {
          id: 327,
          slug: "heating-cooling",
          name: "Heating & Cooling",
          url: "/shop/appliances/heating-cooling",
          shopNavigationItems: [{
            id: 1463,
            slug: "air-conditioning",
            name: "Air Conditioning",
            url: "/shop/appliances/heating-cooling/air-conditioning"
          }, {
            id: 1464,
            slug: "fans",
            name: "Fans",
            url: "/shop/appliances/heating-cooling/fans"
          }, {
            id: 1465,
            slug: "heaters",
            name: "Heaters",
            url: "/shop/appliances/heating-cooling/heaters"
          }, {
            id: 1466,
            slug: "air-treatment",
            name: "Air Treatment",
            url: "/shop/appliances/heating-cooling/air-treatment"
          }, {
            id: 1467,
            slug: "electric-blanket",
            name: "Electric Blanket",
            url: "/shop/appliances/heating-cooling/electric-blanket"
          }]
        }, {
          id: 325,
          slug: "benchtop-cooking",
          name: "Benchtop Cooking",
          url: "/shop/appliances/benchtop-cooking",
          shopNavigationItems: [{
            id: 1449,
            slug: "air-fryers",
            name: "Air Fryers",
            url: "/shop/appliances/benchtop-cooking/air-fryers"
          }, {
            id: 1450,
            slug: "deep-fryers",
            name: "Deep Fryers",
            url: "/shop/appliances/benchtop-cooking/deep-fryers"
          }, {
            id: 1451,
            slug: "pressure-cookers",
            name: "Pressure Cookers",
            url: "/shop/appliances/benchtop-cooking/pressure-cookers"
          }, {
            id: 1452,
            slug: "rice-cooker",
            name: "Rice Cooker",
            url: "/shop/appliances/benchtop-cooking/rice-cooker"
          }, {
            id: 1453,
            slug: "slow-cooker",
            name: "Slow Cooker",
            url: "/shop/appliances/benchtop-cooking/slow-cooker"
          }, {
            id: 1454,
            slug: "multi-cooker",
            name: "Multi Cooker",
            url: "/shop/appliances/benchtop-cooking/multi-cooker"
          }, {
            id: 1455,
            slug: "steamer",
            name: "Steamer",
            url: "/shop/appliances/benchtop-cooking/steamer"
          }, {
            id: 1456,
            slug: "electric-frypan",
            name: "Electric Frypan",
            url: "/shop/appliances/benchtop-cooking/electric-frypan"
          }]
        }, {
          id: 326,
          slug: "healthcare",
          name: "Healthcare",
          url: "/shop/appliances/healthcare",
          shopNavigationItems: [{
            id: 1458,
            slug: "oral-care",
            name: "Oral Care",
            url: "/shop/appliances/healthcare/oral-care"
          }, {
            id: 1459,
            slug: "massager-footspa",
            name: "Massager & Footspa",
            url: "/shop/appliances/healthcare/massager-footspa"
          }, {
            id: 1460,
            slug: "blood-pressure-monitor",
            name: "Blood Pressure Monitor",
            url: "/shop/appliances/healthcare/blood-pressure-monitor"
          }, {
            id: 1461,
            slug: "thermometer",
            name: "Thermometer",
            url: "/shop/appliances/healthcare/thermometer"
          }, {
            id: 1462,
            slug: "alcohol-breath-tester",
            name: "Alcohol Breath Tester",
            url: "/shop/appliances/healthcare/alcohol-breath-tester"
          }]
        }, {
          id: 319,
          slug: "cooking-dishwashers",
          name: "Cooking & Dishwashers",
          url: "/shop/appliances/cooking-dishwashers",
          shopNavigationItems: [{
            id: 1412,
            slug: "built-in-ovens",
            name: "Built in Ovens",
            url: "/shop/appliances/cooking-dishwashers/built-in-ovens"
          }, {
            id: 1413,
            slug: "cooktops-stoves",
            name: "Cooktops & Stoves",
            url: "/shop/appliances/cooking-dishwashers/cooktops-stoves"
          }, {
            id: 1414,
            slug: "rangehoods",
            name: "Rangehoods",
            url: "/shop/appliances/cooking-dishwashers/rangehoods"
          }, {
            id: 1415,
            slug: "microwaves",
            name: "Microwaves",
            url: "/shop/appliances/cooking-dishwashers/microwaves"
          }, {
            id: 1416,
            slug: "dishwashers",
            name: "Dishwashers",
            url: "/shop/appliances/cooking-dishwashers/dishwashers"
          }, {
            id: 1417,
            slug: "outdoor-cooking",
            name: "Outdoor Cooking",
            url: "/shop/appliances/cooking-dishwashers/outdoor-cooking"
          }, {
            id: 1418,
            slug: "sinks-taps",
            name: "Sinks & Taps",
            url: "/shop/appliances/cooking-dishwashers/sinks-taps"
          }, {
            id: 1419,
            slug: "benchtop-ovens",
            name: "Benchtop Ovens",
            url: "/shop/appliances/cooking-dishwashers/benchtop-ovens"
          }]
        }, {
          id: 323,
          slug: "fridges-freezers",
          name: "Fridges & Freezers",
          url: "/shop/appliances/fridges-freezers",
          shopNavigationItems: [{
            id: 1441,
            slug: "freezer",
            name: "Freezer",
            url: "/shop/appliances/fridges-freezers/freezer"
          }, {
            id: 1442,
            slug: "fridge",
            name: "Fridge",
            url: "/shop/appliances/fridges-freezers/fridge"
          }, {
            id: 1443,
            slug: "wine-fridge",
            name: "Wine Fridge",
            url: "/shop/appliances/fridges-freezers/wine-fridge"
          }]
        }],
        topBrands: [{
          id: 5023,
          slug: "bosch",
          name: "Bosch",
          url: "/brand/bosch"
        }]
      }, {
        id: 96,
        slug: "outlets",
        name: "Fashion Outlets",
        url: "/shop/outlets",
        pinToTop: true,
        shopNavigationGroups: [],
        topBrands: [{
          id: 2820,
          slug: "nike",
          name: "Nike",
          url: "/brand/nike"
        }, {
          id: 3710,
          slug: "levis",
          name: "Levi's",
          url: "/brand/levis"
        }, {
          id: 9173,
          slug: "phillip-steele",
          name: "Phillip Steele",
          url: "/brand/phillip-steele"
        }, {
          id: 2825,
          slug: "tommy-hilfiger",
          name: "Tommy Hilfiger",
          url: "/brand/tommy-hilfiger"
        }, {
          id: 9860,
          slug: "saba",
          name: "SABA",
          url: "/brand/saba"
        }]
      }, {
        id: 104,
        slug: "black-friday",
        name: "Black Friday Sales",
        url: "/shop/black-friday",
        pinToTop: true,
        shopNavigationGroups: [],
        topBrands: []
      }, {
        id: 184,
        slug: "target",
        name: "Target",
        url: "/shop/target",
        pinToTop: false,
        shopNavigationGroups: [],
        topBrands: [{
          id: 3856,
          slug: "lonsdale",
          name: "Lonsdale",
          url: "/brand/lonsdale"
        }, {
          id: 5014,
          slug: "target",
          name: "Target",
          url: "/brand/target"
        }, {
          id: 194705,
          slug: "target-active",
          name: "Target Active",
          url: "/brand/target-active"
        }, {
          id: 194709,
          slug: "target-collection",
          name: "Target Collection",
          url: "/brand/target-collection"
        }, {
          id: 194779,
          slug: "target-essentials",
          name: "Target essentials",
          url: "/brand/target-essentials"
        }]
      }, {
        id: 0,
        slug: "today-s-deals",
        name: "Today's Deals",
        url: "/",
        pinToTop: true,
        shopNavigationGroups: [],
        topBrands: []
      }],
      catchEssentialsMenu: {
        giftCards: "/gift-card/",
        catchMobile: "/catch-connect"
      },
      accountMenu: {
        flybuysNotificationCount: 0,
        unreadMarketplaceMessageCount: 0,
        loggedInMenu: [{
          name: "My Orders",
          url: "/my-account/my-orders"
        }, {
          name: "Personal Details",
          url: "/my-account/details"
        }, {
          name: "Wishlist",
          url: "/my-account/wishlist"
        }, {
          name: "Messages",
          url: "/my-account/messages"
        }, {
          name: "OnePass",
          url: "/my-account/onepass-subscription"
        }, {
          name: "Flybuys",
          url: "/flybuys"
        }, {
          name: "Coupons",
          url: "/my-account/my-coupons"
        }, {
          name: "Addresses",
          url: "/my-account/address-book"
        }, {
          name: "Subscriptions",
          url: "/my-account/newsletter-subscriptions"
        }, {
          name: "Saved Card",
          url: "/my-account/saved-card"
        }, {
          name: "Feedback",
          url: "/my-account/feedback"
        }, {
          name: "Help Centre",
          url: "https://help.catch.com.au/"
        }, {
          name: "Logout",
          url: "/logout"
        }],
        loggedOutMenu: [{
          name: "Login",
          url: "/login"
        }, {
          name: "Forgot Password",
          url: "/reset-password"
        }, {
          name: "Sign Up",
          url: "/register"
        }, {
          name: "Help Centre",
          url: "https://help.catch.com.au/"
        }]
      }
    }
  }
};