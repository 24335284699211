import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import shouldForwardProp from "@styled-system/should-forward-prop";
import { compose, layout, space, styled } from "@catchoftheday/theme";
var styleProps = compose(space, layout);
export var Picture = styled("picture", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: auto;\n  ", "\n"])), styleProps);
export default Picture;