import React from "react";
import { Box, IconToolTip, Span, Text, Tooltip } from "@catchoftheday/cg-components";
import { CountdownTimer } from "../CountdownTimer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceLabel = function ProductPriceLabel(_ref) {
  var discount = _ref.discount,
      enabled = _ref.enabled,
      topLabel = _ref.topLabel,
      large = _ref.large,
      retailPriceDisclaimer = _ref.retailPriceDisclaimer;

  if (!topLabel && !discount) {
    return null;
  }

  if (topLabel) {
    return ___EmotionJSX(Span, {
      display: "inline-block",
      fontSize: large ? "0.9rem" : "xs",
      fontWeight: "semibold",
      verticalAlign: "middle",
      color: enabled ? "typography.text" : "typography.inactive"
    }, topLabel, large && retailPriceDisclaimer && ___EmotionJSX(Tooltip, {
      ml: "5px",
      reference: ___EmotionJSX(IconToolTip, {
        "aria-label": "Price disclaimer",
        tabIndex: 0,
        width: "12px",
        height: "12px",
        mb: "2px",
        color: "typography.subText"
      }),
      placement: "top"
    }, ___EmotionJSX(Text, {
      m: "0",
      fontSize: "xs"
    }, retailPriceDisclaimer)));
  }

  var date;

  if (discount !== null && discount !== void 0 && discount.endDate) {
    date = new Date(discount.endDate);
  }

  var timerProps = {
    date: date,
    maxHoursShown: 2,
    timerLabel: "Ends"
  };
  return ___EmotionJSX(Box, {
    display: "block"
  }, ___EmotionJSX(Box, {
    textTransform: "uppercase",
    lineHeight: 0
  }, (discount === null || discount === void 0 ? void 0 : discount.label) && ___EmotionJSX(Span, {
    display: "inline-block",
    fontSize: large ? "0.9rem" : "xs",
    fontWeight: "semibold",
    color: enabled ? "primary" : "typography.inactive"
  }, discount.label, "\xA0"), date && ___EmotionJSX(Span, {
    display: "inline-block",
    color: enabled ? "error" : "typography.inactive",
    fontSize: large ? "0.9rem" : "xs",
    fontWeight: "semibold"
  }, ___EmotionJSX(CountdownTimer, timerProps)), (discount === null || discount === void 0 ? void 0 : discount.percentageLabel) && ___EmotionJSX(Span, {
    display: "inline-block",
    color: enabled ? "error" : "typography.inactive",
    fontSize: large ? "0.9rem" : "xs",
    fontWeight: "semibold"
  }, discount.percentageLabel)));
};