import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex, IconOnePassTags } from "@catchoftheday/cg-components";
import { ShopSectionHeading } from "../ShopSectionHeading";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FeaturedEventSectionHeading = function FeaturedEventSectionHeading(_ref) {
  var title = _ref.title,
      iconProps = _ref.iconProps,
      textProps = _ref.textProps;
  return ___EmotionJSX(Flex, {
    fontSize: {
      xs: "sm",
      lg: "xl"
    },
    alignItems: "center",
    py: 3,
    justifyContent: "center"
  }, ___EmotionJSX(IconOnePassTags, _extends({
    height: "1.25em",
    width: "auto",
    title: "OnePass Tags Icon",
    mr: 1,
    color: "onePass.primary",
    role: "img"
  }, iconProps)), ___EmotionJSX(ShopSectionHeading, _extends({
    fontSize: "inherit"
  }, textProps), title || "Deal of the week"));
};