import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useEffect, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import { SOURCE_TYPE_ENHANCED_SEARCH_BOX, SOURCE_TYPE_ENHANCED_SEARCH_BOX_RECENTLY_VIEWED, SOURCE_TYPE_ENHANCED_SEARCH_BOX_TRENDING_PRODUCTS } from "@catchoftheday/analytics";
import { Flex, IconDownArrow, Link, Text } from "@catchoftheday/cg-components";
import { useRecentlyViewedProducts } from "@catchoftheday/personalisation";
import { MiniProductCard } from "@catchoftheday/product-components";
import { styled } from "@catchoftheday/theme";
import { useTrendingProductsQuery } from "../../../../generated/graphql";
import { getSearchEndpoint, MAX_PRODUCTS_IN_VIEW, MIN_SEARCH_TERM_LENGTH } from "../../consts";
import { mapSearchResultItem } from "../../utilities/mapSearchResultItem";
import { updateRecentSearches } from "../../utilities/updateRecentSearches";
import { ProductCardPlaceholder } from "./ProductsPlaceholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
var IconRightArrow = styled(IconDownArrow)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transform: rotate(270deg);\n"])));
export var SearchProducts = function SearchProducts(_ref) {
  var searchTerm = _ref.searchTerm;

  var _useState = useState({
    title: undefined,
    products: []
  }),
      recommendedProducts = _useState[0],
      setRecommendedProducts = _useState[1];

  var _useState2 = useState(false),
      noSearchResults = _useState2[0],
      setNoSearchResults = _useState2[1];

  var hasValidSearchTerm = !!searchTerm && searchTerm.length > MIN_SEARCH_TERM_LENGTH;
  var hasProductSuggestions = recommendedProducts.products.length > 0; // Display view all results link if there are no search results or no product suggestions found at all

  var displayViewAllResultsLink = hasValidSearchTerm && !noSearchResults || !hasProductSuggestions;

  var _useSWR = useSWR(hasValidSearchTerm ? getSearchEndpoint(searchTerm) : null, axios),
      searchResults = _useSWR.data,
      searchResultsLoading = _useSWR.isLoading;

  var _useRecentlyViewedPro = useRecentlyViewedProducts({
    limit: MAX_PRODUCTS_IN_VIEW
  }),
      recentlyViewedProducts = _useRecentlyViewedPro.products,
      recentlyViewedProductsLoading = _useRecentlyViewedPro.loading;

  var _useTrendingProductsQ = useTrendingProductsQuery({
    ssr: false,
    skip: hasValidSearchTerm && !!(recentlyViewedProducts !== null && recentlyViewedProducts !== void 0 && recentlyViewedProducts.length),
    variables: {
      inStock: true,
      includeAdultCategory: false,
      limit: MAX_PRODUCTS_IN_VIEW
    }
  }),
      trendingProducts = _useTrendingProductsQ.data,
      trendingProductsLoading = _useTrendingProductsQ.loading;

  useEffect(function () {
    setNoSearchResults(hasValidSearchTerm && (searchResults === null || searchResults === void 0 ? void 0 : searchResults.data.payload.results.length) === 0);

    if (hasValidSearchTerm && searchResults !== null && searchResults !== void 0 && searchResults.data.payload.results.length) {
      setRecommendedProducts({
        title: "Product Suggestions",
        products: searchResults.data.payload.results.map(function (result) {
          return mapSearchResultItem(_objectSpread(_objectSpread({}, result.product), {}, {
            trackEventToken: result.trackEventToken
          }));
        })
      });
    } else if (recentlyViewedProducts !== null && recentlyViewedProducts !== void 0 && recentlyViewedProducts.length) {
      setRecommendedProducts({
        title: "Recently Viewed",
        products: recentlyViewedProducts.map(function (product) {
          return _objectSpread(_objectSpread({}, product), {}, {
            url: "/product/".concat(product.slug, "?st=").concat(SOURCE_TYPE_ENHANCED_SEARCH_BOX_RECENTLY_VIEWED)
          });
        })
      });
    } else if (trendingProducts !== null && trendingProducts !== void 0 && trendingProducts.trendingProducts.products.length) {
      setRecommendedProducts({
        title: "Trending Products",
        products: trendingProducts.trendingProducts.products.map(function (product) {
          return _objectSpread(_objectSpread({}, product), {}, {
            url: "/product/".concat(product.slug, "?st=").concat(SOURCE_TYPE_ENHANCED_SEARCH_BOX_TRENDING_PRODUCTS)
          });
        })
      });
    }
  }, [searchResults, hasValidSearchTerm, recentlyViewedProducts, trendingProducts]);

  if (recentlyViewedProductsLoading || trendingProductsLoading || hasValidSearchTerm && searchResultsLoading && !searchResults) {
    return ___EmotionJSX(ProductCardPlaceholder, null);
  }

  return ___EmotionJSX(Flex, {
    flexDirection: "column",
    position: "relative",
    flex: 1,
    "data-cnstrc-item-section": "Products"
  }, ___EmotionJSX(Text, {
    fontSize: "base",
    fontWeight: "medium",
    m: "0px",
    mb: "10px"
  }, !hasProductSuggestions ? "No product suggestions found." : noSearchResults ? "No results found for ".concat(searchTerm, ", checkout these other products") : recommendedProducts.title), ___EmotionJSX(Flex, {
    flexWrap: "wrap",
    pb: "40px"
  }, recommendedProducts.products.slice(0, MAX_PRODUCTS_IN_VIEW).map(function (product, index) {
    return ___EmotionJSX(MiniProductCard, {
      key: product.id,
      product: product,
      width: {
        xs: "100%",
        md: "50%"
      },
      mb: {
        xs: "20px",
        md: index > 1 ? "0" : "30px"
      },
      display: {
        xs: index > 1 ? "none" : "flex",
        md: "flex"
      },
      onClick: function onClick() {
        searchTerm && updateRecentSearches(searchTerm);
      },
      "data-cnstrc-item-id": product.id,
      "data-cnstrc-item-name": product.title
    });
  })), displayViewAllResultsLink && ___EmotionJSX(Link, {
    position: "absolute",
    right: "0px",
    bottom: "10px",
    href: "/search?query=".concat(searchTerm, "&st=").concat(SOURCE_TYPE_ENHANCED_SEARCH_BOX),
    fontSize: "sm",
    fontWeight: "100",
    textDecoration: "underline",
    onClick: function onClick() {
      searchTerm && updateRecentSearches(searchTerm);
    }
  }, ___EmotionJSX(Flex, {
    cursor: "pointer",
    alignItems: "center"
  }, "View all results", ___EmotionJSX(IconRightArrow, {
    height: 5
  }))));
};