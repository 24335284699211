import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Button, IconSearch } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var StyledSearchButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:active {\n    box-shadow: ", ";\n  }\n"])), themeGet("shadows.button"));
export var SearchButton = function SearchButton(props) {
  return ___EmotionJSX(StyledSearchButton, _extends({
    "aria-label": "Search",
    sizeVariant: "sm",
    variant: "tertiary",
    outline: true,
    bg: "white",
    border: "1px solid",
    borderColor: "ui.borderColor",
    borderLeft: "none",
    height: "47px",
    py: {
      sm: 0,
      md: 3
    },
    zIndex: 1
  }, props), ___EmotionJSX(IconSearch, {
    size: "25px"
  }));
};