import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SearchDropdown = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transform: scale(0.97);\n  opacity: 0;\n  display: none;\n  transform-origin: top left;\n  &.open {\n    display: flex;\n    animation: growDown 175ms cubic-bezier(0, 0, 0.4, 1) forwards;\n  }\n  @keyframes growDown {\n    0% {\n      transform: scale(0.97);\n      opacity: 0;\n    }\n    100% {\n      transform: scale(1);\n      opacity: 1;\n    }\n  }\n"])));
export var SearchDropdownZone = function SearchDropdownZone(props) {
  return ___EmotionJSX(SearchDropdown, _extends({
    bg: "white",
    position: "absolute",
    top: 47,
    right: {
      xs: "-57px",
      md: "-280px",
      lg: "-485px",
      xl: "-736px"
    },
    left: {
      xs: "-70px",
      md: "0px"
    },
    overflow: "auto",
    borderRadius: {
      md: "0px 5px 5px 5px"
    },
    boxShadow: "4px 8px 11px 0px rgb(0 0 0 / 25%)",
    maxHeight: "calc(100vh - 90px)",
    minHeight: {
      md: "440px"
    },
    role: "combobox",
    "aria-haspopup": "listbox",
    "aria-expanded": props.isOpen,
    className: props.isOpen ? "open" : undefined
  }, props), ___EmotionJSX(Flex, {
    flexDirection: {
      xs: "column",
      md: "row"
    },
    width: "100%",
    "data-cnstrc-autosuggest": true
  }, props.children));
};