import React from "react";
import { Flex, IconOnePass, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassFreeShippingTag = function OnePassFreeShippingTag() {
  return ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "onePass",
    my: 0,
    mx: {
      md: "5px"
    }
  }, ___EmotionJSX(IconOnePass, {
    color: "onePass.primary",
    width: "47px",
    height: "10px",
    pointerEvents: "none",
    role: "img",
    title: "OnePass",
    "aria-label": "OnePass"
  }), ___EmotionJSX(Span, {
    fontSize: "2xs",
    fontWeight: "bold",
    color: "onePass.textPrimary",
    whiteSpace: "nowrap"
  }, "Free delivery"));
};