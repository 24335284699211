import React from "react";
import { LinkButton } from "@catchoftheday/cg-components";
import { URL_ONEPASS_PUBLIC_MIGRATION } from "../../../../consts";
import { generateOnePassConsentUrl } from "../../../../utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TransferCTAButton = function TransferCTAButton() {
  return ___EmotionJSX(LinkButton, {
    href: generateOnePassConsentUrl(URL_ONEPASS_PUBLIC_MIGRATION),
    rounded: true,
    variant: "onePassPrimary",
    sizeVariant: "lg",
    fontWeight: "semibold",
    mb: 6
  }, "Transfer Now");
};