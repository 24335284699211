import { IconCart, IconCartOutline, IconHamburger, IconHeart, IconHeartOutline, IconHome, IconHomeOutline, IconUser, IconUserOutline } from "@catchoftheday/cg-components";
export var BOTTOM_NAV = [{
  title: "Home",
  url: "/",
  icon: IconHomeOutline,
  activeIcon: IconHome
}, {
  title: "Wishlist",
  url: "/my-account/wishlist",
  icon: IconHeartOutline,
  activeIcon: IconHeart
}, {
  title: "Categories",
  icon: IconHamburger,
  activeIcon: IconHamburger
}, {
  title: "Cart",
  url: "/cart",
  icon: IconCartOutline,
  activeIcon: IconCart
}, {
  title: "Account",
  icon: IconUserOutline,
  activeIcon: IconUser
}];