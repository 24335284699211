import React from "react";
import { Box, Button, LinkButton } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CTA = function CTA(_ref) {
  var btnText = _ref.btnText,
      eventUrl = _ref.eventUrl,
      isSingleProductEvent = _ref.isSingleProductEvent,
      isActiveMember = _ref.isActiveMember,
      isSoldOut = _ref.isSoldOut,
      onPrimaryClick = _ref.onPrimaryClick;
  return ___EmotionJSX(Box, {
    mt: {
      xs: 4,
      md: 5,
      lg: 7
    },
    textAlign: "center"
  }, !isSingleProductEvent ? ___EmotionJSX(LinkButton, {
    width: "auto",
    href: eventUrl,
    variant: "primary"
  }, btnText || "Shop Now") : ___EmotionJSX(Box, null, ___EmotionJSX(LinkButton, {
    width: "auto",
    href: eventUrl,
    variant: "secondary",
    outline: true,
    mr: 1
  }, btnText || "View Deal"), isSoldOut ? ___EmotionJSX(Button, {
    variant: "error",
    width: "auto",
    disabled: true
  }, "Sold Out") : ___EmotionJSX(Button, {
    variant: isActiveMember ? "primary" : "onePassPrimary",
    width: "auto",
    onClick: onPrimaryClick
  }, isActiveMember ? "Add to Cart" : "Join to Buy")));
};