import { graphql } from "msw";
import { buildMockCustomer } from "@common-vendors/mocks/data";
export var inactiveOnePassHandler = graphql.query("Customer", function (_req, res, ctx) {
  return res(ctx.data({
    customer: buildMockCustomer({
      onePass: {
        club: {
          active: false
        },
        membership: null
      }
    })
  }));
});
export var lapsedOnePassHandler = graphql.query("Customer", function (_req, res, ctx) {
  return res(ctx.data({
    customer: buildMockCustomer({
      onePass: {
        club: {
          active: false
        },
        membership: {
          subscription: {
            active: false
          }
        }
      }
    })
  }));
});