import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["inventoryId", "searchQuery", "isMobile", "sourceId", "sourceType", "offerId", "category", "pageId"];
import React, { useContext, useEffect, useState } from "react";
import { emitTrackingPixel, SNOWPLOW_SESSION_ID } from "@catchoftheday/analytics";
import { Flex, Image, Link } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { SponsoredLabel } from "@catchoftheday/product-components";
import { useTheme } from "@catchoftheday/theme";
import { useIsInViewport } from "@catchoftheday/utilities";
import { useRetailMediaBannerQuery } from "../../generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RetailMediaBanner = function RetailMediaBanner(_ref) {
  var inventoryId = _ref.inventoryId,
      searchQuery = _ref.searchQuery,
      isMobile = _ref.isMobile,
      sourceId = _ref.sourceId,
      sourceType = _ref.sourceType,
      offerId = _ref.offerId,
      category = _ref.category,
      pageId = _ref.pageId,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data,
      customerLoading = _useContext.loading;

  var _useState = useState(),
      snowplowSessionId = _useState[0],
      setSnowplowSessionId = _useState[1];

  var _useTheme = useTheme(),
      maxWidth = _useTheme.maxWidths.cotdWebsite;

  useEffect(function () {
    var spId = sessionStorage.getItem(SNOWPLOW_SESSION_ID);

    if (spId) {
      setSnowplowSessionId(spId);
      return;
    } // Wait for snowplow session id to be set in session storage


    var checkSessionId = function checkSessionId(event) {
      if (event.key === SNOWPLOW_SESSION_ID && event.newValue) {
        setSnowplowSessionId(event.newValue);
      }
    };

    window.addEventListener("storage", checkSessionId);
    return function () {
      return window.removeEventListener("storage", checkSessionId);
    };
  }, []);

  var _useRetailMediaBanner = useRetailMediaBannerQuery({
    variables: {
      inventoryId: inventoryId,
      searchQuery: searchQuery,
      platform: isMobile ? "MOBILE" : "DESKTOP",
      snowplowSessionId: snowplowSessionId,
      customerUUID: customerData === null || customerData === void 0 ? void 0 : customerData.metaData.customerUuid,
      offerId: offerId,
      category: category,
      pageId: pageId
    },
    skip: customerLoading || !snowplowSessionId
  }),
      data = _useRetailMediaBanner.data;

  var _useIsInViewport = useIsInViewport({
    // Trigger impression events if 75% of the component is in the viewport on mobile
    threshold: isMobile ? 0.75 : 1,
    onComponentSeen: function onComponentSeen() {
      emitTrackingPixel(tracking.impressionTrackingUrl);
    }
  }),
      ref = _useIsInViewport.ref;

  var rmBanner = data === null || data === void 0 ? void 0 : data.retailMediaBanner;

  if (!rmBanner) {
    return null;
  }

  var banner = rmBanner.banner,
      tracking = rmBanner.tracking;

  if (!banner.path) {
    return null;
  }

  var bannerUrl = "".concat(banner.path, "?st=").concat(sourceType, "&sid=").concat(sourceId);
  return ___EmotionJSX(Flex, _extends({
    maxWidth: maxWidth,
    overflow: "hidden",
    px: 2,
    onClick: function onClick() {
      emitTrackingPixel(tracking.clickTrackingUrl);
    },
    justifyContent: "center",
    ref: ref
  }, props), ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "flex-end"
  }, ___EmotionJSX(Link, {
    href: bannerUrl
  }, ___EmotionJSX(Image, {
    src: banner.image.url,
    alt: banner.image.altText,
    maxWidth: maxWidth,
    margin: "0 auto"
  })), ___EmotionJSX(SponsoredLabel, {
    fontSize: "xs"
  })));
};