import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconFlybuysSquare = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M10.538 5.558c-1.8 0-2.574 1.074-2.58 2.4v4.8h1.29v-3.6h1.626v-1.2H9.242a1.2 1.2 0 011.296-1.2h1.02v-1.2h-1.02z",
    fill: "currentColor"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M15.316 3v12.316H3V3h12.316zm-.948.947H3.948v10.421h10.42V3.948z",
    fill: "currentColor"
  })],
  viewBox: "0 0 18 18"
});