export var scrollTo = function scrollTo(_ref) {
  var scrollerElement = _ref.scrollerElement,
      scrollLeftPosition = _ref.scrollLeftPosition,
      _ref$smoothScroll = _ref.smoothScroll,
      smoothScroll = _ref$smoothScroll === void 0 ? true : _ref$smoothScroll;

  if (scrollerElement) {
    if (smoothScroll) {
      scrollerElement.scroll({
        top: 0,
        left: scrollLeftPosition,
        behavior: "smooth"
      });
    } else {
      /* eslint-disable no-param-reassign */
      scrollerElement.scrollLeft = scrollLeftPosition;
    }
  }
};