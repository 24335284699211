import React from "react";
import { Box, Text } from "@catchoftheday/cg-components";
import { StatusDetailsColumnZone } from "../../layouts/SubscriptionDetailsLayout/SubscriptionDetailsLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var StatusSection = function StatusSection(_ref) {
  var title = _ref.title,
      children = _ref.children;
  return ___EmotionJSX(StatusDetailsColumnZone, null, ___EmotionJSX(Box, null, ___EmotionJSX(Text, {
    fontSize: "base",
    m: 0,
    mb: 4,
    fontWeight: "bold",
    color: "onePass.textPrimary"
  }, title)), children);
};