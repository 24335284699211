import React from "react";
import { Label } from "@catchoftheday/cg-components";
import { SearchBarWrapper, SearchBoxZone } from "../../../layouts/SearchBar/SearchBarLayouts";
import { SearchButton } from "../../../pure/SearchBar/SearchButton/SearchButton";
import { SearchInput } from "../../../pure/SearchBar/SearchInput/SearchInput";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchBarPlaceholder = function SearchBarPlaceholder() {
  return ___EmotionJSX(SearchBarWrapper, {
    role: "status"
  }, ___EmotionJSX(SearchBoxZone, {
    isOpen: false
  }, ___EmotionJSX(Label, null, ___EmotionJSX(SearchInput, {
    isOpen: false,
    disabled: true
  })), ___EmotionJSX(SearchButton, {
    isOpen: false,
    mr: 0
  })));
};