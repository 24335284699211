import React from "react";
import { Snowplow } from "@catchoftheday/analytics";
import { useGetSnowplowConfigurationQuery } from "@header/generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LegacySnowplowConfig = function LegacySnowplowConfig(_ref) {
  var domain = _ref.domain;

  var _useGetSnowplowConfig = useGetSnowplowConfigurationQuery({
    variables: {
      domain: domain
    },
    ssr: false
  }),
      data = _useGetSnowplowConfig.data;

  var snowplowConfig = data === null || data === void 0 ? void 0 : data.cmsConfiguration.analytics.snowplow;
  if (!snowplowConfig) return null;
  return ___EmotionJSX(Snowplow, {
    config: snowplowConfig,
    disableAutomaticTracking: true
  });
};