import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useCallback } from "react";
import { emitSalesRuleTagClick, emitSalesRuleTagImpression } from "@catchoftheday/analytics";
import { SalesRulePromotionCopy } from "@catchoftheday/cart-components";
import { Flex, IconShopbagPlus, IconTickCircle } from "@catchoftheday/cg-components";
import { useIsInViewport } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SalesRulePromotionProgress = function SalesRulePromotionProgress(_ref) {
  var salesRuleId = _ref.salesRuleId,
      description = _ref.description,
      linkText = _ref.linkText,
      eventSlug = _ref.eventSlug,
      isApplied = _ref.isApplied,
      containerProps = _ref.containerProps;
  var trackingOptions = {
    sales_rule_id: salesRuleId,
    source_id: "progression_nudge",
    label: description || "",
    destination_url: eventSlug ? "/event/".concat(eventSlug) : null
  };
  var trackImpressions = useCallback(function () {
    emitSalesRuleTagImpression(trackingOptions);
  }, []);

  var _useIsInViewport = useIsInViewport({
    onComponentSeen: trackImpressions
  }),
      ref = _useIsInViewport.ref;

  var onLinkClick = function onLinkClick() {
    emitSalesRuleTagClick(trackingOptions);
  };

  return ___EmotionJSX(Flex, _extends({
    ref: ref,
    color: isApplied ? "primaryDark" : "brand.secondaryDark",
    p: 2,
    borderRadius: 4,
    backgroundColor: isApplied ? "primaryLight" : "secondaryLight",
    alignItems: "center"
  }, containerProps), isApplied ? ___EmotionJSX(IconTickCircle, {
    "aria-label": "progress completed icon",
    color: "inherit",
    mr: 2,
    height: 16,
    width: 16,
    style: {
      flex: "none"
    }
  }) : ___EmotionJSX(IconShopbagPlus, {
    "aria-label": "progress ongoing icon",
    color: "inherit",
    mr: 2,
    height: 24,
    width: 24,
    style: {
      flex: "none"
    }
  }), ___EmotionJSX(SalesRulePromotionCopy, {
    description: description,
    linkText: linkText,
    eventSlug: eventSlug,
    containerProps: {
      fontSize: "base",
      color: "inherit"
    },
    onLinkClick: onLinkClick
  }));
};
export default SalesRulePromotionProgress;