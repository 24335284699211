import React from "react";
import { Flex, Span } from "@catchoftheday/cg-components";
import { ONEPASS_DISPLAY_NAME } from "../../../../consts";
import { OnePassIcon } from "../../../layouts/PageLayout/PageLayout";
import { StarRatings } from "../StarRatings/StarRatings";
import { jsx as ___EmotionJSX } from "@emotion/react";

function getDisplayLabel(displayName, isStarRating) {
  if (isStarRating) {
    var rating = parseInt(displayName, 10);

    if (!isNaN(rating)) {
      return ___EmotionJSX(StarRatings, {
        rating: rating
      });
    }
  }

  if (displayName.toLowerCase().startsWith(ONEPASS_DISPLAY_NAME)) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(OnePassIcon, null), ___EmotionJSX(Span, null, displayName.replace(new RegExp(ONEPASS_DISPLAY_NAME, "i"), "").trim()));
  }

  return ___EmotionJSX(Span, null, displayName);
}

export var ListFacetOption = function ListFacetOption(_ref) {
  var id = _ref.id,
      value = _ref.value,
      displayName = _ref.displayName,
      count = _ref.count,
      selected = _ref.selected,
      onChange = _ref.onChange,
      isStarRating = _ref.isStarRating,
      InputComponent = _ref.InputComponent;
  return ___EmotionJSX(Flex, {
    alignItems: "center"
  }, ___EmotionJSX(Flex, {
    as: "label",
    alignItems: "center"
  }, ___EmotionJSX(InputComponent, {
    id: id,
    name: value,
    value: value,
    inputContainerProps: {
      mr: 2
    },
    onChange: onChange,
    checked: selected
  }), getDisplayLabel(displayName, isStarRating), ___EmotionJSX(Span, {
    color: "typography.inactive",
    display: "inline-block",
    ml: 2
  }, "(", count, ")")));
};