import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isShopToggleExperiment", "eventId", "product", "isSingleProductEvent"];
import React, { useEffect, useMemo } from "react";
import { useFeaturedEventProductsLazyQuery } from "../../../generated/graphql";
import { HeroEventBannerV2 } from "../../pure/HeroEventBannerV2";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EventBannerWithGallery = function EventBannerWithGallery(_ref) {
  var isShopToggleExperiment = _ref.isShopToggleExperiment,
      eventId = _ref.eventId,
      product = _ref.product,
      isSingleProductEvent = _ref.isSingleProductEvent,
      props = _objectWithoutProperties(_ref, _excluded);

  var _ref2 = product || {},
      assets = _ref2.assets;

  var _ref3 = assets || {},
      gallery = _ref3.gallery;

  var _useFeaturedEventProd = useFeaturedEventProductsLazyQuery({
    ssr: false,
    variables: {
      eventId: eventId
    }
  }),
      _useFeaturedEventProd2 = _slicedToArray(_useFeaturedEventProd, 2),
      getProducts = _useFeaturedEventProd2[0],
      _useFeaturedEventProd3 = _useFeaturedEventProd2[1],
      eventProductsData = _useFeaturedEventProd3.data,
      loading = _useFeaturedEventProd3.loading;

  var _ref4 = eventProductsData || {},
      featuredProductsByEventId = _ref4.featuredProductsByEventId;

  var _ref5 = featuredProductsByEventId || {},
      multiProducts = _ref5.products;

  useEffect(function () {
    if (!isSingleProductEvent) {
      getProducts();
    }
  }, [getProducts, isSingleProductEvent]);
  var gallerySlides = useMemo(function () {
    if (isSingleProductEvent && gallery !== null && gallery !== void 0 && gallery.length) {
      return gallery === null || gallery === void 0 ? void 0 : gallery.filter(function (item) {
        return item.__typename !== "Video";
      });
    } else if (multiProducts !== null && multiProducts !== void 0 && multiProducts.length) {
      return multiProducts === null || multiProducts === void 0 ? void 0 : multiProducts.map(function (product) {
        var _product$assets;

        return (_product$assets = product.assets) === null || _product$assets === void 0 ? void 0 : _product$assets.hero;
      });
    }

    return [];
  }, [gallery, isSingleProductEvent, multiProducts]);
  return ___EmotionJSX(HeroEventBannerV2, _extends({
    isShopToggleExperiment: isShopToggleExperiment,
    gallerySlides: gallerySlides,
    isSingleProductEvent: isSingleProductEvent,
    isGalleryLoading: loading
  }, props));
};