import React, { useState } from "react";
import { Box, Flex, IconOnePassFlybuys, NotificationBadge } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { HeaderTile } from "@header/components/HeaderTile";
import { SpanWithAfterDownArrow } from "@header/components/SpanWithAfterDownArrow";
import { sessionStorage } from "@header/utilities/index";
import flybuysTheme from "../../flybuysTheme";
import { HeaderTileContainer } from "../HeaderTileContainer";
import { PopupContent } from "./PopupContent";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SESSION_STORAGE_KEY = "CCFlyBuysTileHover";
export var OnePassFlybuysTile = function OnePassFlybuysTile() {
  var theme = useTheme();
  var CCFlyBuysTileHoverSessionStorage = (sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem(SESSION_STORAGE_KEY)) != "true";

  var _useState = useState(true),
      ccFlybuysNew = _useState[0],
      setCcFlybuysNew = _useState[1];

  var renderIcon = function renderIcon(_ref) {
    var _theme$colors, _theme$colors$onePass, _theme$colors2, _theme$colors2$brand;

    var isOpen = _ref.isOpen;
    var iconStyleOnHover = {
      svg: {
        ".onepass": {
          fill: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : (_theme$colors$onePass = _theme$colors["onePass"]) === null || _theme$colors$onePass === void 0 ? void 0 : _theme$colors$onePass["primary"]
        },
        ".x-mark": {
          fill: theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : (_theme$colors2$brand = _theme$colors2.brand) === null || _theme$colors2$brand === void 0 ? void 0 : _theme$colors2$brand.primary
        }
      }
    };
    var iconStyle = {
      "&:hover": iconStyleOnHover,
      svg: isOpen ? iconStyleOnHover.svg : undefined,
      ".flybuys": isOpen ? {
        fill: flybuysTheme.bgColor
      } : undefined
    };
    return ___EmotionJSX(HeaderTileContainer, {
      "data-testid": "cc-flybuys-icon",
      hasRightBorder: true,
      flexProps: {
        px: "0.875rem"
      },
      linkProps: {
        css: iconStyle,
        backgroundColor: isOpen ? "white" : undefined,
        color: isOpen ? "secondaryDark" : "white",
        "aria-label": "OnePass and flybuys",
        href: "/onepass",
        onMouseEnter: function onMouseEnter() {
          setCcFlybuysNew(false);
        },
        className: "js-dnt",
        "data-tracking": "header-app-onepass"
      }
    }, ___EmotionJSX(Flex, {
      flexDirection: "column",
      alignItems: "center"
    }, ___EmotionJSX(Box, {
      position: "relative"
    }, CCFlyBuysTileHoverSessionStorage && ccFlybuysNew && ___EmotionJSX(NotificationBadge, {
      text: "1",
      "data-testid": "onepass-flybuys-new",
      absRight: "-18px"
    }), ___EmotionJSX(IconOnePassFlybuys, {
      width: "66px",
      height: "auto",
      title: "OnePass and Flybuys"
    })), ___EmotionJSX(SpanWithAfterDownArrow, null, "$2 = 2 points*")));
  };

  return ___EmotionJSX(HeaderTile, {
    popoverProps: {
      placement: "bottom"
    },
    icon: renderIcon,
    onTileShow: function onTileShow(isOpen) {
      if (isOpen) {
        sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem(SESSION_STORAGE_KEY, "true");
        setCcFlybuysNew(false);
      }
    }
  }, ___EmotionJSX(PopupContent, null));
};