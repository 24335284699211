import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React, { useContext } from "react";
import { Box, Carousel, Container, Flex, IconClock } from "@catchoftheday/cg-components";
import { DEFAULT_APP_DOWNLOAD_LINK, PageConfigurationContext } from "@catchoftheday/common-vendors";
import { useMedia, useTheme } from "@catchoftheday/theme";
import { useOnePassShopUpcomingDealsQuery } from "../../../generated/graphql";
import { ShopSectionHeading } from "../../pure/ShopSectionHeading";
import { AppNudge, AppNudgeCard, DealCard } from "../../pure/UpcomingDeals";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopUpcomingDealsContainer = function ShopUpcomingDealsContainer() {
  var _ref3, _pageConfig$footerItu, _ref4, _pageConfig$footerGoo;

  var theme = useTheme();
  var isMobileScreen = useMedia(["(min-width: ".concat(theme.breakpoints.md, ")"), "(min-width: ".concat(theme.breakpoints.xs, ")")], [false, true]);

  var _useContext = useContext(PageConfigurationContext),
      pageConfig = _useContext.data;

  var _useOnePassShopUpcomi = useOnePassShopUpcomingDealsQuery(),
      upcomingDealsData = _useOnePassShopUpcomi.data;

  var _ref = upcomingDealsData || {},
      onePassUpcomingEntries = _ref.onePassUpcomingEntries;

  var _ref2 = onePassUpcomingEntries || {},
      entries = _ref2.entries;

  if (!entries || entries.length === 0) {
    return null;
  }

  var entriesToShow = entries.slice(0, 2);
  var noPrePromoteBanner = entriesToShow.some(function (deal) {
    var _deal$event, _deal$event$image;

    return !((_deal$event = deal.event) !== null && _deal$event !== void 0 && (_deal$event$image = _deal$event.image) !== null && _deal$event$image !== void 0 && _deal$event$image.onePassPrePromote);
  });

  if (noPrePromoteBanner) {
    return null;
  }

  var iosAppLink = (_ref3 = (_pageConfig$footerItu = pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.footerItunesAppUrl) !== null && _pageConfig$footerItu !== void 0 ? _pageConfig$footerItu : pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.itunesAppUrl) !== null && _ref3 !== void 0 ? _ref3 : DEFAULT_APP_DOWNLOAD_LINK;
  var androidAppLink = (_ref4 = (_pageConfig$footerGoo = pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.footerGooglePlayUrl) !== null && _pageConfig$footerGoo !== void 0 ? _pageConfig$footerGoo : pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.googlePlayUrl) !== null && _ref4 !== void 0 ? _ref4 : DEFAULT_APP_DOWNLOAD_LINK;
  var fullSlidesCount = entriesToShow.length + 1; // +1 for the app nudge

  var slidesPerBreakpoint = [fullSlidesCount, fullSlidesCount, fullSlidesCount, 1, 1];
  return ___EmotionJSX(Box, {
    py: {
      xs: 6,
      md: 8
    },
    px: 3,
    backgroundColor: "ui.backgroundColor"
  }, ___EmotionJSX(Flex, {
    alignItems: "center",
    justifyContent: "center",
    fontSize: {
      xs: "base",
      md: "xl"
    },
    mb: {
      xs: 3,
      md: 6
    },
    gap: 1
  }, ___EmotionJSX(IconClock, {
    height: "1em",
    color: "onePass.primaryDark"
  }), ___EmotionJSX(ShopSectionHeading, {
    fontSize: "inherit"
  }, "Upcoming deals of the week")), ___EmotionJSX(Container, {
    display: "block",
    maxWidth: theme.breakpoints.lg
  }, ___EmotionJSX(Carousel, {
    showNavButtons: false,
    showBullets: isMobileScreen,
    slides: [].concat(_toConsumableArray(!isMobileScreen ? [___EmotionJSX(AppNudge, {
      key: "club-upcoming-deal-desktop-app-nudge"
    })] : []), _toConsumableArray(entriesToShow.map(function (entry, index) {
      var _entry$event, _entry$event2;

      return entry.event && ___EmotionJSX(DealCard, {
        "data-testid": "club-upcoming-deal-".concat((_entry$event = entry.event) === null || _entry$event === void 0 ? void 0 : _entry$event.id),
        key: "club-upcoming-deal-".concat((_entry$event2 = entry.event) === null || _entry$event2 === void 0 ? void 0 : _entry$event2.id),
        event: entry.event,
        startDate: entry.startDate,
        promote: entriesToShow.length > 1 && index === 0 && !isMobileScreen
      });
    })), _toConsumableArray(isMobileScreen ? [___EmotionJSX(AppNudgeCard, {
      key: "club-upcoming-deal-mobile-app-nudge",
      iosAppLink: iosAppLink,
      androidAppLink: androidAppLink
    })] : [])),
    slidesPerBreakpoint: slidesPerBreakpoint,
    step: 1,
    buttonOffset: [11, -10]
  })));
};