export var searchRedirectUrl = function searchRedirectUrl(query, redirects) {
  var redirect = redirects === null || redirects === void 0 ? void 0 : redirects.find(function (redirect) {
    var redirectTerm = redirect.searchTerm.toLowerCase();
    var queryTerm = query.toLowerCase().replace(/\s+/g, "");
    return queryTerm === redirectTerm;
  });

  if (redirect) {
    return redirect.targetPath;
  }

  return null;
};