import React, { useContext } from "react";
import { Lightbox } from "@catchoftheday/cg-components";
import { SizeChartsContext } from "../../contexts/SizeChartsContext/SizeChartsContext";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SizeChartsLightbox = function SizeChartsLightbox() {
  var _useContext = useContext(SizeChartsContext),
      sizeCharts = _useContext.sizeCharts,
      sizeChartsOpen = _useContext.sizeChartsOpen,
      setSizeChartOpen = _useContext.setSizeChartOpen;

  if (!sizeCharts.length) {
    return null;
  }

  return ___EmotionJSX(Lightbox, {
    open: sizeChartsOpen,
    onClose: function onClose() {
      return setSizeChartOpen(false);
    },
    sources: sizeCharts,
    customAttributes: sizeCharts.map(function () {
      return {
        alt: "Size Chart"
      };
    })
  });
};