import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _categoryIconMap;

import { IconAdultsOnly, IconBasketball, IconBook, IconCap, IconCatchLetterC, IconChair, IconDress, IconHomeKitchen, IconLipstick, IconMixer, IconMonitor, IconPet, IconShirt, IconToy } from "@catchoftheday/cg-components";
export var MappedMenuItems;
/** Desktop Menu */

(function (MappedMenuItems) {
  MappedMenuItems["ADULTS_ONLY"] = "adults-only";
  MappedMenuItems["ONEPASS"] = "onepass";
  MappedMenuItems["TODAYS_DEALS"] = "today-s-deals";
  MappedMenuItems["CLEARANCE"] = "clearance";
  MappedMenuItems["CHEAP_GROCERIES"] = "cheap-groceries";
  MappedMenuItems["GROCERY_LIQUOR"] = "grocery-liquor";
  MappedMenuItems["WOMENS_FASHION"] = "womens-fashion";
  MappedMenuItems["MENS_FASHION"] = "mens-fashion";
  MappedMenuItems["BEAUTY"] = "beauty";
  MappedMenuItems["HOME_KITCHEN"] = "home-kitchen";
  MappedMenuItems["SPORTS_OUTDOOR"] = "sports-outdoor";
  MappedMenuItems["ELECTRONICS"] = "electronics";
  MappedMenuItems["KIDS_CLOTHING_TOYS"] = "kids-clothing-toys";
  MappedMenuItems["PETS"] = "pets";
  MappedMenuItems["TOYS_GAMES"] = "toys-games";
  MappedMenuItems["FURNITURE"] = "furniture";
  MappedMenuItems["APPLIANCES"] = "appliances";
  MappedMenuItems["ENTERTAINMENT"] = "entertainment";
  MappedMenuItems["SHOP_BY_BRAND_ID"] = "shop-by-brand";
})(MappedMenuItems || (MappedMenuItems = {}));

export var SCROLL_INCREMENT = 3;
export var SCROLL_BUFFER = 30;
export var CATEGORY_MENU_DESKTOP_WIDTH = 200;
export var POPOVER_ZINDEX = 101;
/** Categories */

export var categoryIconMap = (_categoryIconMap = {}, _defineProperty(_categoryIconMap, MappedMenuItems.CHEAP_GROCERIES, IconCatchLetterC), _defineProperty(_categoryIconMap, MappedMenuItems.GROCERY_LIQUOR, IconCatchLetterC), _defineProperty(_categoryIconMap, MappedMenuItems.WOMENS_FASHION, IconDress), _defineProperty(_categoryIconMap, MappedMenuItems.MENS_FASHION, IconShirt), _defineProperty(_categoryIconMap, MappedMenuItems.BEAUTY, IconLipstick), _defineProperty(_categoryIconMap, MappedMenuItems.HOME_KITCHEN, IconHomeKitchen), _defineProperty(_categoryIconMap, MappedMenuItems.SPORTS_OUTDOOR, IconBasketball), _defineProperty(_categoryIconMap, MappedMenuItems.ELECTRONICS, IconMonitor), _defineProperty(_categoryIconMap, MappedMenuItems.KIDS_CLOTHING_TOYS, IconCap), _defineProperty(_categoryIconMap, MappedMenuItems.PETS, IconPet), _defineProperty(_categoryIconMap, MappedMenuItems.TOYS_GAMES, IconToy), _defineProperty(_categoryIconMap, MappedMenuItems.FURNITURE, IconChair), _defineProperty(_categoryIconMap, MappedMenuItems.APPLIANCES, IconMixer), _defineProperty(_categoryIconMap, MappedMenuItems.ENTERTAINMENT, IconBook), _defineProperty(_categoryIconMap, MappedMenuItems.ADULTS_ONLY, IconAdultsOnly), _categoryIconMap); // Filter out shops that are not top level Category items

export var categoryMenuItemFilter = function categoryMenuItemFilter(shop) {
  return !shop.pinToTop && (shop.shopNavigationGroups.length > 0 || shop.slug === MappedMenuItems.ADULTS_ONLY);
};
export * from "./accountMenuData";
export * from "./bottomNavData";