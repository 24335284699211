import { ITEM_ID_THAT_WILL_THROW_ERROR } from "../../constants/literals";
import { mockOnePassSubscription } from "./mockOnePassSubscription";
export var mockCartParcelItems = [{
  __typename: "CartParcelItem",
  id: "1a8abeec-b5e6-47c7-a8f7-abbc233b4e27",
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [],
  freeShipping: false,
  offer: {
    clubFreeShipping: true,
    freeShipping: false,
    id: "105057",
    originPrice: {
      amount: 699
    },
    sellPrice: {
      amount: 699
    },
    purchasableQuantity: 10,
    variant: {
      inWishlist: false,
      product: {
        id: "241469",
        assets: {
          hero: {
            id: "967353",
            url: "https://static.devcat.ch/images/product/0000/0967/551a3125ef298396884344.jpg",
            altText: "3 x Palmolive Naturals Replenishing Soap Bars Milk & Honey 90g 4pk"
          }
        }
      },
      variantOptionValues: [{
        id: "465468",
        name: "Large",
        option: {
          name: "Size"
        }
      }]
    }
  },
  uri: "/product/3-x-palmolive-naturals-replenishing-soap-bars-milk-honey-90g-4pk-241469/?offer_id=105057",
  quantity: 2,
  totalPrice: {
    amount: 1384
  },
  addedByCoupon: false,
  title: "3 x Palmolive Naturals Replenishing Soap Bars Milk & Honey 90g 4pk",
  notices: [{
    __typename: "CartNotice",
    type: "WARNING",
    message: {
      template: null,
      placeholders: null,
      "default": "This item is not available in the quantity selected. Quantity has been adjusted from 5 to 2"
    },
    advisory: true
  }]
}, {
  __typename: "CartParcelItem",
  id: "4cf5c2a1-e7f3-4453-8e1e-a7540f8ab474",
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [],
  freeShipping: false,
  offer: {
    clubFreeShipping: true,
    freeShipping: false,
    id: "128720",
    originPrice: {
      amount: 6999
    },
    sellPrice: {
      amount: 6999
    },
    purchasableQuantity: 5,
    variant: {
      inWishlist: true,
      variantOptionValues: [],
      product: {
        id: "280972",
        assets: {
          hero: {
            id: "1192777",
            url: "https://static.devcat.ch/images/product/0001/1192/54c06c713cdff103451550.jpg",
            altText: "Converse Chuck Taylor All Star Hi - Black"
          }
        }
      }
    }
  },
  uri: "/product/converse-chuck-taylor-all-star-hi-black-280972/?offer_id=128720",
  quantity: 1,
  totalPrice: {
    amount: 6929
  },
  addedByCoupon: false,
  title: "Converse Chuck Taylor All Star Hi - Black",
  notices: [{
    __typename: "CartNotice",
    type: "WARNING",
    message: {
      template: null,
      placeholders: null,
      "default": "Sorry, this item has sold out."
    },
    advisory: true
  }, {
    __typename: "CartNotice",
    type: "WARNING",
    message: {
      template: null,
      placeholders: null,
      "default": "Sorry, this item is no longer available."
    },
    advisory: true
  }]
}, {
  __typename: "CartParcelItem",
  id: "266221f4-2b16-42e3-bb86-c370bed4e5de",
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [{
    label: "Amount",
    value: "100"
  }, {
    label: "Receiver's First Name",
    value: "John"
  }, {
    label: "Receiver's Last Name",
    value: "Doe"
  }, {
    label: "Receiver's Email",
    value: "john.doe@gmail.com"
  }, {
    label: "Message",
    value: "Some message"
  }],
  freeShipping: true,
  offer: {
    clubFreeShipping: false,
    freeShipping: false,
    id: "378363",
    originPrice: {
      amount: 1
    },
    sellPrice: {
      amount: 1
    },
    purchasableQuantity: 0,
    variant: {
      inWishlist: false,
      variantOptionValues: [],
      product: {
        id: "2",
        assets: {
          hero: {
            id: "1905470",
            url: "https://static.devcat.ch/images/product/0001/1914/592b86d71cf00473493336.jpg",
            altText: "Catch of the Day Gift Card"
          }
        }
      }
    }
  },
  uri: "/product/catch-of-the-day-gift-card-2/?offer_id=378363",
  quantity: 1,
  totalPrice: {
    amount: 10000
  },
  addedByCoupon: false,
  title: "Catch of the Day Gift Card",
  notices: []
}];
export var mockCartParcelItemsForGroup = [{
  __typename: "CartParcelItem",
  id: "a745f896-41f5-4a69-81bc-a7fcee310d8c",
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [],
  freeShipping: false,
  offer: {
    clubFreeShipping: true,
    freeShipping: false,
    id: "198386",
    originPrice: {
      amount: 699
    },
    sellPrice: {
      amount: 699
    },
    purchasableQuantity: 10,
    variant: {
      inWishlist: false,
      product: {
        id: "123456",
        assets: {
          hero: {
            id: "967353",
            url: "https://static.devcat.ch/images/product/0000/0967/551a3125ef298396884344.jpg",
            altText: "3 x Palmolive Naturals Replenishing Soap Bars Milk & Honey 90g 4pk"
          }
        }
      },
      variantOptionValues: [{
        id: "465468",
        name: "Large",
        option: {
          name: "Size"
        }
      }]
    }
  },
  uri: "/product/revlon-colorstay-lipliner-680-blush-312598/?offer_id=198386",
  quantity: 2,
  totalPrice: {
    amount: 999
  },
  addedByCoupon: false,
  title: "Revlon ColorStay Lipliner - #680 Blush",
  notices: [{
    __typename: "CartNotice",
    type: "WARNING",
    message: {
      template: null,
      placeholders: null,
      "default": "This item is not available in the quantity selected. Quantity has been adjusted from 5 to 2"
    },
    advisory: true
  }]
}, {
  __typename: "CartParcelItem",
  id: "a77ce592-fb63-43f0-8fd9-a34f1d4dac2d",
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [],
  freeShipping: false,
  offer: {
    clubFreeShipping: true,
    freeShipping: false,
    id: "65347",
    originPrice: {
      amount: 699
    },
    sellPrice: {
      amount: 699
    },
    purchasableQuantity: 10,
    variant: {
      inWishlist: false,
      product: {
        id: "123654",
        assets: {
          hero: {
            id: "967353",
            url: "https://static.devcat.ch/images/product/0000/0967/551a3125ef298396884344.jpg",
            altText: "3 x Palmolive Naturals Replenishing Soap Bars Milk & Honey 90g 4pk"
          }
        }
      },
      variantOptionValues: [{
        id: "465468",
        name: "Large",
        option: {
          name: "Size"
        }
      }]
    }
  },
  uri: "/product/dove-beauty-bar-4pk-162014/?offer_id=65347",
  quantity: 2,
  totalPrice: {
    amount: 3032
  },
  addedByCoupon: false,
  title: "Dove Beauty Bar 4pk",
  notices: [{
    __typename: "CartNotice",
    type: "WARNING",
    message: {
      template: null,
      placeholders: null,
      "default": "This item is not available in the quantity selected. Quantity has been adjusted from 5 to 2"
    },
    advisory: true
  }]
}];
export var mockCartMarketplaceParcelItems = [{
  __typename: "CartParcelItem",
  id: ITEM_ID_THAT_WILL_THROW_ERROR,
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [],
  freeShipping: false,
  offer: {
    clubFreeShipping: false,
    freeShipping: false,
    id: "6339129",
    originPrice: {
      amount: 2999
    },
    sellPrice: {
      amount: 2999
    },
    purchasableQuantity: 20,
    variant: {
      variantOptionValues: [],
      inWishlist: false,
      product: {
        id: "371339",
        assets: {
          hero: {
            id: "1781003",
            url: "https://static.devcat.ch/images/product/0001/1780/5670d7c13117d388866717.jpg",
            altText: "Lamaze Yo Ho Horace Bathtime Story Set"
          }
        }
      }
    }
  },
  uri: "/product/lamaze-yo-ho-horace-bathtime-story-set-371339/?offer_id=6339129",
  quantity: 1,
  totalPrice: {
    amount: 2969
  },
  addedByCoupon: false,
  title: "Lamaze Yo Ho Horace Bathtime Story Set",
  notices: []
}];
export var mockCartMarketplaceSellerKmartParcelItems = [{
  __typename: "CartParcelItem",
  id: "825557c2-2c48-4de2-b86a-84e5dcc14a6f-1",
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [],
  freeShipping: false,
  offer: {
    clubFreeShipping: false,
    freeShipping: false,
    id: "63391290",
    originPrice: {
      amount: 2998
    },
    sellPrice: {
      amount: 2998
    },
    purchasableQuantity: 20,
    variant: {
      variantOptionValues: [],
      inWishlist: false,
      product: {
        id: "3713390",
        assets: {
          hero: {
            id: "17810030",
            url: "https://static.devcat.ch/images/product/0001/1780/5670d7c13117d388866717.jpg",
            altText: "Lamaze Yo Ho Horace Bathtime Story Set"
          }
        }
      }
    }
  },
  uri: "/product/lamaze-yo-ho-horace-bathtime-story-set-3713390/?offer_id=63391290",
  quantity: 1,
  totalPrice: {
    amount: 2970
  },
  addedByCoupon: false,
  title: "Lamaze Yo Ho Horace Bathtime Story Set From KMart",
  notices: []
}];
export var mockCartParcel = [{
  __typename: "CartParcel",
  id: "target-kmart-cnc",
  clubUpsellEligible: true,
  isFBC: false,
  isShippingMemberDiscounted: false,
  sellerName: "Catch",
  marketplaceSeller: false,
  items: mockCartParcelItems,
  dispatchNotice: {
    message: "Leaves warehouse in 1 - 2 business days",
    type: "IN_STOCK"
  },
  deliveryEstimate: {
    label: "Expected delivery between 29/09 and 02/10",
    labelDescription: "Please note, estimated dates are currently only available for standard delivery.",
    labelType: "Estimated",
    shortLabel: "Expected: Sep 29-Oct 02",
    minRange: "2022-09-29",
    maxRange: "2022-10-02",
    timeStamp: null,
    confidence: "75"
  },
  selectedShippingOption: {
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "FREE Delivery with OnePass",
    price: {
      amount: 0,
      currency: "AUD"
    },
    type: "STANDARD",
    clubFreeShipping: true,
    deliveryShortLabel: "Expected: Sep 29-Oct 02"
  },
  shippingOptions: [{
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "FREE Delivery with OnePass",
    price: {
      amount: 0,
      currency: "AUD"
    },
    attentionTag: "",
    type: "STANDARD",
    clubFreeShipping: true,
    deliveryShortLabel: "Expected: Sep 29-Oct 02"
  }, {
    id: "ExpressShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "Express Delivery",
    price: {
      amount: 200,
      currency: "AUD"
    },
    attentionTag: "",
    type: "EXPRESS",
    clubFreeShipping: false,
    deliveryShortLabel: "Expected: Sep 27-Sep 28"
  }, {
    id: "ClickAndCollect",
    availableCnCOptions: ["TARGET", "KMART"],
    locationType: "CLICK_AND_COLLECT",
    title: "FREE Pick Up from ",
    price: {
      amount: 0,
      currency: "AUD"
    },
    attentionTag: "",
    type: "PICKUP",
    clubFreeShipping: true,
    deliveryShortLabel: null
  }],
  subtotalPrice: {
    amount: 8313
  },
  subGroups: [{
    id: "parcel-group-1",
    items: mockCartParcelItems,
    deliveryEstimate: {
      label: "Expected delivery between 29/09 and 02/10",
      labelDescription: "Please note, estimated dates are currently only available for standard delivery.",
      labelType: "Estimated",
      shortLabel: "Expected: Sep 29-Oct 02",
      minRange: "2022-09-29",
      maxRange: "2022-10-02",
      timeStamp: null,
      confidence: "75"
    }
  }]
}, {
  __typename: "CartParcel",
  id: "marketplace-121",
  clubUpsellEligible: false,
  isFBC: false,
  isShippingMemberDiscounted: false,
  sellerName: "Urvashi Pandey MarketPlace",
  marketplaceSeller: true,
  items: mockCartMarketplaceParcelItems,
  dispatchNotice: {
    message: "Leaves warehouse in 7 business days",
    type: "BULKY_IN_STOCK"
  },
  deliveryEstimate: {
    label: null,
    labelDescription: null,
    labelType: "Fallback",
    shortLabel: null,
    minRange: null,
    maxRange: null,
    timeStamp: null,
    confidence: null
  },
  selectedShippingOption: {
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "Standard Shipping",
    price: {
      amount: 0,
      currency: "AUD"
    },
    type: "STANDARD",
    clubFreeShipping: true
  },
  shippingOptions: [{
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "Standard Shipping",
    price: {
      amount: 0,
      currency: "AUD"
    },
    attentionTag: "",
    type: "STANDARD",
    clubFreeShipping: true
  }],
  subtotalPrice: {
    amount: 2969
  },
  subGroups: [{
    id: "parcel-group-2",
    items: mockCartMarketplaceParcelItems,
    deliveryEstimate: {
      label: null,
      labelDescription: null,
      labelType: "Fallback",
      shortLabel: null,
      minRange: null,
      maxRange: null,
      timeStamp: null,
      confidence: null
    }
  }]
}, {
  __typename: "CartParcel",
  id: "kmart-cnc-only",
  clubUpsellEligible: true,
  isFBC: true,
  isShippingMemberDiscounted: false,
  sellerName: "KMart",
  marketplaceSeller: false,
  items: mockCartMarketplaceSellerKmartParcelItems,
  dispatchNotice: {
    message: "Leaves warehouse in 1 - 2 business days",
    type: "IN_STOCK"
  },
  deliveryEstimate: {
    label: "Expected delivery between 29/09 and 02/10",
    labelDescription: "Please note, estimated dates are currently only available for standard delivery.",
    labelType: "Estimated",
    shortLabel: "Expected: Sep 29-Oct 02",
    minRange: "2022-09-29",
    maxRange: "2022-10-02",
    timeStamp: null,
    confidence: "75"
  },
  selectedShippingOption: {
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "FREE Delivery with OnePass",
    price: {
      amount: 0,
      currency: "AUD"
    },
    type: "STANDARD",
    clubFreeShipping: true
  },
  shippingOptions: [{
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "FREE Delivery with OnePass",
    price: {
      amount: 0,
      currency: "AUD"
    },
    attentionTag: "",
    type: "STANDARD",
    clubFreeShipping: true
  }, {
    id: "ExpressShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "Express Post",
    price: {
      amount: 200,
      currency: "AUD"
    },
    attentionTag: "",
    type: "EXPRESS",
    clubFreeShipping: false
  }, {
    id: "ClickAndCollect",
    availableCnCOptions: ["KMART_CNC"],
    locationType: "CLICK_AND_COLLECT",
    title: "FREE Pick Up from ",
    price: {
      amount: 0,
      currency: "AUD"
    },
    attentionTag: "",
    type: "PICKUP",
    clubFreeShipping: true
  }],
  subtotalPrice: {
    amount: 8313
  },
  subGroups: [{
    id: "parcel-group-3",
    items: mockCartMarketplaceSellerKmartParcelItems,
    deliveryEstimate: {
      label: "Expected delivery between 29/09 and 02/10",
      labelDescription: "Please note, estimated dates are currently only available for standard delivery.",
      labelType: "Estimated",
      shortLabel: "Expected: Sep 29-Oct 02",
      minRange: "2022-09-29",
      maxRange: "2022-10-02",
      timeStamp: null,
      confidence: "75"
    }
  }]
}, {
  __typename: "CartParcel",
  id: "grouped-parcel",
  clubUpsellEligible: true,
  isFBC: false,
  isShippingMemberDiscounted: false,
  sellerName: "Group 1",
  marketplaceSeller: false,
  items: mockCartParcelItemsForGroup,
  dispatchNotice: {
    message: "Leaves warehouse in 1 - 2 business days",
    type: "IN_STOCK"
  },
  deliveryEstimate: {
    label: "Expected delivery between 29/09 and 02/10",
    labelDescription: "Please note, estimated dates are currently only available for standard delivery.",
    labelType: "Estimated",
    shortLabel: "Expected: Sep 29-Oct 02",
    minRange: "2022-09-29",
    maxRange: "2022-10-02",
    timeStamp: null,
    confidence: "75"
  },
  selectedShippingOption: {
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "FREE Delivery with OnePass",
    price: {
      amount: 0,
      currency: "AUD"
    },
    type: "STANDARD",
    clubFreeShipping: true
  },
  shippingOptions: [{
    id: "StandardShipping",
    availableCnCOptions: [],
    locationType: "NORMAL",
    title: "FREE Delivery with OnePass",
    price: {
      amount: 0,
      currency: "AUD"
    },
    attentionTag: "",
    type: "STANDARD",
    clubFreeShipping: true
  }],
  subtotalPrice: {
    amount: 8313
  },
  subGroups: [{
    id: "parcel-group-4",
    items: [mockCartParcelItemsForGroup[0]],
    deliveryEstimate: {
      label: "Expected delivery between 29/09 and 02/10",
      labelDescription: "Please note, estimated dates are currently only available for standard delivery.",
      labelType: "Estimated",
      shortLabel: "Expected: Sep 29-Oct 02",
      minRange: "2022-09-29",
      maxRange: "2022-10-02",
      timeStamp: null,
      confidence: "75"
    }
  }, {
    id: "parcel-group-5",
    items: [mockCartParcelItemsForGroup[1]],
    deliveryEstimate: {
      label: "Expected delivery between 29/09 and 02/10",
      labelDescription: "Please note, estimated dates are currently only available for standard delivery.",
      labelType: "Estimated",
      shortLabel: "Expected: Sep 29-Oct 02",
      minRange: "2022-09-29",
      maxRange: "2022-10-02",
      timeStamp: null,
      confidence: "75"
    }
  }]
}];
export var donationMock = {
  __typename: "OrderSummaryDonation",
  organisationName: "the Smith Family",
  total: {
    amount: 90,
    currency: "AUD"
  },
  image: {
    id: "DonationImageID",
    url: "https://s.catch.com.au/assets/0000/0946/5fbeeff8459a0823860790.jpg",
    altText: "The Smith family",
    height: 250,
    width: 200
  },
  body: "The Smith Family is Australia's leading children's educational-support charity. We help young Australians in need to break the cycle of poverty by supporting their education. By rounding up your purchase today, you'll help these young people to succeed at school, so they can create better futures for themselves.",
  header: "Round up for The Smith Family",
  orderTotalBefore: {
    amount: 16910,
    currency: "AUD"
  },
  orderTotalAfter: {
    amount: 17000,
    currency: "AUD"
  },
  selected: true
};
export var mockCartOrderSummary = {
  __typename: "CartOrderSummary",
  id: "c364576b-d2bd-4h73-364r-96ec580a123f",
  discounts: [{
    discount: {
      amount: 500
    },
    label: "1234",
    deletable: true
  }, {
    discount: {
      amount: 495
    },
    label: "$5 off for orders over $100",
    deletable: false
  }, {
    discount: {
      amount: 995
    },
    label: "Free delivery with OnePass",
    deletable: false
  }],
  delivery: {
    deliveryType: "STANDARD",
    total: {
      amount: 0
    }
  },
  donation: donationMock,
  giftCards: [{
    code: "502904200000456101",
    isOnePassReward: false,
    appliedBalance: {
      amount: 1000
    },
    remainingBalance: {
      amount: 5000
    }
  }],
  itemSummary: {
    items: [].concat(mockCartParcelItems, mockCartMarketplaceParcelItems, mockCartMarketplaceSellerKmartParcelItems),
    total: {
      amount: 19900
    }
  },
  storeCredit: {
    applied: {
      amount: 0
    },
    remaining: {
      amount: 0
    }
  },
  ageConfirmationRequired: false,
  subtotalPrice: {
    __typename: "Money",
    amount: 19900
  },
  totalPrice: {
    __typename: "Money",
    amount: 18400,
    currency: "AUD"
  },
  totalSaving: {
    __typename: "Money",
    amount: 1000
  }
};
export var mockNotices = [{
  __typename: "CartNotice",
  message: {
    template: "There are items in your cart that may incur additional {{ intl_shipping_link }}.",
    placeholders: [{
      id: "intl_shipping_link",
      text: "International Seller & Import charges",
      url: "http://catch.com.au/international-seller"
    }],
    "default": "Something went wrong"
  },
  type: "WARNING",
  advisory: true
}, {
  __typename: "CartNotice",
  message: {
    template: null,
    placeholders: null,
    "default": "Sorry, to use this coupon code: Order amount must be greater than $50."
  },
  type: "WARNING",
  advisory: true
}, {
  __typename: "CartNotice",
  message: {
    template: null,
    placeholders: null,
    "default": "There are issues with some items in your cart. Please review your order."
  },
  type: "WARNING",
  advisory: false
}, {
  __typename: "CartNotice",
  message: {
    template: null,
    placeholders: null,
    "default": "This is a informative cart notice eg; WFH note."
  },
  type: "INFO",
  advisory: false
}];
export var mockCouponMetadata = {
  __typename: "CartCouponMetadata",
  code: "TESTME1"
};
export var mockUserCoupon = {
  __typename: "CartCoupon",
  coupon: mockCouponMetadata,
  notices: []
};
export var mockRecommendationParcelItem = {
  __typename: "CartParcelItem",
  id: "retrt44-b5e6-47c7-a8f7-fegrt656fgtege3yy3",
  metadata: {
    eventId: 0,
    sourcePosition: 0,
    sourceType: 0
  },
  personalisedValues: [],
  freeShipping: false,
  offer: {
    clubFreeShipping: true,
    freeShipping: false,
    id: "205057",
    originPrice: {
      amount: 899
    },
    sellPrice: {
      amount: 899
    },
    purchasableQuantity: 8,
    variant: {
      inWishlist: false,
      variantOptionValues: [],
      product: {
        id: "241490",
        assets: {
          hero: {
            id: "967353",
            url: "https://static.devcat.ch/images/product/0000/0301/54fe7649d05ab977273390.jpg",
            altText: "48 x Cadbury Caramello Egg 39g"
          }
        }
      }
    }
  },
  uri: "/product/48-x-cadbury-caramello-egg-39g-42282",
  quantity: 1,
  totalPrice: {
    amount: 899
  },
  addedByCoupon: false,
  title: "48 x Cadbury Caramello Egg 39g",
  notices: []
};
var mockShippingLocation = {
  city: "MELBOURNE",
  stateCode: "VIC",
  postcode: "3000"
};
export var mockCart = {
  __typename: "Cart",
  id: "c364576b-d2bd-4h73-364r-96ec580a123f",
  clubSubscription: mockOnePassSubscription,
  itemsQuantitySum: 4,
  orderSummary: mockCartOrderSummary,
  parcels: mockCartParcel,
  shippingLocation: mockShippingLocation,
  notices: mockNotices,
  userCoupon: mockUserCoupon,
  canProceedToCheckout: true,
  giftCardSupported: true,
  fingerprint: "1234",
  showOnePassRecommender: false,
  salesRulePromotions: []
};
export default mockCart;