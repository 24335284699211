import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Button, LinkButton } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SubscriptionCTA = function SubscriptionCTA(props) {
  var Component = props.href ? LinkButton : Button;
  return ___EmotionJSX(Component, _extends({
    fontWeight: "bold",
    rounded: true,
    block: true
  }, props));
};