import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconHome = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M2 13l9.293-9.293a1 1 0 011.414 0L22 13h-2v8a1 1 0 01-1 1H5a1 1 0 01-1-1v-8H2z",
    fill: "currentColor"
  }), ___EmotionJSX("rect", {
    key: "part-2",
    x: "14",
    y: "13",
    width: "2",
    height: "2",
    rx: ".5",
    fill: "#fff"
  })],
  fill: "none",
  viewBox: "0 0 24 24"
});