export var URL_ONEPASS_LANDING = "/onepass";
export var URL_ONEPASS_TERMS_AND_CONDITIONS = "https://onepass.com.au/terms-and-conditions";
export var URL_ONEPASS_OAUTH_REDIRECT = "/oauth_redirect/onepass";
export var URL_ONEPASS_SUBSCRIBE = "/onepass/subscribe";
export var URL_ONEPASS_CONSENT = "/onepass-consent";
export var URL_ONEPASS_SHOP = "/shop/onepass";
export var URL_ONEPASS_MANAGEMENT = "/my-account/onepass-subscription";
export var URL_ONEPASS_PUBLIC_MIGRATION = "/onepass-migration";
export var URL_ONEPASS_HELP = "https://help.catch.com.au/s/articles?catName=OnePass";
export var URL_ONEPASS_SUBSCRIPTION_MANAGEMENT = "/onepass/manage-account";
export var URL_FLYBUYS_CATCH = "/flybuys-catch";
export var URL_ONEPASS_PRIVACY_POLICY = "https://onepass.com.au/privacy-policy";
export var URL_LEGACY_ONEPASS_ACCOUNT = "/my-account/legacy-onepass-subscription";