import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["page", "resultsPerPage", "sortBy", "sortDirection"];
import { getFacetsFromQuery } from "../../facets/getFacetsFromQuery";
import { pageTypeMap } from "../mapPageType/mapPageType";
export var mapBrowseInput = function mapBrowseInput(_ref, browseValue, pageType, customerId) {
  var _filters$find;

  var page = _ref.page,
      resultsPerPage = _ref.resultsPerPage,
      sortBy = _ref.sortBy,
      sortDirection = _ref.sortDirection,
      filterParams = _objectWithoutProperties(_ref, _excluded);

  var filters = getFacetsFromQuery(filterParams);
  return {
    browseValue: browseValue,
    pagination: {
      page: page ? parseInt(page) : 1,
      resultsPerPage: resultsPerPage ? parseInt(resultsPerPage) : 40
    },
    sortOptions: {
      key: sortBy || "relevance",
      direction: (sortDirection === null || sortDirection === void 0 ? void 0 : sortDirection.toUpperCase()) || "DESCENDING"
    },
    pageType: pageTypeMap[pageType],
    customerId: customerId,
    filters: filters,
    categoryPath: (_filters$find = filters.find(function (f) {
      return f.key === "group_id";
    })) === null || _filters$find === void 0 ? void 0 : _filters$find.value
  };
};