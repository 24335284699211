export var CART_APOLLO_CACHE_CONFIG = {
  typePolicies: {
    Query: {
      fields: {
        cart: {
          merge: true
        }
      }
    },
    CartParcel: {
      fields: {
        items: {
          merge: false
        }
      }
    },
    CartClubSubscriptionOption: {
      keyFields: ["id", "price", ["amount"]]
    }
  }
};