import { theme } from "@catchoftheday/theme";
import { LQIP_WIDTH, WEBP_FILE_EXTENSION } from "./types";
export function parsePath() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var slashParts = path.split("/");
  var dirName = slashParts.length < 2 ? "" : slashParts.slice(0, -1).join("/");
  var baseName = slashParts[slashParts.length - 1];
  var dotParts = baseName.split(".");
  var extension = dotParts.length < 2 ? "" : dotParts[dotParts.length - 1];
  var fileName = dotParts.length < 2 ? baseName : dotParts.slice(0, -1).join(".");
  return {
    dirName: dirName,
    baseName: baseName,
    fileName: fileName,
    extension: extension
  };
}
export var buildImageUrl = function buildImageUrl(dirName, fileName, extension, w) {
  var h = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var retina = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  var fname = fileName;
  if (w || h) fname += "_";
  if (w) fname += "w" + w;
  if (h) fname += "h" + h;
  if (retina) fname += "@2x";
  return "".concat(dirName, "/").concat(fname, ".").concat(extension);
};
export var generateSizesAttrValue = function generateSizesAttrValue(sizeMap) {
  return sizeMap && ["xl", "lg", "md", "sm"].filter(function (size) {
    return Object.prototype.hasOwnProperty.call(sizeMap, size);
  }).map(function (size) {
    return "(min-width: ".concat(theme.breakpoints[size], ") ").concat(sizeMap[size]).concat(typeof sizeMap[size] === "number" ? "px" : "");
  }).join(", ").concat(", ".concat(sizeMap["default"] || "200").concat(typeof sizeMap["default"] === "number" ? "px" : ""));
};
export var getSources = function getSources() {
  var src = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var widths = arguments.length > 1 ? arguments[1] : undefined;
  var ratio = arguments.length > 2 ? arguments[2] : undefined;

  var _parsePath = parsePath(src),
      dirName = _parsePath.dirName,
      fileName = _parsePath.fileName,
      extension = _parsePath.extension;
  /** source set for webp image sources */


  var webpSrcSet = widths.map(function (width) {
    var height = ratio ? Math.round(width * (ratio / 100)) : undefined;
    return "".concat(buildImageUrl(dirName, fileName, WEBP_FILE_EXTENSION, width, height), " ").concat(width, "w").concat(height ? " ".concat(height, "h") : "");
  }).join(", ");
  /** source set for other image sources e.g. jpg, gif */

  var imageSrcSet = widths.map(function (size) {
    return "".concat(buildImageUrl(dirName, fileName, extension, size), " ").concat(size, "w");
  }).join(", ");
  /** source LQIP (Low Quality Image Placeholder) */

  var lqipSrc = buildImageUrl(dirName, fileName, extension, LQIP_WIDTH);
  /** get an average resolution image source for IE */

  var ieSrc = buildImageUrl(dirName, fileName, extension, widths[Math.floor(widths.length / 2)]);
  return {
    webpSrcSet: webpSrcSet,
    imageSrcSet: imageSrcSet,
    lqipSrc: lqipSrc,
    ieSrc: ieSrc,
    extension: extension
  };
};