import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { useTheme } from "@catchoftheday/theme";
import { useIsInViewport } from "@catchoftheday/utilities";
import { ONEPASS_SHOP_TILES_PER_ROW } from "../../../consts";
import { useOnePassScheduleEntriesQuery } from "../../../generated/graphql";
import { isActiveOnePassMember } from "../../../utilities";
import { EventCardPlaceholder, OnePassEventCard } from "../../pure/EventCard";
import { ScheduleEntryBanner } from "../../pure/ScheduleEntryBanner";
import { CategoryFilterBarContainer } from "./CategoryFilterBarContainer";
import { ExclusiveDealsHeader } from "./ExclusiveDealsHeader";
import { InsertsContainer } from "./InsertsContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ONEPASS_SCHEDULE_ENTRIES_LIMIT = 9;
export var ShopExclusiveDealsContainer = function ShopExclusiveDealsContainer(_ref) {
  var recentlyViewedProductsComponent = _ref.recentlyViewedProductsComponent;
  var theme = useTheme();

  var _useState = useState({
    categoryId: null,
    pagination: 0
  }),
      entriesState = _useState[0],
      setEntriesState = _useState[1];

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data;

  var isActiveMember = isActiveOnePassMember(customerData);

  var _useOnePassScheduleEn = useOnePassScheduleEntriesQuery({
    ssr: false,
    skip: entriesState.pagination < 1,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      input: {
        page: entriesState.pagination,
        limit: ONEPASS_SCHEDULE_ENTRIES_LIMIT,
        categoryId: entriesState.categoryId,
        tilesPerRow: ONEPASS_SHOP_TILES_PER_ROW
      }
    },
    onCompleted: function onCompleted(data) {
      // re-set the pagination to handle cases like category change
      // so that when switching between category, the cached result's entries length
      // can determine the pagination value instead of defaulting to 1
      var eventEntries = data.onePassScheduleEntries.scheduleEntries.filter(function (ent) {
        return ent.event !== null;
      });
      var dataBasedPagination = Math.ceil(eventEntries.length / ONEPASS_SCHEDULE_ENTRIES_LIMIT);
      var pagination = entriesState.pagination;
      var newPagination = pagination > dataBasedPagination ? pagination : dataBasedPagination;
      setEntriesState(function (prevState) {
        return _objectSpread(_objectSpread({}, prevState), {}, {
          pagination: newPagination
        });
      });
    }
  }),
      entriesLoading = _useOnePassScheduleEn.loading,
      fetchMoreEntries = _useOnePassScheduleEn.fetchMore,
      scheduleEntriesData = _useOnePassScheduleEn.data;

  var _ref2 = scheduleEntriesData || {},
      onePassScheduleEntries = _ref2.onePassScheduleEntries;

  var _ref3 = onePassScheduleEntries || {},
      entries = _ref3.scheduleEntries,
      ordinals = _ref3.ordinals;

  var eventEntries = entries ? entries.filter(function (ent) {
    return ent.event !== null;
  }) : [];
  var bannerIndices = useMemo(function () {
    return entries ? entries.map(function (ent, index) {
      return ent.banner ? index : -1;
    }).filter(function (ind) {
      return ind >= 0;
    }) : [];
  }, [entries]);
  var isMax = !entriesLoading && eventEntries.length < entriesState.pagination * ONEPASS_SCHEDULE_ENTRIES_LIMIT;
  var handleLoadMore = useCallback(function () {
    if (!isMax && !entriesLoading) {
      setEntriesState(function (prevState) {
        var pagination = prevState.pagination,
            categoryId = prevState.categoryId;
        fetchMoreEntries({
          variables: {
            input: {
              page: pagination + 1,
              limit: ONEPASS_SCHEDULE_ENTRIES_LIMIT,
              categoryId: categoryId,
              tilesPerRow: ONEPASS_SHOP_TILES_PER_ROW
            }
          }
        });
        return _objectSpread(_objectSpread({}, prevState), {}, {
          pagination: prevState.pagination + 1
        });
      });
    }
  }, [entriesLoading, fetchMoreEntries, isMax]);

  var _useIsInViewport = useIsInViewport({
    threshold: 1,
    triggerOnce: false,
    shouldTrackVisibility: !entriesLoading,
    onComponentSeen: handleLoadMore
  }),
      pageBreakRef = _useIsInViewport.ref;

  var handleCategoryChange = function handleCategoryChange(newCategoryId) {
    setEntriesState({
      pagination: 1,
      categoryId: newCategoryId
    });
  };

  var shouldRenderInserts = !entriesState.categoryId && ordinals;
  return ___EmotionJSX(Box, {
    as: "section",
    pt: {
      xs: 5,
      lg: 15
    }
  }, ___EmotionJSX(ExclusiveDealsHeader, null), ___EmotionJSX(CategoryFilterBarContainer, {
    categoryId: entriesState.categoryId,
    onCategoryChange: handleCategoryChange
  }), ___EmotionJSX(Box, {
    px: {
      xs: 4,
      md: 6
    }
  }, ___EmotionJSX(Box, {
    maxWidth: theme.maxWidths.cotdWebsite,
    display: "grid",
    gridTemplateColumns: {
      xs: "1fr",
      md: "repeat(3, 1fr)"
    },
    columnGap: {
      xs: 2,
      md: 5
    },
    rowGap: {
      xs: 3,
      md: 5
    },
    role: "list",
    "aria-label": "Exclusive deals list",
    mx: "auto",
    mb: {
      xs: 3,
      md: 5
    }
  }, entries && entries.length > 0 && entries.map(function (entry, index) {
    return ___EmotionJSX(React.Fragment, {
      key: "onepass-exclusive-deal-".concat(index)
    }, shouldRenderInserts && ___EmotionJSX(InsertsContainer, {
      positionPointer: index,
      scheduleEntryOrdinals: ordinals,
      eventEntriesLength: eventEntries.length,
      bannerIndices: bannerIndices,
      recentlyViewedProductsComponent: recentlyViewedProductsComponent
    }), entry.event && ___EmotionJSX(OnePassEventCard, {
      isActiveMember: isActiveMember,
      event: entry.event,
      isOnePassExclusive: entry.onePassExclusive
    }), entry.banner && ___EmotionJSX(Box, {
      gridColumn: {
        xs: "auto",
        md: "span ".concat(ONEPASS_SHOP_TILES_PER_ROW)
      }
    }, ___EmotionJSX(ScheduleEntryBanner, {
      position: bannerIndices.indexOf(index) + 1,
      entry: entry
    })));
  }), entriesLoading ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EventCardPlaceholder, null), ___EmotionJSX(EventCardPlaceholder, null), ___EmotionJSX(EventCardPlaceholder, null)) : shouldRenderInserts && isMax && ___EmotionJSX(InsertsContainer, {
    renderRemaining: true,
    scheduleEntryOrdinals: ordinals,
    eventEntriesLength: eventEntries.length,
    recentlyViewedProductsComponent: recentlyViewedProductsComponent
  }), !isMax && ___EmotionJSX(Box, {
    width: "100%",
    ref: pageBreakRef
  }))));
};