import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react"; // eslint-disable-next-line import/named

import ContentLoader from "react-content-loader";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ContentPlaceHolder = function ContentPlaceHolder(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(ContentLoader, _extends({
    uniqueKey: "content-loader-key"
  }, props), children);
};
ContentPlaceHolder.displayName = "ContentPlaceHolder";
export default ContentPlaceHolder;