import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var UpsellBoxFlex = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  gap: ", ";\n  background: linear-gradient(\n    180deg,\n    ", " 0%,\n    ", " 100%\n  );\n\n  @media screen and (min-width: ", ") {\n    & {\n      gap: ", ";\n    }\n  }\n"])), themeGet("space.4"), themeGet("colors.onePass.primary"), themeGet("colors.white"), themeGet("breakpoints.md"), themeGet("space.6"));
export var UpsellBoxZone = function UpsellBoxZone(_ref) {
  var children = _ref.children;
  return ___EmotionJSX(UpsellBoxFlex, {
    borderRadius: "corner",
    overflow: "hidden",
    fontFamily: "onePass",
    mb: 6,
    p: {
      xs: 4,
      md: 6
    },
    flexDirection: {
      xs: "column",
      md: "row"
    }
  }, children);
};
export var UpsellBoxContentZone = function UpsellBoxContentZone(_ref2) {
  var children = _ref2.children;
  return ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "50%"
    },
    p: {
      xs: 4,
      md: 6
    },
    backgroundColor: "white",
    borderRadius: "corner",
    color: "textSecondary"
  }, children);
};
export var BenefitsCopyZone = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  & {\n    flex-direction: column;\n    align-items: flex-start;\n    margin-bottom: ", ";\n    gap: ", ";\n  }\n"])), themeGet("space.4"), themeGet("space.2"));
export var BenefitIconZone = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  & {\n    svg {\n      width: ", ";\n      height: ", ";\n      color: ", ";\n    }\n\n    margin-right: ", ";\n  }\n"])), themeGet("space.8"), themeGet("space.8"), themeGet("colors.onePass.primary"), themeGet("space.2"));
export var BenefitRowZone = styled(Flex)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  & {\n    align-items: center;\n  }\n"])));
export var SideImageZone = function SideImageZone(_ref3) {
  var children = _ref3.children;
  return ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "50%"
    },
    position: "relative"
  }, children);
};