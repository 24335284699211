import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconCart = createSvgIconComponent({
  children: [___EmotionJSX("g", {
    key: "part-1",
    clipPath: "url(#clip0)",
    stroke: "currentColor"
  }, ___EmotionJSX("path", {
    d: "M9.5 20.334a.833.833 0 100-1.667.833.833 0 000 1.667zM18.667 20.334a.833.833 0 100-1.667.833.833 0 000 1.667z",
    fill: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), ___EmotionJSX("path", {
    d: "M2.833 2.833h3.334L8.4 13.991a1.667 1.667 0 001.667 1.342h8.1a1.667 1.667 0 001.666-1.342L21.168 7H7",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), ___EmotionJSX("path", {
    d: "M20.5 7H7l2 8h10l1.5-8z",
    fill: "currentColor"
  })), ___EmotionJSX("defs", {
    key: "part-2"
  }, ___EmotionJSX("clipPath", {
    id: "clip0"
  }, ___EmotionJSX("path", {
    fill: "currentColor",
    transform: "translate(2 2)",
    d: "M0 0h20v20H0z"
  })))],
  fill: "none",
  viewBox: "0 0 24 24",
  fillRule: "evenodd"
});