import React from "react";
import { IconMpFreeShipping, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MarketplaceFreeShippingTag = function MarketplaceFreeShippingTag() {
  return ___EmotionJSX(Span, {
    display: "inline-block",
    width: "44px",
    height: "27px",
    margin: "3px 5px 6px 0"
  }, ___EmotionJSX(IconMpFreeShipping, {
    title: "Free Shipping",
    "aria-label": "Free Shipping",
    color: "green",
    width: "100%",
    minWidth: "30px"
  }));
};