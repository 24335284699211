import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isOpen", "isFbcSfop", "isActiveMember", "onClose", "isFreeTrialEligible", "mainCTAHref", "onMainCTAClick"];
import React from "react";
import { ContentZone } from "../../layouts/UpsellModalLayout/UpsellModalLayout";
import { OnePassModal } from "../OnePassModal";
import { Content } from "./Content";
import { FbcSfopContent } from "./FbcSfopContent";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellModal = function UpsellModal(_ref) {
  var _ref$isOpen = _ref.isOpen,
      isOpen = _ref$isOpen === void 0 ? false : _ref$isOpen,
      _ref$isFbcSfop = _ref.isFbcSfop,
      isFbcSfop = _ref$isFbcSfop === void 0 ? false : _ref$isFbcSfop,
      isActiveMember = _ref.isActiveMember,
      onClose = _ref.onClose,
      isFreeTrialEligible = _ref.isFreeTrialEligible,
      mainCTAHref = _ref.mainCTAHref,
      onMainCTAClick = _ref.onMainCTAClick,
      contentProps = _objectWithoutProperties(_ref, _excluded);

  var commonContentProps = {
    isFreeTrialEligible: isFreeTrialEligible,
    mainCTAHref: mainCTAHref,
    onMainCTAClick: onMainCTAClick
  };
  return ___EmotionJSX(OnePassModal, {
    sizeVariant: isFbcSfop ? "x-small" : {
      xs: "x-small",
      md: "medium"
    },
    isOpen: isOpen,
    onRequestClose: onClose,
    width: "90%"
  }, ___EmotionJSX(ContentZone, null, isFbcSfop ? ___EmotionJSX(FbcSfopContent, _extends({
    isActiveMember: isActiveMember
  }, commonContentProps, contentProps)) : ___EmotionJSX(Content, _extends({
    isResponsiveFontSize: true,
    logoContainerFontSize: {
      xs: "base",
      md: "2xl"
    }
  }, commonContentProps, contentProps))));
};