import React from "react";
import { SOURCE_TYPE_CONSTRUCTOR_RELATED_SEARCHES } from "@catchoftheday/analytics";
import { IconSearch, Link, Span } from "@catchoftheday/cg-components";
import { SEARCH_PAGE_PATH } from "../../../../consts";
import { RelatedSearchesZone } from "../../../layouts/PageLayout/PageLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RelatedSearches = function RelatedSearches(_ref) {
  var relatedSearches = _ref.relatedSearches;
  return ___EmotionJSX(RelatedSearchesZone, null, ___EmotionJSX(Span, {
    fontSize: {
      xs: "xs",
      md: "sm"
    },
    mr: 1
  }, "Are you looking for"), relatedSearches.map(function (term, index) {
    return ___EmotionJSX(Link, {
      fontSize: {
        xs: "xs",
        md: "sm"
      },
      fontWeight: "light",
      key: index,
      href: "".concat(SEARCH_PAGE_PATH, "?query=").concat(term, "&st=").concat(SOURCE_TYPE_CONSTRUCTOR_RELATED_SEARCHES),
      textDecoration: "underline",
      mx: 1
    }, ___EmotionJSX(IconSearch, {
      width: "14px",
      height: "14px",
      mr: 1
    }), term);
  }));
};