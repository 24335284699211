import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["url", "image", "sourcePosition", "onClick"];
import React from "react";
import { emitBannerClick } from "@catchoftheday/analytics";
import { Flex, ImageResponsive, Link } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SCHEDULED_BANNER_ID = 2;
export var ScheduledBanner = function ScheduledBanner(_ref) {
  var _ref$url = _ref.url,
      url = _ref$url === void 0 ? "#" : _ref$url,
      image = _ref.image,
      sourcePosition = _ref.sourcePosition,
      _onClick = _ref.onClick,
      props = _objectWithoutProperties(_ref, _excluded);

  var imageUrl = image.url,
      altText = image.altText,
      width = image.width,
      height = image.height;

  var _useTheme = useTheme(),
      maxHeights = _useTheme.maxHeights,
      maxWidths = _useTheme.maxWidths;

  return ___EmotionJSX(Flex, _extends({
    overflow: "hidden",
    justifyContent: "center",
    onClick: function onClick(e) {
      emitBannerClick({
        id: SCHEDULED_BANNER_ID,
        name: imageUrl.substring(imageUrl.lastIndexOf("/") + 1),
        targetUrl: imageUrl,
        position: sourcePosition
      });
      typeof _onClick === "function" && _onClick(e);
    }
  }, props), ___EmotionJSX(Link, {
    href: url
  }, ___EmotionJSX(ImageResponsive, {
    lazyLoad: true,
    src: imageUrl,
    alt: altText || "Promotional Banner",
    width: "100%",
    maxHeight: maxHeights.pageBanner,
    height: {
      _: "calc(100vw / ".concat(width / height, ")"),
      xl: "calc(".concat(maxWidths.cotdWebsite, " / ").concat(width / height, ")")
    }
  })));
};