import React from "react";
import { Pill } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPricePill = function ProductPricePill(_ref) {
  var priceLabel = _ref.priceLabel,
      priceDiscount = _ref.priceDiscount,
      large = _ref.large;
  var label = "";
  var outline = false;
  var variant = "plain";

  if (priceLabel.saving) {
    label = "Save ".concat(formatPrice(priceLabel.saving.savingAmount.amount, false));
    outline = true;
  } else if (priceDiscount !== null && priceDiscount !== void 0 && priceDiscount.percentageLabel) {
    label = "Save ".concat(priceDiscount.percentageLabel.replace(" off", ""));
    variant = "secondary";
  } else if (priceDiscount && priceLabel.wasPrice) {
    var saving = priceLabel.wasPrice.amount - priceLabel.price.amount;

    if (saving <= 0) {
      return null;
    }

    label = "Save ".concat(formatPrice(priceLabel.wasPrice.amount - priceLabel.price.amount, false, true));
    variant = "secondary";
  } else {
    return null;
  }

  return ___EmotionJSX(Pill, {
    label: label,
    pillSize: "sm",
    variant: variant,
    textProps: {
      fontSize: large ? "16px" : "12px"
    },
    containerProps: {
      height: large ? "20px" : "16px",
      display: "flex",
      alignItems: "center"
    },
    outline: outline
  });
};