export var WEBP_FILE_EXTENSION = "webp";
export var WEBP_MIME_TYPE = "image/webp";
export var DEFAULT_LAZY_LOAD_OPTIMUM_X = 1.25;
export var DEFAULT_LAZY_LOAD_OPTIMUM_X_SETTING = true;
/* Low Quality Image Placeholder default width */

export var LQIP_WIDTH = 16;
/* Default range settings for building sourcesets */

export var DEFAULT_SOURCE_WIDTHS_RANGE = {
  START: 100,
  END: 2000,
  STEP: 100
};