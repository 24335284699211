import React, { useState } from "react";
import useSWR from "swr";
import { Box, Flex, Hide, IconFlybuys, IconOnePass, IconParcel, IconUserOutline, Link, List, ListItem, NoSSR, NotificationBadge, Span } from "@catchoftheday/cg-components";
import { logIn, LOGIN_URL } from "@catchoftheday/customer";
import { date, formatPrice } from "@catchoftheday/utilities";
import { fetcher } from "../../../api/index";
import { HeaderTile } from "../../../components/HeaderTile";
import { HEADER_META_DATA_API, HEADER_MY_ACCOUNT_API } from "../../../consts/index";
import { limitNotificationBadgeCount, localStorage } from "../../../utilities/index";
import flybuysTheme from "../../flybuysTheme";
import { HeaderTileContainer } from "../HeaderTileContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MyAccount = function MyAccount(_ref) {
  var _response$data, _response$data2, _response$data2$recen;

  var showFlybuysLink = _ref.showFlybuysLink,
      isLoggedIn = _ref.isLoggedIn,
      isCCMember = _ref.isCCMember,
      isFlybuysLinked = _ref.isFlybuysLinked,
      messageCount = _ref.messageCount;

  var _useState = useState(false),
      shouldFetch = _useState[0],
      setShouldFetch = _useState[1];

  var _useSWR = useSWR(isLoggedIn && shouldFetch ? HEADER_MY_ACCOUNT_API : null, fetcher),
      response = _useSWR.data;

  var myAccountData = response === null || response === void 0 ? void 0 : response.data;
  var latestOrder = response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && _response$data.recentOrders && response !== null && response !== void 0 && (_response$data2 = response.data) !== null && _response$data2 !== void 0 && (_response$data2$recen = _response$data2.recentOrders) !== null && _response$data2$recen !== void 0 && _response$data2$recen[0] ? response.data.recentOrders[0] : undefined;
  var latestOrderUpdatedAt = latestOrder !== null && latestOrder !== void 0 && latestOrder.updatedAt ? new Date(latestOrder.updatedAt) : null;
  /** render welcome message for user */

  var renderWelcomeMessage = function renderWelcomeMessage() {
    return ___EmotionJSX(Span, {
      ml: ".4375rem",
      fontWeight: 400
    }, "Account");
  };

  var renderMyAccountPopOver = function renderMyAccountPopOver() {
    return ___EmotionJSX(Box, {
      width: "400px",
      px: "12px",
      color: "typography.text",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.50)",
      backgroundColor: "white"
    }, ___EmotionJSX(List, {
      m: 0,
      itemSeparator: "1px solid",
      type: "none",
      role: "listbox"
    }, ___EmotionJSX(ListItem, {
      py: "14px",
      mb: "0"
    }, ___EmotionJSX(Flex, {
      justifyContent: "space-between",
      alignItems: "center"
    }, ___EmotionJSX(Box, {
      pl: "16px"
    }, isCCMember && ___EmotionJSX(Flex, {
      alignItems: "center",
      mb: "5px"
    }, ___EmotionJSX(IconOnePass, {
      width: "73px",
      height: "16px",
      color: "onePass.primary",
      title: "OnePass"
    }), ___EmotionJSX(Span, {
      ml: "5px",
      px: "5px",
      color: "white",
      bg: "onePass.primary",
      fontSize: "2xs",
      fontWeight: "semibold",
      textTransform: "uppercase",
      borderRadius: "3px"
    }, "Active")), showFlybuysLink && isFlybuysLinked && ___EmotionJSX(Box, null, ___EmotionJSX(IconFlybuys, {
      width: "64px",
      height: "20px",
      color: flybuysTheme.primary,
      title: "Flybuys"
    }), ___EmotionJSX(Span, {
      ml: isCCMember ? "14px" : "5px",
      px: "5px",
      color: "white",
      bg: flybuysTheme.bgColor,
      fontSize: "2xs",
      fontWeight: "semibold",
      textTransform: "uppercase",
      borderRadius: "3px"
    }, "Linked")), showFlybuysLink && !isFlybuysLinked && ___EmotionJSX(Box, null, ___EmotionJSX(Link, {
      fontSize: "xs",
      href: "/rewards/flybuys/link",
      fontWeight: "semibold",
      "data-tracking": "header-app-my-account-link-flybuys"
    }, ___EmotionJSX(Flex, {
      alignItems: "center",
      justifyContent: "center"
    }, "Link your", ___EmotionJSX(IconFlybuys, {
      ml: "0.3em",
      mr: "0.3em",
      width: "60px",
      height: "19px",
      color: flybuysTheme.primary,
      title: "Flybuys"
    }), "account")))), ___EmotionJSX(Box, null, ___EmotionJSX(Span, {
      fontWeight: "normal",
      fontSize: "xs",
      color: "typography.subText"
    }, "Store credit:", " ", formatPrice((myAccountData === null || myAccountData === void 0 ? void 0 : myAccountData.storeCredit.amount) || 0))))), ___EmotionJSX(ListItem, {
      py: "17px",
      pl: "16px",
      mb: "0"
    }, ___EmotionJSX(Flex, {
      alignItems: "center"
    }, ___EmotionJSX(Link, {
      href: "/my-account/my-orders",
      color: "inherit",
      "data-tracking": "header-app-my-account-my-orders"
    }, ___EmotionJSX(IconParcel, {
      width: "25px",
      height: "25px",
      title: "My Orders"
    }), ___EmotionJSX(Span, {
      pl: "10px",
      fontWeight: "bold",
      fontSize: "base"
    }, "My Orders")), latestOrder && ___EmotionJSX(Flex, {
      flexDirection: "column",
      pl: "10px",
      fontSize: "xs",
      color: "typography.subText"
    }, ___EmotionJSX(Span, {
      fontSize: "inherit"
    }, "Last Order:", latestOrderUpdatedAt && date.isValid(latestOrderUpdatedAt) ? " ".concat(date.format(latestOrderUpdatedAt, "dd-MM-yyyy")) : "N/A"), ___EmotionJSX(Span, {
      fontSize: "inherit"
    }, latestOrder === null || latestOrder === void 0 ? void 0 : latestOrder.status)))), ___EmotionJSX(ListItem, {
      py: "18px",
      pl: "16px"
    }, ___EmotionJSX(Flex, {
      justifyContent: "space-between",
      alignItems: "center"
    }, ___EmotionJSX(Link, {
      href: "/my-account/details",
      color: "inherit",
      "data-tracking": "header-app-my-account-details"
    }, ___EmotionJSX(Box, {
      position: "relative",
      display: "inline-block"
    }, !!messageCount && Number(messageCount) > 0 && ___EmotionJSX(NoSSR, null, ___EmotionJSX(NotificationBadge, {
      fontSize: "2xs",
      absTop: "-10px",
      absRight: "-5px",
      text: limitNotificationBadgeCount(messageCount, 9),
      "data-testid": "my-account-message-count"
    })), ___EmotionJSX(IconUserOutline, {
      width: "25px",
      height: "23px",
      title: "My Details"
    })), ___EmotionJSX(Span, {
      pl: "10px",
      fontWeight: "bold",
      fontSize: "base"
    }, "My Details")), ___EmotionJSX(Link, {
      href: "/logout",
      pr: "10px",
      "data-tracking": "header-app-my-account-logout",
      onClick: function onClick(e) {
        e.preventDefault();
        localStorage.removeItem(HEADER_META_DATA_API);
        window.location.assign("/logout");
      }
    }, ___EmotionJSX(Box, {
      textAlign: "right"
    }, ___EmotionJSX(Span, {
      fontSize: "sm",
      fontWeight: "normal"
    }, "Logout")))))));
  };

  var renderIcon = function renderIcon(_ref2) {
    var isOpen = _ref2.isOpen;
    return ___EmotionJSX(HeaderTileContainer, {
      "data-testid": "myaccount-reference",
      hasRightBorder: true,
      flexProps: {
        px: "1rem"
      },
      linkProps: {
        "aria-label": "My Account",
        href: isLoggedIn ? "/my-account/my-orders" : LOGIN_URL,
        backgroundColor: isOpen ? "white" : undefined,
        color: isOpen ? "secondary" : "inherit",
        className: "js-dnt",
        "data-tracking": "header-app-my-account",
        onClick: !isLoggedIn ? function (e) {
          e.preventDefault();
          logIn({
            returnToSelf: true
          });
        } : undefined
      }
    }, ___EmotionJSX(Flex, {
      alignItems: "center"
    }, ___EmotionJSX(IconUserOutline, {
      width: 29,
      height: 31,
      title: isLoggedIn ? "My Account" : "Login"
    }), ___EmotionJSX(Hide, {
      xs: true,
      sm: true,
      md: true,
      lg: true
    }, renderWelcomeMessage())));
  };
  /** after extract the popover logic to a single component, add if isLoggedin false code */


  return isLoggedIn ? ___EmotionJSX(HeaderTile, {
    popoverProps: {
      placement: isFlybuysLinked ? "bottom" : "bottom-start"
    },
    icon: renderIcon,
    onTileShow: function onTileShow(show) {
      return setShouldFetch(show);
    }
  }, renderMyAccountPopOver()) : ___EmotionJSX(Box, null, renderIcon({
    isOpen: false
  }));
};
MyAccount.defaultProps = {
  showFlybuysLink: false,
  isLoggedIn: false,
  isCCMember: false,
  userName: ""
};
export default MyAccount;