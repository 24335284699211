import React from "react";
import { Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UnpaidFreeShippingTag = function UnpaidFreeShippingTag() {
  return ___EmotionJSX(Span, {
    fontSize: "xs",
    fontWeight: "normal",
    color: "textTertiary",
    whiteSpace: "nowrap",
    mx: {
      md: "5px"
    }
  }, "Free delivery");
};