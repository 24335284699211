import React from "react";
import { Box, Flex, Heading, NextLink } from "@catchoftheday/cg-components";
import { SponsoredLabel } from "@catchoftheday/product-components";
import { CATCH_SELLER_SLUG } from "../../../consts";
import { getSellerPageUrl } from "../../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SponsoredProductsHeader = function SponsoredProductsHeader(_ref) {
  var sellerName = _ref.sellerName,
      sellerSlug = _ref.sellerSlug;
  return ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, ___EmotionJSX(Box, null, ___EmotionJSX(Heading.h1, {
    m: 0
  }, sellerName ? "".concat(sellerName, " products") : " "), ___EmotionJSX(SponsoredLabel, {
    display: "block"
  })), sellerSlug && sellerSlug !== CATCH_SELLER_SLUG && ___EmotionJSX(NextLink, {
    href: getSellerPageUrl(sellerSlug),
    fontSize: "lg"
  }, "View seller store"));
};