import React from "react";
import Head from "next/head";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Assets = function Assets() {
  return ___EmotionJSX(Head, null, ___EmotionJSX("link", {
    rel: "icon",
    href: "https://s.catch.com.au/static/catch/images/icons/favicon-2c7745d709.png"
  }), ___EmotionJSX("link", {
    rel: "preload",
    href: "https://s.catch.com.au/static/catch/images/logo-condensed-acc008b165.svg",
    as: "svg",
    media: "(max-width: 375px)"
  }));
};