import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["children", "placement", "fullWidth", "hideOnClickOutside", "hideOnLeave", "hideOnLeaveInterval", "enhancedKeyboardNavigation", "hideArrow", "arrowColor", "zIndex", "outsideClickHandlerProps", "innerRef", "isOpen", "modifier"];

var _templateObject, _templateObject2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useMemo } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Manager, Popper, Reference } from "react-popper";
import debounce from "lodash.debounce";
import partition from "lodash.partition";
import { styled } from "@catchoftheday/theme";
import { useControlled } from "@catchoftheday/utilities";
import { Box } from "../Box";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ARROW_X_SIZE = "7px";
var ARROW_Y_SIZE = "10px";

function arrowPosition() {
  var placement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var positions = ["top", "bottom", "left", "right"];
  return positions.find(function (val) {
    return val && placement.includes(val);
  });
}

var autoSizing = function autoSizing(_ref) {
  var state = _ref.state;
  var placement = state.placement,
      rects = state.rects,
      styles = state.styles;
  var refOffsets = rects.reference;
  var leftReferenceOffset = Math.ceil(refOffsets.x);
  rects.popper.left = leftReferenceOffset;
  rects.popper.right = refOffsets.x + refOffsets.width;
  rects.popper.width = styles.popper.width = refOffsets.width;

  if (placement === "bottom") {
    styles.popper.transform = "translate3d(".concat(leftReferenceOffset, "px, ").concat(refOffsets.y + refOffsets.height, "px, 0)");
  }

  return state;
};

var Arrow = styled("div")(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 0;\n  width: 0;\n  border: solid transparent;\n  border-width: ", " ", ";\n  position: absolute;\n\n  ", ";\n"])), ARROW_Y_SIZE, ARROW_X_SIZE, function (props) {
  var placement = arrowPosition(props.placement);
  return placement && "\n      border-".concat(placement, "-color: ").concat(props.color || "black", ";\n      ").concat(placement, ": 100%;\n    ");
});
var ContentWrapper = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  var placement = arrowPosition(props.placement);
  var marginPos = "";

  switch (placement) {
    case "top":
      {
        marginPos = "bottom";
        break;
      }

    case "bottom":
      {
        marginPos = "top";
        break;
      }

    case "left":
      {
        marginPos = "right";
        break;
      }

    case "right":
      {
        marginPos = "left";
        break;
      }

    default:
      break;
  }

  return marginPos && !props.hideArrow && "\n      margin-".concat(marginPos, ": ").concat(ARROW_Y_SIZE, ";\n    ");
});
export var Popover = function Popover(_ref2) {
  var _control$, _content$;

  var children = _ref2.children,
      _ref2$placement = _ref2.placement,
      initialPlacement = _ref2$placement === void 0 ? "bottom" : _ref2$placement,
      _ref2$fullWidth = _ref2.fullWidth,
      fullWidth = _ref2$fullWidth === void 0 ? false : _ref2$fullWidth,
      _ref2$hideOnClickOuts = _ref2.hideOnClickOutside,
      hideOnClickOutside = _ref2$hideOnClickOuts === void 0 ? false : _ref2$hideOnClickOuts,
      _ref2$hideOnLeave = _ref2.hideOnLeave,
      hideOnLeave = _ref2$hideOnLeave === void 0 ? false : _ref2$hideOnLeave,
      _ref2$hideOnLeaveInte = _ref2.hideOnLeaveInterval,
      hideOnLeaveInterval = _ref2$hideOnLeaveInte === void 0 ? 200 : _ref2$hideOnLeaveInte,
      _ref2$enhancedKeyboar = _ref2.enhancedKeyboardNavigation,
      enhancedKeyboardNavigation = _ref2$enhancedKeyboar === void 0 ? false : _ref2$enhancedKeyboar,
      _ref2$hideArrow = _ref2.hideArrow,
      hideArrow = _ref2$hideArrow === void 0 ? false : _ref2$hideArrow,
      _ref2$arrowColor = _ref2.arrowColor,
      arrowColor = _ref2$arrowColor === void 0 ? "black" : _ref2$arrowColor,
      _ref2$zIndex = _ref2.zIndex,
      zIndex = _ref2$zIndex === void 0 ? 1 : _ref2$zIndex,
      outsideClickHandlerProps = _ref2.outsideClickHandlerProps,
      innerRef = _ref2.innerRef,
      isOpenProp = _ref2.isOpen,
      modifier = _ref2.modifier,
      boxProps = _objectWithoutProperties(_ref2, _excluded);

  var _useControlled = useControlled(false, isOpenProp),
      _useControlled2 = _slicedToArray(_useControlled, 2),
      isOpen = _useControlled2[0],
      setIsOpen = _useControlled2[1];

  var _useControlled3 = useControlled(false),
      _useControlled4 = _slicedToArray(_useControlled3, 2),
      isFocus = _useControlled4[0],
      setIsFocus = _useControlled4[1];

  var _useControlled5 = useControlled(false),
      _useControlled6 = _slicedToArray(_useControlled5, 2),
      isMouseEntered = _useControlled6[0],
      setIsMouseEntered = _useControlled6[1];

  var safeToCloseContent = !(isMouseEntered && isFocus);

  function openPopover() {
    setIsOpen(true);
  }

  function closePopover() {
    setIsOpen(false);
  }

  var childrenArray = React.Children.toArray(children);

  var _partition = partition(childrenArray, function (child) {
    var _child$type;

    return (child === null || child === void 0 ? void 0 : (_child$type = child.type) === null || _child$type === void 0 ? void 0 : _child$type.displayName) === "PopoverControl";
  }),
      _partition2 = _slicedToArray(_partition, 2),
      control = _partition2[0],
      content = _partition2[1];

  var controlRenderProps = control === null || control === void 0 ? void 0 : (_control$ = control[0]) === null || _control$ === void 0 ? void 0 : _control$.props;
  var contentRenderProps = content === null || content === void 0 ? void 0 : (_content$ = content[0]) === null || _content$ === void 0 ? void 0 : _content$.props;
  var closePopoverTimeout = debounce(function () {
    setIsOpen(false);
    setIsFocus(false);
    setIsMouseEntered(false);
  }, hideOnLeaveInterval);
  var closePopoverCallback = useCallback(closePopoverTimeout, [closePopoverTimeout]);
  var handleOnControllerLeave = useCallback(function () {
    if (hideOnLeave) {
      closePopoverCallback();
    }
  }, [hideOnLeave, closePopoverCallback]);
  var keysToOpenPopover = useMemo(function () {
    return ["Enter", "Space", initialPlacement.includes("bottom") ? "ArrowDown" : initialPlacement.includes("right") ? "ArrowRight" : null];
  }, [initialPlacement]);
  var handleOnKeyDown = useCallback(function (_ref3) {
    var code = _ref3.code;

    if (enhancedKeyboardNavigation) {
      if (!isOpen && keysToOpenPopover.includes(code)) {
        setIsOpen(true);
      }
    }
  }, [isOpen, enhancedKeyboardNavigation, keysToOpenPopover, setIsOpen]);
  var handleOnFocus = useCallback(function () {
    setIsOpen(true);
    setIsFocus(true);
    setIsMouseEntered(false);
    closePopoverTimeout.cancel();
  }, [setIsOpen, setIsFocus, setIsMouseEntered, closePopoverTimeout]);
  var handleOnBlur = useCallback(function () {
    if (hideOnLeave && safeToCloseContent) {
      closePopoverCallback();
    } else {
      setIsFocus(false);
    }
  }, [hideOnLeave, safeToCloseContent, setIsFocus, closePopoverCallback]);
  var handleOnMouseEnter = useCallback(function () {
    setIsOpen(true);
    setIsMouseEntered(true);
    setIsFocus(false);
    closePopoverTimeout.cancel();
  }, [setIsOpen, setIsMouseEntered, setIsFocus, closePopoverTimeout]);
  var handleOnMouseLeave = useCallback(function () {
    if (hideOnLeave && safeToCloseContent) {
      closePopoverCallback();
    } else {
      setIsMouseEntered(false);
    }
  }, [hideOnLeave, safeToCloseContent, closePopoverCallback, setIsMouseEntered]);

  if (!controlRenderProps) {
    throw new Error("PopoverControl is required");
  }

  return ___EmotionJSX(Box, _extends({
    "aria-expanded": isOpen
  }, boxProps), ___EmotionJSX(OutsideClickHandler, _extends({
    display: "inline",
    onOutsideClick: hideOnClickOutside ? closePopover : function () {
      return null;
    }
  }, outsideClickHandlerProps), ___EmotionJSX(Manager, null, ___EmotionJSX(Reference, {
    innerRef: innerRef
  }, function (_ref4) {
    var ref = _ref4.ref;
    return ___EmotionJSX(React.Fragment, null, controlRenderProps.children && controlRenderProps.children({
      ref: ref,
      openPopover: openPopover,
      closePopover: closePopover,
      isOpen: isOpen,
      handleOnControllerLeave: handleOnControllerLeave,
      handleOnKeyDown: handleOnKeyDown,
      handleOnFocus: handleOnFocus,
      handleOnBlur: handleOnBlur,
      handleOnMouseEnter: handleOnMouseEnter,
      handleOnMouseLeave: handleOnMouseLeave
    }));
  }), isOpen && ___EmotionJSX(Popper, {
    placement: initialPlacement,
    modifiers: [{
      name: "autoSizing",
      enabled: fullWidth && ["top", "bottom"].includes(initialPlacement),
      fn: autoSizing,
      phase: "beforeWrite"
    }].concat(_toConsumableArray(modifier ? modifier : []))
  }, function (_ref5) {
    var ref = _ref5.ref,
        style = _ref5.style,
        placement = _ref5.placement,
        arrowProps = _ref5.arrowProps;
    return ___EmotionJSX(ContentWrapper, {
      onFocus: handleOnFocus,
      onBlur: handleOnBlur,
      onKeyDown: handleOnKeyDown,
      onMouseEnter: handleOnMouseEnter,
      onMouseLeave: handleOnMouseLeave,
      ref: ref,
      style: _objectSpread({}, style),
      zIndex: zIndex,
      placement: placement || initialPlacement,
      hideArrow: hideArrow
    }, isOpen && contentRenderProps && typeof contentRenderProps.children === "function" ? contentRenderProps.children({
      openPopover: openPopover,
      closePopover: closePopover,
      isOpen: isOpen
    }) : content, !hideArrow && ___EmotionJSX(Arrow, {
      ref: arrowProps.ref,
      style: arrowProps.style,
      placement: placement || initialPlacement,
      color: arrowColor
    }));
  }))));
};
Popover.displayName = "Popover";
export var PopoverControl = function PopoverControl(_ref6) {
  var children = _ref6.children;
  return ___EmotionJSX(React.Fragment, null, children);
};
PopoverControl.displayName = "PopoverControl";
export var PopoverContent = function PopoverContent(_ref7) {
  var children = _ref7.children;
  return ___EmotionJSX(React.Fragment, null, children);
};
export default Popover;