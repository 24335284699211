import React from "react";
import { Box, ContentPlaceHolderPresetLineBlock } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryBreadcrumbsPlaceholder = function CategoryBreadcrumbsPlaceholder() {
  return ___EmotionJSX(Box, {
    display: "flex",
    alignItems: "center",
    columnGap: 5
  }, ___EmotionJSX(ContentPlaceHolderPresetLineBlock, {
    width: "100px",
    height: "24px",
    borderRadius: "default"
  }), ___EmotionJSX(ContentPlaceHolderPresetLineBlock, {
    display: {
      _: "none",
      md: "block"
    },
    width: "60px",
    height: "24px",
    borderRadius: "default"
  }), ___EmotionJSX(ContentPlaceHolderPresetLineBlock, {
    width: "80px",
    height: "24px",
    borderRadius: "default"
  }), ___EmotionJSX(ContentPlaceHolderPresetLineBlock, {
    width: "120px",
    height: "24px",
    borderRadius: "default"
  }));
};