export var ASSET_WOMAN_HANDS_UP_WITH_ONEPASS_LIGHT_BG = "https://s.catch.com.au/assets/0001/1042/6448a5aada53b458616313.webp";
export var ASSET_WOMAN_HANDS_UP_WITH_ONEPASS_LIGHT_BG_LANDSCAPE = "https://s.catch.com.au/assets/0001/1042/644b34091cbed820330948.webp";
export var ASSET_ONEPASS_MASCOT_HOLDING_DIVISIONS_LIST = "https://s.catch.com.au/assets/0001/1045/64e4050eb8820179928466.png";
export var ASSET_ONEPASS_MASCOT_FRONT_FACE = "https://s.catch.com.au/assets/0001/1045/64e2aff2a2bf3278405405.png";
export var ASSET_ONEPASS_MASCOT_FLYING_WINKS = "https://s.catch.com.au/assets/0001/1045/64e2b0cf34b70521342560.png";
export var ASSET_APP_DOWNLOAD_QR = "https://s.catch.com.au/assets/0000/0973/60b9ae8faf321792202603.jpg";
export var ASSET_APP_DOWNLOAD_QR_WHITE = "https://s.catch.com.au/assets/0000/0983/611f126fa5cf6975132156.png";
export var ASSET_APPLE_STORE_DOWNLOAD = "https://s.catch.com.au/static/catch/images/app-banner/1x/app-icon--ios.png";
export var ASSET_GOOGLE_PLAY_DOWNLOAD = "https://s.catch.com.au/static/catch/images/app-banner/1x/app-icon--android.png";
export var ASSET_CATCH_APP_BANNER_BG_MOBILE = "https://s.catch.com.au/assets/0000/0983/611f12f568fa3124675715.png";
export var ASSET_CATCH_APP_BANNER_BG_DESKTOP = "https://s.catch.com.au/assets/0000/0983/611f131a20ca0059688579.png"; // Apps

export var APP_DOWNLOAD_QR_IMG = "https://s.catch.com.au/assets/0000/0973/60b9ae8faf321792202603.jpg";
export var APPLE_STORE_DOWNLOAD_IMG = "https://s.catch.com.au/static/catch/images/app-banner/1x/app-icon--ios.png";
export var GOOGLE_PLAY_DOWNLOAD_IMG = "https://s.catch.com.au/static/catch/images/app-banner/1x/app-icon--android.png";