import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isLoggedIn", "isActiveMembership", "isFreeTrialEligible", "showSecondaryCTA", "isPlurals", "onMainCTAClick"];
import React from "react";
import { Box, Flex, IconOnePass, IconOnePassDelivery, Span } from "@catchoftheday/cg-components";
import { UpsellCTAWidgetPrimaryZone } from "../../layouts/CTAWidgetLayout/CTAWidgetLayout";
import { UpsellSecondaryCTA } from "../UpsellSecondaryCTA";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassFreeDeliveryVariant = function OnePassFreeDeliveryVariant(_ref) {
  var isLoggedIn = _ref.isLoggedIn,
      isActiveMembership = _ref.isActiveMembership,
      isFreeTrialEligible = _ref.isFreeTrialEligible,
      showSecondaryCTA = _ref.showSecondaryCTA,
      isPlurals = _ref.isPlurals,
      onMainCTAClick = _ref.onMainCTAClick,
      secondaryCTAProps = _objectWithoutProperties(_ref, _excluded);

  return isActiveMembership ? ___EmotionJSX(Box, {
    color: "onePass.textPrimary",
    display: "inline-flex",
    p: "8px 8px 12px",
    width: "100%"
  }, ___EmotionJSX(IconOnePassDelivery, {
    title: "OnePass free delivery",
    color: "onePass.primary",
    width: "20px",
    height: "16px",
    mt: 1
  }), ___EmotionJSX(Span, {
    fontSize: "sm",
    fontWeight: "bold",
    fontFamily: "onePass",
    ml: 2
  }, "You get free delivery on this item")) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(UpsellCTAWidgetPrimaryZone, {
    onClick: onMainCTAClick,
    "aria-label": isFreeTrialEligible || !isLoggedIn ? "Start free 30 day trial" : "Join OnePass today"
  }, ___EmotionJSX(Flex, {
    alignItems: "center",
    mb: 2
  }, ___EmotionJSX(Span, {
    fontWeight: "bold",
    fontSize: "base",
    whiteSpace: "nowrap"
  }, "Get free delivery with", ___EmotionJSX(IconOnePass, {
    title: "OnePass",
    "aria-label": "OnePass",
    color: "onePass.primary",
    width: "auto",
    height: "1em",
    ml: 1,
    mb: 1
  }))), ___EmotionJSX(Span, {
    fontSize: "xs",
    fontWeight: "medium"
  }, "Join for free delivery on ", isPlurals ? "these items" : "this item", " ", "and more!", " ", ___EmotionJSX(Span, {
    color: "onePass.primary",
    fontWeight: "bold",
    fontSize: "inherit",
    textDecoration: "underline",
    whiteSpace: "nowrap"
  }, isFreeTrialEligible || !isLoggedIn ? "Start free 30 day trial" : "Join OnePass today"))), showSecondaryCTA && ___EmotionJSX(UpsellSecondaryCTA, _extends({
    tight: true,
    isLoggedIn: isLoggedIn
  }, secondaryCTAProps)));
};