import { parseQueryString } from "@catchoftheday/utilities";
export function getSourceValuesFromQueryString() {
  var sourceValues = {};

  if (true) {
    sourceValues = parseQueryString(window.location.search);
  }

  var _sourceValues = sourceValues,
      sid = _sourceValues.sid,
      st = _sourceValues.st,
      sp = _sourceValues.sp;
  return {
    sourceId: sid,
    sourceType: st ? parseInt(st) : undefined,
    sourcePosition: sp ? parseInt(sp) : undefined
  };
}