import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconEnvelope = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M3 5.2c0-.3.12-.6.36-.84S3.9 4 4.2 4h9.6c.3 0 .6.12.84.36s.36.54.36.84v7.2c0 .3-.12.6-.36.84s-.54.36-.84.36H4.2c-.3 0-.6-.12-.84-.36S3 12.7 3 12.4V5.2z",
    fill: "currentColor"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M14.16 5.56L9.42 9.7c-.12.12-.24.18-.42.18s-.3-.06-.42-.18L3.84 5.56v7.2h10.32v-7.2z",
    fill: "#fff"
  }), ___EmotionJSX("path", {
    key: "part-3",
    d: "M4.2 4.84L9 8.8l4.74-3.96H4.2z",
    fill: "#fff"
  })],
  fill: "none",
  viewBox: "0 0 18 18"
});