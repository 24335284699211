import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex, Span } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { ONEPASS_MONTHLY_PRICE, ONEPASS_YEARLY_PRICE } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";

var Price = function Price(props) {
  return ___EmotionJSX(Span, _extends({
    lineHeight: "normal",
    fontSize: "2xl",
    fontWeight: "semibold"
  }, props));
};

var Interval = function Interval(props) {
  return ___EmotionJSX(Span, _extends({
    fontSize: "sm",
    fontWeight: "normal"
  }, props));
};

var PlanBox = function PlanBox(props) {
  return ___EmotionJSX(Flex, _extends({
    width: "108px",
    height: "88px",
    borderRadius: "corner",
    backgroundColor: "onePass.textLight",
    color: "textSecondary",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }, props));
};

export var Pricing = function Pricing() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Flex, {
    mb: 3,
    alignItems: "center"
  }, ___EmotionJSX(PlanBox, null, ___EmotionJSX(Price, null, formatPrice(ONEPASS_MONTHLY_PRICE, false)), ___EmotionJSX(Interval, null, "/month")), ___EmotionJSX(Span, {
    mx: 2,
    fontSize: "base",
    fontWeight: "bold"
  }, "or"), ___EmotionJSX(PlanBox, null, ___EmotionJSX(Price, null, formatPrice(ONEPASS_YEARLY_PRICE, false)), ___EmotionJSX(Interval, null, "/year"))));
};