import React from "react";
import { Box, Flex, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ItemBox = function ItemBox(_ref) {
  var text = _ref.text,
      icon = _ref.icon;
  return ___EmotionJSX(Box, {
    flexBasis: {
      xs: "50%",
      md: "33.3%",
      lg: "20%"
    },
    flexGrow: 0,
    flexShrink: 1,
    p: {
      xs: 1,
      md: 2
    }
  }, ___EmotionJSX(Box, {
    px: 3,
    py: 4,
    borderRadius: "sharpCorner",
    boxShadow: "card",
    backgroundColor: "white",
    fontSize: "base",
    height: "10em"
  }, ___EmotionJSX(Flex, {
    height: "3em",
    alignItems: "center",
    mb: 2
  }, icon), ___EmotionJSX(Text, {
    fontWeight: "normal",
    m: 0,
    fontSize: "inherit",
    color: "textSecondary"
  }, text)));
};