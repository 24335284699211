import React from "react";
import { Box, Flex, Heading, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BenefitCard = function BenefitCard(_ref) {
  var icon = _ref.icon,
      desc = _ref.desc,
      title = _ref.title;
  return ___EmotionJSX(Box, {
    backgroundColor: "ui.backgroundColorSecondary",
    mx: {
      xs: 1,
      lg: 2
    },
    mb: {
      xs: 3,
      lg: 0
    },
    borderRadius: "corner",
    borderColor: "onePass.primary",
    border: 1,
    overflow: "hidden",
    height: "100%"
  }, ___EmotionJSX(Flex, {
    height: "100%",
    padding: {
      xs: 3,
      lg: 6
    },
    flexDirection: "column",
    alignItems: "flex-start"
  }, ___EmotionJSX(Flex, {
    flex: {
      xs: "0 0 auto",
      lg: "0 0 auto"
    },
    minHeight: {
      xs: "60px",
      lg: "80px"
    }
  }, icon), ___EmotionJSX(Heading.h5, {
    mb: 2,
    mt: 0,
    fontSize: {
      xs: "sm",
      lg: "xl"
    },
    color: "onePass.textPrimary",
    fontWeight: "bold",
    fontFamily: "onePass"
  }, title), ___EmotionJSX(Text, {
    m: 0,
    fontSize: {
      xs: "sm",
      lg: "base"
    },
    color: "onePass.textPrimary",
    fontFamily: "onePass",
    fontWeight: "medium"
  }, desc)));
};