import { appendQueryString } from "@catchoftheday/utilities";

function isAdult(item) {
  var _item$data, _item$data$facets;

  return (_item$data = item.data) === null || _item$data === void 0 ? void 0 : (_item$data$facets = _item$data.facets) === null || _item$data$facets === void 0 ? void 0 : _item$data$facets.some(function (facet) {
    return facet.name === "adult" && facet.values.includes("True");
  });
}

export var mapSearchBarProducts = function mapSearchBarProducts(products, sourceType) {
  return products.map(function (item) {
    if (item.data) {
      return {
        id: item.id,
        title: item.value,
        brand: item.data.brand_name ? {
          name: item.data.brand_name,
          slug: item.data.brand_slug || ""
        } : null,
        url: appendQueryString(item.data.url || "", {
          st: sourceType
        }),
        priceTag: {
          priceLabel: {
            price: {
              amount: item.data.price * 100
            }
          },
          clubExclusive: false,
          enabled: true
        },
        assets: {
          hero: {
            url: item.data.image_url,
            altText: item.value
          }
        },
        adult: isAdult(item)
      };
    }
  }).filter(Boolean);
};