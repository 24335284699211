import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useContext } from "react";
import { ArrowLeftIcon, Box, Link, ListItem, Span } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
var IconLeftArrow = styled(ArrowLeftIcon)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  left: -24px;\n  top: -1px;\n"])));
export var CategoryFacetListItem = function CategoryFacetListItem(_ref) {
  var category = _ref.category,
      visitedCategories = _ref.visitedCategories,
      updateVisited = _ref.updateVisited,
      renderList = _ref.renderList;

  var _useContext = useContext(CatalogueContext),
      addFilterValue = _useContext.addFilterValue;

  var selected = category.selected,
      displayName = category.displayName,
      count = category.count,
      currentId = category.id,
      children = category.children,
      slug = category.slug,
      path = category.path;
  var hasChildren = children && children.length > 0;
  var currentSelection = selected;
  return ___EmotionJSX(ListItem, {
    key: currentId
  }, ___EmotionJSX(Box, {
    position: "relative"
  }, !currentSelection && hasChildren && ___EmotionJSX(IconLeftArrow, {
    width: 5,
    height: 5
  }), ___EmotionJSX(Box, null, ___EmotionJSX(Link, {
    href: "/category/".concat(slug || currentId),
    color: selected ? "secondary" : visitedCategories.includes(currentId) ? "purple" : "inherit",
    onClick: function onClick(e) {
      e.preventDefault();

      if (currentSelection) {
        return;
      }

      addFilterValue("f.group_id", path, false);
      updateVisited(currentId);
    },
    fontWeight: currentSelection ? "normal" : "light",
    hoverColor: "secondary",
    fontSize: "sm"
  }, displayName), count && ___EmotionJSX(Span, {
    color: "typography.inactive",
    display: "inline-block",
    ml: 2
  }, "(", count, ")"))), hasChildren && renderList(children, currentId));
};