import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["badges", "containerProps"];
import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { Badge } from "./Badge";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ProductBadge(_ref) {
  var badges = _ref.badges,
      containerProps = _ref.containerProps,
      props = _objectWithoutProperties(_ref, _excluded);

  var theme = useTheme();
  var BadgeStyle = {
    PRICE_DROP: {
      background: theme.colors.secondary,
      border: theme.colors.secondary,
      textColor: "white"
    },
    ALMOST_GONE: {
      background: "white",
      border: theme.colors.secondary,
      textColor: theme.colors.secondary
    },
    SOLD_OUT: {
      background: theme.colors.grey,
      border: theme.colors.grey,
      textColor: theme.colors.text
    },
    SHORT_DATED: {
      background: "white",
      border: theme.colors.secondary,
      textColor: theme.colors.secondary
    },
    CLEARANCE: {
      background: theme.colors.error,
      border: theme.colors.error,
      textColor: "white"
    }
  };

  if (!badges.length) {
    return null;
  }

  var showSoldOut = badges.includes("sold-out") && ___EmotionJSX(Badge, _extends({
    backgroundColor: BadgeStyle.SOLD_OUT.background,
    borderColor: BadgeStyle.SOLD_OUT.border,
    color: BadgeStyle.SOLD_OUT.textColor
  }, props), "Sold Out");

  var showPriceDrop = badges.includes("price-drop") && ___EmotionJSX(Badge, _extends({
    backgroundColor: BadgeStyle.PRICE_DROP.background,
    borderColor: BadgeStyle.PRICE_DROP.border,
    color: BadgeStyle.PRICE_DROP.textColor
  }, props), "Price Drop");

  var showUrgentMessage = badges.includes("clearance") ? ___EmotionJSX(Badge, _extends({
    backgroundColor: BadgeStyle.CLEARANCE.background,
    borderColor: BadgeStyle.CLEARANCE.border,
    color: BadgeStyle.CLEARANCE.textColor
  }, props), "Clearance") : badges.includes("almost-gone") ? ___EmotionJSX(Badge, _extends({
    backgroundColor: BadgeStyle.ALMOST_GONE.background,
    borderColor: BadgeStyle.ALMOST_GONE.border,
    color: BadgeStyle.ALMOST_GONE.textColor
  }, props), "Almost Gone") : null;

  var showShortDated = badges.includes("short-dated") && ___EmotionJSX(Badge, _extends({
    backgroundColor: BadgeStyle.SHORT_DATED.background,
    borderColor: BadgeStyle.SHORT_DATED.border,
    color: BadgeStyle.SHORT_DATED.textColor
  }, props), "Short Dated");

  if (!showSoldOut && !showUrgentMessage && !showShortDated && !showPriceDrop) {
    return null;
  }

  return ___EmotionJSX(Flex, _extends({
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  }, containerProps), ___EmotionJSX(Box, null, showSoldOut || showPriceDrop || showUrgentMessage || showShortDated));
}