import React, { useCallback, useContext } from "react";
import { Box, Carousel, Container } from "@catchoftheday/cg-components";
import { OnePassHeroDealsContext } from "../../../contexts/OnePassHeroDealsContext";
import { ScheduleEntryBanner } from "../../pure/ScheduleEntryBanner";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopBannerStripContainer = function ShopBannerStripContainer() {
  // Hero deals context
  var _useContext = useContext(OnePassHeroDealsContext),
      heroDealsData = _useContext.data;

  var bannerEntries = heroDealsData.bannerEntries;
  var renderBanner = useCallback(function (entry, bannerPosition) {
    return ___EmotionJSX(ScheduleEntryBanner, {
      entry: entry,
      position: bannerPosition
    });
  }, []);

  if (!bannerEntries.length) {
    return null;
  }

  return ___EmotionJSX(Box, {
    px: {
      xs: 4,
      md: 6
    },
    mb: 4
  }, ___EmotionJSX(Container, {
    display: "block"
  }, bannerEntries.length > 1 ? ___EmotionJSX(Carousel, {
    slides: bannerEntries.map(function (entry, index) {
      return entry.banner && renderBanner(entry, index + 1);
    }),
    showNavButtons: true,
    showNavButtonsOnHover: true,
    infinite: true,
    interval: 5000,
    autoPlay: true
  }) : ___EmotionJSX(ScheduleEntryBanner, {
    entry: bannerEntries[0],
    position: 1
  })));
};