import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconAdultsOnly = createSvgIconComponent({
  viewBox: "0 0 16 16",
  fill: "none",
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M12.8476 11C11.8425 12.6207 10.0473 13.7 8 13.7C4.85198 13.7 2.3 11.148 2.3 8C2.3 4.85198 4.85198 2.3 8 2.3C10.0473 2.3 11.8425 3.37933 12.8476 5H14.3264C13.2029 2.63505 10.7924 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C10.7924 15 13.2029 13.3649 14.3264 11H12.8476Z",
    fill: "#222222"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M16 7.4818V8.55246H14.5936V10H13.4064V8.55246H12V7.4818H13.4064V6H14.5936V7.4818H16Z",
    fill: "#222222"
  }), ___EmotionJSX("path", {
    key: "part-3",
    d: "M10.6084 7.71579C11.2908 7.95767 11.766 8.52782 11.766 9.24482C11.766 10.2901 10.8244 10.9898 9.53721 10.9898C8.24141 10.9898 7.2998 10.2901 7.2998 9.24482C7.2998 8.51918 7.74901 7.94903 8.41419 7.70715C7.92178 7.48254 7.55032 7.0247 7.55032 6.35952C7.55032 5.46975 8.25005 4.77002 9.53721 4.77002C10.8071 4.77002 11.5154 5.46111 11.5154 6.36816C11.5154 7.03334 11.144 7.49118 10.6084 7.71579ZM9.53721 5.80665C9.07072 5.80665 8.75973 6.10037 8.75973 6.5323C8.75973 6.95559 9.07936 7.26658 9.53721 7.26658C9.98641 7.26658 10.306 6.95559 10.306 6.5323C10.306 6.10037 9.99505 5.80665 9.53721 5.80665ZM9.53721 9.95319C10.116 9.95319 10.4875 9.59037 10.4875 9.08933C10.4875 8.58829 10.1074 8.21683 9.53721 8.21683C8.95842 8.21683 8.57832 8.58829 8.57832 9.08933C8.57832 9.59037 8.94978 9.95319 9.53721 9.95319Z",
    fill: "#222222"
  }), ___EmotionJSX("path", {
    key: "part-4",
    d: "M6.24604 9.81482H6.96304V10.8774H4V9.81482H4.96752V6.78267H4.01728V5.72876H4.96752V4.87354H6.24604V9.81482Z",
    fill: "#222222"
  })]
});