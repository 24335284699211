export var mockFromProduct = {
  id: "358969",
  title: "Revlon ColorStay Concealer 6.2mL - #02 Light",
  assets: {
    hero: {
      url: "https://static.devcat.ch/images/product/0001/1687/56a5b83a93166236625734.jpg",
      altText: "Revlon ColorStay Concealer 6.2mL - #02 Light"
    }
  },
  offer: {
    id: "293753",
    sellPrice: {
      amount: 1234,
      currency: "AUD"
    },
    purchasableQuantity: 5,
    seller: {
      id: "1",
      name: "Catch",
      slug: "catch"
    },
    clubExclusive: false,
    clubFreeShipping: true,
    freeShipping: false
  }
};
export var mockCopurchasedProducts = {
  coPurchasedProducts: {
    products: [{
      id: "312846",
      title: "Revlon Super Lustrous Lipstick - 130 Rose Velvet",
      bestOffer: {
        id: "198727",
        sellPrice: {
          amount: 1039,
          __typename: "Money"
        },
        purchasableQuantity: 5,
        __typename: "Offer"
      },
      assets: {
        hero: {
          url: "https://static.devcat.ch/images/product/0001/1444/56a5c4ca665de737360725.jpg",
          altText: "Revlon Super Lustrous Lipstick - 130 Rose Velvet",
          __typename: "Image"
        },
        __typename: "ProductAssets"
      },
      __typename: "Product"
    }, {
      id: "362106",
      title: "Revlon Super Lustrous Lipstick - 420 Blushed",
      bestOffer: {
        id: "301041",
        sellPrice: {
          amount: 1050,
          __typename: "Money"
        },
        purchasableQuantity: 5,
        __typename: "Offer"
      },
      assets: {
        hero: {
          url: "https://static.devcat.ch/images/product/0001/1803/56a5b83a7093b640867058.jpg",
          altText: "Revlon Super Lustrous Lipstick - 420 Blushed",
          __typename: "Image"
        },
        __typename: "ProductAssets"
      },
      __typename: "Product"
    }],
    metaData: {
      hydrationErrorCount: 0,
      preHydrationCount: 1,
      postHydrationCount: 1,
      __typename: "RecommenderMetaData"
    },
    __typename: "CoPurchasedProducts"
  }
};