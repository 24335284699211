export var MOCK_FOOTER_DATA = {
  navigationLinkColumns: [{
    columnTitle: "About Us",
    links: [{
      text: "Who we are",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: true,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Careers",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Press",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Sell on Catch",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Commerical",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Affiliates",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Catch Insider",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Kmart",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Target",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Ethical Sourcing",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }]
  }, {
    columnTitle: "Contact & Support",
    links: [{
      text: "Help Centre",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Warranty",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Delivery Information",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Online Safety",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Zip",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "afterpay",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "LatitudePay",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }]
  }, {
    columnTitle: "Legal",
    links: [{
      text: "Target Australia",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Flybuys & Catch",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Reusable Face Masks",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Buy Satisfyer Pro 2",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Black Friday sales",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Christmas lights",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Christmas tree",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Christmas Sales",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Mobile Phone Plan",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Asics Gel Kayano",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Travel Insurance Articles",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Teeth Whitening Kit",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Adults only Products",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "TVs & Home Entertainment",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Portable Air Conditioners",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Catch of the Day",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Screamin' Good Deals",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }]
  }, {
    columnTitle: "Top Brands",
    links: [{
      text: "ASICS",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Sennheiser",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Nike",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Adidas",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Dyson",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "T2",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Lego",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Greenies",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Tommy Hilfiger",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Michael Kors",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "2XU",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Birkenstock",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Havainas",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Lornas Jane",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Finish",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Xiaomi",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Paw Patrol",
      url: "https://www.catch.com.au/about-us",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }]
  }, {
    columnTitle: "Popular Pages",
    links: [{
      text: "Travel Insurance Articles",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Target Australia",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Flybuys & Catch",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Reusable Face Masks",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Buy Satisfyer Pro 2",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Black Friday sales",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Christmas lights",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Christmas tree",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Christmas Sales",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Mobile Phone Plan",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Asics Gel Kayano",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Teeth Whitening Kit",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Adults only Products",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "TVs & Home Entertainment",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Portable Air Conditioners",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Catch Stories - Guides and Hacks",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Catch of the Day",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }, {
      text: "Screamin' Good Deals",
      url: "https://www.catch.com.au/shop/target/",
      openInNewWindow: false,
      isBrightEdgeAutoPilotEnabled: false
    }]
  }],
  copyright: "copy right",
  // copyright: [
  //   `Groceries, Toiletries, Beauty & more! Catch supports the Responsible Service of Alcohol. WARNING: Under the Liquor Control Reform Act 1998 it is an offence: To supply alcohol to a person under the age of 18 years (Penalty exceeds $17,000); For a person under the age of 18 years to purchase or receive liquor (Penalty exceeds $700). Catch.com.au Pty Ltd Liquor Licence No. 36117384.`,
  //   `This appeal is operated by Catch.com.au Pty Ltd, registered fundraiser under the Fundraising Act 1998 (Vic.) registration number FR0015518. All funds donated are collected by Catch in Bentleigh East, Victoria and will be given to the Australian Red Cross. You can contact us on 1300 222 824.`,
  //   `Registered Office: Level 14, Brookfield Place Tower 2, 123 St Georges Terrace, Perth, Western Australia, 6000.`,
  //   `© Copyright 2006-2020 Catch.com.au Pty Ltd (ABN 22 149 779 939). All Rights Reserved.`,
  // ],
  appDownloadLinks: [{
    name: "appStore",
    url: "/app-store"
  }, {
    name: "googlePlay",
    url: "/google-play"
  }]
};