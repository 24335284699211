import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var _excluded = ["heading", "subHeading", "wrapperProps"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useEffect, useState } from "react";
import { RECOMMENDER_DESKTOP_LIMIT, RECOMMENDER_MOBILE_LIMIT } from "@personalisation/consts";
import { emitEventImpression, emitProductImpression, emitRecommenderImpression, emitTrackingPixel, EVENT_RECOMMENDER_EVENT_TYPE } from "@catchoftheday/analytics";
import { Box, Carousel, Heading } from "@catchoftheday/cg-components";
import { ProductCardPlaceholder } from "@catchoftheday/common-vendors";
import { useBreakpoint } from "@catchoftheday/theme";
import { centsToDollars, useIsInViewport } from "@catchoftheday/utilities";
import { getRecommenderTilesPerBreakpoint } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BaseRecommenderCarousel = function BaseRecommenderCarousel(_ref) {
  var tiles = _ref.tiles,
      tilesPerBreakpoint = _ref.tilesPerBreakpoint,
      trackingData = _ref.trackingData,
      sourceType = _ref.sourceType,
      sourceId = _ref.sourceId,
      fromProduct = _ref.fromProduct,
      carouselProps = _ref.carouselProps,
      maxTiles = _ref.maxTiles,
      paginate = _ref.paginate,
      sponsoredTrackingData = _ref.sponsoredTrackingData;

  var _useBreakpoint = useBreakpoint({
    minusOnePx: true
  }),
      isMobile = _useBreakpoint.isMobile;

  var totalTiles = maxTiles || (isMobile ? RECOMMENDER_MOBILE_LIMIT : RECOMMENDER_DESKTOP_LIMIT);

  var _useState = useState({
    slidesPerView: 0,
    currentIndex: 0
  }),
      carouselState = _useState[0],
      setCarouselState = _useState[1];

  var _useState2 = useState(0),
      lastTrackedItemPosition = _useState2[0],
      setLastTrackedItemPosition = _useState2[1];

  var _ref2 = carouselProps || {},
      slidesPerBreakpoint = _ref2.slidesPerBreakpoint,
      buttonOffset = _ref2.buttonOffset,
      showBullets = _ref2.showBullets;

  var recommendationsPerBreakpoint = slidesPerBreakpoint || getRecommenderTilesPerBreakpoint(tilesPerBreakpoint);
  var tilesWithPlaceholders = totalTiles > tiles.length ? tiles.concat(_toConsumableArray(Array(totalTiles - tiles.length).keys()).map(function (_, index) {
    return ___EmotionJSX(Box, {
      key: "product-placeholder-".concat(index),
      px: {
        xs: "2px",
        md: "10px"
      },
      width: "100%",
      height: "100%"
    }, ___EmotionJSX(ProductCardPlaceholder, {
      "aria-label": "Product card loading",
      uniqueKey: "tile-".concat(index),
      height: "100%"
    }));
  })) : tiles;
  var trackItemImpressions = useCallback(function () {
    var slidesPerView = carouselState.slidesPerView,
        currentIndex = carouselState.currentIndex;
    var itemsLength = trackingData.length;
    var visibleNumberOfSlides = slidesPerView > itemsLength ? itemsLength : slidesPerView;

    if (visibleNumberOfSlides > 0 && itemsLength > currentIndex) {
      Array.from({
        length: visibleNumberOfSlides
      }).forEach(function (_v, i) {
        var itemIndex = currentIndex + i;
        var item = trackingData[itemIndex];

        if (item && itemIndex >= lastTrackedItemPosition) {
          item.type == "event" ? emitEventImpression(_objectSpread({
            event_id: Number(item.id),
            source_type: item.sourceType || sourceType,
            position: itemIndex + 1,
            badge_text: item.badgeText || null,
            event_type: EVENT_RECOMMENDER_EVENT_TYPE,
            schedule_entry_id: null,
            subtitle: null,
            title: item.title
          }, fromProduct)) : (emitProductImpression(_objectSpread({
            offer_id: Number(item.offerId),
            source_id: sourceId || "Product page Recommendation",
            source_position: itemIndex + 1,
            source_type: item.sourceType || sourceType,
            product_id: Number(item.id),
            product_title: item.title,
            displayed_price: item.sellPrice ? centsToDollars(item.sellPrice) : null
          }, fromProduct)), sponsoredTrackingData !== null && sponsoredTrackingData !== void 0 && sponsoredTrackingData[item.id] ? emitTrackingPixel(sponsoredTrackingData[item.id].impressionTrackingUrl) : null);
          var trackedUpTo = currentIndex + visibleNumberOfSlides;

          if (trackedUpTo > lastTrackedItemPosition) {
            setLastTrackedItemPosition(trackedUpTo);
          }
        }
      });
    }
  }, [trackingData, fromProduct, sourceType, carouselState, lastTrackedItemPosition]);

  var _useIsInViewport = useIsInViewport({
    shouldTrackVisibility: !!trackingData,
    // Trigger impression events if 75% of the component is in the viewport on mobile
    threshold: isMobile ? 0.75 : 1,
    onComponentSeen: function onComponentSeen() {
      trackItemImpressions();
      emitRecommenderImpression(_objectSpread({
        source_type: sourceType,
        recommended_product_count: totalTiles
      }, fromProduct));
    }
  }),
      ref = _useIsInViewport.ref;

  useEffect(function () {
    // Only track impressions here after the first slide has been looked at
    // The first slide is tracked within onComponentSeen
    if (carouselState.currentIndex > 0) {
      trackItemImpressions();
    }
  }, [trackItemImpressions, carouselState.currentIndex]);
  return ___EmotionJSX(Carousel, _extends({}, carouselProps, {
    containerProps: _objectSpread(_objectSpread({}, (carouselProps === null || carouselProps === void 0 ? void 0 : carouselProps.containerProps) || {}), {}, {
      ref: ref
    }),
    slides: paginate ? tilesWithPlaceholders : tiles,
    slidesPerBreakpoint: recommendationsPerBreakpoint,
    buttonOffset: buttonOffset || [-40, -20],
    showBullets: !!showBullets,
    onSlideChange: function onSlideChange(_ref3) {
      var _carouselProps$onSlid;

      var slidesPerView = _ref3.slidesPerView,
          currentIndex = _ref3.currentIndex,
          direction = _ref3.direction;
      setCarouselState({
        slidesPerView: slidesPerView,
        currentIndex: currentIndex
      });
      carouselProps === null || carouselProps === void 0 ? void 0 : (_carouselProps$onSlid = carouselProps.onSlideChange) === null || _carouselProps$onSlid === void 0 ? void 0 : _carouselProps$onSlid.call(carouselProps, {
        slidesPerView: slidesPerView,
        currentIndex: currentIndex,
        direction: direction
      });
    },
    onCarouselInit: function onCarouselInit(slidesPerView) {
      setCarouselState({
        slidesPerView: slidesPerView,
        currentIndex: 0
      });
    }
  }));
};
export var RecommenderCarousel = function RecommenderCarousel(_ref4) {
  var heading = _ref4.heading,
      subHeading = _ref4.subHeading,
      wrapperProps = _ref4.wrapperProps,
      otherProps = _objectWithoutProperties(_ref4, _excluded);

  return ___EmotionJSX(Box, null, ___EmotionJSX(Box, _extends({
    maxWidth: "1340px",
    margin: "auto",
    position: "relative",
    overflow: "hidden",
    px: {
      xs: "0px",
      md: "80px"
    },
    mx: {
      xs: "-30px",
      md: "auto"
    },
    pb: "40px"
  }, wrapperProps), heading && ___EmotionJSX(Heading.h4, {
    textAlign: "center",
    mb: subHeading ? "0" : undefined
  }, heading), subHeading, ___EmotionJSX(BaseRecommenderCarousel, otherProps)));
};