import { IconFlybuysCardSingle, IconOnePassDeliveryTruck, IconOnePassReturns } from "@catchoftheday/cg-components";
import { COPY_BENEFIT_FLYBUYS_POINTS, COPY_BENEFIT_FREE_DELIVERY, COPY_BENEFIT_RETURNS } from "../../../consts";
export var BENEFITS = [{
  Icon: IconOnePassDeliveryTruck,
  text: COPY_BENEFIT_FREE_DELIVERY
}, {
  Icon: IconOnePassReturns,
  text: COPY_BENEFIT_RETURNS
}, {
  Icon: IconFlybuysCardSingle,
  text: COPY_BENEFIT_FLYBUYS_POINTS
}];