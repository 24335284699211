import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useRef } from "react";
import { useSearchTermRecommendedProductsQuery } from "@personalisation/generated/graphql";
import { emitRecommenderRequest, SOURCE_TYPE_SEARCH_TERM_RECOMMENDER } from "@catchoftheday/analytics";
import { Heading } from "@catchoftheday/cg-components";
import { RecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData, handleRecommenderQueryUpdate, useVisibleRecommenderTiles } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchTermRecommendedProducts = function SearchTermRecommendedProducts(_ref) {
  var searchTerm = _ref.searchTerm,
      sourceId = _ref.sourceId,
      isMobile = _ref.isMobile;
  var fromProduct = {
    from_product_ids: null,
    from_product_offer_id: null,
    from_product_displayed_price: null
  };
  var recommenderSourceType = SOURCE_TYPE_SEARCH_TERM_RECOMMENDER;
  var visibleTiles = useVisibleRecommenderTiles();
  var trackingVariables = useRef({
    offset: 0,
    limit: visibleTiles
  });

  var _useSearchTermRecomme = useSearchTermRecommendedProductsQuery({
    variables: {
      searchTerm: searchTerm,
      limit: visibleTiles,
      offset: 0
    },
    ssr: false,
    skip: !visibleTiles,
    notifyOnNetworkStatusChange: true,
    onCompleted: function onCompleted() {
      emitRecommenderRequest(_objectSpread({
        source_type: recommenderSourceType,
        page_offset: trackingVariables.current.offset,
        page_limit: trackingVariables.current.limit
      }, fromProduct));
    }
  }),
      data = _useSearchTermRecomme.data,
      error = _useSearchTermRecomme.error,
      loading = _useSearchTermRecomme.loading,
      fetchMore = _useSearchTermRecomme.fetchMore;

  if (!(data !== null && data !== void 0 && data.searchTermRecommendedProducts.products.length) || error) {
    return null;
  }

  var products = data.searchTermRecommendedProducts.products.map(function (product) {
    return _objectSpread(_objectSpread({}, product), {}, {
      sourceType: recommenderSourceType
    });
  });
  var _data$searchTermRecom = data.searchTermRecommendedProducts.pagination,
      totalCount = _data$searchTermRecom.totalCount,
      nextItemIndex = _data$searchTermRecom.nextItemIndex;
  var tiles = getProductTiles({
    products: products,
    sourceType: recommenderSourceType,
    sourceId: sourceId,
    fromProduct: fromProduct
  });
  var trackingData = getRecommenderTrackingData(products, "product");
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Heading.h2, {
    mx: 3,
    fontSize: {
      xs: "lg",
      md: "2xl"
    }
  }, "Recommended for you"), ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    sourceType: recommenderSourceType,
    sourceId: sourceId,
    trackingData: trackingData,
    fromProduct: fromProduct,
    paginate: true,
    maxTiles: totalCount,
    wrapperProps: {
      px: 0
    },
    carouselProps: {
      containerProps: {
        marginLeft: {
          xs: "30px",
          md: "-30px"
        },
        marginRight: {
          xs: "30px",
          md: "-30px"
        }
      },
      buttonOffset: [40, 0],
      showNavButtons: !isMobile,
      onSlideChange: function onSlideChange(_ref2) {
        var currentIndex = _ref2.currentIndex,
            slidesPerView = _ref2.slidesPerView,
            direction = _ref2.direction;
        var lastVisibleTile = currentIndex + slidesPerView - 1;

        if (direction === "forward" && !products[lastVisibleTile]) {
          trackingVariables.current = {
            offset: nextItemIndex,
            limit: slidesPerView
          };
          fetchMore({
            variables: {
              offset: nextItemIndex
            },
            updateQuery: function updateQuery(prevResult, _ref3) {
              var fetchMoreResult = _ref3.fetchMoreResult;
              return handleRecommenderQueryUpdate({
                prevResult: prevResult,
                fetchMoreResult: fetchMoreResult,
                recommenderKey: "searchTermRecommendedProducts"
              });
            }
          });
        }
      },
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading
    }
  }));
};