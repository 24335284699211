import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Image as _Image } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { ASSET_ONEPASS_MASCOT_HOLDING_DIVISIONS_LIST } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Image = styled(_Image)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & {\n    object-fit: contain;\n  }\n"])));
export var UpsellBoxSideImage = function UpsellBoxSideImage() {
  return ___EmotionJSX(Image, {
    src: ASSET_ONEPASS_MASCOT_HOLDING_DIVISIONS_LIST,
    alt: "OnePass' mascot holding a list of divisions",
    position: {
      xs: "static",
      md: "absolute"
    },
    top: 0,
    left: 0,
    width: "100%",
    height: {
      xs: "auto",
      md: "100%"
    },
    maxHeight: "480px"
  });
};