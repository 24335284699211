import React from "react";
import { Flex, IconOnePass, IconQuestionMark } from "@catchoftheday/cg-components";
import { triggerOnePassUpsellModal } from "@catchoftheday/state";
import { rem } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UnpaidFreeShippingCTA = function UnpaidFreeShippingCTA(_ref) {
  var upsellDestinationUrl = _ref.upsellDestinationUrl,
      containerProps = _ref.containerProps;
  return ___EmotionJSX(Flex, containerProps, ___EmotionJSX(IconOnePass, {
    color: "onePass.primary",
    width: "55px",
    height: "12px",
    pointerEvents: "none",
    role: "img",
    title: "OnePass",
    "aria-label": "OnePass",
    mr: rem("2px")
  }), ___EmotionJSX(IconQuestionMark, {
    color: "onePass.primary",
    width: "11px",
    height: "11px",
    cursor: "pointer",
    role: "button",
    title: "info",
    "aria-label": "info",
    mt: rem("1px"),
    onClick: function onClick() {
      return triggerOnePassUpsellModal({
        isFbcSfop: true,
        destinationUrl: upsellDestinationUrl
      });
    }
  }));
};