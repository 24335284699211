import React from "react";
import { Box, Image, Link, Span } from "@catchoftheday/cg-components";
import { APPLE_STORE_DOWNLOAD_IMG, GOOGLE_PLAY_DOWNLOAD_IMG } from "../../../consts/assets";
import { Card } from "./Card";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AppNudgeCard = function AppNudgeCard(_ref) {
  var iosAppLink = _ref.iosAppLink,
      androidAppLink = _ref.androidAppLink;
  return ___EmotionJSX(Card, null, ___EmotionJSX(Box, {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "onePass.primary",
    p: 3
  }, ___EmotionJSX(Span, {
    textAlign: "center",
    lineHeight: "tight",
    fontWeight: "bold",
    fontSize: "xl"
  }, "Be the first to know when deals go live."), ___EmotionJSX(Span, {
    textAlign: "center",
    fontWeight: "semibold",
    mb: 3
  }, "Download the Catch App and get notified"), ___EmotionJSX(Link, {
    title: "Download app from App Store",
    href: iosAppLink,
    mb: 3
  }, ___EmotionJSX(Image, {
    alt: "App Store Logo",
    src: APPLE_STORE_DOWNLOAD_IMG,
    width: "120px !important",
    height: "auto !important"
  })), ___EmotionJSX(Link, {
    title: "Download app from Google Play",
    href: androidAppLink
  }, ___EmotionJSX(Image, {
    alt: "Play Store Logo",
    src: GOOGLE_PLAY_DOWNLOAD_IMG,
    width: "120px !important",
    height: "auto !important"
  }))));
};