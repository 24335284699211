import React, { useCallback } from "react";
import { emitMigrationNudgeAction, emitMigrationNudgeImpression } from "@catchoftheday/analytics";
import { useIsInViewport } from "@catchoftheday/utilities";
import { MigrationNudge } from "../../pure/MigrationNudge";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MigrationNudgeContainer = function MigrationNudgeContainer(_ref) {
  var CTAUrl = _ref.CTAUrl;
  var trackImpressions = useCallback(function () {
    emitMigrationNudgeImpression();
  }, []);
  var trackCTAClicks = useCallback(function () {
    emitMigrationNudgeAction("proceed");
  }, []);

  var _useIsInViewport = useIsInViewport({
    onComponentSeen: trackImpressions
  }),
      ref = _useIsInViewport.ref;

  return ___EmotionJSX(MigrationNudge, {
    ref: ref,
    CTAUrl: CTAUrl,
    onCTAClick: trackCTAClicks
  });
};