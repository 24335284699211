import { Text } from "../Text";
export var Heading = {
  h1: Text.withComponent("h1", process.env.NODE_ENV === "production" ? {
    target: "e12cshkt0"
  } : {
    target: "e12cshkt0",
    label: "h1"
  }),
  h2: Text.withComponent("h2", process.env.NODE_ENV === "production" ? {
    target: "e12cshkt1"
  } : {
    target: "e12cshkt1",
    label: "h2"
  }),
  h3: Text.withComponent("h3", process.env.NODE_ENV === "production" ? {
    target: "e12cshkt2"
  } : {
    target: "e12cshkt2",
    label: "h3"
  }),
  h4: Text.withComponent("h4", process.env.NODE_ENV === "production" ? {
    target: "e12cshkt3"
  } : {
    target: "e12cshkt3",
    label: "h4"
  }),
  h5: Text.withComponent("h5", process.env.NODE_ENV === "production" ? {
    target: "e12cshkt4"
  } : {
    target: "e12cshkt4",
    label: "h5"
  }),
  h6: Text.withComponent("h6", process.env.NODE_ENV === "production" ? {
    target: "e12cshkt5"
  } : {
    target: "e12cshkt5",
    label: "h6"
  })
};
Heading.h1.defaultProps = {
  textStyle: "h1"
};
Heading.h1.displayName = "Heading.h1";
Heading.h2.defaultProps = {
  textStyle: "h2"
};
Heading.h2.displayName = "Heading.h2";
Heading.h3.defaultProps = {
  textStyle: "h3"
};
Heading.h3.displayName = "Heading.h3";
Heading.h4.defaultProps = {
  textStyle: "h4"
};
Heading.h4.displayName = "Heading.h4";
Heading.h5.defaultProps = {
  textStyle: "h5"
};
Heading.h5.displayName = "Heading.h5";
Heading.h6.defaultProps = {
  textStyle: "h6"
};
Heading.h6.displayName = "Heading.h6";
export var HeadingPropTable = Heading.h1;
HeadingPropTable.displayName = "Heading.h1";
export default Heading;