import React from "react";
import { Box, Button, Flex, Heading, LinkButton } from "@catchoftheday/cg-components";
import { OnePassModal } from "../OnePassModal";
import { LinkedModalContent, UnlinkedModalContent } from "./ModalContent";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LinkingModal = function LinkingModal(_ref) {
  var isOpen = _ref.isOpen,
      loading = _ref.loading,
      isLinked = _ref.isLinked,
      email = _ref.email,
      ctaUrl = _ref.ctaUrl,
      onClose = _ref.onClose,
      onProceed = _ref.onProceed;
  return ___EmotionJSX(OnePassModal, {
    isOpen: isOpen,
    loading: loading,
    onRequestClose: onClose
  }, ___EmotionJSX(Box, {
    fontFamily: "onePass",
    color: "onePass.textPrimary",
    visibility: loading ? "hidden" : "visible"
  }, ___EmotionJSX(Heading.h3, {
    fontWeight: "bold",
    color: "onePass.textPrimary",
    mt: 0,
    mb: 6
  }, isLinked ? "Account already linked" : "Link OnePass"), isLinked ? ___EmotionJSX(LinkedModalContent, {
    email: email
  }) : ___EmotionJSX(UnlinkedModalContent, {
    email: email
  }), ___EmotionJSX(Flex, {
    mt: 8,
    flexDirection: {
      xs: "column",
      md: "row"
    }
  }, ___EmotionJSX(LinkButton, {
    href: ctaUrl,
    variant: "onePassPrimary",
    flexBasis: "50%",
    sizeVariant: "md",
    fontWeight: "semibold",
    onClick: onProceed
  }, "Proceed"), ___EmotionJSX(Button, {
    variant: "plain",
    flexBasis: "50%",
    sizeVariant: "md",
    onClick: onClose,
    color: "onePass.primary",
    fontWeight: "semibold",
    underlineOnHover: true
  }, "Cancel"))));
};