import React, { useMemo } from "react";
import { Box, Modal, Text } from "@catchoftheday/cg-components";
import { date } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CouponDetailsModal = function CouponDetailsModal(_ref) {
  var isOpen = _ref.isOpen,
      displayName = _ref.displayName,
      code = _ref.code,
      toDate = _ref.toDate,
      _ref$salesRule = _ref.salesRule,
      shortDescription = _ref$salesRule.shortDescription,
      longDescription = _ref$salesRule.longDescription,
      handleModalClose = _ref.handleModalClose;
  var offerExpiryDate = useMemo(function () {
    var expiryDate = new Date(toDate);

    if (!date.isValid(expiryDate)) {
      return null;
    }

    return date.format(expiryDate, "hh:mmaaa dd/MM/yy");
  }, [toDate]);
  return ___EmotionJSX(Modal, {
    isOpen: isOpen,
    width: ["85%", null, "500px"],
    zIndex: 5,
    contentProps: {
      p: "20px"
    },
    onClose: handleModalClose,
    onRequestClose: handleModalClose,
    ariaHideApp: false
  }, ___EmotionJSX(Box, {
    p: "20px",
    mb: "15px",
    border: "1px dashed",
    borderColor: "ui.borderColor",
    borderRadius: "default"
  }, ___EmotionJSX(Box, {
    textAlign: "center",
    color: "secondary",
    mb: "14px"
  }, ___EmotionJSX(Text, {
    as: "span",
    fontWeight: "medium"
  }, displayName ? "Hey ".concat(displayName, ",") : "Hey!", " "), shortDescription && ___EmotionJSX(Text, {
    as: "span",
    fontWeight: "medium",
    textTransform: "lowercase"
  }, shortDescription, " ")), ___EmotionJSX(Text, {
    as: "div",
    mb: "14px",
    fontSize: "xl",
    fontWeight: "medium",
    textAlign: "center"
  }, "Use code", " ", ___EmotionJSX(Text, {
    as: "span",
    p: "5px",
    fontSize: "xl",
    fontWeight: "medium",
    textTransform: "uppercase",
    color: "secondary",
    border: "1px solid",
    borderRadius: "default",
    borderColor: "ui.borderColor"
  }, code), " ", "at checkout"), offerExpiryDate && ___EmotionJSX(Text, {
    mb: "0px",
    fontSize: "xs",
    textAlign: "center"
  }, "Offer valid until ".concat(offerExpiryDate))), longDescription && ___EmotionJSX(Text, {
    mb: "0px",
    fontSize: "2xs",
    color: "typography.subText"
  }, longDescription));
};