import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["navigationLinkColumns"],
    _excluded2 = ["title", "links"];
import React from "react";
import { Box, Link, List, ListItem } from "@catchoftheday/cg-components";
import { useIsSSR } from "@catchoftheday/utilities";
import { Body, Title } from "../primitives";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ColumnNavigation(_ref) {
  var navigationLinkColumns = _ref.navigationLinkColumns,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, _extends({
    as: "nav",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }, boxProps), navigationLinkColumns.map(function (_ref2) {
    var columnTitle = _ref2.columnTitle,
        links = _ref2.links;
    return ___EmotionJSX(Links, {
      key: columnTitle,
      title: columnTitle,
      links: links,
      width: "".concat(100 / navigationLinkColumns.length, "%"),
      paddingRight: "40px"
    });
  }));
}

var Links = function Links(_ref3) {
  var title = _ref3.title,
      links = _ref3.links,
      listProps = _objectWithoutProperties(_ref3, _excluded2);

  var isSSR = useIsSSR();
  return ___EmotionJSX(List, listProps, ___EmotionJSX(ListItem, null, ___EmotionJSX(Title, null, title)), links.map(function (_ref4) {
    var text = _ref4.text,
        url = _ref4.url,
        openInNewWindow = _ref4.openInNewWindow,
        isBrightEdgeAutoPilotEnabled = _ref4.isBrightEdgeAutoPilotEnabled;

    if (!isSSR && isBrightEdgeAutoPilotEnabled) {
      return ___EmotionJSX(ListItem, {
        key: text
      }, ___EmotionJSX(Box, {
        className: "be-ix-link-block"
      }));
    }

    return ___EmotionJSX(ListItem, {
      key: text,
      marginBottom: "5px"
    }, ___EmotionJSX(Link, {
      href: decodeURIComponent(url),
      target: openInNewWindow ? "_blank" : undefined,
      rel: openInNewWindow ? "noopener noreferrer" : undefined
    }, ___EmotionJSX(Body, {
      margin: 0
    }, text)));
  }));
};