import React from "react";
import { Heading, IconOnePass, LinkButton, Text } from "@catchoftheday/cg-components";
import { rem } from "@catchoftheday/theme";
import { URL_ONEPASS_PRIVACY_POLICY, URL_ONEPASS_TERMS_AND_CONDITIONS } from "../../../consts";
import { MigrationNudgeContentZone } from "../../layouts/MigrationNudgeLayout/MigrationNudgeLayout";
import { CTAButton } from "../CTAButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Content = function Content(_ref) {
  var CTAUrl = _ref.CTAUrl,
      onCTAClick = _ref.onCTAClick;
  return ___EmotionJSX(MigrationNudgeContentZone, null, ___EmotionJSX(IconOnePass, {
    "aria-label": "OnePass",
    color: "onePass.primary",
    height: rem("32px"),
    width: "auto",
    mb: 6
  }), ___EmotionJSX(Heading.h3, {
    fontWeight: "semibold",
    fontSize: {
      xs: "base",
      md: "2xl"
    },
    mb: 2,
    mt: 0,
    textAlign: "center"
  }, "Transfer your membership"), ___EmotionJSX(Text, {
    fontSize: {
      xs: "sm",
      md: "base"
    },
    fontWeight: "normal",
    mb: {
      xs: 4,
      md: 6
    },
    mt: 0,
    textAlign: "center"
  }, "OnePass members now get 5x Flybuys points* for every $1 spent in-store at Kmart, Target, Bunnings Warehouse and Officeworks."), ___EmotionJSX(LinkButton, {
    "data-testid": "migration-nudge-cta",
    variant: "onePassPrimary",
    fontSize: "xs",
    rounded: true,
    fontWeight: "semibold",
    onClick: onCTAClick,
    href: CTAUrl,
    maxWidth: rem("240px")
  }, "Transfer your membership"), ___EmotionJSX(Text, {
    fontSize: "xs",
    textAlign: "center",
    fontWeight: "semibold",
    color: "onePass.textGrey",
    my: 2
  }, ___EmotionJSX(CTAButton, {
    fontSize: "inherit",
    fontWeight: "inherit",
    href: URL_ONEPASS_TERMS_AND_CONDITIONS
  }, "Terms & Conditions"), " ", "and", " ", ___EmotionJSX(CTAButton, {
    fontSize: "inherit",
    fontWeight: "inherit",
    href: URL_ONEPASS_PRIVACY_POLICY
  }, "Privacy Policy")), ___EmotionJSX(Text, {
    fontSize: "xs",
    m: 0,
    color: "onePass.textGrey",
    fontWeight: "normal",
    textAlign: "center"
  }, "*T&Cs, exclusions apply. Alternative Flybuys offer for Catch."));
};