import { PageConfigurationDocument } from "@common-vendors/generated/graphql";
import { mockPageConfig } from "@common-vendors/mocks/data";
export var apolloMockPageConfig = {
  request: {
    query: PageConfigurationDocument
  },
  result: {
    data: {
      cmsConfiguration: {
        page: mockPageConfig
      }
    }
  }
};