import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { OnePassHelp } from "./OnePassHelp";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var InlineOnePassHelp = function InlineOnePassHelp() {
  return ___EmotionJSX(Box, {
    my: 8,
    display: {
      xs: "block",
      md: "none"
    }
  }, ___EmotionJSX(OnePassHelp, null));
};