import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { RECOMMENDER_EVENT_LIMIT } from "@personalisation/consts";
import { useRecommendedEventsV2Query } from "@personalisation/generated/graphql";
import { emitRecommenderRequest } from "@catchoftheday/analytics";
import { RecommenderCarousel } from "../RecommenderCarousel";
import { getEventTiles, getRecommenderTrackingData } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RecommendedEvents = function RecommendedEvents(_ref) {
  var productIds = _ref.productIds,
      fromProduct = _ref.fromProduct,
      sourceType = _ref.sourceType,
      sourceId = _ref.sourceId;

  var _useRecommendedEvents = useRecommendedEventsV2Query({
    variables: {
      productIds: productIds,
      limit: RECOMMENDER_EVENT_LIMIT
    },
    ssr: false,
    onCompleted: function onCompleted(_ref2) {
      var _ref2$recommendedEven = _ref2.recommendedEventsV2.metaData,
          preHydrationCount = _ref2$recommendedEven.preHydrationCount,
          postHydrationCount = _ref2$recommendedEven.postHydrationCount,
          hydrationErrorCount = _ref2$recommendedEven.hydrationErrorCount;
      emitRecommenderRequest(_objectSpread({
        source_type: sourceType,
        pre_hydration_product_count: preHydrationCount,
        post_hydration_product_count: postHydrationCount,
        hydration_error_count: hydrationErrorCount
      }, fromProduct));
    }
  }),
      data = _useRecommendedEvents.data,
      error = _useRecommendedEvents.error;

  if (!(data !== null && data !== void 0 && data.recommendedEventsV2.events.length) || error) {
    return null;
  }

  var events = data.recommendedEventsV2.events;
  var tiles = getEventTiles({
    events: events,
    sourceType: sourceType,
    fromProduct: fromProduct
  });
  var trackingData = getRecommenderTrackingData(events, "event");
  return ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    fromProduct: fromProduct,
    sourceType: sourceType,
    sourceId: sourceId,
    trackingData: trackingData,
    maxTiles: RECOMMENDER_EVENT_LIMIT,
    heading: "Also worth checking out...",
    tilesPerBreakpoint: {
      xs: 1,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3
    },
    carouselProps: {
      showBullets: true
    }
  });
};