import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { Box, Button, Flex, IconMpFreeShipping, IconTrash, Image, Link, List, ListItem, Span, Truncate } from "@catchoftheday/cg-components";
import { SalesRulePromotionTag } from "@catchoftheday/product-components";
import { currency } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ListItems = function ListItems(_ref) {
  var _ref$items = _ref.items,
      items = _ref$items === void 0 ? [] : _ref$items,
      onItemRemove = _ref.onItemRemove;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["promotion_visibility_web_aug_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      promotionVisibilityExperiment = _getExperiments$exper[0],
      promotionVisibilityExperimentLoading = _getExperiments.loading;

  var isPromotionVisibilityExperimentPhase2 = !promotionVisibilityExperimentLoading && promotionVisibilityExperiment && promotionVisibilityExperiment.bucket >= 2;
  return items && items.length > 0 ? ___EmotionJSX(List, {
    m: 0,
    itemSeparator: "1px solid",
    type: "none"
  }, (items === null || items === void 0 ? void 0 : items.length) && items.map(function (item, idx) {
    var _item$salesRulePromot;

    return ___EmotionJSX(ListItem, {
      key: "".concat(item.productName, "-").concat(idx),
      p: "13px 16px",
      mb: "0",
      borderBottom: idx != items.length - 1 ? "1px solid" : undefined,
      borderBottomColor: "ui.borderColor"
    }, ___EmotionJSX(Flex, {
      justifyContent: "space-between"
    }, ___EmotionJSX(Flex, null, ___EmotionJSX(Box, {
      border: 1,
      borderColor: "ui.borderColor",
      mr: "8px",
      p: 1
    }, ___EmotionJSX(Link, {
      href: item.link,
      tabIndex: -1,
      "data-tracking": "header-app-mini-cart-item-image"
    }, ___EmotionJSX(Image, {
      alt: item.productName,
      src: item.imageSrc,
      width: "64px",
      height: "52px",
      overflow: "hidden"
    }))), ___EmotionJSX(Box, {
      fontSize: "xs",
      maxWidth: "185px"
    }, ___EmotionJSX(Truncate, {
      lineHeight: "16px"
    }, ___EmotionJSX(Link, {
      href: item.link,
      color: "typography.text",
      fontWeight: 100,
      "data-tracking": "header-app-mini-cart-item-name"
    }, item.productName)), ___EmotionJSX(Box, {
      color: "typography.text",
      mt: "2px"
    }, ___EmotionJSX(Span, {
      fontSize: "inherit"
    }, "Qty: ", item.quantity, "x"), item.attributeValueText && ___EmotionJSX(Span, {
      fontSize: "inherit"
    }, "\xA0|\xA0 ", item.attributeName, ":\xA0", item.attributeValueText)), isPromotionVisibilityExperimentPhase2 && item.salesRulePromotion && ___EmotionJSX(Flex, {
      mt: 1,
      "data-testid": "mini-cart-item-sales-rule-promotion-tag"
    }, ___EmotionJSX(SalesRulePromotionTag, {
      salesRuleId: item.salesRulePromotion.salesRuleId.toString(),
      title: item.salesRulePromotion.label,
      disclaimerTitle: item.salesRulePromotion.disclaimerTitle,
      eventSlug: (_item$salesRulePromot = item.salesRulePromotion.event) === null || _item$salesRulePromot === void 0 ? void 0 : _item$salesRulePromot.slug,
      isApplied: item.salesRulePromotion.applied,
      sourceId: "mini_cart_tag",
      containerProps: {
        py: "2px",
        fontSize: "xs"
      },
      iconProps: {
        width: "12px",
        height: "12px"
      }
    })))), ___EmotionJSX(Box, null, ___EmotionJSX(Box, {
      position: "relative"
    }, ___EmotionJSX(Span, {
      pr: "8px",
      fontWeight: "semibold",
      verticalAlign: "middle",
      mr: "16px"
    }, "$", currency(item.rowTotalPrice).toString()), ___EmotionJSX(Box, {
      position: "absolute",
      top: -4,
      right: 0
    }, ___EmotionJSX(Button, {
      "aria-label": "Remove ".concat(item.productName),
      sizeVariant: "xs",
      variant: "plain",
      p: "1px",
      pb: "4px",
      display: "inline",
      "data-tracking": "header-app-mini-cart-remove-item",
      onClick: function onClick() {
        if (typeof onItemRemove == "function") {
          onItemRemove(item);
        }
      }
    }, ___EmotionJSX(IconTrash, {
      cursor: "pointer",
      hoverColor: "brand.secondary",
      width: "14px",
      height: "15px",
      color: "typography.subText"
    })))), item.freeShippingMarketPlace && ___EmotionJSX(Box, {
      color: "green",
      mt: "5px",
      textAlign: "right"
    }, ___EmotionJSX(IconMpFreeShipping, {
      width: "40px",
      height: "24px",
      "data-testid": "cart-item-mp-freeshipping-icon"
    })), !item.freeShippingMarketPlace && item.freeShippingClubCatch && ___EmotionJSX(Box, {
      color: "onePass.primary",
      mt: "5px",
      textAlign: "right"
    }, ___EmotionJSX(IconMpFreeShipping, {
      width: "40px",
      height: "30px",
      "data-testid": "cart-item-cc-freeshipping-icon"
    })))));
  })) : null;
};
export default ListItems;