import React, { useContext } from "react";
import { Button, Tag, Truncate } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { FacetAccordion } from "../FacetAccordion/FacetAccordion";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AppliedFacets = function AppliedFacets(_ref) {
  var appliedFacets = _ref.appliedFacets;

  var _useContext = useContext(CatalogueContext),
      removeFilterValue = _useContext.removeFilterValue,
      removeAllFilters = _useContext.removeAllFilters;

  return ___EmotionJSX(FacetAccordion, {
    id: "applied-filters",
    displayName: "Applied filters",
    totalApplied: appliedFacets.length,
    hideClearButton: true
  }, ___EmotionJSX(React.Fragment, null, appliedFacets.map(function (_ref2, index) {
    var key = _ref2.key,
        value = _ref2.value,
        displayName = _ref2.displayName;
    var filterKey = key === "f.group_id" ? "category" : "f.".concat(key);
    return ___EmotionJSX(Tag, {
      key: "".concat(key, "-").concat(index),
      onClick: function onClick() {
        removeFilterValue(filterKey, value);
      },
      m: "0 10px 10px 0",
      "aria-label": displayName
    }, ___EmotionJSX(Truncate, {
      fontWeight: "normal"
    }, displayName));
  }), ___EmotionJSX(Button, {
    variant: "secondary",
    sizeVariant: "sm",
    width: "100%",
    outline: true,
    onClick: function onClick() {
      return removeAllFilters();
    }
  }, "Clear All Filters")));
};