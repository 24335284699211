import React from "react";
import { Image } from "@catchoftheday/cg-components";
import { ASSET_WOMAN_HANDS_UP_WITH_ONEPASS_LIGHT_BG, ASSET_WOMAN_HANDS_UP_WITH_ONEPASS_LIGHT_BG_LANDSCAPE } from "../../../consts";
import { useIsMobile } from "../../../hooks";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MigrationModalSideImage = function MigrationModalSideImage() {
  var isMobile = useIsMobile();
  return ___EmotionJSX(Image, {
    width: {
      xs: "100%",
      md: "auto"
    },
    height: {
      xs: "auto",
      md: "100%"
    },
    src: isMobile ? ASSET_WOMAN_HANDS_UP_WITH_ONEPASS_LIGHT_BG_LANDSCAPE : ASSET_WOMAN_HANDS_UP_WITH_ONEPASS_LIGHT_BG,
    alt: "Woman raising hands up in the air with OnePass background"
  });
};