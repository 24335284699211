import React from "react";
import { Box, Button, Heading, Text } from "@catchoftheday/cg-components";
import { HOTLINE_ONEPASS } from "../../../consts";
import { OnePassModal } from "../OnePassModal";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CancellationModal = function CancellationModal(_ref) {
  var isOpen = _ref.isOpen,
      loading = _ref.loading,
      onClose = _ref.onClose,
      onConfirm = _ref.onConfirm;
  return ___EmotionJSX(OnePassModal, {
    isOpen: isOpen,
    onRequestClose: onClose,
    contentProps: {
      height: "auto"
    },
    loading: loading
  }, ___EmotionJSX(Box, {
    fontFamily: "onePass",
    color: "onePass.textPrimary",
    visibility: loading ? "hidden" : "visible"
  }, ___EmotionJSX(Heading.h3, {
    fontWeight: "bold",
    mt: 0,
    mb: {
      xs: 8,
      md: 6
    }
  }, "Important: Your OnePass Subscription"), ___EmotionJSX(Text, {
    mt: 0,
    mb: {
      xs: 12,
      md: 8
    },
    fontSize: "base",
    fontWeight: "medium"
  }, "Looks like you had two active subscriptions with OnePass.", ___EmotionJSX("br", null), ___EmotionJSX("br", null), "We've fixed this to ensure you will be billed correctly, so you can continue enjoying your benefits.", ___EmotionJSX("br", null), ___EmotionJSX("br", null), "If you have any further queries, please reach out to our customer service team ", HOTLINE_ONEPASS, "."), ___EmotionJSX(Button, {
    variant: "onePassPrimary",
    width: {
      xs: "100%",
      md: "50%"
    },
    onClick: onConfirm,
    fontWeight: "semibold"
  }, "Got it")));
};