import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["position", "isCOTD"];
import React, { useCallback, useContext } from "react";
import { AppExperimentContext, SOURCE_TYPE_SEARCH_TOP_SPONSORED_BANNER } from "@catchoftheday/analytics";
import { RETAIL_MEDIA_PRODUCT_INVENTORY_ID, RETAIL_MEDIA_SEARCH_INVENTORY_ID, RetailMediaBanner } from "@catchoftheday/seller";
import { useBreakpoint } from "@catchoftheday/theme";
import { useIsSSR } from "@catchoftheday/utilities";
import { usePageBannerQuery } from "../../../generated/graphql.jsx";
import { BannerPlaceholder } from "../../pure/Banners/BannerPlaceholder/BannerPlaceholder.jsx";
import { ScheduledBanner } from "../../pure/Banners/ScheduledBanner/ScheduledBanner.jsx";
import { getRetailMediaExperiment } from "./utils.js";
import { jsx as ___EmotionJSX } from "@emotion/react";
var commonLayoutProps = {
  pr: {
    _: 2,
    md: 0
  },
  mb: 4
};
export var BannerContainer = function BannerContainer(_ref) {
  var _data$bannerByPage;

  var position = _ref.position,
      isCOTD = _ref.isCOTD,
      bannerProps = _objectWithoutProperties(_ref, _excluded);

  var pageType = bannerProps.pageType;
  var isSSR = useIsSSR();

  var _useBreakpoint = useBreakpoint({
    minusOnePx: true
  }),
      isMobile = _useBreakpoint.isMobile;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments([getRetailMediaExperiment(pageType, position)]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      retailMediaExperiment = _getExperiments$exper[0],
      experimentsLoading = _getExperiments.loading;

  var shouldDisplayRetailMediaBanner = (retailMediaExperiment === null || retailMediaExperiment === void 0 ? void 0 : retailMediaExperiment.bucket) === 1 && isCOTD;

  var _usePageBannerQuery = usePageBannerQuery({
    variables: {
      input: {
        page: pageType
      }
    }
  }),
      data = _usePageBannerQuery.data,
      scheduledBannersLoading = _usePageBannerQuery.loading;

  var banners = data === null || data === void 0 ? void 0 : (_data$bannerByPage = data.bannerByPage) === null || _data$bannerByPage === void 0 ? void 0 : _data$bannerByPage.banners;
  var getBanners = useCallback(function (platform) {
    return (banners || []).filter(function (_ref2) {
      var platforms = _ref2.platforms;
      return platforms.includes(platform);
    });
  }, [banners]);
  if (isSSR) return null;
  if (scheduledBannersLoading || experimentsLoading) return ___EmotionJSX(BannerPlaceholder, commonLayoutProps); // #region Retail media banner

  if (shouldDisplayRetailMediaBanner && (!(banners !== null && banners !== void 0 && banners.length) || position === "bottom")) {
    return ___EmotionJSX(RetailMediaBanner, _extends({
      isMobile: isMobile,
      px: 0
    }, commonLayoutProps, bannerProps.pageType === "SEARCH" ? {
      inventoryId: RETAIL_MEDIA_SEARCH_INVENTORY_ID,
      searchQuery: bannerProps.searchQuery,
      sourceId: bannerProps.searchQuery,
      sourceType: SOURCE_TYPE_SEARCH_TOP_SPONSORED_BANNER,
      pageId: bannerProps.pageType.toLowerCase()
    } : {
      inventoryId: RETAIL_MEDIA_PRODUCT_INVENTORY_ID,
      sourceId: RETAIL_MEDIA_PRODUCT_INVENTORY_ID,
      sourceType: SOURCE_TYPE_SEARCH_TOP_SPONSORED_BANNER,
      offerId: bannerProps.offerId,
      category: bannerProps.category,
      pageId: bannerProps.pageType.toLowerCase()
    }));
  } // #endregion
  // Only retail media banners are supported for bottom position


  if (position === "bottom") return null; // #region Scheduled top banners

  if (isMobile) {
    return ___EmotionJSX(React.Fragment, null, getBanners("MOBILE").map(function (banner, idx) {
      return ___EmotionJSX(ScheduledBanner, _extends({
        key: banner.id,
        sourcePosition: idx + 1
      }, banner, commonLayoutProps));
    }));
  }

  return ___EmotionJSX(React.Fragment, null, getBanners("DESKTOP").map(function (banner, idx) {
    return ___EmotionJSX(ScheduledBanner, _extends({
      key: banner.id,
      sourcePosition: idx + 1
    }, banner, commonLayoutProps));
  })); // #endregion
};