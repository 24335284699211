import { trackSelfDescribingEvent } from "@catchoftheday/analytics";
export var MENU_CLICK_SCHEMA = "iglu:au.com.catch/menu_click/jsonschema/1-0-0";
export var MENU_IMPRESSION_SCHEMA = "iglu:au.com.catch/menu_impression/jsonschema/1-0-0";
export function trackMenuClick(_ref) {
  var name = _ref.name,
      url = _ref.url,
      item_path = _ref.item_path;
  trackSelfDescribingEvent({
    event: {
      schema: MENU_CLICK_SCHEMA,
      data: {
        name: name,
        url: url,
        item_path: item_path
      }
    }
  });
}
export function trackMenuImpression(_ref2) {
  var name = _ref2.name,
      item_path = _ref2.item_path;
  trackSelfDescribingEvent({
    event: {
      schema: MENU_IMPRESSION_SCHEMA,
      data: {
        name: name,
        item_path: item_path
      }
    }
  });
}