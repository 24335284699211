import { createSvgIconComponent } from "../utils";
export var IconFlybuysCardSingle = createSvgIconComponent({
  viewBox: "0 0 30 18",
  paths: [{
    d: "M.536 2.25C.536 1.007 1.572 0 2.85 0h24.3c1.278 0 2.314 1.007 2.314 2.25v13.5c0 1.243-1.036 2.25-2.314 2.25H2.85C1.572 18 .536 16.993.536 15.75V2.25Z",
    props: {
      fill: "#F0F0F0"
    }
  }, {
    d: "M13.018 5.25v1.883l.06-.026a1.7 1.7 0 0 1 .649-.128h.049c.943.027 1.7.822 1.7 1.8v.05c-.026.97-.8 1.75-1.75 1.75l-.048-.001c-.943-.027-1.7-.822-1.7-1.8V5.474l1.036-.223h.005Zm.709 2.695h-.04c-.425.022-.71.387-.71.834 0 .46.303.833.75.833h.039c.426-.022.711-.387.711-.833v-.04c-.018-.443-.316-.794-.75-.794ZM7.722 5.25l-.999.223v5.07h1.003V5.25h-.004Zm-2.102 0c-1.383 0-1.996.788-1.999 1.762v3.53h1.002V7.901h1.26v-.886h-1.26v-.003h-.007c.003-.487.336-.883 1.004-.883h.793v-.88H5.62Zm17.8 2.826c.007.432.338.727 1.01.9l.054.016c.67.193.949.273.949.522a.27.27 0 0 1-.087.2.606.606 0 0 1-.417.146c-.052 0-.508-.01-.548-.393l-.003-.035-.997.224c.165.635.864.922 1.534.922.508 0 .963-.157 1.247-.433a.96.96 0 0 0 .303-.723c-.007-.744-.73-.907-1.367-1.053-.44-.105-.638-.222-.643-.378 0-.141.134-.293.43-.293.358 0 .418.181.445.318l.006.037.98-.22c-.165-.625-.824-.854-1.423-.854-.853 0-1.472.461-1.472 1.097Zm-1.444-.836V8.9c0 .457-.223.711-.663.711-.44 0-.664-.254-.664-.71V7.014l-1.003.224v1.662c0 .99.67 1.678 1.667 1.678.241 0 .464-.063.663-.136v.609c0 .575-.34.732-.634.732h-.246l-.001.966h.267c.998-.01 1.617-.658 1.617-1.698V7.015l-1.003.225Zm-3.812 0V8.9c0 .457-.223.711-.663.711-.44 0-.663-.254-.663-.71V7.014l-1.004.224v1.662c0 .99.67 1.678 1.667 1.678s1.666-.688 1.666-1.678V7.015l-1.003.225Zm-7.655 0V8.9c0 .457-.223.711-.663.711-.44 0-.663-.254-.663-.71V7.014L8.18 7.24v1.662c0 .99.67 1.678 1.666 1.678.242 0 .465-.063.664-.136v.609c0 .575-.34.732-.635.732H9.63v.966h.266c.998-.01 1.617-.658 1.617-1.698V7.015l-1.004.225Z",
    props: {
      fill: "#007CC2"
    }
  }]
});