import React, { useContext } from "react";
import { Heading, Modal, Text } from "@catchoftheday/cg-components";
import { GlobalContext } from "@common-vendors/contexts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SponsoredDisclaimerModal = function SponsoredDisclaimerModal() {
  var _useContext = useContext(GlobalContext),
      _useContext$sponsored = _useContext.sponsoredModal,
      isSponsoredModalOpen = _useContext$sponsored.isSponsoredModalOpen,
      setIsSponsoredModalOpen = _useContext$sponsored.setIsSponsoredModalOpen;

  return ___EmotionJSX(Modal, {
    isOpen: isSponsoredModalOpen,
    onRequestClose: function onRequestClose() {
      return setIsSponsoredModalOpen(false);
    },
    shouldDisableBodyScrolling: true,
    maxWidth: "650px"
  }, ___EmotionJSX(Heading.h4, null, "Why are you seeing this?"), ___EmotionJSX(Text, null, "Sponsored by an advertiser based on your search or the product you are viewing."));
};