import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["active", "direction", "transitionDuration", "children"];

var _templateObject, _templateObject2, _templateObject3;

import React, { useEffect, useRef } from "react";
import { Box } from "@catchoftheday/cg-components";
import { keyframes, styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var slideLeft = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    0% { opacity: 0; transform: translateX(100%); }\n    100% { opacity: 1; transform: translateX(0); }\n"])));
var slideRight = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    0% { opacity: 0; transform: translateX(-100%);  }\n    100% { opacity: 1; transform: translateX(0); }\n"])));
var TransitionDiv = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  overflow-y: scroll;\n  max-height: 100%;\n  animation: ", "\n    300ms ease;\n"])), function (_ref) {
  var active = _ref.active,
      direction = _ref.direction;
  return active && direction === "left" ? slideLeft : active && direction === "right" ? slideRight : "none";
});
export var MenuTransitionContainer = function MenuTransitionContainer(_ref2) {
  var active = _ref2.active,
      direction = _ref2.direction,
      _ref2$transitionDurat = _ref2.transitionDuration,
      transitionDuration = _ref2$transitionDurat === void 0 ? 300 : _ref2$transitionDurat,
      children = _ref2.children,
      props = _objectWithoutProperties(_ref2, _excluded);

  var contentContainer = useRef(null);
  useEffect(function () {
    if (contentContainer.current !== null && active) {
      contentContainer.current.scrollTop = 0;
    }
  }, [active]);
  return ___EmotionJSX(TransitionDiv, _extends({
    ref: contentContainer,
    active: active,
    direction: direction,
    transitionDuration: transitionDuration
  }, props), children);
};