import React from "react";
import { Flex, Span, StarRating } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Rating = function Rating(_ref) {
  var rating = _ref.rating,
      ratingCount = _ref.ratingCount;
  return ___EmotionJSX(Flex, {
    alignItems: "center",
    mt: 2
  }, ___EmotionJSX(StarRating, {
    rating: rating,
    width: 80
  }), ___EmotionJSX(Span, {
    fontSize: "xs",
    color: "textTertiary",
    m: 0,
    ml: 1,
    mt: 1
  }, "(", ratingCount, ")"));
};