import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { DesktopHeaderZone, HeadingZone, MobileHeaderZone, MobileStickyHeader, SortOptionZone } from "../../layouts/PageLayout/PageLayout";
import { PageTitle } from "../../pure/Header/PageTitle/PageTitle";
import { RelatedSearches } from "../../pure/Header/RelatedSearches/RelatedSearches";
import { ToggleFacetsButton } from "../../pure/Header/ToggleFacetsButton/ToggleFacetsButton";
import { SearchSortOptions } from "../../pure/SearchSortOptions/SearchSortOptions";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderContainer = function HeaderContainer(_ref) {
  var _ref$pageTitle = _ref.pageTitle,
      pageTitle = _ref$pageTitle === void 0 ? "" : _ref$pageTitle,
      sortOptions = _ref.sortOptions,
      _ref$totalFacetsAppli = _ref.totalFacetsApplied,
      totalFacetsApplied = _ref$totalFacetsAppli === void 0 ? 0 : _ref$totalFacetsAppli,
      banner = _ref.banner,
      breadcrumbs = _ref.breadcrumbs,
      relatedSearches = _ref.relatedSearches;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["constructor_related_searches_web_nov_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      relatedSearchExperiment = _getExperiments$exper[0];

  var showRelatedSearches = (relatedSearchExperiment === null || relatedSearchExperiment === void 0 ? void 0 : relatedSearchExperiment.bucket) === 1 && relatedSearches && relatedSearches.length > 0; // Toggle between mobile and desktop headers as they have different layouts and orderings of components

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MobileStickyHeader, null, ___EmotionJSX(ToggleFacetsButton, {
    totalFacetsApplied: totalFacetsApplied
  }), sortOptions && sortOptions.length > 0 && ___EmotionJSX(SortOptionZone, null, ___EmotionJSX(SearchSortOptions, {
    options: sortOptions
  }))), ___EmotionJSX(MobileHeaderZone, null, banner && ___EmotionJSX(React.Fragment, null, banner), breadcrumbs && ___EmotionJSX(React.Fragment, null, breadcrumbs), ___EmotionJSX(HeadingZone, null, ___EmotionJSX(PageTitle, {
    title: pageTitle
  })), showRelatedSearches && ___EmotionJSX(RelatedSearches, {
    relatedSearches: relatedSearches
  })), ___EmotionJSX(DesktopHeaderZone, null, banner && ___EmotionJSX(React.Fragment, null, banner), breadcrumbs && ___EmotionJSX(React.Fragment, null, breadcrumbs), ___EmotionJSX(HeadingZone, null, ___EmotionJSX(PageTitle, {
    title: pageTitle
  }), ___EmotionJSX(ToggleFacetsButton, {
    totalFacetsApplied: totalFacetsApplied
  }), sortOptions && sortOptions.length > 0 && ___EmotionJSX(SortOptionZone, null, ___EmotionJSX(SearchSortOptions, {
    options: sortOptions
  }))), showRelatedSearches && ___EmotionJSX(RelatedSearches, {
    relatedSearches: relatedSearches
  })));
};