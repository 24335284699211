import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconTickCircle = createSvgIconComponent({
  viewBox: "0 0 16 17",
  children: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("circle", {
    cx: "8",
    cy: "8.5",
    r: "8",
    fill: "currentColor"
  }), ___EmotionJSX("path", {
    stroke: "#fff",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "m4 9.5 2.8 2 4.2-6"
  }))
});