import React, { useCallback, useContext } from "react";
import { emitUpsellNudgeAction } from "@catchoftheday/analytics";
import { Box, IconOnePass, Text } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { triggerOnePassUpsellModal } from "@catchoftheday/state";
import { COPY_ONEPASS_DIVISIONS_STRING, DEFAULT_FREE_TRIAL_DAYS } from "../../../consts";
import { OnePassConfigurationContext } from "../../../contexts";
import { isMembershipFreeTrialEligible } from "../../../utilities";
import { MemberBenefitsCarousel } from "../../pure/MemberBenefitsCarousel";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MemberBenefitsContainer = function MemberBenefitsContainer() {
  var _membership$subscript;

  var _useContext = useContext(OnePassConfigurationContext),
      onePassConfig = _useContext.data;

  var trialLength = onePassConfig.trialLength,
      priceMonthly = onePassConfig.priceMonthly,
      priceYearly = onePassConfig.priceYearly;

  var _useContext2 = useContext(CustomerContext),
      customerData = _useContext2.data,
      loading = _useContext2.loading;

  var _ref = customerData || {},
      club = _ref.club,
      membership = _ref.membership;

  var isFreeTrialEligible = isMembershipFreeTrialEligible(customerData);
  var handleUpsellCTAClick = useCallback(function () {
    triggerOnePassUpsellModal();
    emitUpsellNudgeAction(isFreeTrialEligible ? "freeTrial" : "join");
  }, [isFreeTrialEligible]);
  if (loading || club !== null && club !== void 0 && club.active || membership !== null && membership !== void 0 && (_membership$subscript = membership.subscription) !== null && _membership$subscript !== void 0 && _membership$subscript.active) return null;
  return ___EmotionJSX(Box, {
    py: {
      xs: 6,
      md: 9
    },
    px: {
      xs: 3,
      md: 10
    },
    backgroundColor: "#F4E7FF",
    "data-testid": "banner-memberBenefitsCarousel"
  }, ___EmotionJSX(Box, {
    textAlign: "center"
  }, ___EmotionJSX(IconOnePass, {
    "aria-label": "OnePass",
    color: "onePass.primary",
    height: {
      xs: "1rem",
      md: "1.5rem"
    },
    width: "auto"
  })), ___EmotionJSX(Text, {
    color: "onePass.textPrimary",
    textAlign: "center",
    fontWeight: "semibold",
    fontSize: {
      xs: "sm",
      md: "base"
    },
    mb: {
      xs: 6,
      md: 9
    },
    mt: {
      md: 2
    }
  }, "Your pass to free delivery on thousands of eligible items or orders across ", COPY_ONEPASS_DIVISIONS_STRING, "."), ___EmotionJSX(MemberBenefitsCarousel, {
    isFreeTrialEligible: isFreeTrialEligible,
    freeTrialLength: trialLength !== null && trialLength !== void 0 ? trialLength : DEFAULT_FREE_TRIAL_DAYS,
    monthlyOnePassPrice: priceMonthly === null || priceMonthly === void 0 ? void 0 : priceMonthly.amount,
    yearlyOnePassPrice: priceYearly === null || priceYearly === void 0 ? void 0 : priceYearly.amount,
    onCTAClick: handleUpsellCTAClick
  }));
};