import React from "react";
import { Flex, IconAlert, IconTick, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NotificationToastContent = function NotificationToastContent(_ref) {
  var message = _ref.message,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? "success" : _ref$type;
  var Icon = type === "success" ? IconTick : IconAlert;
  return ___EmotionJSX(Flex, {
    color: type === "success" ? "primary" : "error",
    alignItems: "center"
  }, ___EmotionJSX(Icon, {
    mr: 3,
    width: "1.25rem",
    "aria-hidden": "true"
  }), ___EmotionJSX(Text, {
    mb: 0,
    mt: 0,
    fontWeight: "bold"
  }, message));
};