import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { IconBellRinging, IconMemberDiscount, IconOnePassDelivery, IconOnePassTags } from "@catchoftheday/cg-components";
import { COPY_ONEPASS_DIVISIONS_STRING } from "./copy";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sharedIconProps = {
  role: "img",
  width: "auto",
  color: "onePass.primaryDark"
};

var FreeDeliveryIcon = ___EmotionJSX(IconOnePassDelivery, _extends({
  "aria-label": "Free delivery",
  height: {
    xs: "36px",
    lg: "52px"
  }
}, sharedIconProps));

var MemberDiscountIcon = ___EmotionJSX(IconMemberDiscount, _extends({
  "aria-label": "Member only pricing",
  height: {
    xs: "40px",
    lg: "60px"
  }
}, sharedIconProps));

var ExclusiveDealsIcon = ___EmotionJSX(IconOnePassTags, _extends({
  "aria-label": "Exclusive deals",
  height: {
    xs: "40px",
    lg: "60px"
  }
}, sharedIconProps));

var EarlyAlertsIcon = ___EmotionJSX(IconBellRinging, _extends({
  "aria-label": "Early alerts",
  height: {
    xs: "40px",
    lg: "52px"
  }
}, sharedIconProps));

export var UPSELL_MEMBER_BENEFITS_LIST = [{
  icon: FreeDeliveryIcon,
  title: "Free delivery",
  desc: "On eligible items or orders at ".concat(COPY_ONEPASS_DIVISIONS_STRING)
}, {
  icon: MemberDiscountIcon,
  title: "Member only prices",
  desc: "Member prices on thousands of products at Catch"
}, {
  icon: ExclusiveDealsIcon,
  title: "Exclusive deals",
  desc: "Access to exclusive weekly deals at Catch"
}, {
  icon: EarlyAlertsIcon,
  title: "Early alerts",
  desc: "Get priority alerts to the best deals at Catch"
}];