import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Button, IconCross } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CloseButton = function CloseButton(props) {
  return ___EmotionJSX(Button, _extends({
    "aria-label": "Close dropdown",
    variant: "plain",
    position: "absolute",
    right: 5,
    top: 5,
    padding: 0,
    minWidth: "0px !important",
    lineHeight: 1,
    zIndex: 1
  }, props), ___EmotionJSX(IconCross, {
    width: 14,
    height: 14,
    cursor: "pointer"
  }));
};