import { graphql } from "msw";
import { recommendedEvents } from "./RecommendedEvents.mock";
export var handler = graphql.query("recommendedEventsV2", function (_request, response, context) {
  return response(context.data({
    __typename: "Query",
    recommendedEventsV2: {
      __typename: "RecommendedEventsV2Response",
      events: recommendedEvents,
      metaData: {
        hydrationErrorCount: 0,
        preHydrationCount: 1,
        postHydrationCount: 1,
        __typename: "RecommenderMetaData"
      }
    }
  }));
});