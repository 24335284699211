import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["uniqueKey"];
import React from "react";
import { Box, ContentPlaceHolder } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MiniProductCardPlaceholder = function MiniProductCardPlaceholder(_ref) {
  var uniqueKey = _ref.uniqueKey,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, props, ___EmotionJSX(ContentPlaceHolder, {
    width: "100%",
    viewBox: "0 0 350 130",
    speed: 2,
    backgroundColor: "#f3f3f3",
    foregroundColor: "#ecebeb",
    uniqueKey: uniqueKey
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    rx: "3",
    ry: "3",
    width: "156",
    height: "130"
  }), ___EmotionJSX("rect", {
    x: "170",
    y: "0",
    rx: "3",
    ry: "3",
    width: "150",
    height: "11"
  }), ___EmotionJSX("rect", {
    x: "170",
    y: "20",
    rx: "3",
    ry: "3",
    width: "120",
    height: "11"
  }), ___EmotionJSX("rect", {
    x: "170",
    y: "50",
    rx: "3",
    ry: "3",
    width: "60",
    height: "11"
  }), ___EmotionJSX("rect", {
    x: "170",
    y: "80",
    rx: "3",
    ry: "3",
    width: "92",
    height: "42"
  })));
};