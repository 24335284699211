import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Flex, Heading } from "@catchoftheday/cg-components";
import { keyframes, styled, themeGet } from "@catchoftheday/theme";
import { CategoryMenu } from "../CategoryMenu";
import { jsx as ___EmotionJSX } from "@emotion/react";
var TRANSITION_IN_DURATION = 300;
var TRANSITION_OUT_DURATION = 200; // shorter duration prevents timing conflicts with transition-in

var slideIn = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    0% { opacity: 0; left: -300px; }\n    100% { opacity: 1; left: 0px; }\n"])));
var slideOut = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    0% { opacity: 1; left: 0px; }\n    100% { opacity: 0; left: -300px; }\n"])));
var fadeIn = keyframes(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    0% { opacity: 0; }\n    100% { opacity: 1; }\n"])));
var fadeOut = keyframes(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    0% { opacity: 1; }\n    100% { opacity: 0; }\n"])));
var MenuContainer = styled(Flex)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: fixed;\n  flex-direction: column;\n  top: 0px;\n  left: 0px;\n  width: 300px;\n  height: 100vh;\n  padding-top: 16px;\n  background-color: ", ";\n  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);\n  z-index: 101;\n  animation: ", "\n    ", "ms ease;\n"])), function (props) {
  return themeGet("colors.ui.backgroundColorSecondary")(props);
}, function (props) {
  return props.open ? slideIn : slideOut;
}, TRANSITION_IN_DURATION);
var Overlay = styled(Box)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.6);\n  z-index: 100;\n  animation: ", "\n    ", "ms ease;\n"])), function (props) {
  return props.open ? fadeIn : fadeOut;
}, TRANSITION_IN_DURATION);
export var SlideInMenu = function SlideInMenu(_ref) {
  var shops = _ref.shops,
      hideMenu = _ref.hideMenu;

  var _useState = useState("open"),
      triggerAnimation = _useState[0],
      setTriggerAnimation = _useState[1];

  var currentScrollPosition = useRef();
  var handleMenuClose = useCallback(function () {
    setTriggerAnimation("close");
  }, [setTriggerAnimation]);
  useEffect(function () {
    var closingAnimationTrigger = setTimeout(function () {
      if (triggerAnimation === "close") {
        hideMenu();
      }
    }, TRANSITION_OUT_DURATION);
    return function () {
      return clearTimeout(closingAnimationTrigger);
    };
  }, [triggerAnimation, hideMenu]);
  useEffect(function () {
    // Should not be scrollable while the popup is active
    if (true) {
      currentScrollPosition.current = document.documentElement.scrollTop;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.overflow = "hidden";
      return function () {
        window.scrollTo(0, currentScrollPosition.current || 0);
        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("position");
        document.body.style.removeProperty("width");
      };
    }
  }, []);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Overlay, {
    open: triggerAnimation === "open",
    onClick: handleMenuClose,
    "aria-label": "Menu overlay"
  }), ___EmotionJSX(MenuContainer, {
    open: triggerAnimation === "open",
    "aria-label": "Side navigation category menu"
  }, ___EmotionJSX(Flex, {
    mb: "14px",
    p: "0px 16px 0px 12px",
    alignItems: "center"
  }, ___EmotionJSX(Box, {
    flex: "1"
  }, ___EmotionJSX(Heading.h3, {
    fontSize: "lg",
    mb: "0px",
    mt: "0px"
  }, "Categories")), ___EmotionJSX(Box, {
    fontWeight: "normal",
    fontSize: "sm",
    color: "secondary",
    cursor: "pointer",
    "aria-label": "Close menu",
    onClick: handleMenuClose
  }, "Close")), ___EmotionJSX(CategoryMenu, {
    shops: shops
  })));
};