import React from "react";
import Head from "next/head";
import { URL_CATCH_LOGO_SEO } from "@catchoftheday/common-vendors";
import { cleanHostName } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SEO = function SEO(_ref) {
  var host = _ref.host;
  var prettyDomain = host && cleanHostName(host, true);
  return ___EmotionJSX(Head, null, ___EmotionJSX("title", null, "Transfer your OnePass subscription for access to all participating brands", prettyDomain ? " | ".concat(prettyDomain) : ""), ___EmotionJSX("meta", {
    name: "description",
    content: "The OnePass membership provider is changing from Catch to Wesfarmers to bring you more benefits. Follow the steps to securely transfer your subscription to the new OnePass."
  }), ___EmotionJSX("meta", {
    property: "og:type",
    content: "website"
  }), ___EmotionJSX("meta", {
    property: "og:site_name",
    content: prettyDomain
  }), ___EmotionJSX("meta", {
    property: "og:image",
    content: URL_CATCH_LOGO_SEO
  }), ___EmotionJSX("meta", {
    property: "og:locale",
    content: "en_AU"
  }));
};