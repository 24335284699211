import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { ContentPlaceHolder } from "../ContentPlaceHolder";
/** Useful for minicart item, wishlist item, etc */

import { jsx as ___EmotionJSX } from "@emotion/react";
export var ContentPlaceHolderPresetListItem = function ContentPlaceHolderPresetListItem(props) {
  return ___EmotionJSX(ContentPlaceHolder, _extends({
    width: "400",
    height: "52",
    viewBox: "0 0 400 52"
  }, props), ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    rx: "5",
    ry: "5",
    width: "64",
    height: "52"
  }), ___EmotionJSX("rect", {
    x: "80",
    y: "0",
    rx: "4",
    ry: "4",
    width: "250",
    height: "13"
  }), ___EmotionJSX("rect", {
    x: "80",
    y: "20",
    rx: "4",
    ry: "4",
    width: "250",
    height: "13"
  }), ___EmotionJSX("rect", {
    x: "340",
    y: "0",
    rx: "4",
    ry: "4",
    width: "60",
    height: "13"
  }));
};