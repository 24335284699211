import React from "react";
import { Box, LoadingSpinner as CGLoadingSpinner } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LoadingSpinner = function LoadingSpinner() {
  return ___EmotionJSX(Box, {
    borderRadius: 1,
    position: "fixed",
    p: 2,
    left: "50%",
    top: "50%",
    m: "-39px 0 0 -30px",
    backgroundColor: "white",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    zIndex: 14
  }, ___EmotionJSX(CGLoadingSpinner, {
    ringBg: "ui.borderColor",
    role: "progressbar"
  }));
};