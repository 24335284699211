export var RECOMMENDER_DESKTOP_LIMIT = 30;
export var RECOMMENDER_MOBILE_LIMIT = 20;
export var RECOMMENDER_EVENT_LIMIT = 20;
export var ONE_RECOMMENDER_LIMIT = 18;
export var RECENTLY_VIEWED_STORAGE_KEY = "recently-viewed-products";
export var BRAND_SLUGS_WITH_RESTRICTED_RECOMMENDERS = ["apple"]; // 2440: Dyson

export var SELLER_IDS_WITH_RESTRICTED_RECOMMENDERS = ["2440"]; //One Recommender

export var ONE_RECOMMENDER_HEADING = "More for you"; // Allows us to limit the amount of sponsored products requested on a per-breakpoint basis

export var SPONSORED_PRODUCTS_MULTIPLIER = 5;