import React from "react";
import { NOTIFICATION_TOAST_CONTAINER_ID } from "@header/consts";
import { NotificationToastContainer } from "../NotificationToast/components";
import { useFlybuysNotification, useOnePassNotification } from "./hooks";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NotificationToast = function NotificationToast() {
  useOnePassNotification();
  useFlybuysNotification();
  return ___EmotionJSX(NotificationToastContainer, {
    containerId: NOTIFICATION_TOAST_CONTAINER_ID
  });
};