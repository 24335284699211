import React from "react";
import Head from "next/head";
import { URL_CATCH_LOGO_SEO } from "@catchoftheday/common-vendors";
import { cleanHostName } from "@catchoftheday/utilities";
import { ONEPASS_SHOP_ID } from "../../../consts";
import { useShopDataQuery } from "../../../generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopPageSEOContainer = function ShopPageSEOContainer(_ref) {
  var host = _ref.host;

  var _useShopDataQuery = useShopDataQuery({
    variables: {
      shopId: ONEPASS_SHOP_ID.toString()
    }
  }),
      data = _useShopDataQuery.data;

  var _ref2 = data || {},
      shop = _ref2.shop;

  var _ref3 = shop || {},
      metaTitle = _ref3.metaTitle,
      metaDescription = _ref3.metaDescription;

  var prettyDomain = host && cleanHostName(host, true);
  return ___EmotionJSX(Head, null, metaTitle && ___EmotionJSX("title", null, metaTitle, prettyDomain ? " | ".concat(prettyDomain) : ""), metaDescription && ___EmotionJSX("meta", {
    name: "description",
    content: metaDescription
  }), ___EmotionJSX("meta", {
    property: "og:type",
    content: "website"
  }), ___EmotionJSX("meta", {
    property: "og:site_name",
    content: prettyDomain
  }), ___EmotionJSX("meta", {
    property: "og:image",
    content: URL_CATCH_LOGO_SEO
  }), ___EmotionJSX("meta", {
    property: "og:locale",
    content: "en_AU"
  }));
};