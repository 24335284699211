import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["numberOfItems"];
import React from "react";
import { Box, ContentPlaceHolder, ContentPlaceHolderPresetListItem } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ListItemPlaceHolder = function ListItemPlaceHolder(_ref) {
  var _ref$numberOfItems = _ref.numberOfItems,
      numberOfItems = _ref$numberOfItems === void 0 ? 3 : _ref$numberOfItems,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ContentPlaceHolder, {
    width: "368",
    height: "13",
    viewBox: "0 0 400 13"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    rx: "5",
    ry: "5",
    width: "90",
    height: "13"
  })), Array(numberOfItems).fill(0).map(function (_, idx) {
    return ___EmotionJSX(Box, _extends({
      key: idx,
      width: "100%"
    }, boxProps), ___EmotionJSX(ContentPlaceHolderPresetListItem, {
      width: "368"
    }));
  }));
};
export default ListItemPlaceHolder;