import React from "react";
import { Box, Flex, Heading, Link, Text } from "@catchoftheday/cg-components";
import { HOTLINE_ONEPASS } from "../../../../consts";
import { FAQsList } from "./FAQsList";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FAQs = function FAQs() {
  return ___EmotionJSX(Box, {
    pb: 8
  }, ___EmotionJSX(Flex, {
    flexDirection: "column"
  }, ___EmotionJSX(Heading.h2, {
    color: "onePass.textPrimary",
    fontSize: {
      xs: "xl",
      md: "3xl"
    },
    fontWeight: 700,
    m: 0,
    pb: 2,
    borderBottom: "1px solid #D7D8E0"
  }, "FAQs"), ___EmotionJSX(FAQsList, null), ___EmotionJSX(Heading.h3, {
    mt: 8,
    mb: {
      xs: 1,
      md: 2
    },
    pt: {
      xs: 0,
      md: 8
    },
    fontSize: {
      xs: "base",
      md: "xl"
    },
    borderTop: {
      md: "1px solid #D7D8E0"
    },
    fontWeight: "bold",
    color: "onePass.textPrimary"
  }, "Have more questions?"), ___EmotionJSX(Text, {
    m: 0,
    color: "textPrimary",
    fontSize: "base",
    fontWeight: "medium"
  }, "Call our friendly priority customer service at", " ", ___EmotionJSX(Link, {
    color: "onePass.primary",
    hoverColor: "onePass.primaryDark",
    href: "tel:".concat(HOTLINE_ONEPASS),
    textDecoration: "underline",
    fontWeight: "bold"
  }, HOTLINE_ONEPASS))));
};