import React from "react";
import { Span, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LinkedModalContent = function LinkedModalContent(_ref) {
  var email = _ref.email;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Text, {
    fontSize: "base",
    fontWeight: "medium",
    m: 0
  }, "You are currently logged in at Catch with", " ", ___EmotionJSX(Span, {
    fontWeight: "bold"
  }, email), ". This Catch account is already linked to a different OnePass account.", ___EmotionJSX("br", null), ___EmotionJSX("br", null), "Would you like to replace the current linking?"));
};
export var UnlinkedModalContent = function UnlinkedModalContent(_ref2) {
  var email = _ref2.email;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Text, {
    fontSize: "base",
    fontWeight: "medium",
    mb: 6
  }, "We will link your OnePass and Catch accounts so you can access your member benefits at Catch. You are currently logged in at Catch with", " ", ___EmotionJSX(Span, {
    fontWeight: "bold"
  }, email)), ___EmotionJSX(Text, {
    fontSize: "xs",
    fontWeight: "medium",
    m: 0
  }, "OnePass and the participating brands collect your purchasing and browsing history from each other and may provide you with personalised communications and advertising."));
};