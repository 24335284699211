import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["searchTerm"];

var _templateObject;

import React from "react";
import { SOURCE_TYPE_CONSTRUCTOR_SEARCH } from "@catchoftheday/analytics";
import { Flex, IconDownArrow, Link } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { SEARCH_PAGE_PATH } from "../../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
var IconRightArrow = styled(IconDownArrow)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transform: rotate(270deg);\n"])));
export var ViewAllLink = function ViewAllLink(_ref) {
  var searchTerm = _ref.searchTerm,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Link, _extends({
    position: "absolute",
    right: "0px",
    bottom: "10px",
    href: "".concat(SEARCH_PAGE_PATH, "?query=").concat(searchTerm, "&st=").concat(SOURCE_TYPE_CONSTRUCTOR_SEARCH),
    fontSize: "sm",
    fontWeight: "light",
    textDecoration: "underline"
  }, props), ___EmotionJSX(Flex, {
    cursor: "pointer",
    alignItems: "center"
  }, "View all results", ___EmotionJSX(IconRightArrow, {
    height: 5
  })));
};