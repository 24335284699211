import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import shouldForwardProp from "@styled-system/should-forward-prop";
import { color, compose, display, hideVisually, layout, position, size, space, styled, textStyle, themeGet, typography } from "@catchoftheday/theme";
var customProps = compose(space, display, layout, position, color, typography, size);
export var Label = styled("label", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n"])), textStyle, customProps, function (props) {
  return props.hidden && hideVisually();
}, function (props) {
  return props.required && "\n    ::after {\n        content: \"*\";\n        display: inline;\n        margin-left: 2px;\n        color: ".concat(themeGet("colors.ui.error")(props), ";\n    }\n  ");
});
Label.defaultProps = {
  textStyle: "label",
  m: 0,
  mb: 1,
  hidden: false,
  display: "block",
  width: "100%",
  required: false
};
Label.displayName = "Label";
export default Label;