import { IconCoupon, IconCreditCardOutline, IconEnvelope, IconFeedbackStars, IconFlybuysSquare, IconHeartOutline, IconLocationPinOutline, IconMessage, IconOnePassTags, IconPadlock, IconParcel, IconSecurity, IconToolTip, IconUserAdd, IconUserOutline } from "@catchoftheday/cg-components";
export var ACCOUNT_MENU_ICON_MAP = [{
  name: "Orders",
  icon: IconParcel
}, {
  name: "Personal Details",
  icon: IconUserOutline
}, {
  name: "Account Security",
  icon: IconSecurity
}, {
  name: "Wishlist",
  icon: IconHeartOutline
}, {
  name: "Messages",
  icon: IconMessage
}, {
  name: "OnePass Subscription",
  icon: IconOnePassTags,
  shrink: true
}, {
  name: "Flybuys",
  icon: IconFlybuysSquare
}, {
  name: "Coupons",
  icon: IconCoupon
}, {
  name: "Addresses",
  icon: IconLocationPinOutline,
  shrink: true
}, {
  name: "Payment Details",
  icon: IconCreditCardOutline
}, {
  name: "Communications",
  icon: IconEnvelope
}, {
  name: "Reviews and Feedback",
  icon: IconFeedbackStars
}, {
  name: "Help Centre",
  icon: IconToolTip,
  shrink: true
}, {
  name: "Login",
  icon: IconUserOutline
}, {
  name: "Forgot Password",
  icon: IconPadlock
}, {
  name: "Sign Up",
  icon: IconUserAdd
}, {
  name: "Refer a Friend",
  icon: IconUserAdd
}, {
  name: "Logout"
}];