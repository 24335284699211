import React, { useContext } from "react";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { FacetAccordion } from "../FacetAccordion/FacetAccordion";
import { SizeFacetOptions } from "../SizeFacetOptions/SizeFacetOptions";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SizeFacet = function SizeFacet(_ref) {
  var facet = _ref.facet;

  var _useContext = useContext(CatalogueContext),
      addFilterValue = _useContext.addFilterValue,
      removeFilterValue = _useContext.removeFilterValue;

  var id = facet.id,
      displayName = facet.displayName,
      totalApplied = facet.totalApplied,
      options = facet.options;

  var onChange = function onChange(name, value, selected) {
    if (selected) {
      removeFilterValue("f.".concat(name), value);
      return;
    }

    addFilterValue("f.".concat(name), value, true);
  };

  return ___EmotionJSX(FacetAccordion, {
    id: id,
    displayName: displayName,
    totalApplied: totalApplied,
    expanded: facet.expanded
  }, ___EmotionJSX(SizeFacetOptions, {
    options: options,
    onChange: onChange
  }));
};