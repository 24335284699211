import React from "react";
import { Flex, Image, Span } from "@catchoftheday/cg-components";
import { date } from "@catchoftheday/utilities";
import { getEventTitles } from "../../../utilities";
import { Card } from "./Card";
import { jsx as ___EmotionJSX } from "@emotion/react";
var IMAGE_BOX_HEIGHT = {
  xs: 215,
  md: 315
};
export var DealCard = function DealCard(_ref) {
  var event = _ref.event,
      startDate = _ref.startDate,
      promote = _ref.promote;
  var id = event.id,
      image = event.image;
  var banner = image.single,
      onePassBanner = image.onePassPrePromote;

  var _getEventTitles = getEventTitles(event),
      title = _getEventTitles.title;

  var launchDay = date.format(date.parseJSON(startDate), "EEEE");
  return ___EmotionJSX(Card, {
    "data-testid": "cc-deal-card-".concat(id),
    display: "flex",
    flexDirection: "column",
    mb: promote ? 10 : 0
  }, ___EmotionJSX(Flex, {
    height: {
      xs: "".concat(IMAGE_BOX_HEIGHT.xs, "px"),
      md: "".concat(IMAGE_BOX_HEIGHT.md, "px")
    },
    borderBottomColor: "ui.borderColor",
    borderBottom: 1,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  }, ___EmotionJSX(Image, {
    src: (onePassBanner === null || onePassBanner === void 0 ? void 0 : onePassBanner.url) || (banner === null || banner === void 0 ? void 0 : banner.url),
    alt: title,
    width: "auto !important",
    height: "100% !important"
  })), ___EmotionJSX(Flex, {
    flex: "1",
    px: 3,
    height: {
      xs: "68px",
      md: "80px"
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }, ___EmotionJSX(Span, {
    color: "textTertiary",
    display: "block",
    fontSize: {
      xs: "xs",
      md: "sm"
    }
  }, "Launching on ", launchDay), ___EmotionJSX(Span, {
    display: "block",
    fontWeight: "semibold",
    fontSize: {
      xs: "sm",
      md: "base"
    }
  }, title)));
};