import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconHeart = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.7 4.34C14.268 3.017 12 7.813 12 7.813s-2.265-4.804-5.7-3.48c-2.922 1.125-4.087 4.48-2.748 7.187 1.147 2.316 3.568 5.361 8.44 8.68 4.873-3.319 7.31-6.357 8.456-8.673 1.339-2.707.174-6.062-2.747-7.187z",
    fill: "currentColor",
    stroke: "currentColor",
    strokeWidth: "2"
  })],
  fill: "none",
  viewBox: "0 0 24 24"
});