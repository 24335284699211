import React from "react";
import { Flex, IconPhone, Link, Span } from "@catchoftheday/cg-components";
import { HOTLINE_ONEPASS } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassHelp = function OnePassHelp(_ref) {
  var stacking = _ref.stacking;
  return ___EmotionJSX(Flex, {
    color: "onePass.textPrimary",
    flexDirection: stacking ? "column" : "row",
    alignItems: stacking ? "flex-end" : "center"
  }, ___EmotionJSX(Span, {
    fontSize: {
      xs: "sm",
      md: "xs"
    },
    color: "inherit"
  }, "For OnePass help call"), ___EmotionJSX(Flex, {
    alignItems: "center",
    ml: stacking ? 0 : 2
  }, ___EmotionJSX(IconPhone, {
    mr: 1,
    color: "inherit",
    height: "15px",
    width: "auto"
  }), ___EmotionJSX(Link, {
    color: "inherit",
    fontWeight: "bold",
    href: "tel: ".concat(HOTLINE_ONEPASS)
  }, HOTLINE_ONEPASS)));
};