import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";

var _templateObject;

var _excluded = ["children", "show", "transitionDuration"];

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import React, { useState } from "react";
import { useWindowSize } from "@react-hookz/web";
import { styled } from "@catchoftheday/theme";
import { useIsomorphicLayoutEffect } from "@catchoftheday/utilities";
import { Box } from "../Box";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Collapsible = function Collapsible(_ref) {
  var children = _ref.children,
      show = _ref.show,
      _ref$transitionDurati = _ref.transitionDuration,
      transitionDuration = _ref$transitionDurati === void 0 ? 200 : _ref$transitionDurati,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(null),
      innerContainer = _useState[0],
      setInnerContainer = _useState[1]; // Whether the content of the collapsible should be hidden


  var _useState2 = useState(!show),
      hidden = _useState2[0],
      setHidden = _useState2[1]; // max height of content container for transition purposes


  var _useState3 = useState(show ? undefined : 0),
      maxHeight = _useState3[0],
      setMaxHeight = _useState3[1]; // monitor window size to avoid clipped content


  var windowSize = useWindowSize(); // Layout effect will run when new DOM tree is ready but not yet painted

  useIsomorphicLayoutEffect(function () {
    // Updates are not necessary if the innerContainer is not ready yet
    if (!innerContainer) {
      return;
    }

    var resizeObserver = new ResizeObserver(function (entries) {
      var _iterator = _createForOfIteratorHelper(entries),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var entry = _step.value;
          var newMaxHeight = show ? Math.round(entry.target.scrollHeight) : 0;

          if (newMaxHeight !== maxHeight) {
            setHidden(false);
            setMaxHeight(show ? newMaxHeight : 0);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    });
    resizeObserver.observe(innerContainer);
    return function () {
      return resizeObserver.disconnect();
    };
  }, [show, innerContainer, maxHeight, windowSize]); // If show and hidden are not consistent it is because we are currently in a transition

  var transitioning = !show !== hidden;
  return ___EmotionJSX(Container, _extends({
    onTransitionEnd: function onTransitionEnd() {
      return setHidden(!show);
    },
    transitionDuration: transitionDuration,
    maxHeight: maxHeight !== null && maxHeight !== void 0 ? maxHeight : "".concat(maxHeight, "px"),
    opacity: show ? 1 : 0,
    overflow: "hidden"
  }, boxProps), ___EmotionJSX(Box, {
    visibility: !transitioning && hidden ? "hidden" : "visible" // Using state instead of ref so we can be notified when the container is ready
    ,
    ref: function ref(node) {
      return setInnerContainer(node);
    }
  }, children));
};
Collapsible.displayName = "Collapsible";
export default Collapsible;
var Container = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: max-height ", "ms ease-out,\n    opacity ", "ms ease-out;\n"])), function (props) {
  return props.transitionDuration;
}, function (props) {
  return props.transitionDuration;
});