import React from "react";
import { emitBannerClick } from "@catchoftheday/analytics";
import { ImageResponsive, Link } from "@catchoftheday/cg-components";
import { minusOnePx, useMedia, useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ScheduleEntryBanner = function ScheduleEntryBanner(_ref) {
  var entry = _ref.entry,
      position = _ref.position;

  var _useTheme = useTheme(),
      breakpoints = _useTheme.breakpoints;

  var isMobile = !!useMedia(["(max-width: ".concat(minusOnePx(breakpoints.md, true), ")")], [true]);
  var id = entry.id,
      banner = entry.banner;

  if (!banner || !banner.platforms.some(function (platform) {
    return platform === "DESKTOP" || platform === "MOBILE";
  })) {
    return null;
  }

  var platforms = banner.platforms,
      image = banner.image,
      bannerUrl = banner.url;
  var width = image.width,
      height = image.height,
      altText = image.altText,
      imageUrl = image.url;
  var isDesktopBanner = platforms.some(function (platform) {
    return platform === "DESKTOP";
  });
  var isMobileBanner = platforms.some(function (platform) {
    return platform === "MOBILE";
  });

  if (isMobile && isMobileBanner || !isMobile && isDesktopBanner) {
    return ___EmotionJSX(Link, {
      title: altText || "promotional banner",
      onClick: function onClick() {
        imageUrl && emitBannerClick({
          id: Number(id),
          name: imageUrl.substring(imageUrl.lastIndexOf("/") + 1),
          targetUrl: bannerUrl,
          position: position
        });
      },
      href: bannerUrl
    }, ___EmotionJSX(ImageResponsive, {
      src: imageUrl,
      alt: altText || "promotional banner",
      ratio: height / (width !== null && width !== void 0 ? width : 1) * 100,
      lazyLoad: true
    }));
  }

  return null;
};