import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceContainer = function ProductPriceContainer(_ref) {
  var large = _ref.large,
      children = _ref.children;
  return ___EmotionJSX(Box, {
    display: "inline-flex",
    flex: "0 1 auto",
    flexDirection: "column",
    lineHeight: 1,
    alignItems: large ? {
      xs: "center",
      lg: "flex-start"
    } : "flex-start"
  }, children);
};