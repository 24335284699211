import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useMemo } from "react";
import { Checkbox, Flex, Radio } from "@catchoftheday/cg-components";
import { MAX_VISIBLE_LIST_FACET_OPTIONS } from "../../../../consts";
import { ListFacetOption } from "../ListFacetOption/ListFacetOption";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ListFacetOptions = function ListFacetOptions(_ref) {
  var options = _ref.options,
      showMore = _ref.showMore,
      onChange = _ref.onChange,
      isRadio = _ref.isRadio,
      isStarRating = _ref.isStarRating;
  var InputComponent = isRadio ? Radio : Checkbox;
  var visibleOptions = showMore ? options : options.slice(0, MAX_VISIBLE_LIST_FACET_OPTIONS);
  var renderedOptions = useMemo(function () {
    return visibleOptions.map(function (option) {
      return ___EmotionJSX(Flex, {
        flexDirection: "row",
        key: option.id,
        mb: 2,
        display: "flex",
        "data-testid": "list-facet-option"
      }, ___EmotionJSX(ListFacetOption, _extends({}, option, {
        onChange: onChange,
        isStarRating: isStarRating,
        InputComponent: InputComponent
      })));
    });
  }, [visibleOptions, onChange, isStarRating, InputComponent]);
  return ___EmotionJSX(React.Fragment, null, renderedOptions);
};