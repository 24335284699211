import React from "react";
import { IconOnePassDelivery, IconOnePassMembershipCard, IconOnePassRibbon } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BENEFITS = [{
  icon: ___EmotionJSX(IconOnePassMembershipCard, {
    width: "60%",
    height: "auto",
    title: "OnePass membership card icon",
    "aria-label": "OnePass membership card icon"
  }),
  description: "All current Catch and OnePass benefits, for the same price."
}, {
  icon: ___EmotionJSX(IconOnePassDelivery, {
    width: "60%",
    height: "auto",
    title: "OnePass delivery truck icon",
    "aria-label": "OnePass delivery truck icon"
  }),
  description: "Free delivery on eligible items or orders* with Kmart, Target, Bunnings Warehouse and Officeworks."
}, {
  icon: ___EmotionJSX(IconOnePassRibbon, {
    width: "60%",
    height: "auto",
    title: "OnePass ribbon icon",
    "aria-label": "OnePass ribbon icon"
  }),
  description: "Personalised offers and rewards from Kmart, Target, Bunnings Warehouse and Officeworks."
}];