import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var StatusDetailsRowZone = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & {\n    width: 100%;\n    flex-wrap: wrap;\n  }\n\n  @media screen and (min-width: ", ") {\n    & {\n      width: 80%;\n    }\n  }\n"])), themeGet("breakpoints.xl"));
export var StatusDetailsColumnZone = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  & {\n    width: 100%;\n  }\n\n  &:not(:first-of-type) {\n    margin-top: ", ";\n  }\n\n  @media screen and (min-width: ", ") {\n    & {\n      width: 70%;\n    }\n  }\n\n  @media screen and (min-width: ", ") {\n    & {\n      width: 50%;\n    }\n\n    &:nth-of-type(odd) {\n      padding-right: ", ";\n    }\n\n    &:nth-of-type(even) {\n      padding-left: ", ";\n    }\n\n    &:not(:first-of-type) {\n      margin-top: 0;\n    }\n  }\n\n  @media screen and (min-width: ", ") {\n    &:nth-of-type(odd) {\n      padding-right: ", ";\n    }\n\n    &:nth-of-type(even) {\n      padding-left: ", ";\n    }\n  }\n"])), themeGet("space.4"), themeGet("breakpoints.md"), themeGet("breakpoints.lg"), themeGet("space.6"), themeGet("space.6"), themeGet("breakpoints.xl"), themeGet("space.10"), themeGet("space.10"));
export var SubscriptionDetailsPageZone = function SubscriptionDetailsPageZone(props) {
  return ___EmotionJSX(Box, _extends({
    pb: {
      xs: 6,
      md: 8
    }
  }, props));
};