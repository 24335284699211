import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Heading, Text } from "@catchoftheday/cg-components";
import { useIsMobile } from "../../../../hooks";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FAQItem = function FAQItem(_ref) {
  var question = _ref.question,
      answer = _ref.answer,
      faqId = _ref.faqId,
      _ref$handleToggle = _ref.handleToggle,
      handleToggle = _ref$handleToggle === void 0 ? function () {
    return false;
  } : _ref$handleToggle,
      _ref$show = _ref.show,
      show = _ref$show === void 0 ? false : _ref$show;
  var isMobile = useIsMobile();
  var questionId = "onepass-faq-question-".concat(faqId);
  var answerId = "onepass-faq-answer-".concat(faqId);
  return ___EmotionJSX(Accordion, {
    id: questionId,
    expanded: isMobile ? show : true,
    showDividerTop: false,
    borderBottom: "none",
    mb: {
      xs: 2,
      md: 4
    },
    py: {
      xs: 4,
      md: 0
    }
  }, isMobile && ___EmotionJSX(AccordionSummary, {
    p: 0,
    pr: 10,
    height: "auto",
    fontSize: {
      xs: "base",
      md: "xl"
    },
    fontWeight: "bold",
    color: "onePass.textPrimary",
    onClick: function onClick() {
      if (typeof handleToggle === "function") {
        handleToggle();
      }
    },
    onKeyDown: function onKeyDown(event) {
      if (typeof handleToggle === "function" && event.code === "Enter") {
        event.preventDefault();
        handleToggle();
      }
    }
  }, question), ___EmotionJSX(AccordionDetails, {
    id: answerId,
    px: 0,
    py: {
      md: 6
    },
    mt: {
      xs: 4
    }
  }, !isMobile && ___EmotionJSX(Heading.h4, {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "bold",
    mb: 4,
    color: "onePass.textPrimary"
  }, question), ___EmotionJSX(Text, {
    m: 0,
    p: 0,
    fontSize: {
      xs: "sm",
      md: "base"
    },
    fontWeight: "medium",
    color: "onePass.textPrimary"
  }, answer)));
};