import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LogRangeSlider, RangeSlider } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { FacetAccordion } from "../FacetAccordion/FacetAccordion";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RangeFacet = function RangeFacet(_ref) {
  var facet = _ref.facet;

  var _useContext = useContext(CatalogueContext),
      addFilterValue = _useContext.addFilterValue,
      isSearchLoading = _useContext.isSearchLoading;

  var id = facet.id,
      displayName = facet.displayName,
      totalApplied = facet.totalApplied,
      min = facet.min,
      max = facet.max,
      minLabel = facet.minLabel,
      maxLabel = facet.maxLabel,
      _facet$selectedRange = facet.selectedRange,
      selectedMin = _facet$selectedRange.min,
      selectedMax = _facet$selectedRange.max;

  var _useState = useState(selectedMin),
      selectedMinVal = _useState[0],
      setSelectedMin = _useState[1];

  var _useState2 = useState(selectedMax),
      selectedMaxVal = _useState2[0],
      setSelectedMax = _useState2[1];

  var _useState3 = useState(min),
      minVal = _useState3[0],
      setMinVal = _useState3[1];

  var _useState4 = useState(max),
      maxVal = _useState4[0],
      setMaxVal = _useState4[1];

  var mode = 3;
  var setMinMaxValues = useCallback(function (min, max) {
    setSelectedMin(min);
    setSelectedMax(max);
  }, []);

  var handleChange = function handleChange(values) {
    setMinMaxValues(values[0], values[1]);
    addFilterValue("f.".concat(id), "".concat(values[0], "-").concat(values[1]), false);
  };

  useEffect(function () {
    setMinMaxValues(selectedMin, selectedMax === selectedMin ? selectedMax + 1 : selectedMax);
    setMinVal(min);
    setMaxVal(max === min ? max + 1 : max);
  }, [selectedMin, selectedMax, min, max, setMinMaxValues]);
  var rangeProps = {
    minMaxValues: [minVal, maxVal],
    defaultValues: [selectedMinVal, selectedMaxVal],
    onChange: handleChange,
    disabled: isSearchLoading,
    mode: mode,
    step: 1,
    displayUnitMin: minLabel,
    displayUnitMax: maxLabel,
    inputProps: {
      pl: id === "price" ? "1.125rem" : "1.875rem",
      maxWidth: "100px"
    },
    containerProps: {
      px: 4
    }
  };
  return ___EmotionJSX(FacetAccordion, {
    id: id,
    displayName: displayName,
    totalApplied: totalApplied,
    expanded: facet.expanded
  }, id === "price" ? ___EmotionJSX(LogRangeSlider, _extends({}, rangeProps, {
    step: 5,
    containerProps: {
      px: 0
    },
    rangeContainerProps: {
      px: 4
    }
  })) : ___EmotionJSX(RangeSlider, rangeProps));
};