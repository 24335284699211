import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopHeroBannerZone = function ShopHeroBannerZone(props) {
  return ___EmotionJSX(Box, _extends({
    backgroundColor: "white",
    borderBottom: 1,
    borderBottomColor: "grey",
    px: {
      xs: 4,
      md: 8
    },
    pt: 4,
    pb: {
      xs: 8,
      md: 12
    }
  }, props));
};
export var ShopHeroBannerContentZone = function ShopHeroBannerContentZone(props) {
  var theme = useTheme();
  return ___EmotionJSX(Box, _extends({
    maxWidth: theme.maxWidths.cotdWebsite,
    margin: "0 auto"
  }, props));
};