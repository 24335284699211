import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex, IconBunningsWarehouse, IconCatch, IconKmart, IconOfficeworks, IconTarget } from "@catchoftheday/cg-components";
import { MigrationNudgeDivisionsZone } from "../../layouts/MigrationNudgeLayout/MigrationNudgeLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
var commonProps = {
  mx: 2,
  my: 2,
  width: "auto"
};

var IconGroup = function IconGroup(props) {
  return ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    justifyContent: "center"
  }, props));
};

export var Divisions = function Divisions() {
  return ___EmotionJSX(MigrationNudgeDivisionsZone, null, ___EmotionJSX(IconGroup, null, ___EmotionJSX(IconCatch, _extends({
    height: "1em",
    "aria-label": "Catch"
  }, commonProps)), ___EmotionJSX(IconKmart, _extends({
    height: "1em",
    "aria-label": "Kmart"
  }, commonProps)), ___EmotionJSX(IconTarget, _extends({
    height: "1em",
    "aria-label": "Target"
  }, commonProps))), ___EmotionJSX(IconGroup, null, ___EmotionJSX(IconBunningsWarehouse, _extends({
    height: "1em",
    "aria-label": "Bunnings Warehouse"
  }, commonProps)), ___EmotionJSX(IconOfficeworks, _extends({
    height: "0.8em",
    "aria-label": "Officeworks"
  }, commonProps))));
};