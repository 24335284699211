import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["copyright", "navigationLinkColumns", "appDownloadLinks"];
import React from "react";
import { Box, Flex, Link } from "@catchoftheday/cg-components";
import { ThemeProvider } from "@catchoftheday/theme";
import { hideOnAndroidWebView } from "@catchoftheday/utilities";
import { useFooterDataQuery } from "../../generated/graphql";
import { AccordionNavigation, Body, ColumnNavigation, IconLinks, SubscribeInput, Title } from "./components";
import { px } from "./utils";
import { jsx as ___EmotionJSX } from "@emotion/react";

function FooterInternal(_ref) {
  var copyright = _ref.copyright,
      navigationLinkColumns = _ref.navigationLinkColumns,
      appDownloadLinks = _ref.appDownloadLinks,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      subscribed = _React$useState2[0],
      setSubscribed = _React$useState2[1];

  var enableAppLinks = appDownloadLinks.every(function (link) {
    return !!link.url;
  });
  return ___EmotionJSX(ThemeProvider, {
    appName: "footer"
  }, ___EmotionJSX(Box, _extends({
    as: "footer",
    fontFamily: "",
    paddingTop: ["40px"],
    paddingBottom: ["40px"],
    backgroundColor: "#ffffff",
    borderTop: "1px solid #ddd",
    fontWeight: "100",
    fontSize: "0.875rem",
    clear: "both"
  }, boxProps), ___EmotionJSX(Box, {
    maxWidth: [1340],
    margin: ["auto"]
  }, ___EmotionJSX(Container, {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: {
      xs: "0px",
      md: "20px"
    },
    paddingRight: {
      xs: "0px",
      md: "20px"
    }
  }, ___EmotionJSX(Box, {
    display: {
      xs: "none",
      md: "block"
    },
    width: {
      xs: "100%",
      lg: "70%"
    },
    textAlign: {
      xs: "center",
      lg: "left"
    }
  }, ___EmotionJSX(ColumnNavigation, {
    navigationLinkColumns: navigationLinkColumns
  })), ___EmotionJSX(Box, {
    display: {
      xs: "block",
      md: "none"
    },
    width: "100%",
    marginBottom: "30px"
  }, ___EmotionJSX(AccordionNavigation, {
    navigationLinkColumns: navigationLinkColumns
  })), ___EmotionJSX(Box, {
    width: {
      sm: "100%",
      md: "100%",
      lg: "30%"
    },
    textAlign: {
      xs: "center",
      lg: "left"
    },
    paddingLeft: {
      xs: "20px",
      md: "0px"
    },
    paddingRight: {
      xs: "20px",
      md: "0px"
    }
  }, ___EmotionJSX(Title, {
    marginBottom: "10px",
    marginTop: "10px"
  }, "Stay in Touch"), subscribed ? ___EmotionJSX(Flex, {
    "data-testid": "subscribe-success",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  }, ___EmotionJSX(Body, {
    color: "primary",
    fontWeight: 700,
    marginTop: 0
  }, "Cool! You are now subscribed to our newsletter."), ___EmotionJSX(Body, {
    color: "primary",
    margin: 0
  }, "Keep an eye on your inbox for our great deals!")) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Body, {
    marginTop: 0,
    marginBottom: "17.5px"
  }, "Sign up for our newsletter to be the first to know about daily deals, special events and new products!"), ___EmotionJSX(SubscribeInput, {
    onSubmit: function onSubmit() {
      return setSubscribed(true);
    }
  }), ___EmotionJSX(Body, {
    fontSize: px(12)
  }, "View our ", ___EmotionJSX(Link, {
    href: "/privacy"
  }, "Privacy Policy"), " and", " ", ___EmotionJSX(Link, {
    href: "/privacy#collection"
  }, "Collection Statement"))))), enableAppLinks && ___EmotionJSX(IconLinks, {
    appDownloadLinks: appDownloadLinks
  }), ___EmotionJSX(Container, {
    textAlign: "center",
    dangerouslySetInnerHTML: {
      __html: copyright
    },
    fontSize: px(10),
    fontWeight: "lighter",
    color: "typography.text",
    mt: "0px",
    mb: "12.5px"
  }))));
}

export var Footer = hideOnAndroidWebView(FooterInternal);

var Container = function Container(props) {
  return ___EmotionJSX(Box, _extends({
    paddingRight: "20px",
    paddingLeft: "20px"
  }, props));
};

export function WrappedFooter() {
  var _useFooterDataQuery = useFooterDataQuery(),
      data = _useFooterDataQuery.data;

  if (!data) {
    return null;
  }

  var footer = data.footer;
  return ___EmotionJSX(Footer, {
    navigationLinkColumns: footer.navigationLinkColumns || [],
    copyright: footer.copyright,
    appDownloadLinks: footer.appDownloadLinks
  });
}