import React from "react";
import { Box, Flex, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Step = function Step(_ref) {
  var _ref$active = _ref.active,
      active = _ref$active === void 0 ? false : _ref$active,
      _ref$step = _ref.step,
      step = _ref$step === void 0 ? 1 : _ref$step,
      _ref$description = _ref.description,
      description = _ref$description === void 0 ? "" : _ref$description,
      _ref$last = _ref.last,
      last = _ref$last === void 0 ? false : _ref$last;
  return ___EmotionJSX(Flex, {
    position: "relative",
    zIndex: 1,
    alignItems: "flex-start",
    mb: last ? 0 : 4
  }, last && ___EmotionJSX(Box, {
    position: "absolute",
    borderTopLeftRadius: "50%",
    borderTopRightRadius: "50%",
    backgroundColor: "white",
    overflow: "hidden",
    top: 0,
    bottom: 0,
    left: 0,
    width: "24px",
    zIndex: -1
  }), ___EmotionJSX(Box, null, ___EmotionJSX(Box, {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    borderColor: "onePass.primary",
    borderStyle: "solid",
    borderWidth: "1px",
    overflow: "hidden",
    color: active ? "white" : "onePass.primary",
    backgroundColor: active ? "onePass.primary" : "white",
    textAlign: "center"
  }, ___EmotionJSX(Span, {
    fontWeight: "bold",
    fontSize: "base",
    lineHeight: 1
  }, step))), ___EmotionJSX(Span, {
    ml: 2,
    fontSize: "base",
    fontWeight: "medium",
    color: "onePass.textPrimary"
  }, description));
};