import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Pill } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryPill = function CategoryPill(props) {
  return ___EmotionJSX(Pill, _extends({
    containerProps: {
      my: 0,
      mx: 1,
      py: 2,
      px: 4,
      backgroundColor: "white",
      border: 1,
      borderColor: "grey",
      hoverBackgroundColor: "grey",
      checkedColor: "white",
      checkedBackgroundColor: "onePass.primary",
      role: "menuitem",
      "aria-label": props.label
    },
    textProps: {
      fontWeight: "semibold",
      fontSize: "base",
      whiteSpace: "nowrap"
    }
  }, props));
};