import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React from "react";
import { Flex, Heading, List } from "@catchoftheday/cg-components";
import { MenuLink } from "../MenuLink";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TopBrands = function TopBrands(_ref) {
  var topBrands = _ref.topBrands,
      itemPathShop = _ref.itemPathShop,
      brandPosition = _ref.brandPosition;
  return ___EmotionJSX(Flex, {
    width: "20%",
    flexDirection: "column",
    justifyContent: "flex-start",
    bg: "ui.backgroundColor",
    pt: "70px",
    pb: "60px",
    px: "30px"
  }, ___EmotionJSX(Heading.h5, {
    borderBottom: "solid 1px",
    borderBottomColor: "ui.borderColor",
    mt: "0px",
    mb: "10px",
    lineHeight: "1.2rem",
    pb: "4px"
  }, "Top Brands"), ___EmotionJSX(List, {
    m: "0"
  }, topBrands.map(function (brand, index) {
    return ___EmotionJSX(MenuLink, {
      key: brand.id,
      navItem: brand,
      itemPathLink: [].concat(_toConsumableArray(itemPathShop), [{
        name: "Top Brands",
        id: itemPathShop[0].id,
        position: brandPosition
      }, {
        name: brand.name,
        id: brand.id,
        position: index + 1
      }])
    });
  })));
};