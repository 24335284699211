import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconCartOutline = createSvgIconComponent({
  children: [___EmotionJSX("g", {
    key: "part-1",
    fill: "none",
    clipPath: "url(#clip0)",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }, ___EmotionJSX("path", {
    d: "M9.5 20.333a.833.833 0 100-1.666.833.833 0 000 1.666zM18.667 20.333a.833.833 0 100-1.666.833.833 0 000 1.666zM2.833 2.833h3.334L8.4 13.992a1.667 1.667 0 001.667 1.341h8.1a1.667 1.667 0 001.666-1.341L21.167 7H7"
  })), ___EmotionJSX("defs", {
    key: "part-2"
  }, ___EmotionJSX("clipPath", {
    id: "clip0"
  }, ___EmotionJSX("path", {
    fill: "currentColor",
    transform: "translate(2 2)",
    d: "M0 0h20v20H0z"
  })))],
  fill: "none",
  viewBox: "0 0 24 24",
  fillRule: "evenodd"
});