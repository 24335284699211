import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { CancellationModalContainer, LinkingModalContainer, MigrationModalContainer, UpsellModalContainer } from "@catchoftheday/onepass";
import { FreeShippingDrawer } from "@catchoftheday/personalisation";
import { ThemeProvider } from "@catchoftheday/theme";
import { FlashMessageBanner } from "@header/components/FlashMessageBanner";
import { GlobalAlertBanner } from "@header/components/GlobalAlertBanner/GlobalAlertBanner";
import { NotificationToast } from "@header/components/NotificationToast";
import theme from "@header/components/theme";
import { HeaderToastContainer } from "../HeaderToast";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderContentWrapper = function HeaderContentWrapper(_ref) {
  var stickyRef = _ref.stickyRef,
      isLoggedIn = _ref.isLoggedIn,
      topHeaderChildren = _ref.topHeaderChildren,
      children = _ref.children;
  return ___EmotionJSX(ThemeProvider, {
    theme: theme,
    enableGlobalFontConfig: false,
    enableGlobalBoxSizingConfig: true
  }, ___EmotionJSX(Box, {
    id: "top-header"
  }, topHeaderChildren), ___EmotionJSX(Box, {
    ref: stickyRef,
    position: "sticky",
    top: 0,
    zIndex: 3
  }, children, ___EmotionJSX(FlashMessageBanner, {
    isLoggedIn: isLoggedIn
  }), ___EmotionJSX(GlobalAlertBanner, null), ___EmotionJSX(HeaderToastContainer, null)), ___EmotionJSX(FreeShippingDrawer, null), ___EmotionJSX(LinkingModalContainer, null), ___EmotionJSX(MigrationModalContainer, null), ___EmotionJSX(CancellationModalContainer, null), ___EmotionJSX(UpsellModalContainer, null), ___EmotionJSX(NotificationToast, null));
};