import React from "react";
import { Box, Flex, Heading, IconOnePass, Link, Text } from "@catchoftheday/cg-components";
import { SocialUrgency } from "@catchoftheday/p13n-integration";
import { URL_ONEPASS_LANDING } from "../../../consts";
import { FeaturedEventSectionHeading } from "../../pure/FeaturedEventSectionHeading";
import { CTA } from "./CTA";
import { Gallery } from "./Gallery";
import { GalleryPlaceholder } from "./GalleryPlaceholder";
import { Prices } from "./Prices";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeroEventBannerV2 = function HeroEventBannerV2(_ref) {
  var isShopToggleExperiment = _ref.isShopToggleExperiment,
      endDate = _ref.endDate,
      isActiveMember = _ref.isActiveMember,
      socialUrgencyStats = _ref.socialUrgencyStats,
      eventUrl = _ref.eventUrl,
      title = _ref.title,
      _ref$subtitle = _ref.subtitle,
      subtitle = _ref$subtitle === void 0 ? null : _ref$subtitle,
      isSingleProductEvent = _ref.isSingleProductEvent,
      isSoldOut = _ref.isSoldOut,
      sellPrice = _ref.sellPrice,
      retailPrice = _ref.retailPrice,
      clubSellPrice = _ref.clubSellPrice,
      onPrimaryCTAClick = _ref.onPrimaryCTAClick,
      gallerySlides = _ref.gallerySlides,
      isGalleryLoading = _ref.isGalleryLoading;
  return ___EmotionJSX(Flex, {
    flexDirection: {
      xs: "column",
      md: "row"
    },
    justifyContent: "center",
    alignItems: "center",
    border: 1,
    borderColor: "onePass.primary",
    borderRadius: "corner",
    px: {
      xs: 6,
      md: 15
    },
    py: {
      xs: 8,
      md: 10
    }
  }, ___EmotionJSX(Flex, {
    width: {
      xs: "100%",
      md: "50%"
    },
    m: "0 auto",
    mb: {
      xs: 6,
      md: 0
    },
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "460px"
  }, ___EmotionJSX(FeaturedEventSectionHeading, {
    iconProps: {
      height: {
        xs: "15px",
        md: "21px"
      }
    },
    title: isShopToggleExperiment ? "Shop our Top Picks" : ""
  }), ___EmotionJSX(Link, {
    href: eventUrl,
    color: "textSecondary",
    hoverColor: "inherit",
    title: title,
    hoverTextDecoration: "none"
  }, ___EmotionJSX(Heading.h2, {
    fontWeight: "semibold",
    fontSize: {
      xs: "xl",
      md: "2xl",
      lg: "3xl",
      xl: "4xl"
    },
    m: 0
  }, title), isSingleProductEvent && sellPrice ? ___EmotionJSX(Prices, {
    sellPrice: sellPrice,
    retailPrice: retailPrice,
    clubSellPrice: clubSellPrice
  }) : ___EmotionJSX(Text, {
    color: "onePass.primary",
    fontWeight: "bold",
    fontSize: {
      xs: "base",
      md: "xl",
      lg: "2xl",
      xl: "3xl"
    },
    m: 0,
    mt: {
      xs: 3,
      md: 4,
      lg: 6
    }
  }, subtitle), ___EmotionJSX(SocialUrgency, {
    bordered: false,
    stats: socialUrgencyStats,
    containerProps: {
      m: 0,
      mt: 1
    }
  })), ___EmotionJSX(CTA, {
    btnText: isShopToggleExperiment ? "Shop thousands of new" : "",
    eventUrl: eventUrl,
    isSingleProductEvent: isSingleProductEvent,
    isActiveMember: isActiveMember,
    isSoldOut: isSoldOut,
    onPrimaryClick: onPrimaryCTAClick
  }), !isActiveMember && ___EmotionJSX(Box, {
    mt: 4,
    color: "onePass.primaryDarker"
  }, ___EmotionJSX(Link, {
    fontSize: "sm",
    fontWeight: "semibold",
    color: "onePass.primaryDarker",
    textDecoration: "underline",
    hoverColor: "onePass.primaryDarker",
    title: "Learn more about OnePass",
    href: URL_ONEPASS_LANDING
  }, "Learn more about", " ", ___EmotionJSX(IconOnePass, {
    height: "1em",
    width: "auto",
    "aria-label": "OnePass",
    title: "OnePass",
    mb: 1
  }))), endDate && ___EmotionJSX(Text, {
    m: 0,
    fontSize: "xs",
    mt: {
      xs: 2,
      md: 4
    }
  }, "Deal ends ", endDate, " or while stocks last.")), ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "50%"
    },
    px: {
      xs: 3,
      md: 0
    }
  }, isGalleryLoading ? ___EmotionJSX(GalleryPlaceholder, null) : ___EmotionJSX(Gallery, {
    slides: gallerySlides || []
  })));
};