export var wishListMockData = {
  items: [{
    id: 1,
    quantity: 1,
    sellPrice: {
      amount: 2359,
      currency: "AUD"
    },
    productTitle: "Dermalogica Skin Smoothing Cream 177mL",
    productId: 23407,
    productSkuId: 2333659,
    imageSrc: "https://s.catch.com.au/images/product/0020/20541/5cde56cbd6f6d613948800_w100.jpg",
    link: "/event/123940/product/dermalogica-skin-smoothing-cream-177ml-4138166/?offer_id=22291026"
  }, {
    id: 2,
    quantity: 0,
    sellPrice: {
      amount: 5989,
      currency: "AUD"
    },
    productTitle: "SUNDAY IN THE CITY Women's Holiday Denim Jacket",
    productId: 5403622,
    productSkuId: 11150847,
    attributeName: "Size",
    attributeValue: "8",
    attributeValueId: 244667,
    imageSrc: "https://s.catch.com.au/images/product/0026/26729/5ea0d124677db194157932_w100.jpg",
    link: "/product/sunday-in-the-city-womens-holiday-denim-jacket-5403622/"
  }, {
    id: 3,
    quantity: 1,
    sellPrice: {
      amount: 17000,
      currency: "AUD"
    },
    productTitle: "ASICS Women's Gel-Quantum Infinity Jin Sportstyle Shoes - Rose/Purple",
    productId: 46825,
    productSkuId: 2733659,
    imageSrc: "https://s.catch.com.au/images/product/0030/30989/5eb40d89f1fe3254644762_w100.jpg",
    link: "/event/asics-running-shoes-125780/product/asics-womens-gel-quantum-infinity-jin-sportstyle-shoes-rose-purple-5042259"
  }, {
    id: 4,
    quantity: 0,
    sellPrice: {
      amount: 9999,
      currency: "AUD"
    },
    productTitle: "ASICS Women's GEL-Pulse 11 D Wide Fit Running Shoes - Mako Blue/Sun Coral",
    productId: 5403753,
    productSkuId: 13250847,
    attributeName: "Size",
    attributeValue: "8",
    attributeValueId: 244667,
    imageSrc: "https://s.catch.com.au/images/product/0030/30455/5e9eba8b00a3e179932662_w100.jpg",
    link: "/event/asics-running-shoes-125780/product/asics-womens-gel-pulse-11-d-wide-fit-running-shoes-mako-blue-sun-coral-6171712/"
  }, {
    id: 5,
    quantity: 1,
    sellPrice: {
      amount: 6500,
      currency: "AUD"
    },
    productTitle: "Nike Men's Club Fleece Pullover Hoodie - Black",
    productId: 125405,
    productSkuId: 2343659,
    imageSrc: "https://s.catch.com.au/images/product/0031/31033/5eb51a599dab1240011678_w100.jpg",
    link: "/event/new-nike-arrivals-125402/product/nike-mens-club-fleece-pullover-hoodie-black-5796867"
  }, {
    id: 6,
    quantity: 0,
    sellPrice: {
      amount: 59.89,
      currency: "AUD"
    },
    productTitle: "View Nike Men's Dri-FIT Tapered Fleece Trackpants / Tracksuit Pants - Charcoal Heather/Black",
    productId: 5796832,
    productSkuId: 58650847,
    attributeName: "Size",
    attributeValue: "8",
    attributeValueId: 244667,
    imageSrc: "https://s.catch.com.au/images/product/0030/30988/5eb3ce13033c8954080598_w100.jpg",
    link: "/event/new-nike-arrivals-125402/product/nike-mens-dri-fit-tapered-fleece-trackpants-tracksuit-pants-charcoal-heather-black-5796290"
  }]
};
export var miniCartMockData = {
  totalPrice: 449,
  subTotalPrice: 439,
  hideClubCatchFreeShippingNotice: false,
  clubCatchMinSubTotal: "45.00",
  items: [{
    link: "/event/123940/product/dermalogica-skin-smoothing-cream-177ml-4138166/?offer_id=22291026",
    imageSrc: "https://s.catch.com.au/images/product/0020/20541/5cde56cbd6f6d613948800_w100.jpg",
    productName: "Dermalogica Skin Smoothing Cream 177mL",
    quantity: 3,
    sellPrice: 119.5,
    rowTotalPrice: 239,
    freeShipping: false,
    attributeValueId: "123",
    productId: "123",
    eventId: "456",
    offerId: "789",
    addedByCoupon: false,
    freeShippingClubCatch: false,
    freeShippingMarketPlace: false,
    attributeName: "",
    salesRulePromotion: null
  }, {
    link: "/event/1/product/adidas-mens-duramo-9-running-sports-shoes-core-black-3773508/?offer_id=20943226&attribute_value_id=122414",
    imageSrc: "https://s.catch.com.au/images/product/0018/18255/5c8611175f14e602049044_w100.jpg",
    productName: "Adidas Men's Duramo 9 Running Sports Shoes - Core Black",
    quantity: 1,
    attributeValueText: "US Men 8",
    attributeValueId: "456",
    sellPrice: 200,
    rowTotalPrice: 200,
    freeShipping: true,
    addedByCoupon: true,
    freeShippingClubCatch: false,
    freeShippingMarketPlace: false,
    attributeName: "Size",
    salesRulePromotion: {
      salesRuleId: 2738,
      label: "Add 2 more to save 15%",
      progressLabel: "Add 2 more Pets and save 15%",
      progressLabelSpan: "Pets",
      disclaimerTitle: "Pets - Buy 3, Save 15%",
      event: {
        id: "55639",
        slug: "promovis-event-b-55639"
      },
      applied: false,
      cartItemIds: ["bb79f26d-7639-4aef-a027-8fefe507ab31"]
    }
  }, {
    offerId: "122041",
    quantity: 2,
    rowTotalPrice: 100,
    sellPrice: 100,
    attributeValueText: "",
    attributeValueId: "0",
    addedByCoupon: false,
    productName: "Ardor Boudoir Single Classic Quilted Valance - Stone",
    clubCatchExclusive: false,
    freeShippingMarketPlace: false,
    freeShippingClubCatch: false,
    attributeName: "",
    imageSrc: "https://static.devcat.ch/images/product/275105/52f82cf329d84372269223.jpg",
    link: "/event/1/product/ardor-boudoir-single-classic-quilted-valance-stone-275105/?offerId=122041&attributeValueId=0",
    salesRulePromotion: {
      salesRuleId: 2755,
      label: "Savings Applied - Buy 2, Save 20%",
      progressLabel: "Savings Applied - Buy 2, Save 20%",
      progressLabelSpan: "Wicak Ardor Boudoir Single",
      disclaimerTitle: "Wicak Ardor Boudoir Single - Buy 2, Save 20%",
      event: null,
      applied: true,
      cartItemIds: ["16ecdc1b-0b18-4bf9-ae19-e1e95efa38ba"]
    }
  }],
  containsClubCatchLineItem: false
};
export var myAccountMockData = {
  storeCredit: {
    amount: 10000,
    currency: "AUD"
  },
  recentOrders: [{
    createdAt: "2020-04-09T11:08:23+10:00",
    updatedAt: "2020-04-09T11:08:23+10:00",
    status: "Pending"
  }]
};