import { ONEPASS_MONTHLY_OFFER_ID } from "../../constants/literals";
export var mockOnePassSelectedOption = {
  __typename: "CartClubSubscriptionOption",
  clubImage: {
    altText: "OnePass 30-Day Free Trial for New Members",
    height: 0,
    id: "136aface-e3cf-5aab-80ff-c78c2db3b9b5",
    url: "https://s.catch.com.au//static/catch/images/product/0021/21170/5cf76cf9125f8710426559.jpg",
    width: 0
  },
  freeTrial: true,
  id: ONEPASS_MONTHLY_OFFER_ID,
  interval: "MONTHLY",
  nextBillingDate: "2021-08-08T12:23:15+10:00",
  price: {
    amount: 0,
    currency: "AUD"
  },
  subtitle: "$4 monthly after 08/08/2021. Cancel anytime.",
  title: "OnePass 30-Day Free Trial for New Members"
};
export var mockOnePassSubscription = {
  __typename: "CartClubSubscription",
  options: [{
    __typename: "CartClubSubscriptionOption",
    clubImage: {
      altText: "OnePass 30-Day Free Trial for New Members",
      height: 0,
      id: "136aface-e3cf-5aab-80ff-c78c2db3b9b5",
      url: "https://s.catch.com.au//static/catch/images/product/0021/21170/5cf76cf9125f8710426559.jpg",
      width: 0
    },
    freeTrial: true,
    id: ONEPASS_MONTHLY_OFFER_ID,
    interval: "MONTHLY",
    nextBillingDate: "2021-08-08T12:23:15+10:00",
    price: {
      amount: 400,
      currency: "AUD"
    },
    subtitle: "$4 monthly after 08/08/2021. Cancel anytime.",
    title: "OnePass 30-Day Free Trial for New Members"
  }, {
    __typename: "CartClubSubscriptionOption",
    clubImage: {
      altText: "OnePass 30-Day Free Trial for New Members",
      height: 0,
      id: "136aface-e3cf-5aab-80ff-c78c2db3b9b4",
      url: "https://s.catch.com.au//static/catch/images/product/0021/21170/5cf76cf9125f8710426559.jpg",
      width: 0
    },
    freeTrial: true,
    id: "1",
    interval: "YEARLY",
    nextBillingDate: "2021-08-08T12:23:15+10:00",
    price: {
      amount: 4000,
      currency: "AUD"
    },
    subtitle: "$40 yearly after 08/08/2021. Cancel anytime.",
    title: "OnePass Yearly Membership"
  }],
  id: "c439744c-a2bd-4a73-996b-96ec580a754f",
  offerUpsell: true,
  selectedOption: {
    __typename: "CartClubSubscriptionOption",
    clubImage: {
      altText: "OnePass 30-Day Free Trial for New Members",
      height: 0,
      id: "136aface-e3cf-5aab-80ff-c78c2db3b9b5",
      url: "https://s.catch.com.au//static/catch/images/product/0021/21170/5cf76cf9125f8710426559.jpg",
      width: 0
    },
    freeTrial: true,
    id: ONEPASS_MONTHLY_OFFER_ID,
    interval: "MONTHLY",
    nextBillingDate: "2021-08-08T12:23:15+10:00",
    price: {
      amount: 400,
      currency: "AUD"
    },
    subtitle: "$4 monthly after 08/08/2021. Cancel anytime.",
    title: "OnePass 30-Day Free Trial for New Members"
  }
};