import React from "react";
import { Box, ContentPlaceHolder } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var GalleryPlaceholder = function GalleryPlaceholder() {
  return ___EmotionJSX(Box, {
    role: "progressbar",
    maxWidth: "500px",
    width: "100%",
    margin: "0 auto"
  }, ___EmotionJSX(ContentPlaceHolder, {
    speed: 2,
    width: "100%",
    height: "408px"
  }, ___EmotionJSX("rect", {
    width: "100%",
    height: "352px",
    x: "0",
    y: "0"
  }), ___EmotionJSX("rect", {
    width: "60px",
    height: "48px",
    x: "0",
    y: "360"
  }), ___EmotionJSX("rect", {
    width: "60px",
    height: "48px",
    x: "76",
    y: "360"
  }), ___EmotionJSX("rect", {
    width: "60px",
    height: "48px",
    x: "152",
    y: "360"
  }), ___EmotionJSX("rect", {
    width: "60px",
    height: "48px",
    x: "228",
    y: "360"
  }), ___EmotionJSX("rect", {
    width: "60px",
    height: "48px",
    x: "304",
    y: "360"
  }), ___EmotionJSX("rect", {
    width: "60px",
    height: "48px",
    x: "380",
    y: "360"
  })));
};