import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React, { useCallback, useState } from "react";
import { Box, Flex, IconDownArrow, IconSVG, Link, List, Popover, PopoverControl } from "@catchoftheday/cg-components";
import { minusOnePx, styled, themeGet } from "@catchoftheday/theme";
import { useIsTabletView } from "@header/hooks/useIsTabletView";
import { CATEGORY_MENU_DESKTOP_WIDTH, categoryIconMap, POPOVER_ZINDEX } from "../../consts";
import { trackMenuClick } from "../../utilities/analytics";
import { SubmenuPopover } from "../SubmenuPopover";
import { jsx as ___EmotionJSX } from "@emotion/react";
var MENU_ITEM_HEIGHT = 40;
var MenuItem = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  align-items: center;\n  padding-left: 12px;\n  height: ", "px;\n  color: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  background-color: ", ";\n\n  &:hover,\n  &:focus,\n  &:active {\n    text-decoration: none;\n    outline: none;\n  }\n\n  @media (min-width: ", ") {\n    width: ", "px;\n    border-right: 1px solid ", ";\n\n    &:hover,\n    &:focus,\n    &:active {\n      background-color: ", ";\n      color: ", ";\n      svg {\n        fill: ", ";\n      }\n\n      .active-marker {\n        display: block;\n      }\n      .right-arrow {\n        visibility: visible;\n      }\n    }\n  }\n\n  @media (max-width: ", ") and (min-width: ", ") {\n    height: 48px;\n    color: ", ";\n    background-color: ", ";\n\n    &:active,\n    &:focus,\n    &:hover {\n      background-color: ", ";\n      color: ", ";\n      svg {\n        fill: ", ";\n      }\n    }\n\n    ", "\n  }\n"])), MENU_ITEM_HEIGHT, function (props) {
  return themeGet("colors.text")(props);
}, function (props) {
  return themeGet("fontWeights.light")(props);
}, function (props) {
  return themeGet("fontSizes.sm")(props);
}, function (props) {
  return props.isSubmenuOpen && themeGet("colors.ui.backgroundColor")(props);
}, function (props) {
  return themeGet("breakpoints.lg")(props);
}, CATEGORY_MENU_DESKTOP_WIDTH, function (props) {
  return themeGet("colors.grey")(props);
}, function (props) {
  return themeGet("colors.ui.backgroundColor")(props);
}, function (props) {
  return themeGet("colors.text")(props);
}, function (props) {
  return themeGet("colors.text")(props);
}, function (props) {
  return minusOnePx(themeGet("breakpoints.lg")(props));
}, function (props) {
  return themeGet("breakpoints.md")(props);
}, function (props) {
  return themeGet(props.isActive ? "colors.secondary" : "colors.text")(props);
}, function (props) {
  return themeGet(props.isActive ? "colors.ui.backgroundColor" : "transparent")(props);
}, function (props) {
  return themeGet("colors.grey")(props);
}, function (props) {
  return themeGet(props.isActive ? "colors.secondary" : "colors.text")(props);
}, function (props) {
  return themeGet(props.isActive ? "colors.secondary" : "colors.text")(props);
}, function (props) {
  return props.isActive && "\n      .active-marker {\n        display: block;\n\t    }\n\t  ";
});
var RightArrow = styled(IconDownArrow)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  transform: rotate(270deg);\n  width: 9px;\n"])));

var MenuItemChildren = function MenuItemChildren(_ref) {
  var shop = _ref.shop,
      MenuIcon = _ref.menuIcon,
      isSubmenuOpen = _ref.isSubmenuOpen;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Box, {
    className: "active-marker",
    display: isSubmenuOpen ? "block" : "none",
    position: "absolute",
    width: "4px",
    height: "100%",
    top: "0px",
    left: "0px",
    bg: "secondary"
  }), ___EmotionJSX(Flex, {
    width: "16px",
    height: "16px",
    mr: "8px",
    alignItems: "center"
  }, ___EmotionJSX(MenuIcon, null)), ___EmotionJSX(Box, {
    flex: 1
  }, shop.name), shop.shopNavigationGroups.length > 0 && ___EmotionJSX(Box, {
    mr: "15px",
    justifySelf: "flex-end"
  }, ___EmotionJSX(RightArrow, {
    "data-testid": "submenu-indicator-".concat(shop.slug),
    className: "right-arrow",
    visibility: isSubmenuOpen ? "visible" : "hidden"
  })));
};

export var CategoryMenu = function CategoryMenu(_ref2) {
  var shops = _ref2.shops,
      setCategoryMenuContainerMaxHeight = _ref2.setCategoryMenuContainerMaxHeight,
      setParentFocus = _ref2.setParentFocus;
  var isTabletView = useIsTabletView();

  var _useState = useState(0),
      focusItemIndex = _useState[0],
      setFocusItemIndex = _useState[1];

  var keyDownHandler = useCallback(function (e) {
    var _document$getElementB, _document$getElementB2;

    switch (e.code) {
      case "Home":
        e.stopPropagation();
        e.preventDefault();
        (_document$getElementB = document.getElementById("category-menu-item-0")) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.focus();
        setFocusItemIndex(0);
        break;

      case "End":
        e.stopPropagation();
        e.preventDefault();
        (_document$getElementB2 = document.getElementById("category-menu-item-".concat(shops.length - 1))) === null || _document$getElementB2 === void 0 ? void 0 : _document$getElementB2.focus();
        setFocusItemIndex(shops.length - 1);
        break;

      case "ArrowUp":
        e.preventDefault();

        if (typeof setParentFocus === "function" && focusItemIndex === 0) {
          setParentFocus();
        } else {
          var _document$getElementB3;

          (_document$getElementB3 = document.getElementById("category-menu-item-".concat(focusItemIndex - 1))) === null || _document$getElementB3 === void 0 ? void 0 : _document$getElementB3.focus();
          setFocusItemIndex(focusItemIndex - 1);
        }

        break;

      case "ArrowDown":
        e.preventDefault();

        if (focusItemIndex !== shops.length - 1) {
          var _document$getElementB4;

          (_document$getElementB4 = document.getElementById("category-menu-item-".concat(focusItemIndex + 1))) === null || _document$getElementB4 === void 0 ? void 0 : _document$getElementB4.focus();
          setFocusItemIndex(focusItemIndex + 1);
        }

        break;

      case "Escape":
        if (typeof setParentFocus === "function") {
          setParentFocus();
        }

        break;

      case "Tab":
        if (e.shiftKey) {
          var _document$getElementB5;

          e.preventDefault();
          (_document$getElementB5 = document.getElementById("mini-cart")) === null || _document$getElementB5 === void 0 ? void 0 : _document$getElementB5.focus();
        }

        break;

      default:
        break;
    }
  }, [shops.length, focusItemIndex, setParentFocus]);
  var setCurrentItemFocus = useCallback(function () {
    var _document$getElementB6;

    (_document$getElementB6 = document.getElementById("category-menu-item-".concat(focusItemIndex))) === null || _document$getElementB6 === void 0 ? void 0 : _document$getElementB6.focus();
  }, [focusItemIndex]);
  var handleMenuItemClick = useCallback(function (_ref3) {
    var shop = _ref3.shop,
        position = _ref3.position;
    trackMenuClick({
      name: shop.name,
      url: "".concat(location.origin).concat(shop.url, "/"),
      item_path: [{
        name: shop.name,
        id: shop.id,
        position: position
      }]
    });
  }, []);
  return ___EmotionJSX(List, {
    overflow: isTabletView ? "scroll" : "hidden",
    role: "menu",
    m: "0px",
    onKeyDown: keyDownHandler,
    ref: function ref(element) {
      if (setCategoryMenuContainerMaxHeight) {
        var _element$scrollHeight;

        setCategoryMenuContainerMaxHeight((_element$scrollHeight = element === null || element === void 0 ? void 0 : element.scrollHeight) !== null && _element$scrollHeight !== void 0 ? _element$scrollHeight : 0);
      }
    }
  }, shops.map(function (shop, index) {
    var isActive = true && window.location.pathname.replace(/\/$/, "") === shop.url;
    var menuItemProps = {
      key: shop.id,
      role: "menuitem",
      href: shop.url,
      isActive: isActive,
      tabIndex: 0,
      onClick: function onClick() {
        return handleMenuItemClick({
          shop: shop,
          position: index + 1
        });
      }
    };
    var menuIcon = categoryIconMap[shop.slug] || IconSVG;
    var submenuOffsetPx = "".concat(index > 0 ? index * MENU_ITEM_HEIGHT : 0, "px");
    if (isTabletView) return ___EmotionJSX(MenuItem, _extends({}, menuItemProps, {
      "aria-current": isActive ? "page" : undefined
    }), ___EmotionJSX(MenuItemChildren, {
      shop: shop,
      menuIcon: menuIcon
    }));
    return ___EmotionJSX(Popover, {
      key: shop.id,
      placement: "right-start",
      hideOnLeave: true,
      hideOnLeaveInterval: 0,
      hideArrow: true,
      zIndex: POPOVER_ZINDEX,
      enhancedKeyboardNavigation: true
    }, ___EmotionJSX(PopoverControl, null, function (_ref4) {
      var ref = _ref4.ref,
          isOpen = _ref4.isOpen,
          handleOnKeyDown = _ref4.handleOnKeyDown,
          handleOnBlur = _ref4.handleOnBlur,
          handleOnMouseEnter = _ref4.handleOnMouseEnter,
          handleOnMouseLeave = _ref4.handleOnMouseLeave;
      return ___EmotionJSX(MenuItem, _extends({}, menuItemProps, {
        tabIndex: -1,
        "aria-current": isActive ? "page" : undefined,
        ref: ref,
        isSubmenuOpen: isOpen,
        onKeyDown: function onKeyDown(e) {
          if (e.code === "ArrowRight" || e.code === "Enter" || e.code === "Space") {
            e.stopPropagation();
            e.preventDefault();
            setTimeout(function () {
              var _document$getElementB7;

              return (_document$getElementB7 = document.getElementById("show-all-link")) === null || _document$getElementB7 === void 0 ? void 0 : _document$getElementB7.focus();
            }, 100);
            handleOnKeyDown(e);
          }
        },
        onBlur: handleOnBlur,
        onMouseEnter: handleOnMouseEnter,
        onMouseLeave: handleOnMouseLeave,
        id: "category-menu-item-".concat(index)
      }), ___EmotionJSX(MenuItemChildren, {
        shop: shop,
        menuIcon: menuIcon,
        isSubmenuOpen: isOpen
      }));
    }), ___EmotionJSX(Box, {
      position: "relative",
      bottom: submenuOffsetPx,
      left: "-".concat(CATEGORY_MENU_DESKTOP_WIDTH, "px")
    }, ___EmotionJSX(SubmenuPopover, {
      shop: shop,
      itemPathShop: [{
        name: shop.name,
        id: shop.id,
        position: index + 1
      }],
      setParentFocus: setCurrentItemFocus
    })));
  }));
};