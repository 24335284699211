import { createSvgIconComponent } from "../utils";
export var IconFeedbackStars = createSvgIconComponent({
  path: "M12.393 9.111l.786 1.63 1.821.244-1.324 1.263.33 1.752-1.613-.815L10.78 14l.29-1.752-1.283-1.263 1.78-.244.827-1.63zm-6.786 0l.827 1.63 1.78.244-1.283 1.263.29 1.752-1.614-.815L3.993 14l.331-1.752L3 10.985l1.82-.244.787-1.63zM9 3l.786 1.63 1.82.244-1.282 1.263.29 1.752L9 7.074l-1.614.815.29-1.752-1.283-1.263 1.82-.244L9 3z",
  stroke: "currentColor",
  strokeWidth: ".75",
  strokeMiterlimit: "10",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  fill: "none",
  viewBox: "0 0 18 18"
});