// URLs
export var DELIVERY_INFORMATION_URL = "/delivery-information";
export var SIGN_UP_URL = "/register"; // TODO: (SM-1368)
// Change below value to /checkout once new checkout page is ready.

export var CHECKOUT_URL = "/checkout/payment";
export var LOGIN_FORM_URL = "/static/login-form?referrer=/cart"; // Add to cart

export var UNABLE_TO_ADD_TO_CART = "Unable to add to cart";
export var UNABLE_TO_ADD_TO_WISHLIST = "Unable to add to wishlist"; // Remove from cart

export var UNABLE_TO_REMOVE_ITEM = "Unable to remove item from cart";
export var UNABLE_TO_REMOVE_FROM_WISHLIST = "Unable to remove from wishlist"; // Gift card & Coupon

export var MAX_GIFT_CARD_AMOUNT = 5;
export var GIFT_CARD_CODE_VALIDATION_MESSAGE = "Please enter an 18-digit card number.";
export var GIFT_CARD_PIN_VALIDATION_MESSAGE = "PIN has 4 digits.";
export var GIFT_CARD_APPLY_VALIDATION_MESSAGE = "Please enter an 18-digit card number and 4-digit PIN.";
export var GIFT_CARD_SUCCESS_MESSAGE = "Gift card applied successfully";
export var ONEPASS_REWARD_CODE_VALIDATION_MESSAGE = "Please enter an 18-digit reward number.";
export var ONEPASS_REWARD_APPLY_VALIDATION_MESSAGE = "Please enter an 18-digit reward number and 4-digit PIN.";
export var ONEPASS_REWARD_SUCCESS_MESSAGE = "OnePass reward applied successfully";
export var COUPON_SUCCESS_MESSAGE = "Coupon code applied"; // Order Summary

export var MULTIPLICATION_SYMBOL = String.fromCharCode(0x00d7); // General

export var GENERIC_ERROR = "Something went wrong. Please try again.";
export var EMPTY_CART_MESSAGE = "You have no items in your cart.";
export var PAGE_HEADING = "Shopping Cart"; // Analytics

export var CART_SOURCE_ID = "Cart";
export var CHECKOUT_SOURCE_ID = "Checkout";
export var CART_PAGE_TYPE = "cart";
export var ONEPASS_EVENT_ID = 3;
export var CLUB_CTA_ELEMENT_ID = "shipment-1-5-club-catch"; // Legacy Cart Notices

export var LEGACY_CART_NOTICES_QUERY_PARAM_KEY = "cart_notices";
export var LEGACY_CART_NOTICES_DELIMITER = "|";