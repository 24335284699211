import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { BaseRecommenderCarousel, getProductTiles, getRecommenderTrackingData } from "../components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var productsCarouselFactory = function productsCarouselFactory() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      recommenderCarouselProps = _ref.recommenderCarouselProps;

  var _ref2 = recommenderCarouselProps || {},
      carouselProps = _ref2.carouselProps,
      maxTiles = _ref2.maxTiles,
      paginate = _ref2.paginate;

  var ProductsCarouselComponent = function ProductsCarouselComponent(_ref3) {
    var products = _ref3.products,
        sourceId = _ref3.sourceId,
        sourceType = _ref3.sourceType,
        _ref3$fromProduct = _ref3.fromProduct,
        fromProduct = _ref3$fromProduct === void 0 ? {
      from_product_ids: null,
      from_product_offer_id: null,
      from_product_displayed_price: null
    } : _ref3$fromProduct;
    var tiles = getProductTiles({
      products: products,
      sourceType: sourceType,
      sourceId: sourceId,
      fromProduct: fromProduct
    });
    var trackingData = getRecommenderTrackingData(products, "product");
    return ___EmotionJSX(BaseRecommenderCarousel, {
      tiles: tiles,
      trackingData: trackingData,
      sourceType: sourceType,
      fromProduct: fromProduct,
      maxTiles: maxTiles,
      paginate: paginate,
      carouselProps: _objectSpread({
        buttonOffset: [0, 0]
      }, carouselProps)
    });
  };

  return ProductsCarouselComponent;
};