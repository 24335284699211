import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["navigationLinkColumns"];
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Link } from "@catchoftheday/cg-components";
import { Body } from "../primitives";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function AccordionNavigation(_ref) {
  var navigationLinkColumns = _ref.navigationLinkColumns,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, _extends({
    as: "nav"
  }, boxProps), navigationLinkColumns.map(function (_ref2, accordionIndex) {
    var columnTitle = _ref2.columnTitle,
        links = _ref2.links;
    return ___EmotionJSX(Accordion, {
      key: columnTitle,
      showDividerTop: accordionIndex === 0
    }, ___EmotionJSX(AccordionSummary, {
      align: "center"
    }, columnTitle.toUpperCase()), ___EmotionJSX(AccordionDetails, {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "0"
    }, links.map(function (_ref3) {
      var text = _ref3.text,
          url = _ref3.url,
          openInNewWindow = _ref3.openInNewWindow;
      return ___EmotionJSX(Link, {
        key: text,
        target: openInNewWindow ? "_blank" : undefined,
        rel: openInNewWindow ? "noopener noreferrer" : undefined,
        href: decodeURIComponent(url),
        marginBottom: "0.29rem",
        padding: "0.66rem 0"
      }, ___EmotionJSX(Body, {
        margin: 0
      }, text));
    })));
  }));
}