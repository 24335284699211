import React from "react";
import { date } from "@catchoftheday/utilities";
import { StatusRow } from "../../pure/StatusRow";
import { StatusSection } from "../../pure/StatusSection";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PaymentDetailsContainer = function PaymentDetailsContainer(_ref) {
  var active = _ref.active,
      latestMembershipPeriodEnd = _ref.latestMembershipPeriodEnd,
      isTeamMember = _ref.isTeamMember,
      isCancelling = _ref.isCancelling;

  if (!active || isCancelling || isTeamMember || !latestMembershipPeriodEnd) {
    return null;
  }

  return ___EmotionJSX(StatusSection, {
    title: "Payment details"
  }, ___EmotionJSX(StatusRow, {
    label: "Your next billing date is",
    value: date.format(latestMembershipPeriodEnd, "dd MMMM yyyy")
  }));
};