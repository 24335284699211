import { graphql } from "msw";
import { mockPageConfig } from "@common-vendors/mocks/data";
export var handler = graphql.query("PageConfiguration", function (_req, res, ctx) {
  return res(ctx.data({
    __typename: "Query",
    cmsConfiguration: {
      __typename: "CMSConfiguration",
      page: mockPageConfig
    }
  }));
});