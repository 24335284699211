import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "reference", "placement", "offsetX", "offsetY"];
import React, { useCallback, useState } from "react";
import debounce from "lodash.debounce";
import { useTheme } from "@catchoftheday/theme";
import { useUID } from "@catchoftheday/utilities";
import { Box } from "../Box";
import { Popover, PopoverContent, PopoverControl } from "../Popover";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Tooltip = function Tooltip(_ref) {
  var children = _ref.children,
      reference = _ref.reference,
      _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? "bottom" : _ref$placement,
      _ref$offsetX = _ref.offsetX,
      offsetX = _ref$offsetX === void 0 ? 0 : _ref$offsetX,
      _ref$offsetY = _ref.offsetY,
      offsetY = _ref$offsetY === void 0 ? 0 : _ref$offsetY,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(false),
      tooltipShow = _useState[0],
      setTooltipShow = _useState[1]; // eslint-disable-next-line react-hooks/exhaustive-deps


  var debouncedSetTooltipShow = useCallback(debounce(setTooltipShow, 50), []);
  var theme = useTheme();
  var popperElementId = useUID();

  var hideTooltip = function hideTooltip() {
    debouncedSetTooltipShow.cancel();
    debouncedSetTooltipShow(false);
  };

  function onMouseLeaveHandler() {
    hideTooltip();
  }

  function onMouseEnterHandler() {
    debouncedSetTooltipShow.cancel();
    setTooltipShow(true);
  }

  function onKeyDownHandler(event) {
    if (tooltipShow && event.key === "Escape") {
      hideTooltip();
    }
  }

  var offset = /^(bottom|top)/.test(placement) ? [offsetX, offsetY] : [offsetY, offsetX];

  var referenceElement = function referenceElement(_ref2) {
    var ref = _ref2.ref;
    return ___EmotionJSX(Box, {
      ref: ref,
      display: "inline-block",
      onFocus: onMouseEnterHandler,
      onMouseEnter: onMouseEnterHandler,
      onClick: onMouseEnterHandler,
      onBlur: onMouseLeaveHandler,
      onMouseLeave: onMouseLeaveHandler,
      onKeyDown: onKeyDownHandler,
      "aria-describedby": tooltipShow ? popperElementId : undefined
    }, reference);
  };

  var popperElement = function popperElement() {
    var _theme$colors, _theme$colors$ui, _theme$shadows;

    return ___EmotionJSX(Box, {
      bg: "white",
      border: "1px solid",
      borderColor: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : (_theme$colors$ui = _theme$colors["ui"]) === null || _theme$colors$ui === void 0 ? void 0 : _theme$colors$ui["borderColor"],
      borderRadius: "4px",
      p: "10px",
      boxShadow: theme === null || theme === void 0 ? void 0 : (_theme$shadows = theme.shadows) === null || _theme$shadows === void 0 ? void 0 : _theme$shadows["toolTip"],
      onFocus: onMouseEnterHandler,
      onMouseEnter: onMouseEnterHandler,
      onBlur: onMouseLeaveHandler,
      onMouseLeave: onMouseLeaveHandler,
      role: "tooltip",
      id: popperElementId
    }, children);
  };

  return ___EmotionJSX(Popover, _extends({
    isOpen: tooltipShow,
    display: "inline-block",
    placement: placement,
    hideArrow: true,
    hideOnClickOutside: true,
    modifier: [{
      name: "offset",
      enabled: true,
      options: {
        offset: offset
      }
    }]
  }, boxProps), ___EmotionJSX(PopoverControl, null, referenceElement), ___EmotionJSX(PopoverContent, null, popperElement));
};
Tooltip.displayName = "Tooltip";
export default Tooltip;