import React from "react";
import { date, formatPrice, pluralize } from "@catchoftheday/utilities";
import { StatusRow } from "../../pure/StatusRow";
import { StatusSection } from "../../pure/StatusSection";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PlanDetailsContainer = function PlanDetailsContainer(_ref) {
  var _interval$toLowerCase;

  var active = _ref.active,
      activeDaysRemaining = _ref.activeDaysRemaining,
      purchasePrice = _ref.purchasePrice,
      interval = _ref.interval,
      isCancelling = _ref.isCancelling,
      isTeamMember = _ref.isTeamMember,
      latestMembershipPeriodEnd = _ref.latestMembershipPeriodEnd;
  var planDurationUnit = (_interval$toLowerCase = interval === null || interval === void 0 ? void 0 : interval.toLowerCase().slice(0, -2)) !== null && _interval$toLowerCase !== void 0 ? _interval$toLowerCase : "month";
  var defaultPrice = planDurationUnit === "month" ? 400 : 4000;
  var planPrice = formatPrice(purchasePrice !== null && purchasePrice !== void 0 ? purchasePrice : defaultPrice, false);
  var showPlanTypeRow = (active || isTeamMember) && !isCancelling;
  var showRenewalRow = active && !isTeamMember && !isCancelling;
  var showEndDateRow = isCancelling;
  var statusValue = !active ? "No active plan" : isCancelling ? "Cancelled" : "Active";
  return ___EmotionJSX(StatusSection, {
    title: "Plan details"
  }, ___EmotionJSX(StatusRow, {
    label: "Status",
    value: statusValue,
    valueColor: statusValue === "Active" ? "primary" : undefined
  }), showPlanTypeRow && ___EmotionJSX(StatusRow, {
    label: "Plan type",
    value: isTeamMember ? "Team member" : "".concat(planPrice, " per ").concat(planDurationUnit)
  }), showRenewalRow && ___EmotionJSX(StatusRow, {
    label: "Automatic renewal",
    value: "".concat(pluralize(activeDaysRemaining !== null && activeDaysRemaining !== void 0 ? activeDaysRemaining : 0, "day"))
  }), showEndDateRow && ___EmotionJSX(StatusRow, {
    label: "Your benefits will end on",
    value: date.format(latestMembershipPeriodEnd, "do MMMM")
  }));
};