import React from "react";
import { Flex } from "../Flex";
import { IconStarRating } from "../Icon";
import { IconSVG } from "../Icon/icons/IconSVG";
import { jsx as ___EmotionJSX } from "@emotion/react";
var BASE_WIDTH = 122;
var BASE_HEIGHT = 22;
export var StarRating = function StarRating(_ref) {
  var children = _ref.children,
      rating = _ref.rating,
      _ref$starColour = _ref.starColour,
      starColour = _ref$starColour === void 0 ? "#ffb700" : _ref$starColour,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? BASE_WIDTH : _ref$width,
      _ref$backgroundColour = _ref.backgroundColour,
      backgroundColour = _ref$backgroundColour === void 0 ? "#FFF" : _ref$backgroundColour;
  var barWidth = rating / 5 * 100 + 5 * Math.floor(rating);
  var barHeight = Math.round(width / (BASE_WIDTH / BASE_HEIGHT));
  return ___EmotionJSX(Flex, {
    alignItems: "center"
  }, children, ___EmotionJSX(IconSVG, {
    viewBox: "0 0 ".concat(BASE_WIDTH, " ").concat(BASE_HEIGHT),
    preserveAspectRatio: "xMinYMin slice",
    width: "".concat(width, "px"),
    height: "".concat(barHeight, "px"),
    role: "img"
  }, ___EmotionJSX("title", null, "".concat(rating, " Star Rating")), ___EmotionJSX("rect", {
    className: "bg-rect",
    x: "1",
    y: "1",
    width: "".concat(BASE_WIDTH, "px"),
    height: "".concat(BASE_HEIGHT, "px"),
    fill: "#ddd"
  }), ___EmotionJSX("rect", {
    className: "rating-rect",
    x: "1",
    y: "1",
    width: "".concat(barWidth, "px"),
    height: "".concat(BASE_HEIGHT, "px"),
    fill: starColour
  }), ___EmotionJSX(IconStarRating, {
    fill: backgroundColour
  })));
};
StarRating.displayName = "StarRating";
export default StarRating;