import React, { useContext } from "react";
import { Box, Button, FilterListIcon, NotificationBadge, Span } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ToggleFacetsButton = function ToggleFacetsButton(_ref) {
  var totalFacetsApplied = _ref.totalFacetsApplied;

  var _useContext = useContext(CatalogueContext),
      toggleMobileFacets = _useContext.toggleMobileFacets;

  return ___EmotionJSX(Box, {
    width: "50%",
    display: {
      xs: "block",
      md: "none"
    }
  }, ___EmotionJSX(Button, {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    p: "0",
    sizeVariant: "sm",
    textTransform: "none",
    variant: "plain",
    onClick: toggleMobileFacets,
    fontSize: "base"
  }, ___EmotionJSX(FilterListIcon, {
    style: {
      marginRight: "5px"
    }
  }), " Filters", totalFacetsApplied > 0 && ___EmotionJSX(NotificationBadge, {
    text: totalFacetsApplied,
    position: "relative",
    display: "inline-block",
    absTop: "0",
    backgroundColor: "secondary"
  })), ___EmotionJSX(Span, {
    display: "block",
    position: "absolute",
    right: "50%",
    top: "9px",
    width: "1px",
    height: "22px",
    margin: "auto 0",
    backgroundColor: "ui.borderColor"
  }));
};