import { createSvgIconComponent } from "../utils";
export var IconInstagram = createSvgIconComponent({
  viewBox: "0 0 16 16",
  paths: [{
    d: "M11.302,3.728a0.956,0.956 0 1,0 1.912,0a0.956,0.956 0 1,0 -1.912,0"
  }, {
    d: "M7.995 3.88a4.101 4.101 0 1 0 0 8.202 4.101 4.101 0 0 0 0-8.202zm0 6.765a2.664 2.664 0 1 1 0-5.328 2.664 2.664 0 0 1 0 5.328z"
  }, {
    d: "M15.565 2.756A4.085 4.085 0 0 0 13.231.42 5.863 5.863 0 0 0 11.29.05C10.437.01 10.167 0 8 0S5.558 0 4.71.049A5.863 5.863 0 0 0 2.77.42 4.085 4.085 0 0 0 .434 2.756a5.863 5.863 0 0 0-.373 1.94C.024 5.549.014 5.82.014 7.986s0 2.443.048 3.291c.014.663.14 1.32.373 1.94a4.085 4.085 0 0 0 2.334 2.335c.62.242 1.276.377 1.94.4C5.563 15.988 5.833 16 8 16s2.442 0 3.29-.049a5.863 5.863 0 0 0 1.94-.372 4.085 4.085 0 0 0 2.335-2.335c.233-.62.36-1.277.373-1.94.038-.853.048-1.124.048-3.29s0-2.443-.048-3.291a5.863 5.863 0 0 0-.373-1.967zm-1.08 8.456a4.42 4.42 0 0 1-.276 1.497 2.648 2.648 0 0 1-1.519 1.519 4.42 4.42 0 0 1-1.48.275c-.843.038-1.081.049-3.242.049s-2.383 0-3.243-.049a4.42 4.42 0 0 1-1.48-.275 2.642 2.642 0 0 1-1.524-1.519 4.42 4.42 0 0 1-.276-1.48c-.037-.843-.048-1.081-.048-3.243s0-2.382.048-3.242a4.42 4.42 0 0 1 .276-1.496c.27-.7.824-1.252 1.524-1.519a4.42 4.42 0 0 1 1.48-.275c.843-.038 1.081-.05 3.243-.05s2.383 0 3.242.05a4.42 4.42 0 0 1 1.48.275c.698.27 1.25.82 1.519 1.519.176.474.269.974.275 1.48.038.843.049 1.08.049 3.242s0 2.394-.038 3.242h-.01z"
  }]
});