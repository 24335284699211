import React, { useContext } from "react";
import { Box, Flex, IconToolTip, Span } from "@catchoftheday/cg-components";
import { GlobalContext } from "@catchoftheday/common-vendors";
import { formatPrice } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceWas = function ProductPriceWas(_ref) {
  var priceLabel = _ref.priceLabel,
      topLabel = _ref.topLabel,
      retailPriceDisclaimer = _ref.retailPriceDisclaimer,
      hasClubSellPrice = _ref.hasClubSellPrice,
      large = _ref.large;

  var _useContext = useContext(GlobalContext),
      setIsPriceTagModalOpen = _useContext.priceTagModal.setIsPriceTagModalOpen;

  var dontPayLabel = topLabel === null || topLabel === void 0 ? void 0 : topLabel.toLowerCase();
  var label = priceLabel.wasPrice ? "Was ".concat(formatPrice(priceLabel.wasPrice.amount, false)) : (dontPayLabel === null || dontPayLabel === void 0 ? void 0 : dontPayLabel.includes("don't pay")) && "".concat(dontPayLabel.replace("don't pay", "RRP"));

  if (!label) {
    if (hasClubSellPrice) {
      // If OnePass price is displayed with don't need the spacer
      return null;
    }

    if (!large) {
      // Spacer for consistent price heights across cards
      return ___EmotionJSX(Box, {
        height: "18px",
        mt: 1
      });
    }

    return null;
  }

  return ___EmotionJSX(Flex, {
    alignItems: "center",
    height: "18px",
    mb: large ? 0 : 1
  }, ___EmotionJSX(Span, {
    fontSize: "xs",
    color: "textTertiary"
  }, label), !!retailPriceDisclaimer && ___EmotionJSX(IconToolTip, {
    "aria-label": "Price disclaimer",
    tabIndex: 0,
    width: "12px",
    height: "12px",
    ml: 1,
    color: "textTertiary",
    cursor: "pointer",
    onClick: function onClick(event) {
      event.preventDefault();
      setIsPriceTagModalOpen(true);
    }
  }));
};