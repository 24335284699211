import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconSecurity = createSvgIconComponent({
  children: [___EmotionJSX("rect", {
    key: "part-1",
    x: "5.5",
    y: "11.5",
    width: "19",
    height: "16.5",
    rx: "2",
    stroke: "currentColor"
  }), ___EmotionJSX("circle", {
    key: "part-2",
    cx: "15",
    cy: "20",
    r: "1.5",
    stroke: "currentColor"
  }), ___EmotionJSX("path", {
    key: "part-3",
    d: "M9 12C9 12 9 10.253 9 8.78571C9 5.59035 11.6863 3 15 3C18.3137 3 21 5.59035 21 8.78571C21 10.253 21 12 21 12",
    stroke: "currentColor"
  })],
  viewBox: "0 0 30 30",
  fill: "none"
});