var productPriceSplit = function productPriceSplit(value) {
  var roundCents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var priceSplit = (value / 100).toFixed(2).split(".");
  var dollars = priceSplit[0];

  if (roundCents && priceSplit[1] === "00") {
    return {
      dollars: dollars
    };
  }

  var cents = priceSplit[1];
  return {
    dollars: dollars,
    cents: cents
  };
};

export default productPriceSplit;