import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "onClick"];
import React from "react";
import { Button } from "@catchoftheday/cg-components";
import { triggerOnePassUpsellModal } from "@catchoftheday/state";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellButton = function UpsellButton(_ref) {
  var children = _ref.children,
      _onClick = _ref.onClick,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Button, _extends({
    variant: "onePassPrimary",
    sizeVariant: "sm",
    onClick: function onClick(e) {
      triggerOnePassUpsellModal();
      _onClick && _onClick(e);
    },
    textTransform: "uppercase",
    fontSize: {
      xs: "xs",
      md: "base"
    },
    fontWeight: "normal"
  }, props), children !== null && children !== void 0 ? children : "Join to Buy");
};