import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["showText"];

var _templateObject;

import React from "react";
import { Box, Flex, IconAdultsOnly, Span, Text } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var BlurBox = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  backdrop-filter: blur(12px);\n  -webkit-backdrop-filter: blur(12px);\n  z-index: 10;\n"])));
export var ProductCardAdultBlur = function ProductCardAdultBlur(_ref) {
  var _ref$showText = _ref.showText,
      showText = _ref$showText === void 0 ? true : _ref$showText,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(BlurBox, _extends({
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    px: 8
  }, props), ___EmotionJSX(Box, null, ___EmotionJSX(IconAdultsOnly, {
    width: "32px",
    height: "32px",
    title: "Adult Content"
  }), showText && ___EmotionJSX(Text, {
    fontWeight: "semibold"
  }, "Adult Content", ___EmotionJSX("br", null), ___EmotionJSX(Span, {
    fontSize: "xs"
  }, "Adjust Safe Search settings in search filters"))));
};