import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CARD_HEIGHT = {
  xs: 280,
  md: 390
};
export var Card = function Card(props) {
  return ___EmotionJSX(Flex, {
    mx: {
      xs: 2,
      md: 3
    },
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "stretch"
  }, ___EmotionJSX(Box, _extends({
    height: {
      xs: "".concat(CARD_HEIGHT.xs, "px"),
      md: "".concat(CARD_HEIGHT.md, "px")
    },
    borderRadius: "default",
    borderColor: "ui.borderColor",
    border: 1,
    overflow: "hidden",
    backgroundColor: "ui.backgroundColorSecondary"
  }, props)));
};