import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconCreditCardOutline = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    strokeMiterlimit: "10",
    d: "M27.8 23.5H2.2c-1 0-1.7-.8-1.7-1.7V8.2c0-1 .8-1.7 1.7-1.7h25.5c1 0 1.7.8 1.7 1.7v13.5c.1 1-.7 1.8-1.6 1.8z"
  }), ___EmotionJSX("path", {
    key: "part-2",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    d: "M4 15.5h10M20 15.5h5M4 18.5h4"
  }), ___EmotionJSX("path", {
    key: "part-3",
    strokeMiterlimit: "10",
    d: "M0 11.5h30"
  })],
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 30 30"
});