import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, Flex, IconX } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var CloseIconContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  :focus {\n    outline: 1px solid white;\n  }\n"])));
export var SalesRulePromotionNotice = function SalesRulePromotionNotice(_ref) {
  var _ref$showCloseButton = _ref.showCloseButton,
      showCloseButton = _ref$showCloseButton === void 0 ? false : _ref$showCloseButton,
      hideNotice = _ref.hideNotice,
      containerProps = _ref.containerProps,
      children = _ref.children;
  return ___EmotionJSX(Flex, _extends({
    "aria-label": "sales promotion cart notice",
    py: 2,
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  }, containerProps), children, showCloseButton && ___EmotionJSX(CloseIconContainer, {
    role: "button",
    "aria-label": "close button",
    tabIndex: 0,
    position: "absolute",
    right: 0,
    cursor: "pointer",
    color: "white",
    zIndex: 1,
    onClick: hideNotice
  }, ___EmotionJSX(IconX, {
    height: 28,
    width: 28
  })));
};