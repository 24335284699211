import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import { Box } from "@catchoftheday/cg-components";
import { AppContextProvider, CartContextProvider, PageConfigurationContextProvider } from "@catchoftheday/common-vendors";
import { CustomerContext } from "@catchoftheday/customer";
import { URL_ONEPASS_SHOP } from "../../../consts";
import { OnePassConfigurationContextProvider, OnePassHeroDealsContextProvider } from "../../../contexts";
import { generateOnePassConsentUrl } from "../../../utilities";
import { MemberBenefitsContainer } from "../MemberBenefitsContainer";
import { MigrationNudgeContainer } from "../MigrationNudgeContainer";
import { ShopBannerStripContainer } from "../ShopBannerStripContainer";
import { ShopExclusiveDealsContainer } from "../ShopExclusiveDealsContainer";
import { ShopFeaturedProductsContainer } from "../ShopFeaturedProductsContainer";
import { ShopHeroBannerContainer } from "../ShopHeroBannerContainer";
import { ShopPageSEOContainer } from "../ShopPageSEOContainer";
import { ShopRecommendersContainer } from "../ShopRecommendersContainer";
import { ShopUpcomingDealsContainer } from "../ShopUpcomingDealsContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopPageContainer = function ShopPageContainer(_ref) {
  var host = _ref.host,
      productsCarousels = _ref.productsCarousels,
      ProductsCarouselComponent = _ref.ProductsCarouselComponent;
  var searchParams = useSearchParams();

  var _useState = useState({}),
      currentQuery = _useState[0],
      setCurrentQuery = _useState[1];

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data;

  var _ref2 = customerData || {},
      membership = _ref2.membership;

  useEffect(function () {
    if (searchParams.size > 0) {
      searchParams.forEach(function (value, key) {
        return setCurrentQuery(_objectSpread(_objectSpread({}, currentQuery), {}, _defineProperty({}, key, value)));
      });
    }
  }, [currentQuery, searchParams, searchParams.size]);
  var bestSeller = productsCarousels.bestSeller,
      trending = productsCarousels.trending,
      recentlyViewed = productsCarousels.recentlyViewed;
  return ___EmotionJSX(AppContextProvider, {
    contexts: [OnePassConfigurationContextProvider, PageConfigurationContextProvider, OnePassHeroDealsContextProvider, CartContextProvider]
  }, ___EmotionJSX(ShopPageSEOContainer, {
    host: host
  }), ___EmotionJSX(ShopBannerStripContainer, null), ___EmotionJSX(ShopHeroBannerContainer, {
    host: host
  }), ___EmotionJSX(ShopFeaturedProductsContainer, {
    ProductsCarouselComponent: ProductsCarouselComponent
  }), (membership === null || membership === void 0 ? void 0 : membership.eligibleForMigration) && ___EmotionJSX(Box, {
    maxWidth: "1260px",
    py: 4,
    px: 3,
    m: "0 auto"
  }, ___EmotionJSX(MigrationNudgeContainer, {
    CTAUrl: generateOnePassConsentUrl(URL_ONEPASS_SHOP, currentQuery)
  })), ___EmotionJSX(ShopUpcomingDealsContainer, null), ___EmotionJSX(MemberBenefitsContainer, null), ___EmotionJSX(ShopRecommendersContainer, {
    bestSellerProductsNode: bestSeller,
    trendingProductsNode: trending,
    ProductsCarouselComponent: ProductsCarouselComponent
  }), ___EmotionJSX(ShopExclusiveDealsContainer, {
    recentlyViewedProductsComponent: recentlyViewed
  }));
};