import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];

/**
 * Global Alert Component
 */
import { useContext, useEffect } from "react";
import { useUID } from "@catchoftheday/utilities";
import { GlobalAlertContext } from "./GlobalAlertContext";
export function GlobalAlert(props) {
  var id = useUID();
  var globalAlertMap = useContext(GlobalAlertContext); // Children can't be stringified (circular reference) so we need to remove it

  var children = props.children,
      alertProps = _objectWithoutProperties(props, _excluded);

  var alertPropsStringified = JSON.stringify(alertProps);
  useEffect(function () {
    if (!globalAlertMap) {
      console.warn("Attempted to add global alert without GlobalAlertProvider");
      return;
    } // Add alert to map on mount


    globalAlertMap.set(id, {
      props: alertProps,
      children: children
    });
    return function () {
      globalAlertMap["delete"](id); // Remove the alert from the map when it's unmounted
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, alertPropsStringified]);
  useEffect(function () {
    if (!globalAlertMap) return;
    var alert = globalAlertMap.get(id);
    if (!alert) return; // Always ensure the children are up-to-date

    alert.children = children;
  });
  return null;
}