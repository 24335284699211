import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { Benefits } from "./Benefits";
import { ContentWrapper } from "./ContentWrapper";
import { Divisions } from "./Divisions";
import { FAQs } from "./FAQs";
import { HeaderBanner } from "./HeaderBanner";
import { HowToTransfer } from "./HowToTransfer";
import { SEO } from "./SEO";
import { TransferReason } from "./TransferReason";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MigrationPage = function MigrationPage(_ref) {
  var host = _ref.host;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SEO, {
    host: host
  }), ___EmotionJSX(Box, {
    fontFamily: "onePass"
  }, ___EmotionJSX(HeaderBanner, null), ___EmotionJSX(ContentWrapper, null, ___EmotionJSX(Divisions, null), ___EmotionJSX(Benefits, null), ___EmotionJSX(TransferReason, null), ___EmotionJSX(HowToTransfer, null), ___EmotionJSX(FAQs, null))));
};