import { emitSearchAction } from "@catchoftheday/analytics";
import { encodeSearchTerm } from "@catchoftheday/utilities";
import { SEARCH_PAGE_PATH } from "../../consts";
import { updateRecentSearches } from "./updateRecentSearches";
export function navigateToSearchPage(_ref) {
  var term = _ref.term,
      inputTerm = _ref.inputTerm,
      sourceType = _ref.sourceType,
      category = _ref.category,
      redirectUrl = _ref.redirectUrl;
  if (!term || term.length == 1) return;
  updateRecentSearches(term);
  var encodedSearchTerm = encodeSearchTerm(term);
  var query = "".concat(SEARCH_PAGE_PATH, "?query=").concat(encodedSearchTerm);
  var params = "".concat(category ? "&f.group_id=".concat(encodeURIComponent(category.path)) : "").concat(sourceType ? "&st=".concat(sourceType) : "");
  var url = "".concat(query).concat(params);
  var isSpecialSearch = !!(redirectUrl || category);
  var specialSearchDetails = isSpecialSearch ? {
    type: redirectUrl ? "redirect" : "search_in_category",
    category: category === null || category === void 0 ? void 0 : category.name,
    redirect_path: redirectUrl || undefined
  } : undefined;
  emitSearchAction({
    user_text: inputTerm,
    is_typehead: term !== inputTerm,
    final_text: term,
    is_special_search: isSpecialSearch,
    special_search_details: specialSearchDetails
  });

  if (redirectUrl) {
    return location.assign(redirectUrl);
  }

  window.location.assign(url);
}