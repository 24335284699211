import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useEffect, useState } from "react";
import { SOURCE_TYPE_SEARCH_NO_RESULTS } from "@catchoftheday/analytics";
import { Box, Heading, IconSearch } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { sessionStorage } from "@catchoftheday/utilities";
import { SEARCH_PAGE_PATH, TRENDING_TERMS_KEY } from "../../../../consts";
import { SearchPillNavigation } from "../../SearchPillNavigation/SearchPillNavigation";
import { jsx as ___EmotionJSX } from "@emotion/react";
var TrendingTerm = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:hover {\n    text-decoration: underline;\n  }\n"])));
export var TrendingSearches = function TrendingSearches() {
  var _useState = useState([]),
      terms = _useState[0],
      setTerms = _useState[1];

  useEffect(function () {
    var trendingTerms = sessionStorage.getItem(TRENDING_TERMS_KEY);

    if (trendingTerms) {
      var _terms = JSON.parse(trendingTerms).map(function (term) {
        return {
          name: ___EmotionJSX(TrendingTerm, null, ___EmotionJSX(IconSearch, {
            width: "14px",
            height: "14px",
            mr: 1
          }), term),
          url: "".concat(SEARCH_PAGE_PATH, "/?query=").concat(term, "&st=").concat(SOURCE_TYPE_SEARCH_NO_RESULTS),
          selected: false
        };
      });

      setTerms(_terms);
    }
  }, []);

  if (terms.length === 0) {
    return null;
  }

  return ___EmotionJSX(Box, {
    px: 3
  }, ___EmotionJSX(Heading.h2, {
    fontSize: {
      xs: "lg",
      md: "2xl"
    }
  }, "Try these common search terms"), ___EmotionJSX(SearchPillNavigation, {
    items: terms,
    pillContainerProps: {
      borderColor: "brand.primary",
      color: "brand.primary",
      borderWidth: "2px",
      hoverColor: "brand.primary",
      hoverBackgroundColor: "white",
      height: "44px"
    }
  }));
};