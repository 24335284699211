import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useEffect } from "react";
import { Box, Flex, Heading, Image, Link } from "@catchoftheday/cg-components";
import { useNoResultsCategoryNavQuery } from "../../../../generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryNav = function CategoryNav(_ref) {
  var sourceType = _ref.sourceType,
      _ref$domain = _ref.domain,
      domain = _ref$domain === void 0 ? "" : _ref$domain;

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      navCategories = _React$useState2[0],
      setNavCategories = _React$useState2[1];

  var _useNoResultsCategory = useNoResultsCategoryNavQuery({
    variables: {
      domain: domain
    }
  }),
      data = _useNoResultsCategory.data;

  useEffect(function () {
    var navData = data === null || data === void 0 ? void 0 : data.cmsConfiguration.header.megaMenu.navData.shops;

    if (navData !== null && navData !== void 0 && navData.length) {
      var _navCategories = navData.filter(function (item) {
        return !!item.image;
      }).map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          image: item.image
        });
      });

      setNavCategories(_navCategories);
    }
  }, [data]);
  return ___EmotionJSX(Box, {
    display: "inline-block",
    mb: 4,
    px: 3,
    width: "100%"
  }, ___EmotionJSX(Heading.h2, {
    fontSize: {
      xs: "lg",
      md: "2xl"
    }
  }, "Browse by category"), ___EmotionJSX(Box, {
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(2, 1fr)",
      md: "repeat(4, 1fr)",
      lg: "repeat(6, 1fr)"
    },
    gap: 4
  }, navCategories.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        url = _ref2.url,
        image = _ref2.image;
    return ___EmotionJSX(Link, {
      key: id,
      href: "".concat(url).concat(sourceType ? "?st=".concat(sourceType) : ""),
      color: "typography.text"
    }, ___EmotionJSX(Flex, {
      flexDirection: {
        xs: "row",
        md: "column"
      },
      alignItems: "center",
      justifyContent: {
        xs: "flex-start",
        md: "center"
      }
    }, ___EmotionJSX(Image, {
      src: image,
      alt: name,
      maxHeight: {
        xs: "40px",
        md: "none"
      },
      width: {
        xs: "auto",
        md: "100%"
      },
      mr: {
        xs: 1,
        md: 0
      }
    }), ___EmotionJSX(Box, {
      mt: {
        xs: 0,
        md: 2
      },
      fontSize: {
        xs: "sm",
        md: "base"
      }
    }, name)));
  })));
};