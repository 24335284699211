import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, Flex, Heading, IconOnePassBg } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var BgIcon = styled(IconOnePassBg)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mix-blend-mode: overlay;\n"])));
export var ModalContent = function ModalContent(_ref) {
  var children = _ref.children;
  return ___EmotionJSX(Flex, {
    zIndex: 1000,
    flexDirection: {
      xs: "column",
      md: "row"
    },
    justifyContent: "center",
    alignItems: "center"
  }, ___EmotionJSX(Flex, {
    position: "relative",
    alignItems: {
      xs: "center",
      md: "flex-end"
    },
    px: {
      xs: "20px",
      md: "32px"
    },
    backgroundColor: "onePass.primaryDarker",
    alignSelf: "stretch",
    justifyContent: "center"
  }, ___EmotionJSX(Heading.h1, {
    fontSize: {
      xs: "base",
      md: "2xl"
    },
    textAlign: {
      xs: "center",
      md: "left"
    },
    color: "textWhite",
    fontWeight: "bold",
    fontFamily: "onePass",
    mb: {
      xs: undefined,
      md: "60px",
      lg: "90px"
    }
  }, "Get FREE delivery on these great products!"), ___EmotionJSX(Box, {
    display: {
      xs: "none",
      md: "block"
    },
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%"
  }, ___EmotionJSX(BgIcon, {
    name: "OnePass background",
    fill: "white",
    width: "auto",
    height: {
      xs: "100%",
      md: "40%",
      lg: "50%"
    },
    opacity: 0.5,
    role: "img"
  }))), ___EmotionJSX(Flex, {
    pt: "12px",
    width: {
      xs: "100%",
      md: "75%"
    }
  }, children));
};