import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isMobile", "pageType", "bannerData"];
import React, { useContext } from "react";
import { emitBannerClick } from "@catchoftheday/analytics";
import { Flex, ImageResponsive, Link } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageBanner = function PageBanner(_ref) {
  var _customerData$members;

  var isMobile = _ref.isMobile,
      pageType = _ref.pageType,
      bannerData = _ref.bannerData,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data;

  var _useTheme = useTheme(),
      breakpoints = _useTheme.breakpoints,
      maxWidths = _useTheme.maxWidths;

  var isMigrationEligible = customerData === null || customerData === void 0 ? void 0 : (_customerData$members = customerData.membership) === null || _customerData$members === void 0 ? void 0 : _customerData$members.eligibleForMigration;
  var banners = bannerData.filter(function (banner) {
    return banner.platforms.some(function (platform) {
      return platform === "DESKTOP" || platform === "MOBILE";
    });
  });

  if (!(banners !== null && banners !== void 0 && banners.length) || isMigrationEligible) {
    return null;
  }

  var desktopBanners = banners === null || banners === void 0 ? void 0 : banners.filter(function (banner) {
    return banner.platforms.some(function (platform) {
      return platform === "DESKTOP";
    });
  });
  var mobileBanners = banners === null || banners === void 0 ? void 0 : banners.filter(function (banner) {
    return banner.platforms.some(function (platform) {
      return platform === "MOBILE";
    });
  });

  var renderBanners = function renderBanners(banners) {
    return ___EmotionJSX(React.Fragment, null, banners.length > 0 && banners.map(function (banner, index) {
      return ___EmotionJSX(Flex, _extends({
        key: banner.id,
        maxWidth: maxWidths.cotdWebsite,
        mb: "30px",
        overflow: "hidden",
        px: "10px",
        onClick: function onClick() {
          return banner.image.url && emitBannerClick({
            id: 2,
            name: banner.image.url.substring(banner.image.url.lastIndexOf("/") + 1),
            targetUrl: banner.image.url,
            position: index + 1
          });
        },
        justifyContent: "center"
      }, props), ___EmotionJSX(Link, {
        href: banner.url
      }, ___EmotionJSX(ImageResponsive, {
        src: banner.image.url,
        alt: banner.image.altText || "promotional banner",
        maxHeight: "192px",
        maxWidth: breakpoints.xl,
        width: "auto",
        height: {
          xs: "calc((100vw - 20px) / ".concat(banner.image.width / banner.image.height, ")"),
          xl: "calc(".concat(breakpoints.xl, " / ").concat(banner.image.width / banner.image.height, ")")
        },
        lazyLoad: true,
        margin: "0 auto"
      })));
    }));
  };

  if (isMobile) {
    return renderBanners(mobileBanners);
  } else {
    return renderBanners(desktopBanners);
  }
};