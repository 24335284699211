import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isActiveMember", "isFreeTrialEligible", "mainCTAHref", "onMainCTAClick", "showSecondaryCTA", "isLoggedIn"];
import React from "react";
import { IconOnePass, LinkButton, Text } from "@catchoftheday/cg-components";
import { rem } from "@catchoftheday/theme";
import { UpsellSecondaryCTA } from "../UpsellSecondaryCTA";
import { Benefits } from "./Benefits";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FbcSfopContent = function FbcSfopContent(_ref) {
  var isActiveMember = _ref.isActiveMember,
      isFreeTrialEligible = _ref.isFreeTrialEligible,
      mainCTAHref = _ref.mainCTAHref,
      onMainCTAClick = _ref.onMainCTAClick,
      showSecondaryCTA = _ref.showSecondaryCTA,
      isLoggedIn = _ref.isLoggedIn,
      secondaryCTAProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconOnePass, {
    height: rem("32px"),
    width: "auto",
    color: "onePass.primary",
    "aria-label": "OnePass",
    mb: 4
  }), ___EmotionJSX(Text, {
    fontSize: "xs",
    fontWeight: "medium",
    color: "text",
    textAlign: "center",
    mt: 0,
    mb: 3
  }, "OnePass subscription benefits at Catch"), ___EmotionJSX(Benefits, {
    isFbcSfop: true
  }), !isActiveMember && ___EmotionJSX(LinkButton, {
    my: 3,
    href: mainCTAHref,
    variant: "onePassPrimary",
    sizeVariant: "sm" // NOTE: have to do this since setting responsive sizeVariant overrides the fontweight
    ,
    fontWeight: {
      xs: "semibold",
      md: "semibold"
    },
    textTransform: "uppercase",
    whiteSpace: "wrap",
    maxWidth: "245px",
    rounded: true,
    onClick: onMainCTAClick
  }, isFreeTrialEligible ? "Start your free trial" : "Join OnePass"), showSecondaryCTA && ___EmotionJSX(UpsellSecondaryCTA, _extends({
    tight: true,
    isLoggedIn: isLoggedIn
  }, secondaryCTAProps)), isFreeTrialEligible && ___EmotionJSX(Text, {
    fontSize: "xs",
    fontWeight: "normal",
    textAlign: "center",
    color: "textSecondary",
    my: 2
  }, "Try it free, cancel anytime."), ___EmotionJSX(Text, {
    fontSize: "xs",
    fontWeight: "normal",
    textAlign: "center",
    color: "onePass.textGrey",
    mt: 0
  }, "*T&Cs, Exclusions apply."));
};