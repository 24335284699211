import React, { useCallback, useContext } from "react";
import { useRouter } from "next/router";
import { emitUpsellNudgeAction, emitUpsellNudgeImpression } from "@catchoftheday/analytics";
import { CustomerContext } from "@catchoftheday/customer";
import { triggerOnePassLinkingModal, triggerOnePassUpsellModal } from "@catchoftheday/state";
import { useIsInViewport } from "@catchoftheday/utilities";
import { isActiveOnePassMember, isMembershipFreeTrialEligible } from "../../../utilities";
import { UpsellCTAWidget } from "../../pure/UpsellCTAWidget";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellCTAWidgetContainer = function UpsellCTAWidgetContainer(_ref) {
  var _customer$membership;

  var isPlurals = _ref.isPlurals,
      _ref$useOnePassVarian = _ref.useOnePassVariant,
      useOnePassVariant = _ref$useOnePassVarian === void 0 ? true : _ref$useOnePassVarian,
      widgetLayoutProps = _ref.widgetLayoutProps;

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data,
      loggedIn = _useContext.loggedIn;

  var _useRouter = useRouter(),
      asPath = _useRouter.asPath;

  var isFreeTrialEligible = isMembershipFreeTrialEligible(customer);
  var isActiveMembership = isActiveOnePassMember(customer);
  var isLinked = customer === null || customer === void 0 ? void 0 : (_customer$membership = customer.membership) === null || _customer$membership === void 0 ? void 0 : _customer$membership.linked;
  var showSecondaryCTA = !loggedIn || !isLinked;
  var onMainCTAClick = useCallback(function () {
    emitUpsellNudgeAction(isFreeTrialEligible ? "freeTrial" : "join");
    triggerOnePassUpsellModal();
  }, [isFreeTrialEligible]);
  var onLinkButtonClick = useCallback(function () {
    emitUpsellNudgeAction("link");
    triggerOnePassLinkingModal({
      originalUrl: asPath
    });
  }, [asPath]);
  var onLoginClick = useCallback(function () {
    emitUpsellNudgeAction("login");
  }, []);
  var trackImpression = useCallback(function () {
    if (!isActiveMembership) {
      emitUpsellNudgeImpression();
    }
  }, [isActiveMembership]);

  var _useIsInViewport = useIsInViewport({
    onComponentSeen: trackImpression
  }),
      upsellComponent = _useIsInViewport.ref;

  return ___EmotionJSX(UpsellCTAWidget, {
    ref: upsellComponent,
    useOnePassVariant: useOnePassVariant,
    isLoggedIn: loggedIn,
    isActiveMembership: isActiveMembership,
    isFreeTrialEligible: isFreeTrialEligible,
    showSecondaryCTA: showSecondaryCTA,
    isPlurals: isPlurals,
    widgetLayoutProps: widgetLayoutProps,
    onMainCTAClick: onMainCTAClick,
    onLinkClick: onLinkButtonClick,
    onLoginClick: onLoginClick
  });
};