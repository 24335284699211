import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconHomeOutline = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M19 22H5a1 1 0 01-1-1v-8H2l9.292-9.293a1 1 0 011.415 0L22 13h-2v8a1 1 0 01-1 1zm-9-2h8v-8.172l-6-6-6 6V20h4z",
    fill: "currentColor"
  }), ___EmotionJSX("rect", {
    key: "part-2",
    x: "14",
    y: "13",
    width: "2",
    height: "2",
    rx: ".5",
    fill: "currentColor"
  })],
  viewBox: "0 0 24 24"
});