import React, { useState } from "react";
import { Flex, Link } from "@catchoftheday/cg-components";
import { HOTLINE_ONEPASS } from "../../../../consts";
import { FAQItem } from "./FAQItem";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FAQ_ITEMS = [{
  order: 1,
  question: "I'm already a OnePass member with Catch, so why do I need to create a new account?",
  answer: ___EmotionJSX(React.Fragment, null, "The OnePass membership provider is changing from Catch to Wesfarmers One Pass Pty Ltd., the operator of OnePass, to bring you more benefits. To access your new membership benefits, such as free delivery on eligible items or orders when shopping at Kmart, Target, Bunnings Warehouse and Officeworks, you'll need to create a new OnePass account.", ___EmotionJSX("br", null), ___EmotionJSX("br", null), "After creating a new account, your payment will transfer across to the new provider and you will no longer be billed by Catch. You'll also start to see Wesfarmers appear on your bank statement.")
}, {
  order: 2,
  question: "What happens after I create a new account with OnePass?",
  answer: ___EmotionJSX(React.Fragment, null, "You'll receive immediate access to your new benefits like free delivery on eligible items!", ___EmotionJSX("br", null), ___EmotionJSX("br", null), "Your membership, including your subscription payment, will move across to the new provider and you'll start to see Wesfarmers appear on your bank statement. We'll also notify you of more Kmart, Target, Bunnings Warehouse and Officeworks benefits from OnePass along with personalised communications and advertising from OnePass and participating brands.")
}, {
  order: 3,
  question: "What will happen if I don't create a new OnePass account?",
  answer: "You'll still have access to your current membership benefits at Catch for the time being; however, you will not get access to any new benefits, such as free delivery when you shop at Kmart, Target, Bunnings Warehouse and Officeworks."
}, {
  order: 4,
  question: "Why do you need to share my personal information and payment details with the new provider?",
  answer: ___EmotionJSX(React.Fragment, null, "The OnePass membership provider is changing from Catch to Wesfarmers One Pass Pty Ltd to better deliver you member benefits from Catch, Kmart, Target, Bunnings Warehouse and Officeworks with a personalised and streamlined experience.", ___EmotionJSX("br", null), ___EmotionJSX("br", null), "Your payment details, purchasing and browsing history will be transferred as part of your account setup.")
}, {
  order: 5,
  question: "What if I already have a OnePass account set up with the new provider?",
  answer: "No problems! Please continue with the instructions to set up a new OnePass account. Once complete, we\u2019ll cancel your subscription with Catch OnePass (previously Club Catch) to ensure you are billed correctly."
}, {
  order: 6,
  question: "What happens if there's been an error when I've tried to create a new OnePass account?",
  answer: ___EmotionJSX(React.Fragment, null, "If you experience an error when creating a new account, refresh your browser and try again. If you're still having difficulties, contact the", ___EmotionJSX(Link, {
    color: "onePass.primary",
    hoverColor: "onePass.primaryDark",
    fontWeight: "bold",
    mx: 1,
    href: "https://help.catch.com.au/s/",
    style: {
      whiteSpace: "pre"
    },
    textDecoration: "underline"
  }, "Catch Help Centre"), "or call our priority customer service line: ", HOTLINE_ONEPASS, ".")
}];
export var FAQsList = function FAQsList() {
  var _useState = useState(null),
      showIndex = _useState[0],
      setShowIndex = _useState[1];

  var _handleToggle = function handleToggle(faqIndex) {
    setShowIndex(showIndex === faqIndex ? null : faqIndex);
  };

  return ___EmotionJSX(Flex, {
    margin: 0,
    flexDirection: {
      xs: "column",
      md: "row"
    }
  }, ___EmotionJSX(Flex, {
    flexDirection: "column",
    flex: "1",
    pr: {
      md: 8
    }
  }, FAQ_ITEMS.slice(0, 3).map(function (faq) {
    return ___EmotionJSX(FAQItem, {
      key: "onepass-consent-faq-".concat(faq.order),
      question: faq.question,
      answer: faq.answer,
      faqId: faq.order,
      handleToggle: function handleToggle() {
        return _handleToggle(faq.order);
      },
      show: showIndex === faq.order
    });
  })), ___EmotionJSX(Flex, {
    flexDirection: "column",
    flex: "1",
    pl: {
      md: 8
    }
  }, FAQ_ITEMS.slice(3).map(function (faq) {
    return ___EmotionJSX(FAQItem, {
      key: "onepass-consent-faq-".concat(faq.order),
      question: faq.question,
      answer: faq.answer,
      faqId: faq.order,
      handleToggle: function handleToggle() {
        return _handleToggle(faq.order);
      },
      show: showIndex === faq.order
    });
  })));
};