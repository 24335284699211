import { PRODUCT_SUGGESTION_INDEX_NAME, RECENTLY_VIEWED_PRODUCTS_POD_ID, SEARCH_INPUT_PLACEHOLDER, SEARCH_SUGGESTION_INDEX_NAME, SEARCH_TRENDING_PRODUCTS_POD_ID, SEARCH_TRENDING_TERM_POD_ID } from "./index";
export var constructorConfig = {
  placeholder: SEARCH_INPUT_PLACEHOLDER,
  sections: [{
    indexSectionName: SEARCH_SUGGESTION_INDEX_NAME,
    numResults: 12
  }, {
    indexSectionName: PRODUCT_SUGGESTION_INDEX_NAME,
    numResults: 4
  }],
  zeroStateSections: [{
    indexSectionName: SEARCH_SUGGESTION_INDEX_NAME,
    podId: SEARCH_TRENDING_TERM_POD_ID,
    type: "recommendations",
    numResults: 12
  }, {
    indexSectionName: PRODUCT_SUGGESTION_INDEX_NAME,
    podId: SEARCH_TRENDING_PRODUCTS_POD_ID,
    type: "recommendations",
    numResults: 4
  }, {
    indexSectionName: PRODUCT_SUGGESTION_INDEX_NAME,
    podId: RECENTLY_VIEWED_PRODUCTS_POD_ID,
    type: "recommendations",
    numResults: 4
  }],
  advancedParameters: {
    displaySearchSuggestionImages: true,
    numTermsWithGroupSuggestions: 1,
    numGroupsSuggestedPerTerm: 2
  }
};