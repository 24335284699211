import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useCallback } from "react";
import { emitSalesRuleTagClick, emitSalesRuleTagImpression } from "@catchoftheday/analytics";
import { Flex, IconTickCircle, Link, Truncate } from "@catchoftheday/cg-components";
import { useIsInViewport } from "@catchoftheday/utilities";
import { SalesRulePromotionTagDisclaimer } from "../SalesRulePromotionTagDisclaimer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SalesRulePromotionTag = function SalesRulePromotionTag(_ref) {
  var salesRuleId = _ref.salesRuleId,
      title = _ref.title,
      disclaimerTitle = _ref.disclaimerTitle,
      eventSlug = _ref.eventSlug,
      eventLink = _ref.eventLink,
      isApplied = _ref.isApplied,
      _ref$sourceId = _ref.sourceId,
      sourceId = _ref$sourceId === void 0 ? "tag" : _ref$sourceId,
      containerProps = _ref.containerProps,
      iconProps = _ref.iconProps;
  var tagUrl = eventLink || "/event/".concat(eventSlug);
  var trackingOptions = {
    sales_rule_id: salesRuleId,
    source_id: sourceId,
    label: title,
    destination_url: eventSlug ? tagUrl : null
  };
  var trackImpressions = useCallback(function () {
    emitSalesRuleTagImpression(trackingOptions);
  }, []);

  var _useIsInViewport = useIsInViewport({
    onComponentSeen: trackImpressions
  }),
      ref = _useIsInViewport.ref;

  var onLinkClick = function onLinkClick() {
    emitSalesRuleTagClick(trackingOptions);
  };

  return ___EmotionJSX(Flex, _extends({
    ref: ref,
    color: isApplied ? "brand.tertiary" : "brand.secondaryDark",
    fontSize: "sm",
    fontWeight: "medium",
    py: 1,
    px: isApplied ? 0 : 2,
    borderLeft: isApplied ? 0 : "2px solid",
    borderRadius: 2,
    gap: 1,
    borderLeftColor: "secondary",
    backgroundColor: isApplied ? "" : "secondaryLight",
    alignItems: "center",
    textAlign: "left"
  }, containerProps), isApplied && ___EmotionJSX(IconTickCircle, _extends({
    height: 14,
    width: 14,
    "aria-label": "applied icon"
  }, iconProps)), eventSlug ? ___EmotionJSX(Truncate, {
    lines: 1,
    fontSize: "inherit",
    fontWeight: "inherit",
    m: 0
  }, ___EmotionJSX(Link, {
    href: tagUrl,
    color: "inherit",
    textDecoration: "underline",
    style: {
      cursor: "pointer"
    },
    onClick: onLinkClick
  }, title)) : ___EmotionJSX(Truncate, {
    lines: 1,
    fontSize: "inherit",
    fontWeight: "inherit",
    m: 0
  }, title), ___EmotionJSX(Flex, {
    flex: "0 0 auto"
  }, ___EmotionJSX(SalesRulePromotionTagDisclaimer, {
    title: disclaimerTitle || title,
    iconProps: iconProps
  })));
};
export default SalesRulePromotionTag;