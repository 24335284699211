export var mockBanner = {
  bannerByPage: {
    banners: [{
      id: "579736",
      url: "https://www.youtube.com/watch?v=xm3YgoEiEDc",
      platforms: ["DESKTOP", "MOBILE", "IPAD", "IPHONE", "ANDROID"],
      image: {
        url: "https://static.devcat.ch/assets/0000/0579/57be5858ae5d6441341638.jpg",
        altText: "Testy McTestface",
        height: 615,
        width: 1968
      }
    }]
  }
};