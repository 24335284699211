import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { OnePassIconsGroup } from "../../OnePassIconsGroup";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Divisions = function Divisions() {
  return ___EmotionJSX(Flex, {
    display: {
      xs: "none",
      md: "flex"
    },
    justifyContent: "center",
    mb: 10
  }, ___EmotionJSX(OnePassIconsGroup, {
    includePartners: true
  }));
};