import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { OnePassHelp } from "./OnePassHelp";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PinnedOnePassHelp = function PinnedOnePassHelp() {
  return ___EmotionJSX(Box, {
    display: {
      xs: "none",
      md: "block"
    },
    position: "absolute",
    top: {
      xs: 6,
      md: 8
    },
    right: {
      xs: 4,
      md: 8
    }
  }, ___EmotionJSX(OnePassHelp, {
    stacking: true
  }));
};