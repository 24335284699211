import { createSvgIconComponent } from "../utils";
export var IconBunningsWarehouse = createSvgIconComponent({
  viewBox: "0 0 108 33",
  fill: "none",
  paths: [{
    d: "M.75 7.975L6.677 3.81h5.186L.75 11.957V7.975z",
    props: {
      fill: "#EC1C24"
    }
  }, {
    d: "M.75 8.012l5.927-4.167h5.186L.75 11.994V8.012z",
    props: {
      stroke: "#EC1C24",
      strokeWidth: "0.116",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M96.877 7.178l-2.13 1.76c-.92-1.537-2.415-2.785-4.258-2.778-1.435.118-2.72.783-3.428 2.038-1.077 1.573-.995 4.152.186 5.649.936 1.58 2.72 1.996 4.446 1.667 1.097-.294 2.508-1.418 2.591-2.686h-3.61c-.043-.81.082-1.6 0-2.315h6.946c.025 2.835-.888 5.539-3.52 6.946-2.264 1.38-5.958 1.048-8.057-.555-2.465-1.81-3.338-4.762-2.779-7.688.562-2.314 2.678-4.56 4.908-5.371 2.958-.77 6.235-.02 8.151 2.408.284.274.45.565.554.925",
    props: {
      fill: "#006F66"
    }
  }, {
    d: "M96.877 7.178l-2.13 1.76c-.92-1.537-2.415-2.785-4.258-2.778-1.435.118-2.72.783-3.428 2.038-1.077 1.573-.995 4.152.186 5.649.936 1.58 2.72 1.996 4.446 1.667 1.097-.294 2.508-1.418 2.591-2.686h-3.61c-.043-.81.082-1.6 0-2.315h6.946c.025 2.835-.888 5.539-3.52 6.946-2.264 1.38-5.958 1.048-8.057-.555-2.465-1.81-3.338-4.762-2.779-7.688.562-2.314 2.678-4.56 4.908-5.371 2.958-.77 6.235-.02 8.151 2.408.284.274.45.565.554.925v0z",
    props: {
      stroke: "#fff",
      strokeWidth: "0.056",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M20.382 13.476c.24.87 1.112 1.744 1.944 1.945 1.243.13 2.363-.119 2.964-1.111.561-.879.52-2.46.556-3.706a209.245 209.245 0 010-6.76h2.778c-.075 3.557.008 9.337-.278 10.743-.42 1.714-2.038 3.17-3.797 3.334-2.183.376-4.425.127-5.927-1.573-1.112-1.254-1.195-2.75-1.204-4.446l.093-8.15c.87.072 1.908-.011 2.87 0v9.725z",
    props: {
      fill: "#006F66"
    }
  }, {
    d: "M20.382 13.476c.24.87 1.112 1.744 1.944 1.945 1.243.13 2.363-.119 2.964-1.111.561-.879.52-2.46.556-3.706a209.245 209.245 0 010-6.76h2.778c-.075 3.557.008 9.337-.278 10.743-.42 1.714-2.038 3.17-3.797 3.334-2.183.376-4.425.127-5.927-1.573-1.112-1.254-1.195-2.75-1.204-4.446l.093-8.15c.87.072 1.908-.011 2.87 0v9.725z",
    props: {
      stroke: "#fff",
      strokeWidth: "0.056",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M2.324 6.9l4.352-3.055h5.187l-9.446 6.853v4.909l8.335-6.205 2.685-1.76a79.824 79.824 0 000 4.075l-8.983 6.576h6.39c2.02-1.575 5.34-2.907 5.187-5.928a4.925 4.925 0 00-2.594-4.723c.34-.449.712-.947.926-1.482.658-1.587.243-3.541-.926-4.722C12.614.414 11.992.124 11.03.14 8.05.124 5.27.164 2.324.14v6.761",
    props: {
      fill: "#006F66"
    }
  }, {
    d: "M106.698 5.326c-.418.827-1.083 1.493-1.668 2.224-.617-.648-1.49-1.563-2.593-1.112-.266.215-.722.506-.832.926-.181.412-.14 1.035.092 1.39 1.511 1.6 4.208 1.35 5.093 3.704.444 1.348.36 2.72-.278 3.89-1.228 1.784-3.304 2.49-5.463 2.037-1.078-.212-1.949-.795-2.871-1.481v-.185l1.666-2.039c.916.747 1.994 1.497 3.242 1.205.454-.207.828-.665.926-1.019.191-.561.151-1.394-.37-1.76-1.43-1.255-3.671-1.13-4.63-3.056-.659-1.069-.576-2.649-.095-3.797.598-1.514 2.174-2.304 3.706-2.408 1.58-.104 3.033.395 4.075 1.481z",
    props: {
      fill: "#fff"
    }
  }, {
    d: "M106.698 5.326c-.418.827-1.083 1.493-1.668 2.224-.617-.648-1.49-1.563-2.593-1.112-.266.215-.722.506-.832.926-.181.412-.14 1.035.092 1.39 1.511 1.6 4.208 1.35 5.093 3.704.444 1.348.36 2.72-.278 3.89-1.228 1.784-3.304 2.49-5.463 2.037-1.078-.212-1.949-.795-2.871-1.481v-.185l1.666-2.039c.916.747 1.994 1.497 3.242 1.205.454-.207.828-.665.926-1.019.191-.561.151-1.394-.37-1.76-1.43-1.255-3.671-1.13-4.63-3.056-.659-1.069-.576-2.649-.095-3.797.598-1.514 2.174-2.304 3.706-2.408 1.58-.104 3.033.395 4.075 1.481z",
    props: {
      fill: "#006F66",
      stroke: "#fff",
      strokeWidth: "0.056",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M43.906 17.921l-10.28-8.15c-.014-.042-.014-.125-.092-.092v8.243h-2.872V4.03l.37.092 10.002 7.871h.092V3.937h2.78V17.92",
    props: {
      fill: "#006F66"
    }
  }, {
    d: "M43.906 17.921l-10.28-8.15c-.014-.042-.014-.125-.092-.092v8.243h-2.872V4.03l.37.092 10.002 7.871h.092V3.937h2.78V17.92h0z",
    props: {
      stroke: "#fff",
      strokeWidth: "0.056",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M62.52 4.03h2.963v13.892H62.52V4.03z",
    props: {
      fill: "#006F66"
    }
  }, {
    d: "M62.52 4.03h2.963v13.892H62.52V4.03z",
    props: {
      stroke: "#fff",
      strokeWidth: "0.056",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M59.835 17.921l-10.28-8.15c-.09-.042-.09-.042-.186 0l.093 8.15h-2.87V4.03l.277.185 10.095 7.872v-8.15h2.871V17.92z",
    props: {
      fill: "#006F66"
    }
  }, {
    d: "M59.835 17.921l-10.28-8.15c-.09-.042-.09-.042-.186 0l.093 8.15h-2.87V4.03l.277.185 10.095 7.872v-8.15h2.871V17.92z",
    props: {
      stroke: "#fff",
      strokeWidth: "0.056",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M81.135 17.921l-10.187-8.15h-.093v8.243h-2.87V4.121l.276.094 10.003 7.871.092-8.057h2.779v13.892",
    props: {
      fill: "#006F66"
    }
  }, {
    d: "M67.984 18.015V4.122l.278.094 10.002 7.87.092-8.056h2.779v13.892l-10.187-8.15h-.093v8.243h-2.87z",
    props: {
      stroke: "#fff",
      strokeWidth: "0.056",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M2.88 19.034h31.766v-7.04l8.798 7.04h7.13V12.18l8.706 6.854h12.688V12.18l8.797 6.854h26.487v13.891H2.881V19.034z",
    props: {
      fill: "#EC1C24"
    }
  }, {
    d: "M50.575 19.034V12.18l8.705 6.854h12.688V12.18l8.797 6.854h26.487v13.891H2.881V19.034h31.765v-7.04l8.798 7.04h7.13z",
    props: {
      stroke: "#EC1C24",
      strokeWidth: "0.081",
      strokeMiterlimit: "3.864"
    }
  }, {
    d: "M38.72 21.07h-3.15v9.91h3.15v-3.983c-.031-1.738.403-3.03 2.129-3.055.514.025.931.082 1.389.37v-3.426h-.648c-1.217.03-2.258.571-2.87 1.759V21.07z",
    props: {
      fill: "#fff",
      fillRule: "evenodd",
      clipRule: "evenodd"
    }
  }, {
    d: "M29.736 31.166c.762-.151 1.47-.582 1.851-1.297h.094v1.113h3.147V21.07h-3.147v.928c-.506-.61-1.203-1.006-1.945-1.204v2.964c1.275.016 2.02 1.039 2.037 2.222-.016 1.286-.762 2.252-2.037 2.222v2.964zm-.648-10.465a.244.244 0 01-.186 0c-2.65.023-4.473 2.455-4.445 5.279-.028 2.85 1.83 5.3 4.445 5.278.08.022.136.02.186 0V28.11c-.889-.23-1.394-1.066-1.39-2.13-.004-.965.499-1.834 1.39-2.13v-3.149zm27.32.278c-.6.276-1.071.705-1.482 1.298V17.46l-3.242-2.409v15.93h3.242v-5.093c-.044-1.119.321-2.262 1.481-2.315V20.98zm.648 2.594c.784.292.9 1.313.926 2.315v5.094h3.148v-6.114c-.014-2.484-1.107-4.143-3.333-4.167-.3.023-.55.048-.741.093v2.779zm10.002 7.685c2.684-.127 4.997-2.016 5-5.278-.004-3.212-2.32-5.08-5-5.186v2.964c1.042.2 1.662 1.146 1.667 2.222-.005 1.176-.626 2.08-1.667 2.222v3.056zm-.742-3.056c-1.04-.146-1.66-1.048-1.666-2.222.006-1.075.626-2.022 1.666-2.222l.094-3.057c-2.771.17-5.088 2.062-5.094 5.279.006 3.278 2.332 5.15 5 5.279v-3.057zm10.28.279c-.997-.162-1.226-1-1.204-2.13v-5.28h-3.148v5.926c-.036 3.143 1.863 4.196 4.352 4.26v-2.776zm.648 2.777c2.545-.064 4.446-1.114 4.446-4.26V21.07H78.54v5.281c-.012 1.138-.224 1.957-1.297 2.13v2.777zm-30.19 0c1.953-.05 3.836-1.117 4.352-3.333H48.35c-.321.542-.739.804-1.296.834v2.5z",
    props: {
      fill: "#fff"
    }
  }, {
    d: "M46.409 28.758c-1.027-.15-1.56-.901-1.575-2.13h6.668v-.37c-.001-3.252-1.624-5.33-4.445-5.557v2.316c.734.122 1.37.704 1.482 1.574h-3.612c.14-.91.733-1.434 1.482-1.574V20.7c-2.785.12-4.72 2.09-4.724 5.278.004 3.279 1.996 5.112 4.724 5.279v-2.5zm48.435 2.5c2-.051 3.882-1.118 4.352-3.334H96.14c-.276.542-.694.805-1.297.834v2.5z",
    props: {
      fill: "#fff"
    }
  }, {
    d: "M94.283 28.758c-1.071-.15-1.605-.901-1.574-2.13h6.576v-.37c.044-3.252-1.579-5.33-4.445-5.557v2.316c.78.122 1.415.704 1.575 1.574h-3.706c.187-.91.78-1.434 1.574-1.574V20.7c-2.83.12-4.763 2.09-4.723 5.278-.04 3.279 1.95 5.112 4.723 5.279v-2.5zm-7.779-4.261c-.436-.087-.823-.282-.833-.74.01-.467.635-.64 1.019-.648.52.007 1.197.201 1.666.463l1.112-2.224c-.955-.451-1.963-.624-2.964-.647-1.951.023-3.931 1.104-3.89 3.518-.041 1.793.95 2.333 1.946 2.594.278.111.561.173.833.278l1.111-2.594z",
    props: {
      fill: "#fff"
    }
  }, {
    d: "M86.045 27.276c.285.114.49.314.462.648.028.558-.561.77-.926.742-.816.028-1.894-.474-2.5-1.019l-1.202 2.5c1.097.728 2.348 1.133 3.61 1.11 2.072.023 4.207-1.058 4.168-3.703.04-1.793-1.073-2.565-2.594-2.871l-1.018 2.593zM12.049 21.07H8.623l4.446 9.911h1.944l.37-1.204-3.334-8.707zm5.928 0h-1.76l-1.76 4.447 1.297 3.427 1.296-3.427 2.13 5.464h1.945l.37-.834-3.518-9.077zm3.889 8.243l3.612-8.243h-3.242l-1.852 4.54 1.482 3.703z",
    props: {
      fill: "#fff"
    }
  }]
});