import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["src", "sourceWidths", "sizeMap", "lazyLoad", "lazyLoadOptimumX", "lazyLoadOptimumXEnabled", "ratio", "className"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import range from "lodash.range";
import { css, keyframes, styled } from "@catchoftheday/theme";
import { isIE } from "@catchoftheday/utilities";
import "lazysizes";
import "lazysizes/plugins/optimumx/ls.optimumx";
import { Box } from "../Box";
import { Image } from "../Image";
import { Picture } from "../Picture";
import { DEFAULT_LAZY_LOAD_OPTIMUM_X, DEFAULT_LAZY_LOAD_OPTIMUM_X_SETTING, DEFAULT_SOURCE_WIDTHS_RANGE, WEBP_MIME_TYPE } from "./types";
import { generateSizesAttrValue, getSources } from "./utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
var shimmerAnimation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% { background-position: -1440px 0 }\n  100% { background-position: 1440px 0 }\n"])));
var GreyShimmerBox = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  overflow: hidden;\n  background: #fafafa;\n  background-image: linear-gradient(\n    to right,\n    #fafafa 0%,\n    #edeef1 20%,\n    #f6f7f8 40%,\n    #fafafa 100%\n  );\n  ", "\n  background-size: 1440px 100%;\n  animation: ", ";\n  ", "\n"])), function (props) {
  return props.isLazyloaded && "background: transparent;";
}, function (props) {
  return props.lazyLoad ? css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n          ", " 2s linear 0s infinite normal forwards\n        "])), shimmerAnimation) : "none";
}, function (props) {
  if (props.ratio) {
    return "height: 0;\n        padding-bottom: ".concat(props.ratio, "%;\n      ");
  }

  return null;
});
var LazyLoadImage = styled(Image)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  visibility: ", ";\n  ", "\n"])), function (props) {
  return props.lazyload ? props.lazyloaded ? "inherit" : "hidden" : "inherit";
}, function (props) {
  if (props.useAbsolute) {
    return "position: absolute;\n      top: 0;\n      right: 0;\n      bottom: 0;\n      left: 0;";
  }
});
var SUPPORTED_ORIGINAL_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "webp"];
var DEFAULT_SOURCE_WIDTHS = range(DEFAULT_SOURCE_WIDTHS_RANGE.START, DEFAULT_SOURCE_WIDTHS_RANGE.END, DEFAULT_SOURCE_WIDTHS_RANGE.STEP);
export var ImageResponsive = function ImageResponsive(_ref) {
  var src = _ref.src,
      _ref$sourceWidths = _ref.sourceWidths,
      sourceWidths = _ref$sourceWidths === void 0 ? DEFAULT_SOURCE_WIDTHS : _ref$sourceWidths,
      sizeMap = _ref.sizeMap,
      _ref$lazyLoad = _ref.lazyLoad,
      lazyLoad = _ref$lazyLoad === void 0 ? false : _ref$lazyLoad,
      _ref$lazyLoadOptimumX = _ref.lazyLoadOptimumX,
      lazyLoadOptimumX = _ref$lazyLoadOptimumX === void 0 ? DEFAULT_LAZY_LOAD_OPTIMUM_X : _ref$lazyLoadOptimumX,
      _ref$lazyLoadOptimumX2 = _ref.lazyLoadOptimumXEnabled,
      lazyLoadOptimumXEnabled = _ref$lazyLoadOptimumX2 === void 0 ? DEFAULT_LAZY_LOAD_OPTIMUM_X_SETTING : _ref$lazyLoadOptimumX2,
      ratio = _ref.ratio,
      className = _ref.className,
      props = _objectWithoutProperties(_ref, _excluded);

  var imgRef = useRef(null);

  var _useState = useState(lazyLoad ? false : true),
      isLazyloaded = _useState[0],
      setIsLazyLoaded = _useState[1];

  useEffect(function () {
    var _imgRef$current;

    (_imgRef$current = imgRef.current) === null || _imgRef$current === void 0 ? void 0 : _imgRef$current.addEventListener("lazyloaded", function () {
      setIsLazyLoaded(true);
    });
  });
  var imageSizes = generateSizesAttrValue(sizeMap);

  var _getSources = getSources(src, sourceWidths, ratio),
      webpSrcSet = _getSources.webpSrcSet,
      imageSrcSet = _getSources.imageSrcSet,
      lqipSrc = _getSources.lqipSrc,
      ieSrc = _getSources.ieSrc,
      extension = _getSources.extension;

  var isOptimumXEnabled = lazyLoad && lazyLoadOptimumXEnabled && lazyLoadOptimumX;
  var classNameImage = classNames(lazyLoad && "lazyload", className);

  var renderImage = function renderImage() {
    return ___EmotionJSX(LazyLoadImage, _extends({
      useAbsolute: !!ratio,
      lazyload: lazyLoad,
      lazyloaded: isLazyloaded
    }, props, {
      ref: imgRef,
      maxWidth: "100%",
      sizes: isOptimumXEnabled ? undefined : imageSizes,
      "data-sizes": isOptimumXEnabled ? "auto" : null,
      "data-optimumx": isOptimumXEnabled ? lazyLoadOptimumX : null,
      src: lazyLoad ? !ratio ? lqipSrc : undefined : src,
      "data-src": lazyLoad ? ieSrc : null,
      srcSet: lazyLoad && imageSrcSet ? undefined : imageSrcSet,
      "data-srcset": lazyLoad && imageSrcSet ? imageSrcSet : null,
      className: classNameImage
    }));
  }; // Fallback to normal Image component


  if (!SUPPORTED_ORIGINAL_IMAGE_EXTENSIONS.includes(extension)) {
    return ___EmotionJSX(GreyShimmerBox, {
      ratio: ratio,
      lazyLoad: lazyLoad
    }, ___EmotionJSX(Image, _extends({
      src: src,
      className: classNameImage
    }, props)));
  }

  if (webpSrcSet) {
    return ___EmotionJSX(GreyShimmerBox, {
      ratio: ratio,
      lazyLoad: lazyLoad,
      isLazyloaded: isLazyloaded
    }, ___EmotionJSX(Picture, null, !isIE && ___EmotionJSX("source", {
      type: WEBP_MIME_TYPE,
      sizes: isOptimumXEnabled ? undefined : imageSizes,
      "data-sizes": isOptimumXEnabled ? "auto" : null,
      "data-srcset": webpSrcSet,
      srcSet: lazyLoad ? undefined : webpSrcSet
    }), renderImage()));
  }

  return ___EmotionJSX(GreyShimmerBox, {
    ratio: ratio,
    lazyLoad: lazyLoad
  }, renderImage());
};
export default ImageResponsive;