import React from "react";
import { Flex, IconOnePass, Link, LinkButton, Span, Text } from "@catchoftheday/cg-components";
import { rem } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PopupContent = function PopupContent() {
  return ___EmotionJSX(Flex, {
    width: "400px",
    flexDirection: "column",
    alignItems: "center",
    color: "typography.text",
    boxShadow: "card",
    bg: "white",
    borderRadius: "corner",
    overflow: "hidden",
    fontFamily: "onePass",
    pt: 10,
    pb: 6,
    px: 6
  }, ___EmotionJSX(IconOnePass, {
    height: rem("28px"),
    width: "auto",
    color: "onePass.primary",
    "aria-label": "OnePass",
    mb: 4
  }), ___EmotionJSX(Text, {
    fontSize: "sm",
    m: 0,
    fontWeight: "normal"
  }, ___EmotionJSX(Span, {
    fontWeight: "semibold"
  }, "Collect 2 Flybuys points"), " for every $2 spent online*"), ___EmotionJSX(LinkButton, {
    block: true,
    rounded: true,
    fontWeight: "semibold",
    sizeVariant: "md",
    variant: "onePassPrimary",
    href: "/rewards/flybuys/link",
    "data-tracking": "header-app-onepass-link-flybuys",
    my: 4
  }, "LINK Flybuys"), ___EmotionJSX(Link, {
    color: "onePass.primary",
    href: "/flybuys-catch",
    "data-tracking": "header-app-flybuys-learn-more",
    textDecoration: "underline",
    fontWeight: "semibold",
    fontSize: "xs",
    mb: 2
  }, "Learn more"), ___EmotionJSX(Text, {
    fontSize: "xs",
    m: 0,
    fontWeight: "medium",
    color: "onePass.textGrey"
  }, "*Term, conditions and exclusions apply."));
};