import React from "react";
import { Box, Text } from "@catchoftheday/cg-components";
import { ShopSectionHeading } from "../../pure/ShopSectionHeading";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ExclusiveDealsHeader = function ExclusiveDealsHeader() {
  return ___EmotionJSX(Box, null, ___EmotionJSX(ShopSectionHeading, {
    mb: 1,
    textAlign: "center"
  }, "Exclusive Deals"), ___EmotionJSX(Text, {
    m: 0,
    fontSize: "sm",
    color: "textTertiary",
    textAlign: "center"
  }, "Shop new & exclusive deals available only for our members"));
};