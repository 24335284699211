import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { HEADER_XMAS_HAT, HEADER_XMAS_POMPOM } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
var hatDimensions = ["2.5rem", "2.5rem", "2.5rem", "3rem"];
var ChristmasContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n"])));
var SantaHat = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-image: url(\"", "\");\n  background-size: 100%;\n  background-repeat: no-repeat;\n"])), HEADER_XMAS_HAT);
var SantaPomPom = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  height: 3.5rem;\n  background-image: url(\"", "\");\n  background-size: 100%;\n  background-repeat: no-repeat;\n  z-index: 1;\n  top: 0px;\n  transform-origin: top right;\n  animation: waveHat 1.5s ease-in-out infinite;\n  @keyframes waveHat {\n    0%,\n    100% {\n      transform: rotate(10deg);\n    }\n    50% {\n      transform: rotate(0);\n    }\n  }\n"])), HEADER_XMAS_POMPOM);
export var ChristmasTheme = function ChristmasTheme() {
  return ___EmotionJSX(ChristmasContainer, {
    top: ["-1.3rem", "-1.0rem", "-1.0rem", "-1.6rem"],
    width: hatDimensions,
    height: hatDimensions,
    role: "img",
    "aria-label": "Santa hat"
  }, ___EmotionJSX(SantaHat, null), ___EmotionJSX(SantaPomPom, {
    width: ["1rem", "1rem", "1rem", "1.2rem"],
    left: ["-0.3rem", "-0.3rem", "-0.4rem", "-0.4rem"]
  }));
};