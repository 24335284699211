import React, { useCallback } from "react";
import { emitUpsellModalAction } from "@catchoftheday/analytics";
import { Flex } from "@catchoftheday/cg-components";
import { generateOnePassUpsellUrl, UpsellModalContent } from "@catchoftheday/onepass";
import { triggerOnePassLinkingModal } from "@catchoftheday/state";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PopupContent = function PopupContent(_ref) {
  var showFreeTrial = _ref.showFreeTrial,
      showLogIn = _ref.showLogIn,
      showAlreadyAMember = _ref.showAlreadyAMember;

  var trackClicks = function trackClicks(label) {
    emitUpsellModalAction({
      action: "Upsell Modal Header",
      label: label
    });
  };

  var onLinkButtonClick = useCallback(function () {
    trackClicks("link");
    triggerOnePassLinkingModal({
      originalUrl: window.location.pathname
    });
  }, []);
  return ___EmotionJSX(Flex, {
    width: "400px",
    flexDirection: "column",
    alignItems: "center",
    px: 6,
    pt: 10,
    pb: 6,
    color: "typography.text",
    boxShadow: "card",
    bg: "white",
    borderRadius: "corner",
    overflow: "hidden",
    fontFamily: "onePass"
  }, ___EmotionJSX(UpsellModalContent, {
    logoContainerFontSize: "base",
    hideMainPricingUI: true,
    mainCTAHref: generateOnePassUpsellUrl({
      url: window.location.pathname,
      cta: showFreeTrial ? "start-free-trial" : "join"
    }),
    isFreeTrialEligible: showFreeTrial,
    showSecondaryCTA: showAlreadyAMember,
    isLoggedIn: !showLogIn,
    onLearnMoreClick: function onLearnMoreClick() {
      return trackClicks("learnMore");
    },
    onLoginClick: function onLoginClick() {
      return trackClicks("login");
    },
    onLinkClick: onLinkButtonClick,
    onMainCTAClick: function onMainCTAClick() {
      return trackClicks(showFreeTrial ? "freeTrial" : "join");
    }
  }));
};