import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React, { useMemo, useState } from "react";
import { Carousel, Flex, Image } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Gallery = function Gallery(_ref) {
  var slides = _ref.slides;

  var _useState = useState(0),
      currentIdx = _useState[0],
      setCurrentIdx = _useState[1];

  var carouselArrowStyle = {
    color: "onePass.primary",
    backgroundColor: "transparent"
  };
  var carouselBtnStyle = {
    backgroundColor: "transparent",
    padding: "0",
    width: "16px"
  };
  var previewItems = slides.map(function (item) {
    return ___EmotionJSX(Image, {
      key: "preview-".concat(item.id),
      alt: item.altText,
      src: item.url,
      maxHeight: "360px"
    });
  });
  var navItems = slides.map(function (item, idx) {
    return ___EmotionJSX(Flex, {
      key: "slide-".concat(item.id),
      width: "60px",
      height: "48px",
      alignItems: "center",
      justifyContent: "center",
      mx: 2,
      border: 1,
      color: item.id === slides[currentIdx].id ? "onePass.primaryDark" : "transparent",
      cursor: "pointer",
      onClick: function onClick() {
        return setCurrentIdx(idx);
      }
    }, ___EmotionJSX(Image, {
      alt: item.altText,
      src: item.url
    }));
  });
  var navCarouselBreakpoints = useMemo(function () {
    var desktopViewBreakpoints = new Array(3).fill(navItems.length >= 6 ? 6 : navItems.length);
    var mobileViewBreakpoints = new Array(2).fill(navItems.length >= 4 ? 4 : navItems.length);
    return [].concat(_toConsumableArray(desktopViewBreakpoints), _toConsumableArray(mobileViewBreakpoints));
  }, [navItems.length]);
  if (!slides) return null;
  return ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    maxWidth: "500px",
    width: "100%"
  }, ___EmotionJSX(Carousel, {
    containerProps: {
      maxWidth: "100%",
      mb: 2
    },
    infinite: true,
    slides: previewItems,
    onSlideChange: function onSlideChange(_ref2) {
      var currentIndex = _ref2.currentIndex;
      setCurrentIdx(currentIndex);
    },
    arrowStyles: carouselArrowStyle,
    currentSlide: currentIdx,
    previousButtonStyles: carouselBtnStyle,
    nextButtonStyles: carouselBtnStyle
  }), ___EmotionJSX(Carousel, {
    containerProps: {
      maxWidth: "100%",
      px: 8
    },
    slides: navItems,
    slidesPerBreakpoint: navCarouselBreakpoints,
    onSlideChange: function onSlideChange(_ref3) {
      var currentIndex = _ref3.currentIndex;
      setCurrentIdx(currentIndex);
    },
    currentSlide: currentIdx,
    showNavButtons: false
  }));
};