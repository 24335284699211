import React from "react";
import { Box, Heading, LinkButton } from "@catchoftheday/cg-components";
import { URL_CATCH_HELP_CENTER } from "@catchoftheday/common-vendors";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoResultsFooter = function NoResultsFooter() {
  return ___EmotionJSX(Box, {
    px: 3,
    textAlign: "center"
  }, ___EmotionJSX(Heading.h2, {
    fontSize: {
      xs: "lg",
      md: "2xl"
    }
  }, "Need more help?"), ___EmotionJSX(LinkButton, {
    id: "no-results-help-center-link",
    href: URL_CATCH_HELP_CENTER,
    variant: "secondary",
    outline: true,
    width: "auto",
    textTransform: "none"
  }, "Visit our help centre"));
};