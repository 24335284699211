import { SOURCE_TYPE_CONSTRUCTOR_SEARCH } from "@catchoftheday/analytics";
import { SEARCH_SUGGESTION_INDEX_NAME } from "../../consts";
import { navigateToSearchPage } from "./navigateToSearchPage";
export var getSearchTermSuggestionSection = function getSearchTermSuggestionSection(term) {
  return {
    section: {
      data: [{
        value: term,
        matched_terms: [],
        group_id: "search",
        group_name: "search",
        is_slotted: false,
        labels: {}
      }],
      displayName: SEARCH_SUGGESTION_INDEX_NAME,
      indexSectionName: SEARCH_SUGGESTION_INDEX_NAME
    },
    getItemProps: function getItemProps() {
      return {
        onClick: function onClick() {
          navigateToSearchPage({
            term: term,
            inputTerm: term,
            sourceType: SOURCE_TYPE_CONSTRUCTOR_SEARCH
          });
        }
      };
    }
  };
};