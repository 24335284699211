import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useContext, useEffect, useRef } from "react";
import { ButtonBack, ButtonNext, CarouselContext, CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel";
import { css, styled, themeGet, useMedia } from "@catchoftheday/theme";
import { useWindowSize } from "@catchoftheday/utilities";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { IconArrow } from "../Icon";
import { CarouselStyles } from "./Carousel.styles";
import { CarouselWatcherDecorated } from "./CarouselWatcher";
import { jsx as ___EmotionJSX } from "@emotion/react";
var excludeProps = ["buttonOffset", "showNavButtonsOnHover", "isVertical", "previousButtonStyles", "nextButtonStyles", "showNavButtons", "requiresScroll"];

var shouldForwardProp = function shouldForwardProp(prop) {
  return !excludeProps.includes(prop);
};

var buttonStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 35px;\n  height: 50px;\n  text-indent: 50px;\n  position: absolute;\n  overflow: hidden;\n  background-color: rgba(221, 221, 221, 0.5);\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &[disabled] {\n    cursor: not-allowed;\n    opacity: 0.5;\n  }\n"])));
var PreviousButton = styled(ButtonBack, {
  shouldForwardProp: shouldForwardProp
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  left: ", "px;\n  top: calc(50% - 25px + ", "px);\n  ", "\n  border-radius: ", ";\n  ", ";\n"])), buttonStyles, function (props) {
  return props.buttonOffset[0];
}, function (props) {
  return props.buttonOffset[1];
}, function (props) {
  return props.isVertical && "top: 0px; right: 0px;";
}, function (props) {
  return props.isVertical ? 0 : "0 3px 3px 0";
}, function (props) {
  return css(_objectSpread({}, props.previousButtonStyles));
});
var NextButton = styled(ButtonNext, {
  shouldForwardProp: shouldForwardProp
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  right: ", "px;\n  top: calc(50% - 25px + ", "px);\n  ", "\n  border-radius: ", ";\n  ", "\n"])), buttonStyles, function (props) {
  return props.buttonOffset[0];
}, function (props) {
  return props.buttonOffset[1];
}, function (props) {
  return props.isVertical && "top: auto; bottom: 0px; right: 0px;";
}, function (props) {
  return props.isVertical ? 0 : "3px 0 0 3px";
}, function (props) {
  return css(_objectSpread({}, props.nextButtonStyles));
});
var CarouselContainer = styled(CarouselProvider, {
  shouldForwardProp: shouldForwardProp
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: relative;\n  ", "\n  ", "\n  ", "\n  ", "\n  .slide-height {\n    height: auto;\n  }\n  .carousel__slide-focus-ring {\n    outline: none !important;\n  }\n"])), function (props) {
  return props.showNavButtons && (props.orientation == "vertical" ? "\n  padding-top: 30px;\n  padding-bottom: 30px;\n  height: 100%;\n  " : "\n  padding-left: 30px;\n  padding-right: 30px;\n  > div {\n    padding-bottom: 1px;\n  }\n  ");
}, function (props) {
  return !props.requiresScroll && "\n  .carousel__slider-tray--vertical {\n    transform: none !important;\n  }\n  .carousel__slider-tray--horizontal {\n    transform: none !important;\n  }\n  ";
}, function (props) {
  return props.showNavButtonsOnHover && "\n      > button {\n        display: none;\n      }\n      &:hover > button {\n        display: flex;\n      }\n  ";
}, function (props) {
  return props.orientation == "vertical" && "\n  .carousel__inner-slide {\n      position: absolute;\n    }\n  ";
});
var Bullet = styled(Dot)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  opacity: 0.3;\n  background: ", ";\n  margin: 0 4px;\n  border: none;\n  padding-left: 6px; // explicitly set this to prevent Safari mobile browser making it look stretched\n  padding-right: 6px;\n  &[disabled] {\n    cursor: not-allowed;\n    opacity: 0.3;\n  }\n  &.carousel__dot--selected {\n    opacity: 1;\n  }\n"])), themeGet("colors.typography.subText"));
export var Carousel = function Carousel(_ref) {
  var slides = _ref.slides,
      _ref$slidesPerBreakpo = _ref.slidesPerBreakpoint,
      slidesPerBreakpoint = _ref$slidesPerBreakpo === void 0 ? [1, 1, 1, 1, 1] : _ref$slidesPerBreakpo,
      _ref$autoPlay = _ref.autoPlay,
      autoPlay = _ref$autoPlay === void 0 ? false : _ref$autoPlay,
      _ref$interval = _ref.interval,
      interval = _ref$interval === void 0 ? 5000 : _ref$interval,
      _ref$infinite = _ref.infinite,
      infinite = _ref$infinite === void 0 ? false : _ref$infinite,
      _ref$showNavButtons = _ref.showNavButtons,
      showNavButtons = _ref$showNavButtons === void 0 ? true : _ref$showNavButtons,
      _ref$showNavButtonsOn = _ref.showNavButtonsOnHover,
      showNavButtonsOnHover = _ref$showNavButtonsOn === void 0 ? false : _ref$showNavButtonsOn,
      _ref$showBullets = _ref.showBullets,
      showBullets = _ref$showBullets === void 0 ? false : _ref$showBullets,
      buttonOffset = _ref.buttonOffset,
      _ref$carouselName = _ref.carouselName,
      carouselName = _ref$carouselName === void 0 ? "Carousel" : _ref$carouselName,
      _ref$dragEnabled = _ref.dragEnabled,
      dragEnabled = _ref$dragEnabled === void 0 ? true : _ref$dragEnabled,
      _ref$slideWidth = _ref.slideWidth,
      slideWidth = _ref$slideWidth === void 0 ? 0 : _ref$slideWidth,
      _ref$slideHeight = _ref.slideHeight,
      slideHeight = _ref$slideHeight === void 0 ? 0 : _ref$slideHeight,
      isVertical = _ref.isVertical,
      step = _ref.step,
      dragStep = _ref.dragStep,
      containerProps = _ref.containerProps,
      onSlideChange = _ref.onSlideChange,
      onCarouselInit = _ref.onCarouselInit,
      previousButtonStyles = _ref.previousButtonStyles,
      nextButtonStyles = _ref.nextButtonStyles,
      arrowStyles = _ref.arrowStyles,
      disableNavButtons = _ref.disableNavButtons,
      _ref$touchEnabled = _ref.touchEnabled,
      touchEnabled = _ref$touchEnabled === void 0 ? true : _ref$touchEnabled,
      currentSlide = _ref.currentSlide;
  var visibleSlides = useMedia(["(min-width: 1280px)", "(min-width: 1024px)", "(min-width: 768px)", "(min-width: 320px)", "(min-width: 0px)"], slidesPerBreakpoint);
  var requiresScroll = !!visibleSlides && slides.length > visibleSlides;
  return ___EmotionJSX(Box, containerProps, ___EmotionJSX(CarouselContainer, {
    naturalSlideWidth: slideWidth,
    naturalSlideHeight: slideHeight,
    isIntrinsicHeight: !isVertical,
    totalSlides: slides.length,
    visibleSlides: visibleSlides,
    isPlaying: requiresScroll && autoPlay,
    interval: interval,
    infinite: infinite,
    step: step || visibleSlides,
    dragStep: dragStep || visibleSlides,
    showNavButtons: requiresScroll && showNavButtons,
    showNavButtonsOnHover: showNavButtonsOnHover,
    dragEnabled: dragEnabled,
    touchEnabled: touchEnabled,
    orientation: isVertical ? "vertical" : undefined,
    requiresScroll: requiresScroll,
    currentSlide: currentSlide
  }, ___EmotionJSX(CarouselInner, {
    carouselName: carouselName,
    slides: slides,
    showNavButtons: requiresScroll && showNavButtons,
    visibleSlides: visibleSlides,
    showBullets: showBullets && requiresScroll,
    buttonOffset: buttonOffset,
    onSlideChange: onSlideChange,
    onCarouselInit: onCarouselInit,
    isVertical: isVertical,
    previousButtonStyles: previousButtonStyles,
    nextButtonStyles: nextButtonStyles,
    arrowStyles: arrowStyles,
    disableNavButtons: disableNavButtons
  })));
};

var CarouselInner = function CarouselInner(_ref2) {
  var carouselName = _ref2.carouselName,
      slides = _ref2.slides,
      showNavButtons = _ref2.showNavButtons,
      visibleSlides = _ref2.visibleSlides,
      showBullets = _ref2.showBullets,
      _ref2$buttonOffset = _ref2.buttonOffset,
      buttonOffset = _ref2$buttonOffset === void 0 ? [0, 0] : _ref2$buttonOffset,
      onSlideChange = _ref2.onSlideChange,
      onCarouselInit = _ref2.onCarouselInit,
      _ref2$isVertical = _ref2.isVertical,
      isVertical = _ref2$isVertical === void 0 ? false : _ref2$isVertical,
      previousButtonStyles = _ref2.previousButtonStyles,
      nextButtonStyles = _ref2.nextButtonStyles,
      arrowStyles = _ref2.arrowStyles,
      disableNavButtons = _ref2.disableNavButtons;
  var lastIndex = useRef(0);
  var carouselContext = useContext(CarouselContext);
  var windowWidth = useWindowSize().width;
  var slideGroups = visibleSlides ? Math.ceil(slides.length / visibleSlides) : slides.length;
  var onChange = useCallback(function () {
    var slideEvent = {
      currentIndex: carouselContext.state.currentSlide,
      slidesPerView: carouselContext.state.visibleSlides,
      direction: carouselContext.state.currentSlide > lastIndex.current ? "forward" : "backward"
    };

    if (lastIndex.current !== carouselContext.state.currentSlide) {
      onSlideChange === null || onSlideChange === void 0 ? void 0 : onSlideChange(slideEvent);
    }

    lastIndex.current = carouselContext.state.currentSlide;
  }, [carouselContext.state.currentSlide, onSlideChange]);
  useEffect(function () {
    carouselContext.subscribe(onChange);
    return function () {
      return carouselContext.unsubscribe(onChange);
    };
  }, [carouselContext, onChange]);
  useEffect(function () {
    if (visibleSlides && typeof onCarouselInit === "function") {
      onCarouselInit(visibleSlides);
    } // Only fire when visibleSlides change
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [visibleSlides]);
  return ___EmotionJSX(React.Fragment, null, windowWidth && ___EmotionJSX(CarouselWatcherDecorated, {
    windowWidth: windowWidth
  }), ___EmotionJSX(Slider, {
    "aria-label": carouselName,
    trayTag: "div",
    tabIndex: -1
  }, slides.map(function (Content, index) {
    return ___EmotionJSX(Slide, {
      key: "slide-".concat(index),
      index: index,
      tag: "div",
      className: "slide-height"
    }, Content);
  })), showNavButtons && !!visibleSlides && slides.length > visibleSlides && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(PreviousButton, {
    buttonOffset: buttonOffset,
    isVertical: isVertical,
    previousButtonStyles: previousButtonStyles,
    disabled: (disableNavButtons === null || disableNavButtons === void 0 ? void 0 : disableNavButtons.previous) || undefined,
    "aria-label": "Previous"
  }, ___EmotionJSX(IconArrow, _extends({
    title: "Previous arrow",
    style: {
      transform: isVertical ? "rotate(90deg)" : "rotate(0deg)"
    },
    color: "typography.inactive",
    width: "22px",
    height: "22px"
  }, arrowStyles))), ___EmotionJSX(NextButton, {
    buttonOffset: buttonOffset,
    isVertical: isVertical,
    nextButtonStyles: nextButtonStyles,
    disabled: (disableNavButtons === null || disableNavButtons === void 0 ? void 0 : disableNavButtons.next) || undefined,
    "aria-label": "Next"
  }, ___EmotionJSX(IconArrow, _extends({
    title: "Next arrow",
    style: {
      transform: isVertical ? "rotate(-90deg)" : "rotate(180deg)"
    },
    color: "typography.inactive",
    width: "22px",
    height: "22px"
  }, arrowStyles)))), showBullets && ___EmotionJSX(Flex, {
    justifyContent: "center",
    py: "10px"
  }, !!visibleSlides && _toConsumableArray(Array(slideGroups)).map(function (_, index) {
    return ___EmotionJSX(Bullet, {
      slide: index * visibleSlides,
      key: "bullet-".concat(index),
      "aria-label": "Go to slide ".concat(index * visibleSlides),
      disabled: (disableNavButtons === null || disableNavButtons === void 0 ? void 0 : disableNavButtons.next) || (disableNavButtons === null || disableNavButtons === void 0 ? void 0 : disableNavButtons.previous) || undefined
    });
  })), CarouselStyles);
};

Carousel.displayName = "Carousel";
export default Carousel;