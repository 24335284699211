import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React, { useContext } from "react";
import { IconMobile, List } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { MenuItem } from "../../../MenuItem";
import { MobileMenuContext } from "../../../MobileMenuContext";
import { jsx as ___EmotionJSX } from "@emotion/react";
var GiftCardsItem = styled(MenuItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 0px;\n  padding: 0px 15px;\n  border-top: 4px solid ", ";\n  border-bottom: 4px solid ", ";\n"])), themeGet("colors.ui.borderColor"), themeGet("colors.ui.borderColor"));
var EssentialsItem = styled(MenuItem)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-left: 7px;\n  align-items: center;\n  flex-direction: row;\n\n  svg {\n    margin-right: 8px;\n    width: 16px;\n  }\n\n  svg,\n  a:focus svg,\n  a:active svg {\n    color: #979797;\n  }\n"])));
export var CatchEssentialsMenu = function CatchEssentialsMenu(_ref) {
  var startIndex = _ref.startIndex;

  var _useContext = useContext(MobileMenuContext),
      essentialsMenu = _useContext.essentialsMenu;

  var _ref2 = essentialsMenu || {},
      giftCards = _ref2.giftCards,
      catchMobile = _ref2.catchMobile;

  return ___EmotionJSX(List, {
    mb: "0px",
    "aria-label": "Catch essentials menu"
  }, giftCards && ___EmotionJSX(GiftCardsItem, {
    url: giftCards,
    itemPath: [{
      name: "Gift Cards",
      id: 0,
      position: startIndex + 1
    }]
  }, "Gift Cards"), catchMobile && ___EmotionJSX(EssentialsItem, {
    url: catchMobile,
    itemPath: [{
      name: "Catch Mobile",
      id: 0,
      position: startIndex + 2
    }]
  }, ___EmotionJSX(IconMobile, {
    height: "20px"
  }), "Catch Mobile"));
};