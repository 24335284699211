import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconLocationPinOutline = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M6.018 11.862c-.204.19-.38.35-.518.473A30.787 30.787 0 013.122 9.97c-.676-.754-1.341-1.588-1.835-2.404C.787 6.736.5 5.985.5 5.379.5 2.695 2.728.5 5.5.5s5 2.195 5 4.88c0 .604-.287 1.356-.787 2.185-.494.816-1.159 1.65-1.835 2.405a30.787 30.787 0 01-1.86 1.892z",
    stroke: "currentColor"
  }), ___EmotionJSX("circle", {
    key: "part-2",
    cx: "5.5",
    cy: "5.5",
    r: "2",
    stroke: "currentColor"
  })],
  fill: "none",
  viewBox: "0 0 11 13"
});