import { SEARCH_SUGGESTION_INDEX_NAME, SEARCH_TRENDING_TERM_POD_ID } from "../../consts";

function hasTrendingSearchTermPodId(entity) {
  return "podId" in entity && entity.podId === SEARCH_TRENDING_TERM_POD_ID;
}

export function isTrendingSearchTerms(item) {
  return hasTrendingSearchTermPodId(item);
}
export function getTrendingSearchTerms(sections) {
  return sections === null || sections === void 0 ? void 0 : sections.find(hasTrendingSearchTermPodId);
}
export function getSuggestedSearchTerms(sections) {
  return sections === null || sections === void 0 ? void 0 : sections.find(function (section) {
    return !("podId" in section) && "indexSectionName" in section && section.indexSectionName === SEARCH_SUGGESTION_INDEX_NAME;
  });
}