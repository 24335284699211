import React from "react";
import { Container, ToastContainer } from "@catchoftheday/cg-components";
import { theme } from "@catchoftheday/theme";
import { HEADER_TOAST_CONTAINER_ID } from "@catchoftheday/toast";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderToastContainer = function HeaderToastContainer() {
  return ___EmotionJSX(Container, {
    position: "relative",
    maxWidth: theme.maxWidths["default"]
  }, ___EmotionJSX(ToastContainer, {
    containerId: HEADER_TOAST_CONTAINER_ID,
    containerStyles: {
      position: "absolute",
      right: "15px",
      transform: "none",
      left: "auto",
      width: "320px"
    },
    toastStyles: {
      fontFamily: theme.fonts["catch"]
    }
  }));
};