import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { useTheme } from "@catchoftheday/theme";
import { Flex } from "../Flex";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Container = function Container(styledProps) {
  var theme = useTheme();
  return ___EmotionJSX(Flex, _extends({
    maxWidth: theme.maxWidths.cotdWebsite,
    mx: "auto",
    my: "0"
  }, styledProps));
};
Container.displayName = "Container";
export default Container;