import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Modal } from "@catchoftheday/cg-components";
import { useEventBus } from "@catchoftheday/state";
import { cookies, getCookie } from "@catchoftheday/utilities";
import { TRIGGER_ADULT_MODAL_EVENT, triggerAdultModal } from "./trigger";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AdultModal = function AdultModal(_ref) {
  var _ref$adult = _ref.adult,
      adult = _ref$adult === void 0 ? false : _ref$adult;

  var _useState = useState(adult),
      open = _useState[0],
      setOpen = _useState[1];

  useEffect(function () {
    if (adult) {
      var hasAdultCookie = getCookie("over18", false);
      setOpen(!hasAdultCookie);
    }
  }, [adult]);

  var closeModal = function closeModal(isAdult) {
    if (isAdult) {
      setOpen(false);
      triggerAdultModal({
        open: false
      });
      cookies.set("over18", "true", {
        expires: 30
      });
    } else {
      // As the adult modal is only connected to the product spa window.location is needed to redirect to relevant home page.
      window.location.assign("/");
    }
  };

  useEventBus(TRIGGER_ADULT_MODAL_EVENT, function (event) {
    if (event.payload) {
      var _open = event.payload.open;
      setOpen(_open);
    }
  });
  return ___EmotionJSX(Modal, {
    shouldCloseOnOverlayClick: false,
    isOpen: open,
    onRequestClose: function onRequestClose() {
      return closeModal(false);
    },
    contentProps: {
      p: 0
    },
    maxWidth: "600px",
    enableDefaultResponsiveStyles: false
  }, ___EmotionJSX(Box, {
    borderBottom: "1px solid #ddd",
    backgroundColor: "#f7f7f7",
    p: "20px"
  }, ___EmotionJSX(Heading.h4, {
    lineHeight: 1,
    fontSize: "1.25rem",
    fontWeight: "600px",
    paddingRight: "36px",
    textAlign: "left",
    m: 0
  }, "You must be 18+ to view this sale")), ___EmotionJSX(Box, {
    marginBottom: 5
  }, ___EmotionJSX(Heading.h4, {
    fontSize: "1.25rem",
    textAlign: "center"
  }, "Are you over 18 years old?"), ___EmotionJSX(Flex, {
    justifyContent: "center"
  }, ___EmotionJSX(Button, {
    variant: "error",
    fontSize: "1rem",
    textAlign: "center",
    marginLeft: "10px",
    marginRight: "10px",
    onClick: function onClick() {
      return closeModal(false);
    }
  }, "No"), ___EmotionJSX(Button, {
    variant: "primary",
    fontSize: "1rem",
    textAlign: "center",
    marginLeft: "10px",
    marginRight: "10px",
    onClick: function onClick() {
      return closeModal(true);
    }
  }, "Yes"))));
};