export var getRecommenderTrackingData = function getRecommenderTrackingData(items, type) {
  return items.map(function (item) {
    var _item$bestOffer, _item$priceTag;

    return {
      id: item.id,
      title: item.title,
      offerId: (_item$bestOffer = item.bestOffer) === null || _item$bestOffer === void 0 ? void 0 : _item$bestOffer.id,
      sellPrice: (_item$priceTag = item.priceTag) === null || _item$priceTag === void 0 ? void 0 : _item$priceTag.priceLabel.price.amount,
      badgeText: item.badgeText || "",
      type: type,
      sourceType: item.sourceType
    };
  });
};