import React from "react";
import { ToastContainer } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NotificationToastContainer = function NotificationToastContainer(_ref) {
  var containerId = _ref.containerId;
  return ___EmotionJSX(ToastContainer, {
    containerId: containerId,
    autoClose: 5000,
    containerStyles: {
      marginTop: "70px"
    }
  });
};