import React from "react";
import { Box, Flex, ImageResponsive, Link, Text } from "@catchoftheday/cg-components";
import { SocialUrgency } from "@catchoftheday/p13n-integration";
import { ExclusiveBadge } from "../ExclusiveBadge";
import { CTA } from "./CTA";
import { Titles } from "./Titles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeroEventBanner = function HeroEventBanner(_ref) {
  var isShopToggleExperiment = _ref.isShopToggleExperiment,
      socialUrgencyStats = _ref.socialUrgencyStats,
      isActiveMember = _ref.isActiveMember,
      isOnePassExclusive = _ref.isOnePassExclusive,
      imageUrl = _ref.imageUrl,
      imageAltText = _ref.imageAltText,
      eventUrl = _ref.eventUrl,
      title = _ref.title,
      _ref$subtitle = _ref.subtitle,
      subtitle = _ref$subtitle === void 0 ? null : _ref$subtitle,
      isSingleProductEvent = _ref.isSingleProductEvent,
      isSoldOut = _ref.isSoldOut,
      endDate = _ref.endDate,
      onPrimaryCTAClick = _ref.onPrimaryCTAClick;
  return ___EmotionJSX(Flex, {
    flexDirection: {
      xs: "column",
      md: "row-reverse"
    },
    justifyContent: "center",
    alignItems: "center"
  }, ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "50%"
    },
    px: {
      xs: 3,
      md: 0
    }
  }, ___EmotionJSX(Link, {
    href: eventUrl,
    title: title
  }, ___EmotionJSX(ImageResponsive, {
    src: imageUrl,
    alt: imageAltText || title,
    width: "100%",
    height: "auto"
  }))), ___EmotionJSX(Flex, {
    width: {
      xs: "100%",
      md: "50%"
    },
    px: 2,
    pt: 2,
    pb: {
      xs: 10,
      md: 2
    },
    flexDirection: "column",
    maxWidth: "400px",
    alignItems: {
      xs: "center",
      md: "flex-start"
    }
  }, ___EmotionJSX(Link, {
    href: eventUrl,
    color: "textSecondary",
    hoverColor: "inherit",
    title: title,
    hoverTextDecoration: "none"
  }, isOnePassExclusive && ___EmotionJSX(ExclusiveBadge, null), ___EmotionJSX(Titles, {
    title: title,
    subtitle: subtitle
  })), ___EmotionJSX(SocialUrgency, {
    bordered: false,
    stats: socialUrgencyStats,
    containerProps: {
      m: 0,
      mt: 1
    }
  }), ___EmotionJSX(CTA, {
    btnText: isShopToggleExperiment ? "Shop thousands of new" : "",
    eventUrl: eventUrl,
    isActiveMember: isActiveMember,
    isSingleProductEvent: isSingleProductEvent,
    isSoldOut: isSoldOut,
    onPrimaryClick: onPrimaryCTAClick
  }), endDate && ___EmotionJSX(Text, {
    m: 0,
    fontSize: {
      xs: "2xs",
      md: "xs"
    },
    mt: {
      xs: 2,
      md: 3
    },
    textAlign: {
      xs: "center",
      md: "left"
    }
  }, "Deal ends ", endDate, " or while stocks last.")));
};