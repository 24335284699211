import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { ProductPriceLabel } from "./ProductPriceLabel/ProductPriceLabel";
import { ProductPriceOnePass } from "./ProductPriceOnePass";
import { ProductPriceSell } from "./ProductPriceSell";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPrice = function ProductPrice(_ref) {
  var _priceLabel$wasPrice;

  var priceTag = _ref.priceTag,
      _ref$large = _ref.large,
      large = _ref$large === void 0 ? false : _ref$large;
  var priceDiscount = priceTag.priceDiscount,
      topLabel = priceTag.topLabel,
      priceLabel = priceTag.priceLabel,
      clubSellPrice = priceTag.clubSellPrice,
      enabled = priceTag.enabled,
      clubExclusive = priceTag.clubExclusive,
      retailPriceDisclaimer = priceTag.retailPriceDisclaimer;
  var hasDisplayLabel = !!priceDiscount || !!topLabel;
  return ___EmotionJSX(Box, {
    display: "inline-flex",
    flex: "1 1 auto",
    flexDirection: "column",
    fontSize: large ? "2.5rem" : "1.75rem",
    lineHeight: 1,
    alignItems: large ? {
      xs: "center",
      lg: "flex-start"
    } : "center",
    mt: hasDisplayLabel ? "0px" : "16px"
  }, ___EmotionJSX(ProductPriceLabel, {
    discount: priceDiscount,
    topLabel: topLabel,
    enabled: enabled,
    large: large,
    retailPriceDisclaimer: retailPriceDisclaimer === null || retailPriceDisclaimer === void 0 ? void 0 : retailPriceDisclaimer.description
  }), ___EmotionJSX(ProductPriceSell, {
    price: priceLabel.price.amount,
    saving: priceLabel.saving,
    wasPrice: (_priceLabel$wasPrice = priceLabel.wasPrice) === null || _priceLabel$wasPrice === void 0 ? void 0 : _priceLabel$wasPrice.amount,
    onePassExclusive: clubExclusive,
    hasPromotion: !!priceTag.priceDiscount,
    enabled: enabled,
    large: large
  }), clubSellPrice && enabled && ___EmotionJSX(ProductPriceOnePass, {
    price: clubSellPrice.amount,
    large: large
  }));
};