import { useEffect, useState } from "react";
import { findLastIndex } from "lodash-es";
import { useSalesRulePromotionsQuery } from "@catchoftheday/cart-components/src/generated/graphql";
export var useSalesRulePromotionProgress = function useSalesRulePromotionProgress(_ref) {
  var queryInput = _ref.queryInput,
      skip = _ref.skip;

  var _useState = useState({
    salesRuleId: "",
    description: "",
    linkText: "",
    eventSlug: "",
    isApplied: false
  }),
      srpProgressCopy = _useState[0],
      setSrpProgressCopy = _useState[1];

  var _useState2 = useState(false),
      initSrpProgress = _useState2[0],
      setInitSrpProgress = _useState2[1];

  var _useState3 = useState(false),
      srpProgressDone = _useState3[0],
      setSrpProgressDone = _useState3[1];

  var _useSalesRulePromotio = useSalesRulePromotionsQuery({
    skip: skip,
    ssr: false,
    variables: {
      input: queryInput
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: function onCompleted(data) {
      if (!initSrpProgress) {
        var _progressSrp$event;

        var promotions = data.salesRulePromotions || [];
        var progressSrp = promotions.find(function (promotion) {
          return !(promotion !== null && promotion !== void 0 && promotion.isApplied);
        });
        var lastProgressSrpIndex = findLastIndex(promotions, "isApplied");
        setSrpProgressCopy({
          salesRuleId: (progressSrp === null || progressSrp === void 0 ? void 0 : progressSrp.id.toString()) || "",
          description: progressSrp === null || progressSrp === void 0 ? void 0 : progressSrp.progressLabel,
          linkText: progressSrp === null || progressSrp === void 0 ? void 0 : progressSrp.progressLabelSpan,
          eventSlug: progressSrp === null || progressSrp === void 0 ? void 0 : (_progressSrp$event = progressSrp.event) === null || _progressSrp$event === void 0 ? void 0 : _progressSrp$event.slug,
          isApplied: progressSrp === null || progressSrp === void 0 ? void 0 : progressSrp.isApplied
        });
        setSrpProgressDone(progressSrp ? progressSrp.isApplied : lastProgressSrpIndex > -1);
        setInitSrpProgress(true);
      }
    }
  }),
      salesRulePromotionsData = _useSalesRulePromotio.data,
      salesRulePromotionsLoading = _useSalesRulePromotio.loading,
      queryError = _useSalesRulePromotio.error,
      salesRulePromotionsRefetch = _useSalesRulePromotio.refetch;

  var addToCartEventHandler = function addToCartEventHandler(salesRulePromotions) {
    if (salesRulePromotions.salesRulePromotions.length === 0) {
      setSrpProgressCopy({
        salesRuleId: "",
        description: "",
        linkText: "",
        eventSlug: "",
        isApplied: false
      });
      return;
    }

    var promotions = salesRulePromotions.salesRulePromotions;
    var latestProgressSrp = promotions.find(function (promotion) {
      return !(promotion !== null && promotion !== void 0 && promotion.isApplied);
    });
    var lastAppliedSrpIndex = findLastIndex(salesRulePromotions.salesRulePromotions, "isApplied");
    var lastAppliedSrp = lastAppliedSrpIndex > -1 && salesRulePromotions.salesRulePromotions[lastAppliedSrpIndex];
    var progressionSrp = lastAppliedSrp || latestProgressSrp;

    if (!srpProgressCopy.isApplied) {
      var _progressionSrp$event;

      setSrpProgressCopy({
        salesRuleId: (progressionSrp === null || progressionSrp === void 0 ? void 0 : progressionSrp.id.toString()) || "",
        description: progressionSrp === null || progressionSrp === void 0 ? void 0 : progressionSrp.progressLabel,
        linkText: progressionSrp === null || progressionSrp === void 0 ? void 0 : progressionSrp.progressLabelSpan,
        eventSlug: progressionSrp === null || progressionSrp === void 0 ? void 0 : (_progressionSrp$event = progressionSrp.event) === null || _progressionSrp$event === void 0 ? void 0 : _progressionSrp$event.slug,
        isApplied: progressionSrp === null || progressionSrp === void 0 ? void 0 : progressionSrp.isApplied
      });
    } else if (lastAppliedSrp) {
      var _lastAppliedSrp$event;

      setSrpProgressCopy({
        salesRuleId: lastAppliedSrp.id.toString(),
        description: lastAppliedSrp.progressLabel,
        linkText: lastAppliedSrp.progressLabelSpan,
        eventSlug: (_lastAppliedSrp$event = lastAppliedSrp.event) === null || _lastAppliedSrp$event === void 0 ? void 0 : _lastAppliedSrp$event.slug,
        isApplied: lastAppliedSrp.isApplied
      });
    }

    if (promotions.every(function (promotion) {
      return promotion === null || promotion === void 0 ? void 0 : promotion.isApplied;
    })) {
      setSrpProgressDone(true);
    }
  };

  useEffect(function () {
    if (initSrpProgress && !srpProgressDone && !salesRulePromotionsLoading && salesRulePromotionsData) {
      addToCartEventHandler(salesRulePromotionsData);
    }
  }, [salesRulePromotionsLoading]);

  var resetSalesRulePromotions = function resetSalesRulePromotions(_payload) {
    if (true) {
      setSrpProgressDone(false);
      setSrpProgressCopy({
        salesRuleId: "",
        description: "",
        linkText: "",
        eventSlug: "",
        isApplied: false
      });
      salesRulePromotionsRefetch();
    }
  };

  return {
    srpProgressCopy: srpProgressCopy,
    srpProgressDone: srpProgressDone,
    addToCartEventHandler: addToCartEventHandler,
    resetSalesRulePromotions: resetSalesRulePromotions,
    query: {
      data: salesRulePromotionsData,
      error: queryError,
      loading: salesRulePromotionsLoading,
      refetch: salesRulePromotionsRefetch
    }
  };
};