import React from "react";
import { Box, Span, Text } from "@catchoftheday/cg-components";
import { centsToDollars, formatPrice } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Prices = function Prices(_ref) {
  var sellPrice = _ref.sellPrice,
      retailPrice = _ref.retailPrice,
      clubSellPrice = _ref.clubSellPrice;
  var mainPrice = clubSellPrice ? clubSellPrice : sellPrice;
  var regularPrice = retailPrice;
  return ___EmotionJSX(Box, {
    mt: 4
  }, regularPrice && ___EmotionJSX(Text, {
    m: 0,
    color: "textTertiary",
    fontSize: "xs"
  }, "Regular price ", formatPrice(regularPrice, false)), ___EmotionJSX(Box, {
    as: "span",
    fontWeight: "bold",
    color: "onePass.primary",
    lineHeight: "1",
    fontSize: {
      xs: "6xl",
      lg: "7xl"
    },
    display: "inline-flex",
    alignItems: "flex-start"
  }, ___EmotionJSX(Span, {
    lineHeight: "inherit",
    fontWeight: "inherit",
    fontSize: {
      xs: "2xl",
      lg: "4xl"
    }
  }, "$"), centsToDollars(mainPrice)));
};