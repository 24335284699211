import React from "react";
import { Flex, IconFlybuys, IconOnePass, Span, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UnpaidFreeDeliveryVariant = function UnpaidFreeDeliveryVariant(_ref) {
  var isLoggedIn = _ref.isLoggedIn,
      isActiveMembership = _ref.isActiveMembership,
      isFreeTrialEligible = _ref.isFreeTrialEligible,
      onMainCTAClick = _ref.onMainCTAClick;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconOnePass, {
    title: "OnePass",
    "aria-label": "OnePass",
    color: "onePass.primary",
    width: "auto",
    height: "1em"
  }), ___EmotionJSX(Flex, {
    alignItems: "center"
  }, ___EmotionJSX(Flex, {
    width: "26px",
    height: "16px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightGrey",
    mr: 2
  }, ___EmotionJSX(IconFlybuys, {
    color: "brand.flybuys",
    width: "21px",
    height: "auto"
  })), ___EmotionJSX(Text, null, isActiveMembership ? ___EmotionJSX(React.Fragment, null, "You'll get ", ___EmotionJSX("strong", null, "2x Flybuys points"), " on this item.*") : ___EmotionJSX(React.Fragment, null, "Get ", ___EmotionJSX("strong", null, "2x Flybuys points.*"), ___EmotionJSX(Span, {
    role: "button",
    color: "onePass.primary",
    fontWeight: "bold",
    fontSize: "inherit",
    textDecoration: "underline",
    whiteSpace: "nowrap",
    ml: 1,
    style: {
      cursor: "pointer"
    },
    onClick: onMainCTAClick
  }, isFreeTrialEligible || !isLoggedIn ? "Start free 30 day trial" : "Join OnePass today")))));
};