import React from "react";
import { Box, Flex, Heading, Image, Text } from "@catchoftheday/cg-components";
import { ASSET_ONEPASS_MASCOT_FLYING_WINKS } from "../../../../consts";
import { TransferCTAButton } from "../TransferCTAButton";
import { Step } from "./Step";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HowToTransfer = function HowToTransfer() {
  return ___EmotionJSX(Flex, {
    flexDirection: "row-reverse",
    mb: {
      xs: 16,
      md: 12
    },
    flexWrap: "wrap",
    alignItems: "center"
  }, ___EmotionJSX(Image, {
    width: {
      xs: "100%",
      md: "40%"
    },
    height: "auto",
    maxWidth: {
      xs: "360px",
      md: "426px"
    },
    mr: {
      xs: "auto",
      md: 0
    },
    ml: "auto",
    pb: {
      xs: 0,
      md: 10
    },
    src: ASSET_ONEPASS_MASCOT_FLYING_WINKS,
    alt: "OnePass mascot flying with its jetpack and winking"
  }), ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "60%"
    },
    maxWidth: "600px",
    mx: "auto",
    pr: {
      xs: 0,
      md: 10
    },
    color: "onePass.textPrimary"
  }, ___EmotionJSX(Heading.h2, {
    fontSize: "3xl",
    fontWeight: "bold",
    mb: {
      xs: 4,
      md: 6
    }
  }, "How do I transfer?"), ___EmotionJSX(Text, {
    fontSize: "base",
    fontWeight: "medium",
    m: 0,
    mb: 6
  }, "To transfer your membership follow the steps below:"), ___EmotionJSX(Box, {
    position: "relative",
    mb: 6
  }, ___EmotionJSX(Box, {
    position: "absolute",
    width: "12px",
    backgroundColor: "onePass.primaryLight",
    top: "12px",
    bottom: "12px",
    left: "6px"
  }), ___EmotionJSX(Step, {
    step: 1,
    active: true,
    description: "Click the transfer now link and login to your Catch Account"
  }), ___EmotionJSX(Step, {
    step: 2,
    description: "Complete your details on the OnePass form"
  }), ___EmotionJSX(Step, {
    step: 3,
    description: "Complete the verification step for your account"
  }), ___EmotionJSX(Step, {
    step: 4,
    last: true,
    description: "Pick your membership plan and enter your payment details"
  })), ___EmotionJSX(Text, {
    fontSize: "base",
    fontWeight: "medium",
    m: 0,
    mb: 6
  }, "You are all set! You will now be able to enjoy the benefits of your OnePass subscription."), ___EmotionJSX(TransferCTAButton, null)));
};