import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { mapSponsoredProductsTracking } from "@catchoftheday/analytics";
import { Pagination } from "@catchoftheday/cg-components";
import { GlobalContextProvider, MetaTags, MetaTitle, SponsoredDisclaimerModal } from "@catchoftheday/common-vendors";
import { convertProductCard, ProductGrid } from "@catchoftheday/product-components";
import { scrollToTop } from "@catchoftheday/utilities";
import { PRODUCTS_PER_PAGE } from "../../../consts";
import { useRetailMediaBannerOffersQuery } from "../../../generated/graphql";
import { HeaderZone, PageContentZone, PaginationZone } from "../../layouts/PageLayout/PageLayout";
import { LoadingSpinner } from "../../pure/LoadingSpinner";
import { SponsoredProductsHeader } from "../../pure/SponsoredProductsHeader/SponsoredProductsHeader";
import { getOffset, getPageTotal, getSellerPageUrl } from "../../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
var limit = PRODUCTS_PER_PAGE;
export var SponsoredProductsContainer = function SponsoredProductsContainer() {
  var router = useRouter();
  var _router$query = router.query,
      sellerSlug = _router$query.sellerSlug,
      requestId = _router$query.requestId,
      _page = _router$query.page,
      st = _router$query.st,
      sourceId = _router$query.sid;
  var page = parseInt(_page, 10) || 1;
  var sourceType = parseInt(st, 10);

  var _useState = useState(new Array(limit).fill(null)),
      sponsoredProducts = _useState[0],
      setSponsoredProducts = _useState[1];

  var _useState2 = useState(),
      sponsoredItemsTracking = _useState2[0],
      setSponsoredItemsTracking = _useState2[1];

  var _useState3 = useState(getOffset(page)),
      offset = _useState3[0],
      setOffset = _useState3[1];

  var _useState4 = useState(0),
      pageTotal = _useState4[0],
      setPageTotal = _useState4[1];

  var _useState5 = useState(),
      urlPath = _useState5[0],
      setUrlPath = _useState5[1];

  var _useRetailMediaBanner = useRetailMediaBannerOffersQuery({
    variables: {
      requestId: requestId,
      limit: limit,
      offset: offset
    },
    notifyOnNetworkStatusChange: true
  }),
      data = _useRetailMediaBanner.data,
      fetchMore = _useRetailMediaBanner.fetchMore,
      loading = _useRetailMediaBanner.loading,
      error = _useRetailMediaBanner.error;

  var _ref = (data === null || data === void 0 ? void 0 : data.retailMediaBannerOffers) || {},
      result = _ref.result,
      sellerName = _ref.sellerName,
      totalCount = _ref.totalCount;

  useEffect(function () {
    if (result && totalCount) {
      setSponsoredProducts(result.products.map(function (product) {
        return _objectSpread(_objectSpread({}, convertProductCard(product, product.selectedOffer)), {}, {
          sourceType: sourceType,
          sourceId: sourceId,
          isSponsored: true,
          hideSponsoredLabel: true,
          linkToOffer: true
        });
      }));
      setSponsoredItemsTracking(mapSponsoredProductsTracking(result.tracking));
      setPageTotal(getPageTotal(totalCount));
    }
  }, [result, sourceType, sourceId, totalCount]);
  useEffect(function () {
    setUrlPath(window.location.href);
  }, []);
  useEffect(function () {
    setOffset(getOffset(page));
  }, [page]);
  useEffect(function () {
    var _data$retailMediaBann, _data$retailMediaBann2, _data$retailMediaBann3;

    if (!loading && !(data !== null && data !== void 0 && (_data$retailMediaBann = data.retailMediaBannerOffers) !== null && _data$retailMediaBann !== void 0 && (_data$retailMediaBann2 = _data$retailMediaBann.result) !== null && _data$retailMediaBann2 !== void 0 && (_data$retailMediaBann3 = _data$retailMediaBann2.products) !== null && _data$retailMediaBann3 !== void 0 && _data$retailMediaBann3.length) || error) {
      // If no results or error, redirect to seller page.
      location.assign(getSellerPageUrl(sellerSlug));
      return;
    }
  }, [data === null || data === void 0 ? void 0 : data.retailMediaBannerOffers, error, router, loading, sellerSlug]);

  function goToPage(page) {
    router.push({
      pathname: router.pathname,
      query: _objectSpread(_objectSpread({}, router.query), {}, {
        page: page
      })
    });
    fetchMore({
      variables: {
        offset: getOffset(page)
      }
    });
    scrollToTop();
  }

  return ___EmotionJSX(GlobalContextProvider, null, sellerName && ___EmotionJSX(MetaTitle, null, "".concat(sellerName, " Products")), ___EmotionJSX(MetaTags, {
    tags: [{
      name: "robots",
      content: "noindex"
    }],
    withHead: true
  }), ___EmotionJSX(SponsoredDisclaimerModal, null), ___EmotionJSX(PageContentZone, null, loading && ___EmotionJSX(LoadingSpinner, null), ___EmotionJSX(HeaderZone, null, ___EmotionJSX(SponsoredProductsHeader, {
    sellerName: sellerName,
    sellerSlug: sellerSlug
  })), ___EmotionJSX(ProductGrid, {
    productItems: sponsoredProducts,
    sponsoredItemsTracking: sponsoredItemsTracking,
    positionOffset: getOffset(page),
    gridTemplateColumns: {
      sm: "repeat(2, 1fr)",
      md: "repeat(3, 1fr)",
      lg: "repeat(4, 1fr)",
      xl: "repeat(6, 1fr)"
    }
  })), ___EmotionJSX(PaginationZone, null, ___EmotionJSX(Pagination, {
    pageCurrent: page,
    pageTotal: pageTotal,
    onPageClick: goToPage,
    parameter: "page",
    urlPath: urlPath
  })));
};