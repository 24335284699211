import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { getEncodedUrl } from "@catchoftheday/utilities";
import { URL_ONEPASS_CONSENT, URL_ONEPASS_OAUTH_REDIRECT, URL_ONEPASS_SUBSCRIBE } from "../consts";
export var generateOnePassLinkingUrl = function generateOnePassLinkingUrl(url, queryParams) {
  return "".concat(URL_ONEPASS_OAUTH_REDIRECT, "?n=1&_destination=").concat(encodeURIComponent(getEncodedUrl(url, _objectSpread({
    showmessage: "l"
  }, queryParams))));
};
export var generateOnePassUpsellUrl = function generateOnePassUpsellUrl(_ref) {
  var _ref$url = _ref.url,
      url = _ref$url === void 0 ? "/" : _ref$url,
      _ref$cta = _ref.cta,
      cta = _ref$cta === void 0 ? "start-free-trial" : _ref$cta;
  return "".concat(URL_ONEPASS_SUBSCRIBE, "?_destination=").concat(getEncodedUrl(url, {
    showmessage: "u"
  }), "&utm_cta=").concat(cta);
};
export var generateOnePassConsentUrl = function generateOnePassConsentUrl(url, queryParams) {
  var destinationUrl = queryParams && Object.keys(queryParams).length > 0 ? getEncodedUrl(url, _objectSpread({}, queryParams)) : url;
  return "".concat(URL_ONEPASS_CONSENT, "?_destination=").concat(encodeURIComponent(destinationUrl));
};