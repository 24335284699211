import React from "react";
import Countdown from "react-countdown";
import { Box, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CountdownTimer = function CountdownTimer(_ref) {
  var date = _ref.date,
      maxHoursShown = _ref.maxHoursShown,
      timerLabel = _ref.timerLabel;

  var renderer = function renderer(_ref2) {
    var _ref2$formatted = _ref2.formatted,
        hours = _ref2$formatted.hours,
        minutes = _ref2$formatted.minutes,
        seconds = _ref2$formatted.seconds,
        completed = _ref2.completed;
    if (completed) return null;
    var hourNum = Number(hours);

    if (hourNum > maxHoursShown) {
      var daysLeft = Math.floor(hourNum / 24);
      return ___EmotionJSX(Box, {
        display: "inline-block",
        color: "errorDark",
        textTransform: "uppercase",
        mr: "0.11em"
      }, ___EmotionJSX(Span, {
        fontWeight: "semibold"
      }, hourNum < 24 ? "".concat(hourNum, " hours left") : "".concat(daysLeft, " day").concat(daysLeft > 1 ? "s" : "", " left")));
    } else return ___EmotionJSX(Box, {
      display: "inline-block",
      color: "errorDark",
      textTransform: "uppercase",
      mr: "0.11em"
    }, timerLabel && ___EmotionJSX(Span, {
      fontWeight: "semibold"
    }, timerLabel, "\xA0"), ___EmotionJSX(Span, {
      fontFamily: "Helvetica,Arial,sans-serif",
      fontWeight: "semibold"
    }, hours, ":", minutes, ":", seconds));
  };

  return ___EmotionJSX(Countdown, {
    daysInHours: true,
    date: date,
    renderer: renderer
  });
};