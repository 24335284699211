import { createSvgIconComponent } from "../utils";
export var IconOnePassTags = createSvgIconComponent({
  paths: [{
    d: "m7.533 30.092 1.917 4.132c.487 1.046 1.712 1.491 2.725.993l.224-.11-4.866-5.015ZM3.312 20.623 20.136 3.266 1.6 12.414c-1.013.502-1.445 1.763-.962 2.808l2.561 5.525c.04-.041.073-.087.114-.124ZM40.33.333H27.087c-1.123 0-2.693.672-3.486 1.491L4.347 21.687a2.16 2.16 0 0 0 0 2.978l14.44 14.896a2.007 2.007 0 0 0 2.89 0L40.93 19.698c.794-.819 1.445-2.438 1.445-3.596V2.439C42.371 1.284 41.453.333 40.33.333Zm-9.5 22.207c-.004 1.287-.44 2.434-1.219 3.427-1.277 1.623-2.948 2.374-4.968 2.193-1.664-.148-3.007-.951-4.02-2.321-.809-1.091-1.142-2.34-1.142-3.699.004-1.487 0-2.974 0-4.457 0-.102-.01-.204-.014-.306-1.27-.004-2.54-.007-3.812-.015h-.366c.113-.124.18-.2.249-.268 1.66-1.713 3.318-3.43 4.986-5.133.472-.483.936-1 1.478-1.385 2.023-1.445 4.686-1.37 6.618.193 1.43 1.158 2.206 2.69 2.21 4.593.007 2.389.007 4.781 0 7.178Zm5.937-14.372c-1.098 0-1.987-.917-1.987-2.049s.89-2.05 1.987-2.05 1.986.918 1.986 2.05c0 1.132-.889 2.05-1.986 2.05Z",
    props: {
      fill: "currentColor"
    }
  }, {
    d: "M27.793 14.973c-.197-1.09-.805-1.846-1.825-2.182-1.029-.34-1.958-.071-2.722.695-1.273 1.28-2.517 2.589-3.772 3.883.099.008.198.023.293.023.823 0 1.646.008 2.465-.004.22-.004.29.053.29.29-.012 1.567-.008 3.13-.004 4.696 0 1.498 1.181 2.713 2.641 2.717 1.47.004 2.663-1.211 2.663-2.721v-7a2.802 2.802 0 0 0-.029-.397Z",
    props: {
      fill: "currentColor"
    }
  }],
  viewBox: "0 0 43 41"
});