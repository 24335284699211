import React from "react";
import { CalloutTag } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceOnePass = function ProductPriceOnePass(_ref) {
  var price = _ref.price,
      large = _ref.large;
  return ___EmotionJSX(CalloutTag, {
    variant: "onepass",
    fontSize: large ? "16px" : "12px",
    mt: 1
  }, formatPrice(price.amount, false), " with OnePass");
};