import React from "react";
import { Box, ContentPlaceHolder } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchSuggestionsLoading = function SearchSuggestionsLoading() {
  return ___EmotionJSX(Box, {
    mt: 4,
    role: "progressbar"
  }, ___EmotionJSX(ContentPlaceHolder, {
    width: "300px",
    viewBox: "0 0 300 145",
    speed: 2,
    backgroundColor: "#f3f3f3",
    foregroundColor: "#ecebeb"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    width: "220",
    height: "16"
  }), ___EmotionJSX("rect", {
    x: "0",
    y: "28",
    width: "120",
    height: "16"
  }), ___EmotionJSX("rect", {
    x: "0",
    y: "56",
    width: "200",
    height: "16"
  }), ___EmotionJSX("rect", {
    x: "0",
    y: "84",
    width: "182",
    height: "16"
  }), ___EmotionJSX("rect", {
    x: "0",
    y: "112",
    width: "225",
    height: "16"
  })));
};