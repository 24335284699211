import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Heading, Text } from "@catchoftheday/cg-components";
import { px } from "../../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Title = function Title(props) {
  return ___EmotionJSX(Heading.h5, _extends({
    fontWeight: 600,
    fontSize: "sm",
    textTransform: "uppercase",
    marginBottom: "10px",
    marginTop: "0px",
    color: "#222222"
  }, props));
};

var BaseText = function BaseText(props) {
  return ___EmotionJSX(Text, _extends({
    lineHeight: 1.4,
    color: "#333333"
  }, props));
};

export var Body = function Body(props) {
  return ___EmotionJSX(BaseText, _extends({
    fontSize: px(14)
  }, props));
};