import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useContext } from "react";
import { IconToolTip, Span } from "@catchoftheday/cg-components";
import { GlobalContext } from "@catchoftheday/common-vendors";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SponsoredLabel = function SponsoredLabel(_ref) {
  var props = _extends({}, _ref);

  var _useContext = useContext(GlobalContext),
      setIsSponsoredModalOpen = _useContext.sponsoredModal.setIsSponsoredModalOpen;

  return ___EmotionJSX(Span, _extends({
    color: "typography.subText",
    display: "inline-block"
  }, props), "Sponsored", ___EmotionJSX(IconToolTip, {
    "aria-label": "Price disclaimer",
    tabIndex: 0,
    width: "12px",
    height: "12px",
    ml: 1,
    mb: "2px",
    color: "textTertiary",
    cursor: "pointer",
    onClick: function onClick(event) {
      event.preventDefault();
      setIsSponsoredModalOpen(true);
    }
  }));
};