import React from "react";
import { Flex, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var StatusRow = function StatusRow(_ref) {
  var label = _ref.label,
      value = _ref.value,
      valueColor = _ref.valueColor;
  return ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    mb: 4
  }, ___EmotionJSX(Text, {
    m: 0,
    fontSize: "base"
  }, label), ___EmotionJSX(Text, {
    m: 0,
    fontSize: "base",
    fontWeight: "bold",
    color: valueColor
  }, value));
};