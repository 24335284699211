import React from "react";
import { Box, Flex, Heading, Image, Text } from "@catchoftheday/cg-components";
import { ASSET_ONEPASS_MASCOT_FRONT_FACE } from "../../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TransferReason = function TransferReason() {
  return ___EmotionJSX(Flex, {
    mb: 20,
    flexWrap: "wrap",
    alignItems: "center"
  }, ___EmotionJSX(Image, {
    width: {
      xs: "100%",
      md: "40%"
    },
    height: "auto",
    maxWidth: {
      xs: "360px",
      md: "475px"
    },
    ml: {
      xs: "auto",
      md: 0
    },
    mr: "auto",
    src: ASSET_ONEPASS_MASCOT_FRONT_FACE,
    alt: "OnePass mascot looking straight to the camera"
  }), ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "60%"
    },
    maxWidth: "600px",
    mx: "auto",
    pl: {
      xs: 0,
      md: 5
    },
    color: "onePass.textPrimary"
  }, ___EmotionJSX(Heading.h2, {
    fontSize: {
      xs: "xl",
      md: "3xl"
    },
    fontWeight: "bold",
    mb: {
      xs: 4,
      md: 6
    }
  }, "Why do I need to transfer?"), ___EmotionJSX(Text, {
    fontSize: "base",
    m: 0,
    fontWeight: "medium"
  }, "The OnePass membership provider is changing from Catch to Wesfarmers One Pass Pty Ltd., the operator of OnePass, to bring you more benefits. To access your new membership benefits, such as free delivery on eligible items or orders when shopping at Kmart, Target, Bunnings Warehouse and Officeworks, you'll need to create a new OnePass account.", ___EmotionJSX("br", null), ___EmotionJSX("br", null), "After creating a new account, your payment will transfer across to the new provider and you will no longer be billed by Catch. You'll also start to see Wesfarmers appear on your bank statement.")));
};