import React, { useContext, useState } from "react";
import { MAX_VISIBLE_LIST_FACET_OPTIONS, ONEPASS_DISPLAY_NAME } from "../../../../consts";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { FacetAccordion } from "../FacetAccordion/FacetAccordion";
import { ListFacetOptions } from "../ListFacetOptions/ListFacetOptions";
import { ShowMoreButton } from "../ShowMoreButton/ShowMoreButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ListFacet = function ListFacet(_ref) {
  var facet = _ref.facet;

  var _useContext = useContext(CatalogueContext),
      addFilterValue = _useContext.addFilterValue,
      removeFilterValue = _useContext.removeFilterValue;

  var id = facet.id,
      displayName = facet.displayName,
      totalApplied = facet.totalApplied,
      options = facet.options,
      name = facet.name,
      type = facet.type;
  var isRadio = type === "SINGLE";

  var _useState = useState(false),
      showMore = _useState[0],
      setShowMore = _useState[1];

  var toggleShowMore = function toggleShowMore() {
    setShowMore(!showMore);
  };

  var onChange = function onChange(e) {
    if (!e.target.checked) {
      removeFilterValue("f.".concat(name), e.target.value);
      return;
    }

    addFilterValue("f.".concat(name), e.target.value, !isRadio);
  };

  return ___EmotionJSX(FacetAccordion, {
    id: id,
    displayName: displayName,
    totalApplied: totalApplied,
    order: displayName.toLowerCase() === ONEPASS_DISPLAY_NAME ? -1 : 0,
    expanded: facet.expanded
  }, ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ListFacetOptions, {
    options: options,
    showMore: showMore,
    onChange: onChange,
    isRadio: isRadio,
    isStarRating: displayName.toLowerCase().includes("rating")
  }), options.length > MAX_VISIBLE_LIST_FACET_OPTIONS && ___EmotionJSX(ShowMoreButton, {
    showMore: showMore,
    toggleShowMore: toggleShowMore
  })));
};