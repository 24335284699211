import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["highlight", "text", "highlightTextProps", "textProps", "reverse"];
import React from "react";
import { escapeStringRegex } from "@catchoftheday/utilities";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Highlighter = function Highlighter(_ref) {
  var _ref$highlight = _ref.highlight,
      highlight = _ref$highlight === void 0 ? "" : _ref$highlight,
      _ref$text = _ref.text,
      text = _ref$text === void 0 ? "" : _ref$text,
      highlightTextProps = _ref.highlightTextProps,
      textProps = _ref.textProps,
      reverse = _ref.reverse,
      props = _objectWithoutProperties(_ref, _excluded);

  var highlights = Array.isArray(highlight) ? highlight : [highlight];
  var escapedHighlights = highlights.map(function (term) {
    return escapeStringRegex(term);
  });
  var combinedRegex = new RegExp("(".concat(escapedHighlights.join("|"), ")"), "gi");
  var parts = text.split(combinedRegex);

  function renderHighlightedString(text, key) {
    if (!text) {
      return null;
    }

    return ___EmotionJSX(Span, _extends({
      key: key
    }, highlightTextProps), text);
  }

  return ___EmotionJSX(Span, _extends({}, textProps, props, {
    fontWeight: "bold"
  }), parts.map(function (part, idx) {
    return reverse ? highlights.some(function (term) {
      return part.toLowerCase() === term.toLowerCase();
    }) ? part : renderHighlightedString(part, idx) : highlights.some(function (term) {
      return part.toLowerCase() === term.toLowerCase();
    }) ? renderHighlightedString(part, idx) : part;
  }));
};
Highlighter.displayName = "Highlighter";
export default Highlighter;