import React, { useContext } from "react";
import { Flex, Heading, IconX, TextButton } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { MobileFacetHeaderZone } from "../../../layouts/PageLayout/PageLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MobileFacetHeader = function MobileFacetHeader(_ref) {
  var hasFacetsApplied = _ref.hasFacetsApplied;

  var _useContext = useContext(CatalogueContext),
      toggleMobileFacets = _useContext.toggleMobileFacets,
      removeAllFilters = _useContext.removeAllFilters;

  return ___EmotionJSX(MobileFacetHeaderZone, null, ___EmotionJSX(Flex, {
    alignItems: "center"
  }, ___EmotionJSX(TextButton, {
    color: "white",
    "aria-label": "Close",
    width: "50px",
    onClick: toggleMobileFacets
  }, ___EmotionJSX(IconX, {
    title: "Close",
    width: "34px",
    height: "34px"
  })), ___EmotionJSX(Heading.h4, {
    margin: "14px 0px",
    ml: "6px",
    color: "textWhite",
    fontSize: "xl"
  }, "Filters")), hasFacetsApplied && ___EmotionJSX(TextButton, {
    onClick: function onClick() {
      return removeAllFilters();
    }
  }, ___EmotionJSX(Heading.h5, {
    margin: "14px 20px",
    color: "textWhite"
  }, "clear all")));
};