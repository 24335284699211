export var recommendedEvents = [{
  id: "162465",
  title: "Easter Chocolate Shop",
  badgeText: "All the chocolate",
  url: "/event/easter-chocolate-shop-162465",
  product: null,
  image: {
    single: {
      url: "https://s.catch.com.au/assets/0001/1007/623158aed3bc4907826317.jpg",
      altText: "Easter Chocolate Shop",
      __typename: "Image"
    },
    __typename: "EventImage"
  },
  __typename: "Event"
}, {
  id: "162783",
  title: "Sports & Fitness Superstore",
  badgeText: null,
  url: "/event/sports-fitness-superstore-162783",
  product: null,
  image: {
    single: {
      url: "https://s.catch.com.au/assets/0001/1007/62300cafbafc3112868175.jpg",
      altText: "Sports & Fitness Superstore",
      __typename: "Image"
    },
    __typename: "EventImage"
  },
  __typename: "Event"
}];