import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Flex, Heading, Link, Text } from "@catchoftheday/cg-components";
import { URL_FLYBUYS_CATCH, URL_ONEPASS_LANDING, URL_ONEPASS_TERMS_AND_CONDITIONS } from "../../../../consts";
import { ItemBox } from "./ItemBox";
import { BENEFIT_ITEMS } from "./items";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Benefits = function Benefits() {
  return ___EmotionJSX(Box, {
    mb: {
      xs: 10,
      md: 20
    }
  }, ___EmotionJSX(Heading.h4, {
    mt: 0,
    mb: {
      xs: 5,
      md: 6
    },
    fontSize: {
      xs: "base",
      md: "2xl"
    },
    color: "text",
    fontWeight: "semibold",
    textAlign: "center"
  }, "Along with all current Catch and OnePass benefits you'll get access to:"), ___EmotionJSX(Flex, {
    flexWrap: "wrap"
  }, BENEFIT_ITEMS.map(function (item, index) {
    return ___EmotionJSX(ItemBox, _extends({
      key: "benefit-item-box-".concat(index)
    }, item));
  })), ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "center"
  }, ___EmotionJSX(Text, {
    textAlign: "center",
    my: {
      xs: 5,
      md: 6
    },
    fontSize: "lg",
    color: "textSecondary",
    fontWeight: "normal"
  }, ___EmotionJSX(Link, {
    href: URL_ONEPASS_LANDING,
    fontWeight: "semibold",
    color: "onePass.primary",
    textDecoration: "underline"
  }, "Learn more"), " ", "on what you get at our brands"), ___EmotionJSX(Text, {
    mx: "auto",
    my: 0,
    fontSize: "xs",
    color: "onePass.textGrey",
    fontWeight: "normal"
  }, "*", ___EmotionJSX(Link, {
    href: URL_ONEPASS_TERMS_AND_CONDITIONS,
    fontWeight: "semibold",
    textDecoration: "underline",
    color: "inherit"
  }, "T&Cs,"), " ", "Exclusions apply. Express click & collect already available to all customers at Officeworks & Target.", " ", ___EmotionJSX(Link, {
    color: "inherit",
    textDecoration: "underline",
    href: URL_FLYBUYS_CATCH
  }, "Alternative Flybuys offer for Catch."))));
};