import React from "react";
import { Box, Flex, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BenefitItem = function BenefitItem(_ref) {
  var icon = _ref.icon,
      children = _ref.children;
  return ___EmotionJSX(Flex, {
    alignItems: "center",
    my: 2
  }, ___EmotionJSX(Box, {
    flexShrink: 0,
    color: "onePass.primary",
    width: "3rem"
  }, icon), ___EmotionJSX(Text, {
    m: 0,
    fontWeight: "inherit"
  }, children));
};