import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconDress = createSvgIconComponent({
  children: [___EmotionJSX("g", {
    key: "part-1",
    clipPath: "url(#clip0)"
  }, ___EmotionJSX("path", {
    d: "M2.87 13.4c-.04.14-.1.43-.1.48 0 .65 1.06 1.13 1.53 1.35A8.94 8.94 0 008 16a8.89 8.89 0 003.7-.77c.47-.22 1.54-.7 1.54-1.35a2.922 2.922 0 00-.15-.45A8.64 8.64 0 018 15.01a8.6 8.6 0 01-5.13-1.61zm7-6.38a4.84 4.84 0 01-3.97.02 11.2 11.2 0 00-2.92 5.47 8.03 8.03 0 009.97.07c-1.37-4.41-3.09-5.56-3.09-5.56h.01zM9.8.18A.18.18 0 009.62 0l-.24.02a.18.18 0 00-.18.19v1.56a10.15 10.15 0 00-1.32.87 9.63 9.63 0 00-1.54-.96V.2a.18.18 0 00-.18-.19L5.93 0a.18.18 0 00-.18.18v1.58a2.53 2.53 0 00-.51.92l.8 3.81a4.44 4.44 0 003.67 0l.8-3.81s-.27-.91-.71-1.04V.18z"
  })), ___EmotionJSX("defs", {
    key: "part-2"
  }, ___EmotionJSX("clipPath", {
    id: "clip0"
  }, ___EmotionJSX("path", {
    d: "M0 0h16v16H0z"
  })))],
  fill: "currentColor",
  viewBox: "0 0 16 16"
});