import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconUserAdd = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M4.294 6.004A3.748 3.748 0 118 9.752a3.738 3.738 0 01-3.706-3.748zM8 8.249a2.255 2.255 0 10-2.255-2.245A2.245 2.245 0 008 8.249zM10.345 15.11H3.62c.054-.354.172-.695.35-1.006A2.859 2.859 0 015.6 12.77c.302-.09.617-.133.932-.127h3.176a.7.7 0 00.689-.7.688.688 0 00-.678-.687H6.353a3.907 3.907 0 00-2.234.751 4.235 4.235 0 00-1.663 2.383c-.13.417-.187.854-.169 1.291a.815.815 0 00.688.795c.092.01.184.01.275 0h7.095a.699.699 0 000-1.387v.02z",
    fill: "currentColor"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M16.073 12.865h-1.684v-1.546a.7.7 0 00-1.397 0v1.546h-1.684a.699.699 0 100 1.397h1.684v1.546a.7.7 0 001.397 0v-1.546h1.684a.699.699 0 000-1.397z",
    fill: "currentColor"
  })],
  viewBox: "0 0 18 18"
});