import { trackSelfDescribingEvent } from "@catchoftheday/analytics";
import { date as dateUtils } from "@catchoftheday/utilities";
export var COUPON_CONTEXT_SCHEMA = "iglu:au.com.catch/coupon/jsonschema/1-0-0";
export var COUPON_IMPRESSION_SCHEMA = "iglu:au.com.catch/coupon_impression/jsonschema/1-0-0";
export var COUPON_CLICK_SCHEMA = "iglu:au.com.catch/coupon_click/jsonschema/1-0-0";

var getFormattedDate = function getFormattedDate(date) {
  var dateObj = new Date(date);

  if (!dateUtils.isValid(dateObj)) {
    return null;
  }

  return dateUtils.format(dateObj, "yyyy-MM-dd HH:mm:ss");
};

export var getCouponContext = function getCouponContext(_ref) {
  var couponData = _ref.couponData;
  return {
    schema: COUPON_CONTEXT_SCHEMA,
    data: {
      id: couponData.id,
      sales_rule_id: couponData.salesRule.id,
      code: couponData.code,
      from_date: getFormattedDate(couponData.fromDate),
      to_date: getFormattedDate(couponData.toDate),
      usage_limit: couponData.usageLimit,
      usage_per_customer: couponData.usagePerCustomer,
      specific_customers: couponData.specificCustomers,
      is_active: couponData.isActive,
      promo_active: couponData.promoActive,
      version: couponData.version,
      ordinal: couponData.ordinal,
      times_used: couponData.timesUsed,
      sales_rule_description: couponData.salesRule.shortDescription,
      sales_rule_public_name: couponData.salesRule.name
    }
  };
};
export var trackCouponImpression = function trackCouponImpression(_ref2) {
  var couponData = _ref2.couponData,
      customerId = _ref2.customerId;
  trackSelfDescribingEvent({
    event: {
      schema: COUPON_IMPRESSION_SCHEMA,
      data: {
        customer_id: customerId
      }
    },
    context: [getCouponContext({
      couponData: couponData
    })]
  });
};
export var trackCouponClick = function trackCouponClick(_ref3) {
  var couponData = _ref3.couponData,
      customerId = _ref3.customerId;
  trackSelfDescribingEvent({
    event: {
      schema: COUPON_CLICK_SCHEMA,
      data: {
        customer_id: customerId
      }
    },
    context: [getCouponContext({
      couponData: couponData
    })]
  });
};