import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Button, Link } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
var DEFAULT_MESSAGE = "Please click here for more information.";
export var TemplatedMessage = function TemplatedMessage(_ref) {
  var templateString = _ref.templateString,
      buttonProps = _ref.buttonProps,
      onClick = _ref.onClick;
  var template = templateString.template,
      placeholders = templateString.placeholders,
      defaultMessage = templateString["default"];

  if (!template || !placeholders) {
    return ___EmotionJSX(React.Fragment, null, defaultMessage);
  }

  var re = /(\{\{.+?\}\})/;
  var splitStrings = template.split(re);
  var subComponentList = [];
  splitStrings.forEach(function (splitString) {
    if (!re.test(splitString)) {
      return subComponentList.push(splitString);
    }

    var matchId = splitString.replace(/\{\{(.+?)\}\}/, function (_, p1) {
      return p1.trim();
    });
    var matchPlaceholder = placeholders.find(function (placeholder) {
      return (placeholder === null || placeholder === void 0 ? void 0 : placeholder.id) === matchId;
    });

    if (onClick) {
      subComponentList.push(___EmotionJSX(Button, _extends({
        onClick: onClick,
        key: matchId,
        variant: "plain",
        sizeVariant: "sm",
        p: 0,
        color: "secondary",
        textTransform: "initial",
        fontSize: "xs",
        fontWeight: "normal",
        underlineOnHover: true
      }, buttonProps || {}), (matchPlaceholder === null || matchPlaceholder === void 0 ? void 0 : matchPlaceholder.text) || DEFAULT_MESSAGE));
    } else if (matchPlaceholder !== null && matchPlaceholder !== void 0 && matchPlaceholder.url) {
      subComponentList.push(___EmotionJSX(Link, {
        href: matchPlaceholder.url,
        key: matchId,
        target: "_blank"
      }, (matchPlaceholder === null || matchPlaceholder === void 0 ? void 0 : matchPlaceholder.text) || DEFAULT_MESSAGE));
    } else if (matchPlaceholder !== null && matchPlaceholder !== void 0 && matchPlaceholder.text) {
      subComponentList.push(matchPlaceholder.text);
    }
  });
  return ___EmotionJSX(React.Fragment, null, subComponentList);
};