import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var _excluded = ["checked", "defaultChecked", "label", "onChange", "containerProps", "textProps", "url", "variant", "pillSize", "theme", "outline", "useNextLink"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback } from "react";
import { createShouldForwardProp, props } from "@styled-system/should-forward-prop";
import Link from "next/link";
import { color, compose, css, layout, size, space, styled, system, themeGet, typography } from "@catchoftheday/theme";
import { useControlled } from "@catchoftheday/utilities";
import { Box } from "../Box";
import { NextLink } from "../Link";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
var shouldForwardProp = createShouldForwardProp([].concat(_toConsumableArray(props), ["checked", "checkedBackgroundColor", "checkedColor", "hoverBackgroundColor", "hoverColor"]));
var hoverProps = system({
  hoverBackgroundColor: {
    property: "backgroundColor",
    scale: "colors"
  },
  hoverColor: {
    property: "color",
    scale: "colors"
  }
});
var checkedProps = system({
  checkedBackgroundColor: {
    property: "backgroundColor",
    scale: "colors"
  },
  checkedColor: {
    property: "color",
    scale: "colors"
  }
});
var styleProps = compose(space, layout, color, typography, size);
var variants = {
  light: {
    primary: {
      backgroundColor: "primaryLight",
      color: "primary"
    },
    secondary: {
      backgroundColor: "secondaryLight",
      color: "secondary"
    },
    warning: {
      backgroundColor: "warningLight",
      color: "warningDark"
    },
    error: {
      backgroundColor: "errorLight",
      color: "error"
    },
    plain: {
      backgroundColor: "white",
      color: "secondary"
    }
  },
  solid: {
    primary: {
      backgroundColor: "primary",
      color: "white"
    },
    secondary: {
      backgroundColor: "secondary",
      color: "white"
    },
    warning: {
      backgroundColor: "warning",
      color: "white"
    },
    error: {
      backgroundColor: "error",
      color: "white"
    },
    plain: {
      backgroundColor: "white",
      color: "secondary"
    }
  }
};
var borderStyle = {
  borderStyle: "solid",
  borderWidth: "1px"
};
var borderColor = {
  plain: {
    borderColor: "secondary"
  },
  primary: {
    borderColor: "primary"
  },
  secondary: {
    borderColor: "secondary"
  },
  warning: {
    borderColor: "warningDark"
  },
  error: {
    borderColor: "error"
  }
};
var PillContainer = styled(Box, {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n  display: inline-block;\n  font-size: 1rem;\n  ", "\n  position: relative;\n  text-align: center;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-decoration: none;\n  ", "\n  ", "\n  ", "\n"])), themeGet("colors.ui.backgroundColor"), themeGet("colors.typography.text"), themeGet("radii.rounded"), function (props) {
  return "\n    padding: ".concat(props.pillSize === "sm" ? "4px 8px" : "10px 30px", ";\n  ");
}, styleProps, function (props) {
  return !props.checked && !props.noHover && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      &:hover {\n        ", "\n        & > * {\n          ", "\n        }\n      }\n    "])), hoverProps(props), hoverProps(props));
}, function (props) {
  return props.checked && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      ", "\n      &:hover {\n        ", "\n        & > * {\n          ", "\n        }\n      }\n    "])), checkedProps(props), checkedProps(props), checkedProps(props));
});
export var BasePill = styled("input")(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  appearance: none;\n  cursor: pointer;\n  height: 100%;\n  left: 0;\n  opacity: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 1;\n"])));
export var PillText = styled(Span)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  white-space: nowrap;\n"])));
var spanProps = {
  fontSize: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit"
};
export var Pill = function Pill(_ref) {
  var checkedProp = _ref.checked,
      defaultChecked = _ref.defaultChecked,
      label = _ref.label,
      onChange = _ref.onChange,
      containerProps = _ref.containerProps,
      textProps = _ref.textProps,
      url = _ref.url,
      variant = _ref.variant,
      _ref$pillSize = _ref.pillSize,
      pillSize = _ref$pillSize === void 0 ? "md" : _ref$pillSize,
      _ref$theme = _ref.theme,
      theme = _ref$theme === void 0 ? "solid" : _ref$theme,
      outline = _ref.outline,
      useNextLink = _ref.useNextLink,
      otherProps = _objectWithoutProperties(_ref, _excluded);

  var _useControlled = useControlled(defaultChecked, checkedProp),
      _useControlled2 = _slicedToArray(_useControlled, 2),
      checked = _useControlled2[0],
      setChecked = _useControlled2[1];

  var variantProps = variant ? variants[theme][variant] : {};
  var outlineProps = outline ? _objectSpread(_objectSpread({}, borderColor[variant || "plain"]), borderStyle) : {};

  var pillProps = _objectSpread(_objectSpread(_objectSpread({}, containerProps), variantProps), outlineProps);

  var handleOnChange = useCallback(function (e) {
    setChecked(e.target.checked);

    if (typeof onChange === "function") {
      onChange(e);
    }
  }, [onChange, setChecked]);

  if (url) {
    return ___EmotionJSX(PillContainer, _extends({
      as: useNextLink ? NextLink : Link,
      href: url,
      checked: checked
    }, pillProps), ___EmotionJSX(PillText, _extends({}, spanProps, textProps), label));
  }

  if (typeof onChange === "function") {
    return ___EmotionJSX(PillContainer, _extends({
      checked: checked
    }, pillProps), ___EmotionJSX(PillText, _extends({}, spanProps, textProps), label), ___EmotionJSX(BasePill, _extends({
      "aria-label": "Navigation Pill",
      type: "checkbox",
      onChange: handleOnChange
    }, otherProps)));
  }

  return ___EmotionJSX(PillContainer, _extends({}, pillProps, {
    pillSize: pillSize,
    noHover: true
  }), ___EmotionJSX(PillText, _extends({}, spanProps, textProps), label));
};
Pill.displayName = "Pill";
PillContainer.defaultProps = {
  hoverColor: "typography.text",
  hoverBackgroundColor: "ui.borderColor",
  checkedColor: "white",
  checkedBackgroundColor: "secondary"
};
export default Pill;