import React from "react";
import { Box, Flex, Heading, Image, Link, Span, Text } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { ASSET_ONEPASS_MASCOT_HOLDING_DIVISIONS_LIST, URL_ONEPASS_PRIVACY_POLICY, URL_ONEPASS_TERMS_AND_CONDITIONS } from "../../../../consts";
import { TransferCTAButton } from "../TransferCTAButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderBanner = function HeaderBanner() {
  var theme = useTheme();
  return ___EmotionJSX(Box, {
    background: "linear-gradient(180deg, ".concat(theme.colors.onePass.primary, " 0%, ").concat(theme.colors.white, " 100%)"),
    pt: {
      xs: 10,
      md: 0
    },
    color: "white"
  }, ___EmotionJSX(Flex, {
    maxWidth: theme.maxWidths.cotdWebsite,
    px: 4,
    flexWrap: "wrap",
    alignItems: "center",
    margin: "0 auto"
  }, ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "40%"
    },
    pr: {
      xs: 0,
      md: 5
    }
  }, ___EmotionJSX(Heading.h1, {
    fontSize: {
      xs: "2xl",
      md: "3xl"
    },
    fontWeight: "bold",
    mb: 6
  }, "Transfer your OnePass membership for access to all participating brands"), ___EmotionJSX(Box, {
    backgroundColor: "white",
    borderRadius: "corner",
    p: {
      xs: 6,
      md: 8
    },
    color: "textSecondary"
  }, ___EmotionJSX(Text, {
    mt: 0,
    mb: 6,
    fontSize: "base",
    fontWeight: "medium"
  }, "The OnePass membership provider is changing from Catch to OnePass to bring you more benefits. Follow the steps to securely transfer your membership to the new OnePass."), ___EmotionJSX(TransferCTAButton, null), ___EmotionJSX(Span, {
    display: "block",
    textAlign: "center",
    fontWeight: "medium",
    fontSize: "base",
    color: "textSecondary",
    mb: 4
  }, ___EmotionJSX(Link, {
    color: "inherit",
    hoverColor: "inherit",
    fontWeight: "bold",
    textDecoration: "underline",
    href: URL_ONEPASS_TERMS_AND_CONDITIONS
  }, "Terms & Conditions"), " ", "and", " ", ___EmotionJSX(Link, {
    href: URL_ONEPASS_PRIVACY_POLICY,
    color: "inherit",
    hoverColor: "inherit",
    fontWeight: "bold",
    textDecoration: "underline"
  }, "Privacy Policy")))), ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "50%"
    },
    pl: {
      xs: 0,
      md: 5
    }
  }, ___EmotionJSX(Image, {
    alt: "OnePass' mascot holding a list of divisions",
    src: ASSET_ONEPASS_MASCOT_HOLDING_DIVISIONS_LIST,
    width: "100%",
    mx: "auto",
    maxWidth: "580px",
    height: "auto"
  }))));
};