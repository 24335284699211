import { PAGES_TICKER_EXCLUDED_ON } from "@header/consts";
export var isTickerVisible = function isTickerVisible() {
  if (true) {
    var path = window.location.pathname;
    var pathParts = path.split("/").filter(Boolean);
    var isExcludedPage = PAGES_TICKER_EXCLUDED_ON.find(function (excludedPath) {
      return pathParts.includes(excludedPath);
    });

    if (isExcludedPage) {
      return false;
    }
  }

  return true;
};