import React from "react";
import { Flex, Span, StarRating } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var StarRatings = function StarRatings(_ref) {
  var rating = _ref.rating;
  return ___EmotionJSX(Flex, null, ___EmotionJSX(StarRating, {
    rating: rating,
    width: 95
  }), rating < 5 ? ___EmotionJSX(Span, {
    ml: "5px"
  }, "& up") : "");
};