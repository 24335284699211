export var CMS_CONFIG_APOLLO_CACHE_CONFIG = {
  typePolicies: {
    Query: {
      fields: {
        cmsConfiguration: {
          merge: true
        }
      }
    }
  }
};