import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex, Span } from "@catchoftheday/cg-components";
import { SizeFacetOption } from "../SizeFacetOption/SizeFacetOption";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SizeFacetOptions = function SizeFacetOptions(_ref) {
  var options = _ref.options,
      onChange = _ref.onChange;
  return ___EmotionJSX(React.Fragment, null, options.map(function (option) {
    return ___EmotionJSX(Flex, {
      flexDirection: "column",
      key: option.id,
      mb: 2
    }, ___EmotionJSX(Span, {
      fontWeight: "semibold"
    }, option.displayName), ___EmotionJSX(Flex, {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 1,
      my: 2
    }, option.children.map(function (child) {
      return ___EmotionJSX(SizeFacetOption, _extends({}, child, {
        optionKey: child.key,
        key: child.id,
        onChange: onChange
      }));
    })));
  }));
};