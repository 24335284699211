import React from "react";
import { Flex, IconOnePass, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardShipping = function ProductCardShipping(props) {
  var onePassEligible = props.onePassEligible,
      freeShippingAvailable = props.freeShippingAvailable;

  if (onePassEligible || freeShippingAvailable) {
    return ___EmotionJSX(Flex, {
      alignItems: "center",
      mt: 1,
      height: "14px"
    }, onePassEligible && !freeShippingAvailable && ___EmotionJSX(IconOnePass, {
      color: "onePass.primary",
      width: "55px",
      height: "12px",
      pointerEvents: "none",
      role: "img",
      title: "OnePass",
      "aria-label": "OnePass",
      mr: 1
    }), ___EmotionJSX(Span, {
      height: "14px",
      lineHeight: "inherit",
      fontSize: "xs",
      color: "textTertiary"
    }, "Free delivery"));
  }

  return ___EmotionJSX(Span, {
    height: "14px"
  });
};