import React from "react";
import { Box, Flex, Span } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import productPriceSplit from "../../utilities/productPriceSplit.utilities";
import { ProductPriceAside } from "./ProductPriceAside";
import { ProductPriceItemChildren } from "./ProductPriceItem";
import { ProductPriceMain } from "./ProductPriceMain";
import { ProductPriceParts } from "./ProductPriceParts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceSell = function ProductPriceSell(_ref) {
  var price = _ref.price,
      saving = _ref.saving,
      wasPrice = _ref.wasPrice,
      onePassExclusive = _ref.onePassExclusive,
      hasPromotion = _ref.hasPromotion,
      enabled = _ref.enabled,
      large = _ref.large;
  var showCents = !!wasPrice && !!productPriceSplit(wasPrice, true).cents;
  var hasAside = !!wasPrice || !!saving;
  return ___EmotionJSX(Box, {
    className: "sell-price",
    width: "fit-content",
    fontWeight: "base",
    "data-testid": "product-price"
  }, ___EmotionJSX(ProductPriceItemChildren, null, wasPrice && ___EmotionJSX(ProductPriceAside, {
    showArrow: true,
    large: large
  }, ___EmotionJSX(Flex, {
    marginRight: hasPromotion ? "0" : "4px",
    fontSize: large ? "2.5rem" : "1.75rem"
  }, ___EmotionJSX(Span, {
    fontSize: showCents ? "0.5em" : {
      sm: "0.625em",
      md: "0.656em"
    },
    fontWeight: "semibold",
    textDecoration: "line-through",
    color: enabled ? "typography.text" : "typography.inactive"
  }, formatPrice(wasPrice, false)))), ___EmotionJSX(ProductPriceMain, {
    onePassExclusive: onePassExclusive,
    hasAside: hasAside,
    hasPromotion: hasPromotion,
    enabled: enabled,
    large: large
  }, ___EmotionJSX(ProductPriceParts, {
    price: price,
    fontWeight: "semibold"
  })), saving && ___EmotionJSX(ProductPriceAside, {
    large: large
  }, ___EmotionJSX(Flex, null, ___EmotionJSX(Span, {
    textTransform: "uppercase",
    fontSize: large ? "0.9rem" : "0.728rem",
    fontWeight: "semibold",
    color: enabled ? "typography.text" : "typography.inactive",
    lineHeight: "1rem"
  }, saving.label, ___EmotionJSX("br", null), formatPrice(saving.savingAmount.amount, false))))));
};