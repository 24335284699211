import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconWarning = createSvgIconComponent({
  children: [___EmotionJSX("path", {
    key: "part-1",
    d: "M9.265 1.49c.333-.653 1.137-.653 1.47 0l8.141 15.979c.344.674-.068 1.531-.735 1.531H1.859c-.667 0-1.079-.857-.735-1.531L9.264 1.49z",
    stroke: "currentColor",
    strokeWidth: "2"
  }), ___EmotionJSX("path", {
    key: "part-2",
    d: "M11.048 7.939c0 .586-.448 1.061-1 1.061s-1-.475-1-1.061c0-.587.448-1.062 1-1.062s1 .475 1 1.062zM9.048 11.5c0-.586.448-1.061 1-1.061s1 .475 1 1.062v3.965c0 .587-.448 1.062-1 1.062s-1-.475-1-1.062v-3.965z",
    fill: "currentColor"
  })],
  fill: "none",
  strokeWidth: "2",
  viewBox: "0 0 20 20"
});