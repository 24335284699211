import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useContext, useEffect, useState } from "react";
import { AppExperimentContext, mapSponsoredProductsTracking, SOURCE_TYPE_SEARCH_TOP_SPONSORED } from "@catchoftheday/analytics";
import { convertProductCard } from "@catchoftheday/product-components";
import { useSearchSponsoredProductsQuery } from "../../generated/graphql";
import { hasUnsupportedSponsoredFacets, mapSponsoredFacets } from "../../utilities/sponsored";
export var useSponsoredProducts = function useSponsoredProducts(_ref) {
  var skipQuery = _ref.skipQuery,
      queryParams = _ref.queryParams,
      customerUuid = _ref.customerUuid,
      spSessionId = _ref.spSessionId,
      isMobile = _ref.isMobile,
      searchTerm = _ref.searchTerm,
      inventoryId = _ref.inventoryId,
      brandSlug = _ref.brandSlug,
      categorySlug = _ref.categorySlug,
      pageId = _ref.pageId;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["retail_media_search_results_top_oct_23"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      retailMediaExperiment = _getExperiments$exper[0];

  var _useState = useState(null),
      appliedSponsoredFacets = _useState[0],
      setAppliedSponsoredFacets = _useState[1];

  var _useState2 = useState(false),
      hideSponsored = _useState2[0],
      setHideSponsored = _useState2[1];

  var sponsoredItemsLimit = isMobile ? 2 : 4;
  var updateSponsoredFacets = useCallback(function () {
    var sponsoredFacets = mapSponsoredFacets(queryParams);
    setHideSponsored(!!queryParams["sortBy"] && queryParams["sortBy"] !== "relevance" || hasUnsupportedSponsoredFacets(queryParams));
    setAppliedSponsoredFacets(Object.keys(sponsoredFacets).length > 0 ? sponsoredFacets : null);
  }, [queryParams]);
  useEffect(function () {
    updateSponsoredFacets();
    addEventListener("popstate", updateSponsoredFacets);
    return function () {
      removeEventListener("popstate", updateSponsoredFacets);
    };
  }, [updateSponsoredFacets]);
  var skip = skipQuery || !spSessionId || !(retailMediaExperiment !== null && retailMediaExperiment !== void 0 && retailMediaExperiment.bucket) || hideSponsored;

  var _useSearchSponsoredPr = useSearchSponsoredProductsQuery({
    variables: {
      limit: sponsoredItemsLimit,
      inventoryId: inventoryId,
      snowplowSessionId: spSessionId,
      customerUUID: customerUuid,
      searchQuery: searchTerm,
      facets: _objectSpread(_objectSpread({}, appliedSponsoredFacets), {}, {
        brandSlug: brandSlug,
        categorySlug: categorySlug
      }),
      pageId: pageId
    },
    errorPolicy: "all",
    ssr: true,
    skip: skip
  }),
      data = _useSearchSponsoredPr.data;

  var mapSponsoredItems = useCallback(function (data) {
    return data.retailMediaProducts.products.map(function (product) {
      return _objectSpread(_objectSpread({}, convertProductCard(product, product.selectedOffer)), {}, {
        sourceType: SOURCE_TYPE_SEARCH_TOP_SPONSORED,
        sourceId: searchTerm,
        isSponsored: true,
        linkToOffer: true
      });
    });
  }, [searchTerm]);
  var mapSponsoredItemsTracking = useCallback(function (data) {
    return mapSponsoredProductsTracking(data.retailMediaProducts.tracking);
  }, []);
  return {
    sponsoredItems: data ? mapSponsoredItems(data) : [],
    sponsoredItemsTracking: data && mapSponsoredItemsTracking(data)
  };
};