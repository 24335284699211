import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Button, Carousel, Flex, Link, Span } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { DEFAULT_FREE_TRIAL_DAYS, ONEPASS_MONTHLY_PRICE, ONEPASS_YEARLY_PRICE, UPSELL_MEMBER_BENEFITS_LIST } from "../../../consts";
import { useIsMobile } from "../../../hooks";
import { BenefitCard } from "./BenefitCard";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MemberBenefitsCarousel = function MemberBenefitsCarousel(_ref) {
  var isFreeTrialEligible = _ref.isFreeTrialEligible,
      _ref$freeTrialLength = _ref.freeTrialLength,
      freeTrialLength = _ref$freeTrialLength === void 0 ? DEFAULT_FREE_TRIAL_DAYS : _ref$freeTrialLength,
      _ref$monthlyOnePassPr = _ref.monthlyOnePassPrice,
      monthlyOnePassPrice = _ref$monthlyOnePassPr === void 0 ? ONEPASS_MONTHLY_PRICE : _ref$monthlyOnePassPr,
      _ref$yearlyOnePassPri = _ref.yearlyOnePassPrice,
      yearlyOnePassPrice = _ref$yearlyOnePassPri === void 0 ? ONEPASS_YEARLY_PRICE : _ref$yearlyOnePassPri,
      onCTAClick = _ref.onCTAClick;
  var isMobile = useIsMobile();
  return ___EmotionJSX(Box, null, ___EmotionJSX(Carousel, {
    containerProps: {
      maxWidth: "1200px",
      margin: "0 auto"
    },
    slides: UPSELL_MEMBER_BENEFITS_LIST.map(function (benefit, index) {
      return ___EmotionJSX(BenefitCard, _extends({
        key: "onepass-benefit-".concat(index)
      }, benefit));
    }),
    slidesPerBreakpoint: [4, 4, 4, 2, 2],
    showNavButtons: false,
    showBullets: isMobile
  }), ___EmotionJSX(Flex, {
    mt: {
      xs: 3,
      md: 9
    },
    flexDirection: "column",
    alignItems: "center"
  }, ___EmotionJSX(Button, {
    width: "auto",
    sizeVariant: "md",
    variant: "onePassPrimary",
    mb: 2,
    px: 6,
    py: 3,
    onClick: onCTAClick ? onCTAClick : undefined
  }, isFreeTrialEligible ? "START FREE ".concat(freeTrialLength, " DAY TRIAL") : "JOIN ONEPASS TODAY"), ___EmotionJSX(Link, {
    href: "/onepass",
    fontSize: "xs",
    fontWeight: "bold",
    color: "onePass.primary",
    hoverColor: "onePass.primary",
    textDecoration: "underline",
    fontFamily: "onePass"
  }, "Learn More"), ___EmotionJSX(Span, {
    fontSize: "2xs",
    textAlign: "center",
    mt: 2
  }, isFreeTrialEligible ? "After your ".concat(freeTrialLength, " day free trial, ") : "", "OnePass is only ", formatPrice(monthlyOnePassPrice, false), "/month or you can save more at", " ", formatPrice(yearlyOnePassPrice, false), "/year. Cancel anytime.")));
};