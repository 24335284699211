import React from "react";
import { Heading, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Titles = function Titles(_ref) {
  var title = _ref.title,
      subtitle = _ref.subtitle;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Heading.h2, {
    fontWeight: "semibold",
    fontSize: {
      xs: "xl",
      md: "2xl",
      lg: "3xl",
      xl: "4xl"
    },
    m: 0,
    mb: {
      xs: 3,
      md: 4,
      lg: 5
    },
    textAlign: {
      xs: "center",
      md: "left"
    }
  }, title), subtitle && ___EmotionJSX(Text, {
    fontSize: {
      xs: "sm",
      md: "base"
    },
    m: 0,
    textAlign: {
      xs: "center",
      md: "left"
    }
  }, subtitle));
};