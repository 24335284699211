import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import { css, minusOnePx, styled, themeGet } from "@catchoftheday/theme";

var breakpoints = function breakpoints(props) {
  return {
    xs: "@media screen and (max-width: ".concat(minusOnePx(themeGet("breakpoints.0")(props)), ")"),
    sm: "@media screen and (min-width: ".concat(themeGet("breakpoints.0")(props), ")\n                     and (max-width: ").concat(minusOnePx(themeGet("breakpoints.1")(props)), ")"),
    md: "@media screen and (min-width: ".concat(themeGet("breakpoints.1")(props), ")\n                     and (max-width: ").concat(minusOnePx(themeGet("breakpoints.2")(props)), ")"),
    lg: "@media screen and (min-width: ".concat(themeGet("breakpoints.2")(props), ")\n  and (max-width: ").concat(minusOnePx(themeGet("breakpoints.3")(props)), ")"),
    xl: "@media screen and (min-width: ".concat(themeGet("breakpoints.3")(props), ")")
  };
};

var hidden = function hidden(key) {
  return function (props) {
    return props[key] && css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    ", " {\n      display: none;\n    }\n  "])), breakpoints(props)[key]);
  };
};

export var Hide = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), hidden("xs"), hidden("sm"), hidden("md"), hidden("lg"), hidden("xl"));
Hide.displayName = "Hide";
export default Hide;