import React, { useContext } from "react";
import { Flex, Heading, List, ListItem, Text, TextButton } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoResultsHeader = function NoResultsHeader(_ref) {
  var searchQuery = _ref.searchQuery,
      hasAppliedFilters = _ref.hasAppliedFilters,
      _ref$showTitle = _ref.showTitle,
      showTitle = _ref$showTitle === void 0 ? true : _ref$showTitle;

  var _useContext = useContext(CatalogueContext),
      removeAllFilters = _useContext.removeAllFilters;

  return ___EmotionJSX(Flex, {
    flexDirection: "column",
    px: 3
  }, showTitle && ___EmotionJSX(Heading.h1, {
    mb: 0,
    mt: {
      xs: 4,
      md: 0
    },
    fontSize: {
      xs: "2xl",
      md: "3xl"
    }
  }, "Sorry, we couldn't find any results", " ", searchQuery ? "for \"".concat(searchQuery, "\"") : ""), ___EmotionJSX(Text, {
    fontSize: "base",
    mb: 1,
    fontWeight: "normal"
  }, "To improve your search you can try:"), ___EmotionJSX(List, {
    type: "disc",
    style: {
      listStylePosition: "outside"
    },
    pl: 5,
    fontWeight: "light",
    mt: "0",
    lineHeight: "1.5rem"
  }, hasAppliedFilters && ___EmotionJSX(ListItem, null, ___EmotionJSX(TextButton, {
    onClick: function onClick() {
      return removeAllFilters();
    }
  }, "Clear all filters")), ___EmotionJSX(ListItem, null, "Search with fewer words or checking your spelling"), ___EmotionJSX(ListItem, null, "Search with more general terms"), ___EmotionJSX(ListItem, null, "Search a similar keyword")));
};