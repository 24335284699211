import React from "react";
import { Box, Flex, Heading, Hide, Image, Link, Text } from "@catchoftheday/cg-components";
import { useMedia, useTheme } from "@catchoftheday/theme";
import { ASSET_APP_DOWNLOAD_QR_WHITE, ASSET_APPLE_STORE_DOWNLOAD, ASSET_CATCH_APP_BANNER_BG_DESKTOP, ASSET_CATCH_APP_BANNER_BG_MOBILE, ASSET_GOOGLE_PLAY_DOWNLOAD } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DEFAULT_APP_DOWNLOAD_LINK = "https://catch.onelink.me/N79h/152f671c";
export var AppTile = function AppTile(_ref) {
  var appStoreLink = _ref.appStoreLink,
      googlePlayLink = _ref.googlePlayLink;
  var theme = useTheme();
  var isMobileSize = useMedia(["(min-width: ".concat(theme.breakpoints.lg, ")"), "(min-width: ".concat(theme.breakpoints.xs, ")")], [false, true]);
  return ___EmotionJSX(Flex, {
    position: "relative",
    width: {
      xs: "100%",
      lg: "30%"
    },
    color: "white"
  }, ___EmotionJSX(Image, {
    alt: "",
    src: isMobileSize ? ASSET_CATCH_APP_BANNER_BG_MOBILE : ASSET_CATCH_APP_BANNER_BG_DESKTOP,
    style: {
      position: "absolute",
      right: 0,
      bottom: 0
    },
    width: {
      xs: "100%",
      lg: "120%"
    },
    height: {
      xs: "110%",
      lg: "100%"
    }
  }), ___EmotionJSX(Flex, {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    pt: {
      xs: 12,
      lg: 5
    },
    px: 5,
    py: {
      xs: 7,
      lg: 5
    },
    flexDirection: {
      xs: "column",
      md: "row"
    }
  }, ___EmotionJSX(Hide, {
    xs: true,
    sm: true,
    md: true
  }, ___EmotionJSX(Box, {
    mr: {
      xs: 0,
      lg: 6
    },
    mb: {
      xs: 2,
      lg: 0
    }
  }, ___EmotionJSX(Image, {
    alt: "Catch App QR code",
    src: ASSET_APP_DOWNLOAD_QR_WHITE,
    width: "100px",
    height: "100px"
  }))), ___EmotionJSX(Box, {
    mr: {
      xs: 0,
      md: 5,
      lg: 0
    }
  }, ___EmotionJSX(Heading.h3, {
    mt: 0,
    mb: {
      xs: 0,
      lg: 2
    },
    fontSize: {
      xs: "sm",
      md: "xl"
    },
    color: "inherit",
    textAlign: {
      xs: "center",
      md: "left"
    }
  }, "Shop faster with the Catch app."), ___EmotionJSX(Text, {
    m: 0,
    fontSize: {
      xs: "sm",
      md: "xl",
      lg: "base"
    },
    fontWeight: "semibold",
    color: "inherit",
    textAlign: {
      xs: "center",
      md: "left"
    }
  }, isMobileSize ? "Available on" : "Scan this code to download the Catch app!")), ___EmotionJSX(Hide, {
    lg: true,
    xl: true
  }, ___EmotionJSX(Flex, {
    alignItems: "center",
    justifyContent: "center",
    mt: {
      xs: 3,
      md: 0
    }
  }, ___EmotionJSX(Link, {
    href: appStoreLink || DEFAULT_APP_DOWNLOAD_LINK,
    title: "Download app from App Store",
    marginRight: 3
  }, ___EmotionJSX(Image, {
    src: ASSET_APPLE_STORE_DOWNLOAD,
    alt: "App Store Logo",
    width: "auto",
    height: "40px"
  })), ___EmotionJSX(Link, {
    href: googlePlayLink || DEFAULT_APP_DOWNLOAD_LINK,
    title: "Download app from Google Play"
  }, ___EmotionJSX(Image, {
    src: ASSET_GOOGLE_PLAY_DOWNLOAD,
    alt: "Play Store Logo",
    width: "auto",
    height: "40px"
  }))))));
};