import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useCallback, useContext } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { Box } from "@catchoftheday/cg-components";
import { ShopProductsCarouselZone } from "../../layouts/ShopProductsCarouselLayout/ShopProductsCarouselLayout";
import { ShopRecommendedProductsContainer } from "../ShopRecommendedProductsContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopRecommendersContainer = function ShopRecommendersContainer(_ref) {
  var bestSellerProductsNode = _ref.bestSellerProductsNode,
      trendingProductsNode = _ref.trendingProductsNode,
      ProductsCarouselComponent = _ref.ProductsCarouselComponent;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["club_shop_recommender_components_jun21", "onepass_shop_toggle_may24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 2),
      recommenderComponentsExperiment = _getExperiments$exper[0],
      shopToggleExperiment = _getExperiments$exper[1],
      loading = _getExperiments.loading;

  var renderRecommenders = useCallback(function () {
    if ((recommenderComponentsExperiment === null || recommenderComponentsExperiment === void 0 ? void 0 : recommenderComponentsExperiment.bucket) === 1) {
      return ___EmotionJSX(ShopRecommendedProductsContainer, {
        ProductsCarouselComponent: ProductsCarouselComponent
      });
    } else if ((recommenderComponentsExperiment === null || recommenderComponentsExperiment === void 0 ? void 0 : recommenderComponentsExperiment.bucket) === 2) {
      return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ShopRecommendedProductsContainer, {
        ProductsCarouselComponent: ProductsCarouselComponent
      }), (shopToggleExperiment === null || shopToggleExperiment === void 0 ? void 0 : shopToggleExperiment.bucket) === 0 && ___EmotionJSX(ShopProductsCarouselZone, {
        title: "Trending Products"
      }, trendingProductsNode));
    }

    return ___EmotionJSX(ShopProductsCarouselZone, {
      title: "Best Sellers"
    }, bestSellerProductsNode);
  }, [ProductsCarouselComponent, bestSellerProductsNode, recommenderComponentsExperiment === null || recommenderComponentsExperiment === void 0 ? void 0 : recommenderComponentsExperiment.bucket, shopToggleExperiment === null || shopToggleExperiment === void 0 ? void 0 : shopToggleExperiment.bucket, trendingProductsNode]);
  if (loading) return null;
  return ___EmotionJSX(Box, {
    pt: 4
  }, renderRecommenders());
};