import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React from "react";
import { MetaTags, MetaTitle } from "@catchoftheday/common-vendors";
import { CataloguePageType } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CataloguePageMetaTags = function CataloguePageMetaTags(_ref) {
  var host = _ref.host,
      pageType = _ref.pageType,
      _ref$metaTitle = _ref.metaTitle,
      metaTitle = _ref$metaTitle === void 0 ? "" : _ref$metaTitle,
      _ref$metaDescription = _ref.metaDescription,
      metaDescription = _ref$metaDescription === void 0 ? "" : _ref$metaDescription;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MetaTitle, {
    host: host
  }, metaTitle), ___EmotionJSX(MetaTags, {
    withHead: true,
    tags: [].concat(_toConsumableArray(pageType === CataloguePageType.SEARCH ? [{
      name: "robots",
      content: "noindex,nofollow"
    }] : []), [{
      name: "description",
      content: metaDescription
    }])
  }));
};