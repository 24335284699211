import React, { useContext } from "react";
import { Button } from "@catchoftheday/cg-components";
import { CatalogueContext } from "../../../../contexts/CatalogueContext.jsx";
import { MobileFacetFooterZone } from "../../../layouts/PageLayout/PageLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MobileFacetFooter = function MobileFacetFooter(_ref) {
  var hasFacetsApplied = _ref.hasFacetsApplied,
      totalResults = _ref.totalResults;

  var _useContext = useContext(CatalogueContext),
      toggleMobileFacets = _useContext.toggleMobileFacets;

  return ___EmotionJSX(MobileFacetFooterZone, null, ___EmotionJSX(Button, {
    flexGrow: 1,
    variant: "secondary",
    textTransform: "none",
    lineHeight: 0,
    onClick: toggleMobileFacets
  }, hasFacetsApplied && totalResults ? "View ".concat(totalResults, " Result").concat(totalResults != 1 ? "s" : "") : "View Results"));
};