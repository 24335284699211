import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["productId", "rating", "ratingCount", "url"];
import React from "react";
import { Flex, IconStar, Link, Span } from "@catchoftheday/cg-components";
import { theme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardRating = function ProductCardRating(_ref) {
  var productId = _ref.productId,
      rating = _ref.rating,
      ratingCount = _ref.ratingCount,
      url = _ref.url,
      props = _objectWithoutProperties(_ref, _excluded);

  if (rating) {
    return ___EmotionJSX(Flex, props, ___EmotionJSX(Link, {
      lineHeight: 1,
      href: "".concat(url, "#review-section"),
      hoverColor: "typography.subText"
    }, ___EmotionJSX(Flex, {
      alignItems: "center"
    }, ___EmotionJSX(IconStar, {
      width: "14px",
      height: "14px",
      fill: theme.colors.brand.clubCatchSecondary
    }), ___EmotionJSX(Span, {
      fontSize: "xs",
      ml: 1,
      color: "typography.subText"
    }, "".concat(rating, " (").concat(ratingCount, " reviews)")))));
  }

  return null;
};