import { createSvgIconComponent } from "../utils";
export var IconKmart = createSvgIconComponent({
  viewBox: "0 0 36 12",
  paths: [{
    d: "M35.9041 10.8147L19.8316 5.51808L34.1388 0.270675C34.1964 0.25222 34.2284 0.190703 34.2156 0.135338C34.2028 0.0799722 34.1516 0.0369103 34.0877 0.0369103H17.3884C17.3756 0.0369103 17.3564 0.0369103 17.3436 0.043062L13.5893 1.40874L14.2801 0.184551C14.2992 0.147641 14.2992 0.0984273 14.2801 0.0615171C14.2545 0.0246068 14.2161 0 14.1713 0H4.97425C4.92309 0 4.87832 0.0307585 4.85913 0.0738205L0.0111563 10.8762C-0.00803092 10.9131 -0.00163518 10.9562 0.0239478 10.9931C0.0495308 11.03 0.0879052 11.0485 0.132675 11.0485H9.26579C9.31696 11.0485 9.36173 11.0177 9.38092 10.9746L10.1676 9.15374L10.7432 9.06147L16.7232 11.0546C16.736 11.0608 16.7488 11.0608 16.768 11.0608H35.8721C35.936 11.0608 35.9872 11.0177 36 10.9562C36 10.8947 35.968 10.8393 35.9041 10.8147Z",
    props: {
      fill: "white"
    }
  }, {
    d: "M18.5141 5.5427L32.3417 0.467544H17.446L12.6172 2.22078L13.6213 0.430634H5.18533L0.618774 10.6117H9.04197L9.84783 8.76005L10.7816 8.60625L16.8192 10.624H33.9278L18.5141 5.5427Z",
    props: {
      fill: "#e5002b"
    }
  }, {
    d: "M35.968 4.90292C35.9424 4.86601 35.9041 4.84756 35.8593 4.84756H34.9511L35.1941 4.30006C35.2133 4.25699 35.2069 4.20778 35.1749 4.17087C35.1494 4.14626 35.1174 4.12781 35.079 4.12781C35.0662 4.12781 35.0534 4.12781 35.047 4.13396L32.7638 4.73683C32.2969 4.70607 31.8875 4.8045 31.7212 4.84756H27.8966C27.7815 4.82295 27.5384 4.77989 27.2122 4.77989C26.5855 4.77989 26.0482 4.93368 25.6581 5.14899C25.5302 4.97059 25.1912 4.81065 24.6667 4.81065C24.3534 4.81065 23.9632 4.85986 23.5283 5.0075C23.4004 4.92138 23.1573 4.81065 22.7288 4.81065C22.6073 4.81065 22.4346 4.82295 22.2747 4.84756H20.5287L19.8955 5.12438L19.1856 6.68077C19.1664 6.71768 19.1728 6.76074 19.1984 6.79765C19.224 6.83456 19.2623 6.85301 19.3071 6.85301H31.0881C31.1392 6.85301 31.184 6.82226 31.2032 6.77919C31.2032 6.77919 31.4462 6.22554 31.4846 6.14557C31.5166 6.0779 31.5358 6.07175 31.8492 6.01638C31.9259 6.00408 32.0218 5.99793 32.137 5.99793C32.073 6.18248 32.0922 6.37933 32.1881 6.53313C32.3097 6.72998 32.5335 6.84686 32.7829 6.84686H35.0854C35.1366 6.84686 35.1813 6.8161 35.2005 6.77304L35.9872 5.01365C36 4.9829 35.9936 4.93983 35.968 4.90292Z",
    props: {
      fill: "white"
    }
  }, {
    d: "M25.3766 5.66573C25.3766 5.66573 25.5301 5.19205 24.6795 5.19205C23.8864 5.19205 23.5347 5.40121 23.4259 5.44427C23.4259 5.44427 23.33 5.19205 22.7416 5.19205C21.9485 5.19205 21.7311 5.37045 21.6223 5.41351L21.7119 5.22281H20.2792L19.7036 6.47775H21.1427L21.456 5.78876C21.5264 5.7334 21.6223 5.67188 21.8014 5.67188C21.9869 5.67188 22.0253 5.72725 22.0061 5.77031L21.6863 6.47775H23.0806L23.394 5.78876C23.4643 5.7334 23.5602 5.67188 23.7457 5.67188C23.9248 5.67188 23.9632 5.72725 23.9504 5.77031L23.6306 6.47775H25.0057L25.3766 5.66573Z",
    props: {
      fill: "#0069b4"
    }
  }, {
    d: "M26.9692 5.84414C26.95 5.92411 27.1419 5.97332 27.4233 5.97332C27.7239 5.97332 27.9221 5.8749 27.9413 5.79492C27.9605 5.71495 27.7878 5.66574 27.4936 5.66574C27.2058 5.66574 26.982 5.76417 26.9692 5.84414ZM27.1802 5.16745C27.4233 5.16745 27.9669 5.22282 28.1716 5.3397L28.2419 5.21666H29.6938L29.1245 6.47161H27.6727L27.7558 6.29321C27.6151 6.36703 27.2378 6.50237 26.7453 6.50237C26.2528 6.50237 25.4022 6.41625 25.4406 5.96102C25.4726 5.50579 26.2337 5.16745 27.1802 5.16745Z",
    props: {
      fill: "#0069b4"
    }
  }, {
    d: "M32.4759 5.63498C31.6252 5.58577 31.2159 5.78262 31.1328 5.97333L30.9089 6.47162H29.4507L30.0135 5.21667H31.4781L31.4142 5.3397C31.8747 5.1613 32.4119 5.08748 32.7253 5.10594L32.4759 5.63498Z",
    props: {
      fill: "#0069b4"
    }
  }, {
    d: "M34.9062 6.47161L35.1173 5.99793H34.0236L34.1899 5.62268H35.2836L35.4691 5.21666H34.369L34.6312 4.6261L32.9619 5.05672L32.5078 6.07175C32.4055 6.28706 32.5462 6.47161 32.7829 6.47161H34.9062Z",
    props: {
      fill: "#0069b4"
    }
  }]
});