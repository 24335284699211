import React, { useEffect, useState } from "react";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoSSR = function NoSSR(_ref) {
  var children = _ref.children;

  var _useState = useState(false),
      shouldRender = _useState[0],
      setShouldRender = _useState[1];

  useEffect(function () {
    setShouldRender(true);
  }, []);
  return shouldRender ? ___EmotionJSX(React.Fragment, null, children) : null;
};
NoSSR.displayName = "NoSSR";
export default NoSSR;