export var mockCartContext = {
  orderSummary: {
    itemSummary: {
      items: [{
        id: "4d3f0574-1fa9-4492-b492-40d70647b297",
        offer: {
          variant: {
            product: {
              id: "343977"
            }
          }
        }
      }, {
        id: "878ceea2-1207-4f3d-abfc-414a6a3ce0e7",
        offer: {
          variant: {
            product: {
              id: "377362"
            }
          }
        }
      }]
    },
    totalPrice: {
      amount: 4692
    }
  }
};